import { useQuery } from 'react-query';
import API from '../../config/axios';
import { QUERY_KEY } from '../../constans/query-keys';

const onGetDailyVisitor = async () => {
  const { data } = await API.get('/getDailyVisitor');
  return data;
};

const useGetDailyVisitor = () => {
  return useQuery([QUERY_KEY.GET_DAILY_VISITOR], onGetDailyVisitor);
};

export default useGetDailyVisitor;
