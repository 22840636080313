import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import { API_URL } from '../../BaseUrlApi/Url';
import Navbar from '../Navbar/Navbar';
import './PatientMeasure.css';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};
class PatientMeasure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '',
      isLoaded: false,
      measurementId: null,
      body_weight: '',
      body_length: '',
      head_circumference: '',
      temperature: '',
      queueId: null,
      startDate: new Date(),
      date_taken: null,
      loading_button: false,
      physicalMeasurement: [],
      excludeDate: [],
    };
  }

  async componentDidMount() {
    const { tab, queueId, childId } = queryString.parse(
      this.props.location.search
    );
    this.setState({ isLoaded: true });

    try {
      let getQueue = await axios.get(`${API_URL}/nurse/queues/${queueId}`, {
        headers,
      });

      await this.setState({
        tab,
        queueId,
        childId,
        physicalMeasurement: getQueue.data.queue_info.physical_measurements,
      });
      const { data: dataDates } = await axios.get(`${API_URL}/blocked_dates`, {
        headers,
      });

      //parse date to format string long to ISO
      let filterBlockedDates = await dataDates.blockedDates.map((item) =>
        Date.parse(new Date(item.date).toUTCString())
      );

      const { data: dataDatesKonsulOnline } = await axios.get(
        `${API_URL}/blocked_dates?shift_type=konsultasi_online`,
        {
          headers,
        }
      );

      let filterBlockedDatesKonsultasiOnline =
        await dataDatesKonsulOnline.blockedDates.map((item) =>
          Date.parse(new Date(item.date).toUTCString())
        );

      if (this.state.physicalMeasurement.length > 0) {
        this.getPhysicalMeasurementDatas();
      }
      this.setState({
        excludeDate: [
          ...filterBlockedDatesKonsultasiOnline,
          ...filterBlockedDates,
        ],
      });
      this.setState({ isLoaded: false });
    } catch (e) {
      swal({
        title: 'GAGAL',
        text: 'Gagal Mengambil Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  }

  getPhysicalMeasurementDatas = async (e) => {
    this.setState({
      date_taken: Date.parse(
        new Date(this.state.physicalMeasurement[0].date_taken).toUTCString()
      ),
      body_weight: this.state.physicalMeasurement[0].body_weight,
      body_length: this.state.physicalMeasurement[0].body_length,
      head_circumference: this.state.physicalMeasurement[0].head_circumference,
      temperature: this.state.physicalMeasurement[0].temperature,
    });
  };

  inputHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // selectedDate = (value) => {
  //   if (this.state.date_taken === null) {
  //     return "";
  //   } else if (this.state.date_taken !== null) {
  //     return new Date(this.state.date_taken);
  //   }
  // };

  // dateHandler = async (date) => {
  //   await this.setState({
  //     startDate: date,
  //   });
  //   if (this.state.startDate !== null) {
  //     this.dateConverter(this.state.startDate);
  //   }
  // };

  // dateConverter = async (value) => {
  //   let date = value,
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   let convertDate = [date.getFullYear(), mnth, day].join("-");
  //   await this.setState({
  //     date_taken: convertDate,
  //   });
  // };

  saveMeasurement = async () => {
    this.setState({ loading_button: true });
    let {
      physicalMeasurement,
      childId,
      body_weight,
      body_length,
      head_circumference,
      date_taken,
      temperature,
      tab,
      queueId,
    } = this.state;

    // UPDATE DATA
    if (physicalMeasurement.length > 0) {
      let data = {
        _method: 'PATCH',
        child_id: parseInt(childId),
        body_weight: parseFloat(body_weight),
        body_length: parseFloat(body_length),
        head_circumference: parseFloat(head_circumference),
        date_taken: moment(date_taken).format('YYYY-MM-DD'),
        temperature: parseFloat(temperature),
        nurse_id: parseInt(localStorage.getItem('nurse_id')),
      };

      axios
        .post(
          `${API_URL}/nurse/physical_measurements/${physicalMeasurement[0].id}`,
          data,
          { headers }
        )
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Data Pasien Telah Berhasil Ditambahkan!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.history.back();
              // window.location.href = `/patient-detail?tab=${tab}&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          this.setState({ loading_button: false });
          swal({
            title: 'GAGAL',
            text: 'Lengkapi semua data terlebih dahulu',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });
    } else {
      this.setState({ loading_button: true });
      // POST FIRST DATA
      let data = {
        child_id: parseInt(childId),
        body_weight: parseFloat(body_weight),
        body_length: parseFloat(body_length),
        head_circumference: parseFloat(head_circumference),
        date_taken: moment(date_taken).format('YYYY-MM-DD'),
        temperature: parseFloat(temperature),
        nurse_id: parseInt(localStorage.getItem('nurse_id')),
        queue_id: queueId,
      };
      console.log(data);
      axios
        .post(`${API_URL}/nurse/physical_measurements`, data, { headers })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Data Pasien Telah Berhasil Ditambahkan!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.history.back();
              // window.location.href = `/patient-detail?tab=${tab}&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          this.setState({ loading_button: false });
          swal({
            title: 'GAGAL',
            text: 'Gagal Menyimpan Data',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });
    }
  };

  render() {
    let {
      tab,
      isLoaded,
      loading_button,
      queueId,
      body_length,
      body_weight,
      head_circumference,
      temperature,
      date_taken,
      excludeDate,
    } = this.state;

    const isWeekday = (date) => {
      // console.log(date)
      const day = date.getDay();
      return day !== 0;
    };

    return (
      <div className="nurse-overlay">
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <Navbar
            navtitle="Ukur Pasien"
            url={`/patient-detail?tab=${tab}&queueId=${queueId}`}
          />

          <div className="measure-body">
            <div className="merk-vacine">
              <DatePicker
                selected={date_taken}
                onChange={(e) => {
                  console.log(e);
                  this.setState({ date_taken: e });
                }}
                excludeDates={excludeDate}
                filterDate={isWeekday}
                // minDate={new Date()}
                dateFormat="dd MMMM, yyyy"
                placeholderText="Tanggal Pengambilan Data"
              />
            </div>
            <input
              type="text"
              name="body_weight"
              value={body_weight}
              placeholder="Berat Badan"
              className="measure-input"
              onChange={this.inputHandler}
            />
            <input
              type="text"
              name="body_length"
              value={body_length}
              placeholder="Panjang Badan"
              className="measure-input"
              onChange={this.inputHandler}
            />
            <input
              type="text"
              name="head_circumference"
              value={head_circumference}
              placeholder="Lingkaran Kepala"
              className="measure-input"
              onChange={this.inputHandler}
            />
            <input
              type="text"
              name="temperature"
              value={temperature}
              placeholder="Temperature"
              className="measure-input"
              onChange={this.inputHandler}
            />

            <button
              className="btn btn-primary measure-button"
              onClick={this.saveMeasurement}
            >
              {loading_button ? (
                <div>
                  <FontAwesomeIcon icon={faSpinner} spin /> Loading
                </div>
              ) : (
                'SIMPAN'
              )}
            </button>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default PatientMeasure;
