import React, { Component } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import AdminSidebar from "../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../BaseUrlApi/Url";
import "./Context.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

const { SearchBar } = Search;
class Context extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isOpen: false,
      products: [],
      contextId: null,
      title: "",
      body: "",
      attributes: {},
      columns: [
        {
          dataField: "title",
          text: "Judul Pesan",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
        },
        {
          dataField: "body",
          text: "Isi Pesan",
          headerStyle: { textAlign: "center", width: "40rem" },
          style: { textAlign: "center" },
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          formatter: (cell, row, rowIndex, formatExtraData) => {
            return (
              <div className="table-buttons">
                <button
                  className="btn btn-primary patient-change"
                  onClick={() => {
                    this.showModal(row.id);
                  }}
                >
                  Ubah
                </button>
              </div>
            );
          },
        },
      ],
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let getContext = await axios.get(`${API_URL}/admin/notification_contexts`, {
      headers,
    });
    this.setState({
      products: getContext.data.notificationContexts,
    });
    this.setState({ isLoaded: false });
  }

  showModal = async (value) => {
    this.setState({ isOpen: !this.state.isOpen });
    let getContext = await axios.get(
      `${API_URL}/admin/notification_contexts/${value}`,
      { headers }
    );

    let obj = getContext.data.notificationContext.attributes;
    let parse_obj = JSON.parse(obj);

    this.setState({
      contextId: value,
      title: getContext.data.notificationContext.title,
      body: getContext.data.notificationContext.body,
      attributes: parse_obj,
    });

    if (parse_obj !== null) {
      this.getAttributeText();
    }
  };

  hideModal = async (e) => {
    this.setState({ isOpen: false });
  };

  inputHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  postMessage = async (e, formData, inputs) => {
    e.preventDefault();
    let message = {
      title: this.state.title,
      body: this.state.body,
    };
    axios
      .post(`${API_URL}/admin/broadcast_messages`, message, { headers })
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Pesan Berhasil Ditambahkan!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = "/broadcast";
          } else {
            return;
          }
        });
      });
  };

  getAttributeText = () => {
    let text = document.createElement("p");
    let main = document.getElementById("textNote");
    Object.keys(this.state.attributes).forEach((key, index) => {
      text.innerHTML += `#${key}# : ${this.state.attributes[key]} </br>`;
    });
    main.appendChild(text);
  };

  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan {from} / {to} dari {size} Hasil
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "Semua",
          value: this.state.products.length,
        },
      ],
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <ToolkitProvider
              keyField="id"
              columns={this.state.columns}
              data={this.state.products}
              search
            >
              {(toolkitprops) => (
                <div>
                  <SearchBar
                    {...toolkitprops.searchProps}
                    className="search-content"
                  />
                  <BootstrapTable
                    bordered={false}
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    );
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Konteks</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Notifikasi</span>
                <span style={{ color: "#00000029" }}> / Konteks</span>
              </h2>
            </div>
            <PaginationProvider pagination={paginationFactory(options)}>
              {contentTable}
            </PaginationProvider>
            <Modal show={this.state.isOpen} onHide={this.hideModal}>
              <Modal.Header>
                <Modal.Title>
                  <h1 className="note-child-title">Buat Pesan</h1>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ValidationForm
                  onSubmit={this.postMessage.bind(this)}
                  ref={this.formRef}
                  immediate={this.state.immediate}
                  setFocusOnError={this.state.setFocusOnError}
                >
                  <table cellPadding="10" className="add-table">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Judul Pesan
                          </label>
                        </td>
                        <td>
                          
                          :
                          <TextInput
                            type="text"
                            name="title"
                            value={this.state.title}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Isi Pesan
                          </label>
                          <span className="colon-style">:</span>
                        </td>
                        <td>
                          <textarea
                            type="text"
                            name="body"
                            value={this.state.body}
                            onChange={this.inputHandler}
                            className="add-broadcast"
                            required
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ValidationForm>
              </Modal.Body>
              <Modal.Footer>
                <div className="context-note">
                  <div id="textNote">Keterangan :</div>
                </div>
                <div className="patient-buttons">
                  <button
                    className="btn btn-primary"
                    onClick={this.postMessage}
                  >
                    Simpan
                  </button>
                  <button
                    className="btn btn-danger mr-1"
                    onClick={this.hideModal}
                  >
                    Batal
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default Context;
