import { useMutation } from 'react-query';
import API from '../../config/axios';

const onAddVaccineType = async ({ vaccineTypeId, ...payload }) => {
  const pathAdd = '/vaccine_types';
  const pathEdit = `/vaccine_types/${vaccineTypeId}`;
  const url = !!vaccineTypeId ? pathEdit : pathAdd;
  const { data } = await API.post(url, payload);
  return data;
};

const useAddVaccineType = () => {
  return useMutation((payload) => onAddVaccineType(payload));
};

export default useAddVaccineType;
