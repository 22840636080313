import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { API_URL } from "../../../BaseUrlApi/Url";
import AdminSidebar from "../../AdminSidebar/AdminSidebar";
// import "./BlockedDates.css";

const { SearchBar } = Search;

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

function buttonsFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="detail-buttons">
      <button
        onClick={() => {
          swal({
            title: "Yakin Ingin Menghapus?",
            icon: "warning",
            buttons: ["Batal", "Hapus"],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/bank_accounts/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = `/bank-accounts`;
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger patient-delete"
        style={{margin: '.5em'}}
      >
        Hapus
      </button>{' '}
      <Link to={`/update-bank-account/${row.id}`}>
        <button style={{margin: '.5em'}} className="btn btn-primary patient-change">Ubah</button>
      </Link>
    </div>
  );
}

class BankAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      banks: [],
      columns: [
        {
          dataField: "bank.name",
          text: "Nama Bank",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
        },
        {
          dataField: "bank.code",
          text: "Kode Bank",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
        },
        {
            dataField: "number",
            text: "No. Rekening",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "name",
            text: "Nama",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          headerStyle: { textAlign: "center" },
          style: { margin: "auto" },
          formatter: buttonsFormatter,
        },
      ],
    };
  }
  async componentDidMount() {
      try {
        this.setState({ isLoaded: true });
        const {data} = await axios.get(`${API_URL}/admin/bank_accounts`, {
          headers,
        });
        this.setState({
          banks: data.bank_accounts,
        });
        this.setState({ isLoaded: false });
      } catch(e) {
        this.setState({ isLoaded: false });
        console.log(e.response.data)
        swal({
            title: `${e.response.data.message}`,
            icon: "warning",
            buttons: "OK",
            dangerMode: true,
          })
      }
    
  }
  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan {from} / {to} dari {size} Hasil
      </span>
    );
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "All",
          value: this.state.banks.length,
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <ToolkitProvider
              keyField="id"
              columns={this.state.columns}
              data={this.state.banks}
              search={{ searchFormatted: true }}
            >
              {(toolkitprops) => (
                <div>
                  <br />
                  <SearchBar
                    {...toolkitprops.searchProps}
                    className="search-content"
                  />
                  <BootstrapTable
                    bordered={false}
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    );
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section">
              <h1 className="patient-page-title">Rekening Bank</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Master Data</span>
                <span style={{ color: "#00000029" }}> / Rekening Bank</span>
              </h2>
              <div className="add-button">
                <Link to="/add-bank-account">
                  <button className="btn btn-primary btn-add-table mb-2 mr-3">
                    Tambah Rekening Bank
                  </button>
                </Link>
              </div>
            </div>
            <PaginationProvider pagination={paginationFactory(options)}>
              {contentTable}
            </PaginationProvider>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default BankAccounts;
