import { useEffect, useState } from 'react';
import { onGetDetailChild } from '../services/getDetailChild';

export const useDetailChild = (id) => {
  const [isLoading, setIsLoading] = useState(false);
  const [childDetail, setChildDetail] = useState(null);
  const [vaccinationHistory, setVaccinationHistory] = useState([]);

  const onGetDetail = async (id) => {
    setIsLoading(true);
    const responseChildDetail = await onGetDetailChild(id).finally(() =>
      setIsLoading(false)
    );

    const temDataHistoryVaccine =
      responseChildDetail?.data?.vaccinationsByType?.map((item, index) => ({
        ...item,
        no: index + 1,
        key: index + 1,
      }));
    setVaccinationHistory(temDataHistoryVaccine);
    setChildDetail(responseChildDetail);
  };

  useEffect(() => {
    onGetDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    isLoading,
    dataDetail: childDetail?.data || undefined,
    childDetail,
    vaccinationHistory,
    error: childDetail?.error || false,
  };
};
