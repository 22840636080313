export const STATUS_CERTIFICATE = {
  SENT: 'sent',
  REQUESTED: 'requested',
  REJECTED: 'rejected',
  WAITING_FOR_SIGNING: 'waiting_for_signing',
};

export const FILTER_CERTIFICATE_BY_STATUS = [
  {
    text: 'Sent',
    value: STATUS_CERTIFICATE.SENT,
  },
  {
    text: 'Requested',
    value: STATUS_CERTIFICATE.REQUESTED,
  },
  {
    text: 'Rejected',
    value: STATUS_CERTIFICATE.REJECTED,
  },
  {
    text: 'Waiting For Signing',
    value: STATUS_CERTIFICATE.WAITING_FOR_SIGNING,
  },
];
