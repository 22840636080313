import moment from 'moment';
import { useEffect, useState } from 'react';
import { onGetDetailRequestCertificate } from '../services/getDetailRequestCertificate';
import { onFormatPatientAge } from '../../../../utils/ageFormat';

export const useDetailRequestCertificate = (id) => {
  const [isLoading, setIsLoading] = useState(false);
  const [requestDetail, setRequestDetail] = useState(null);
  const [finishedVaccinations, setFinishedVaccinations] = useState(null);
  const [childDetail, setChildDetail] = useState(null);
  const [parentsDetail, setParentsDetail] = useState(null);
  const [refetch, setRefetch] = useState(0);
  const onSetRefetch = () => setRefetch((prev) => prev + 1);

  const onGetDetail = async (id) => {
    setIsLoading(true);
    const getDetailCertificate = await onGetDetailRequestCertificate(
      id
    ).finally(() => setIsLoading(false));
    const { data } = getDetailCertificate;
    const { vaccination_certificate_request, finished_vaccinations } = data;

    const patientAgeInYear = vaccination_certificate_request?.child?.age;
    const patientAgeInMonth =
      vaccination_certificate_request?.child?.age_in_month;

    const tempChildDetail = [
      {
        label: 'Email',
        value: vaccination_certificate_request?.child?.email || '-',
      },
      {
        label: 'Nama Anak',
        value: vaccination_certificate_request?.child?.fullname || '-',
      },
      {
        label: 'Tempat Tanggal Lahir',
        value: `${vaccination_certificate_request?.child?.birthplace || '-'}, ${
          moment(vaccination_certificate_request?.child?.birthdate).format(
            'DD/MM/YYYY'
          ) || '-'
        }`,
      },
      {
        label: 'Jenis Kelamin',
        value:
          vaccination_certificate_request?.child?.gender === 'male'
            ? 'Laki - Laki'
            : 'Perempuan',
      },
      {
        label: 'Umur',
        value: onFormatPatientAge(patientAgeInYear, patientAgeInMonth),
      },
      {
        label: 'Tanggal Request',
        value:
          moment(vaccination_certificate_request?.created_at).format(
            'DD/MM/YY'
          ) || '-',
      },
      {
        label: 'Vaksin Terakhir',
        value: vaccination_certificate_request?.child?.last_vaccination_date
          ? moment(
              vaccination_certificate_request?.child?.last_vaccination_date
            ).format('DD/MM/YY')
          : '-',
      },
      {
        label: 'Tujuan Penggunaan Sertifikat',
        value: vaccination_certificate_request?.reason_for_request || '-',
      },
      {
        label: 'Alamat',
        value: vaccination_certificate_request?.child?.parents?.address || '-',
      },
      {
        label: 'Status',
        value: vaccination_certificate_request?.status || '-',
      },
    ];

    const tempParentDetail = [
      {
        label: 'Nama Ayah',
        value:
          vaccination_certificate_request?.child?.parents?.father_name || '-',
      },
      {
        label: 'Nama Ibu',
        value:
          vaccination_certificate_request?.child?.parents?.mother_name || '-',
      },
      {
        label: 'Pekerjaan Ayah',
        value: '-',
      },
      {
        label: 'Pekerjaan Ibu',
        value: '-',
      },
      {
        label: 'No. Telp Ayah',
        value:
          vaccination_certificate_request?.child?.parents?.phone_number || '-',
      },
      {
        label: 'No. Telp Ibu',
        value:
          vaccination_certificate_request?.child?.parents?.phone_number || '-',
      },
      {
        label: 'Alamat',
        value: vaccination_certificate_request?.child?.parents?.address || '-',
      },
    ];

    setFinishedVaccinations(finished_vaccinations);
    setParentsDetail(tempParentDetail);
    setChildDetail(tempChildDetail);
    setRequestDetail(getDetailCertificate);
  };

  useEffect(() => {
    onGetDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refetch]);

  return {
    isLoading,
    dataDetail: requestDetail?.data || undefined,
    finishedVaccinations,
    childDetail,
    parentsDetail,
    refetch: onSetRefetch,
    error: requestDetail?.error || false,
  };
};
