import React, { Component } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import swal from "sweetalert";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { API_URL } from "../../../BaseUrlApi/Url";
import AdminSidebar from "../../AdminSidebar/AdminSidebar";
import "./Visits.css";

const { SearchBar } = Search;
const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

function dateFormatter(cell, row, rowIndex, formatExtraData) {
  let date = new Date(row.date_plan);
  let dd = date.getDate();
  let mm = date.getMonth() + 1; //January is 0!

  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  let set_date = dd + "-" + mm + "-" + yyyy;
  return set_date;
}

function scheduleFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="table-buttons">
      <button
        onClick={() => {
          swal({
            title: "Yakin Ingin Menghapus?",
            icon: "warning",
            buttons: ["Batal", "Hapus"],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/visits/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = "/visits";
                });
            } else {
              return;
            }
          });
        }}
        style={{margin: '0.5em'}}
        className="btn btn-danger patient-delete"
      >
        Hapus
      </button>
      <Link to={`/update-visit/${row.id}`}>
        <button style={{margin: '0.5em'}} className="btn btn-primary patient-change">Ubah</button>
      </Link>
    </div>
  );
}

function ticketFormatter(cell, row, rowIndex, formatExtraData) {
  if (row.queue !== null) {
    return <p className="text-success">Sudah Ada Tiket</p>
  } else {
    return <p className="text-danger">Belum Ada Tiket</p>;
  }
}

function shiftFormatter(cell,row){
  if(cell !== undefined){
    return cell
  }else{
    return '-'
  }
}

class Visits extends Component {
  state = {
    isLoaded: false,
    startDate: new Date(),
    date: null,
    products: [],
    columns: [
      {
        dataField: "date_plan",
        text: "Jadwal Kunjungan Akan Datang",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        formatter: dateFormatter,
      },
      {
        dataField: "child.fullname",
        text: "Nama",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
      },

      {
        dataField: "visit_type.name",
        text: "Jenis Kontrol",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
      },
      {
        dataField: "queue",
        text: "Tiket",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        formatter: ticketFormatter,
      },
      {
        dataField: 'queue.shift.name',
        text: 'Shift',
        headerStyle:{textAlign:'center'},
        style:{textAlign:'center'},
        formatter: shiftFormatter
      },
      {
        dataField: "Aksi",
        text: "Aksi",
        headerStyle: { textAlign: "center" },
        style: { textAlign: "center" },
        formatter: scheduleFormatter,
      },
    ],
  };

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let getChildren = await axios.get(
      `${API_URL}/admin/visits/upcomingVisits`,
      { headers }
    );
    this.setState({
      products: getChildren.data.visits,
    });
    this.setState({ isLoaded: false });
  }
  dateHandler = async (date) => {
    this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateConverter(this.state.startDate);
    }
  };

  dateConverter = async (value) => {
    let date = value,
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join("-");
    this.setState({
      date: convertDate,
    });
    let getVisits = await axios.get(
      `${API_URL}/admin/visits?date_plan=${convertDate}`,
      { headers }
    );
    this.setState({
      products: getVisits.data.visits,
    });
  };
  selectedDate = (value) => {
    if (this.state.date === null) {
      return "";
    } else {
      return new Date(this.state.date);
    }
  };

  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan {from} / {to} dari {size} Hasil
      </span>
    );
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "Semua",
          value: this.state.products.length,
        },
      ],
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <ToolkitProvider
              keyField="id"
              columns={this.state.columns}
              data={this.state.products}
              search={{ searchFormatted: true }}
            >
              {(toolkitprops) => (
                <div>
                  <SearchBar
                    {...toolkitprops.searchProps}
                    className="search-content"
                  />
                  <BootstrapTable
                    bordered={false}
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    );
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">
                Manajemen Atur Jadwal Kunjungan
              </h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>
                  Manajemen Atur Jadwal Kunjungan
                </span>
                <span style={{ color: "#00000029" }}>
                  
                  / Daftar Jadwal Kunjungan
                </span>
              </h2>
              <Link to="/visit">
                <div className="add-button">
                  <button className="btn btn-primary btn-add-table mr-3 mb-3">
                    Tambah Jadwal Kunjungan
                  </button>
                </div>
              </Link>
            </div>
            <PaginationProvider pagination={paginationFactory(options)}>
              {contentTable}
            </PaginationProvider>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default Visits;
