import { InfoCircleTwoTone } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import React from 'react';

const { Title, Text } = Typography;

const ModalDelete = ({ isOpen, onClose, onSubmit, isLoading }) => {
  return (
    <Modal
      title={
        <Title level={4} style={{ margin: 0, textAlign: 'center' }}>
          Hapus Antrian
        </Title>
      }
      open={isOpen}
      onCancel={onClose}
      okText="Hapus"
      cancelText="Batal"
      okButtonProps={{
        loading: isLoading,
        size: 'large',
        danger: true,
      }}
      cancelButtonProps={{
        size: 'large',
      }}
      onOk={onSubmit}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 16,
        }}
      >
        <InfoCircleTwoTone twoToneColor="#FF5858" style={{ fontSize: 100 }} />
        <Text style={{ fontSize: 16 }}>
          Apakah Anda Yakin Ingin Menghapus Antrian ?
        </Text>
      </div>
    </Modal>
  );
};

export default ModalDelete;
