import React, { Component } from 'react';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from 'react-bootstrap-table-next';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import LoadingOverlay from 'react-loading-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { API_URL } from '../../../BaseUrlApi/Url';
import './UpcomingSchedule.css';
import DynamicVaccine from '../../../CustomJS/DynamicVaccine';
import API from '../../../../config/axios';
import _ from 'lodash';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

function vaccinationFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="detail-buttons">
      <button
        onClick={() => {
          swal({
            title: 'Yakin Ingin Menghapus?',
            icon: 'warning',
            buttons: ['Batal', 'Hapus'],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/vaccinations/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = `/upcoming-schedule/${row.child_id}`;
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger"
      >
        Hapus
      </button>
    </div>
  );
}

function visitFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="detail-buttons">
      <button
        onClick={() => {
          swal({
            title: 'Yakin Ingin Menghapus?',
            icon: 'warning',
            buttons: ['Batal', 'Hapus'],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/visits/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = `/upcoming-schedule/${row.child_id}`;
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger"
      >
        Hapus
      </button>
    </div>
  );
}

function dateFormatter(cell, row, rowIndex, formatExtraData) {
  let date = new Date(row.date_plan);
  let dd = date.getDate();
  let mm = date.getMonth() + 1; //January is 0!

  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  let set_date = dd + '-' + mm + '-' + yyyy;
  return set_date;
}

class UpcomingSchedule extends Component {
  state = {
    openVaccination: false,
    openVisit: false,
    openMeasurement: false,
    isLoaded: false,
    childId: null,
    parentId: null,
    fullname: null,
    gender: null,
    warning: '',
    choose_gender: '',
    parent_name: '',
    birthdate: '',
    birthplace: '',
    age: '',
    date_taken: '',
    image_path: null,
    image: '',

    // FORM
    immediate: true,
    setFocusOnError: true,
    clearInputOnReset: false,
    vaccine_type: {},
    vaccine_types: [],
    nextVaccinationPeriod: null,
    vaccination_type_ids: [],
    dynamic_vaccine: [
      // {
      //   id: 1,
      //   vaccine_selected: {
      //     value: 1,
      //     label: ''
      //   },
      //   period: 1
      // }
    ],
    lastChoice: 0,
    shifts: [],
    shift: {},
    period: null,
    products: [],
    vaccination_date_taken: null,

    // VISIT FORM
    visit_types: [],
    visit_type: {},
    visit_date_taken: null,
    startDate: new Date(),

    // MEASUREMENT FORM

    measurement_date_taken: null,
    vaccination_products: [],
    vaccination_columns: [
      {
        dataField: 'date_plan',
        text: 'Jadwal',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: dateFormatter,
      },
      {
        dataField: 'vaccine_types',
        text: 'Tipe Vaksin - Periode',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      // {
      //   dataField: "period",
      //   text: "Periode",
      //   headerStyle: { textAlign: "center" },
      //   style: { textAlign: "center" },
      // },
      {
        dataField: 'Aksi',
        text: 'Aksi',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: vaccinationFormatter,
      },
    ],
    visit_products: [],
    visit_columns: [
      {
        dataField: 'date_plan',
        text: 'Jadwal',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: dateFormatter,
      },
      {
        dataField: 'visit_type.name',
        text: 'Tipe Kunjungan',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      {
        dataField: 'Aksi',
        text: 'Aksi',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: visitFormatter,
      },
    ],
  };

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let childId = parseInt(this.props.match.params.value);

    // GET CHILD DATA
    let getData = await axios.get(`${API_URL}/admin/children/${childId}`, {
      headers,
    });
    this.setState({
      fullname: getData.data.child.fullname,
      gender: getData.data.child.gender,
      birthplace: getData.data.child.birthplace,
      birthdate: getData.data.child.birthdate,
      childId: childId,
      image_path: getData.data.child.image_url,
      age: getData.data.child.age,
      parentId: parseInt(getData.data.child.parent_id),
    });
    let getShifts = await axios.get(`${API_URL}/admin/shifts`, { headers });
    let shifts = getShifts.data.shifts.map((type) => ({
      value: type.id,
      label: type.name,
    }));
    let getUpcomingVaccinations = await axios.get(
      `${API_URL}/admin/vaccinations/upcomingVaccinations?child_id=${childId}`,
      { headers }
    );
    let getUpcomingVisits = await axios.get(
      `${API_URL}/admin/visits/upcomingVisits?child_id=${childId}`,
      { headers }
    );
    let filterVaccinationData = getUpcomingVaccinations.data.vaccinations.map(
      (items) => {
        return {
          ...items,
          vaccine_types: items.vaccine_types
            .map(
              (itemx, i) =>
                `(${i + 1}). ${itemx.name} - Periode: Ke-${itemx.pivot.period}`
            )
            .join('; '),
        };
      }
    );
    this.setState({
      vaccination_products: filterVaccinationData,
      visit_products: getUpcomingVisits.data.visits,
      shifts: shifts,
    });
    this.setState({ isLoaded: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState) === JSON.stringify(this.state)) return null;
    const { childId, vaccination_type_ids } = this.state;

    if (childId && !!vaccination_type_ids.length) {
      API.get('/vaccinations/next-vaccination-period', {
        params: {
          child_id: childId,
          vaccine_type_ids: vaccination_type_ids.join(',').toString(),
        },
      })
        .then((res) => {
          const tempGeneratePeriodVaccine = _.map(
            this.state?.dynamic_vaccine,
            (user) => {
              const vaccineId = user.vaccine_selected.value;
              const matchedVaccine = _.find(res?.data?.vaccine_types, {
                id: vaccineId,
              });
              if (matchedVaccine) {
                return _.assign({}, user, {
                  period: matchedVaccine.next_vaccination_period,
                });
              }
              return user;
            }
          );

          this.setState({
            dynamic_vaccine: [...tempGeneratePeriodVaccine],
          });
        })
        .catch((err) => console.log(err));
    }
  }

  // === CREATE UPCOMING VACCINATION ===
  showVaccination = async (e) => {
    let getVaccineTypes = await axios.get(`${API_URL}/admin/vaccine_types`, {
      headers,
    });
    let vaccine_types = getVaccineTypes.data.data.map((type) => ({
      value: type.id,
      label: type.name,
    }));

    const newVaccine = [
      {
        id: this.state.lastChoice + 1,
        vaccine_selected: null,
        period: '',
      },
    ];

    this.setState({
      vaccine_types: vaccine_types,
      openVaccination: !this.state.openVaccination,
      lastChoice: this.state.lastChoice + 1,
      dynamic_vaccine: newVaccine,
    });
  };

  hideVaccination = (e) => {
    this.setState({
      openVaccination: false,
      lastChoice: 0,
      dynamic_vaccine: [],
    });
  };

  vaccineChangeHandler = (vaccine_id, newVaccineSelected, newPeriod) => {
    this.setState({
      vaccination_type_ids: [newVaccineSelected?.value],
    });
    const newVaccine = {
      id: vaccine_id,
      vaccine_selected: {
        value: newVaccineSelected.value,
        label: newVaccineSelected.label,
      },
      period: newPeriod,
    };

    const oldVaccine = this.state.dynamic_vaccine.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    let newVaccines = [...oldVaccine, newVaccine];
    newVaccines.sort((a, b) => a.id - b.id);
    this.setState({
      dynamic_vaccine: newVaccines,
    });
  };

  vaccineRemoveHandler = (vaccine_id) => {
    const vaccinesValue = [...this.state.dynamic_vaccine];
    const newVaccines = vaccinesValue.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    this.setState({
      dynamic_vaccine: newVaccines,
    });
  };

  vaccineAddHandler = () => {
    let objValue = this.state.dynamic_vaccine.find(
      (c) => c.period === '' || c.vaccine_selected === null
    );
    if (objValue !== undefined) {
      return;
    }

    const newVaccine = {
      id: this.state.lastChoice + 1,
      vaccine_selected: null,
      period: '',
    };
    this.setState({
      lastChoice: this.state.lastChoice + 1,
      dynamic_vaccine: [...this.state.dynamic_vaccine, newVaccine],
    });
  };

  inputHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  selectedVaccinationDate = (value) => {
    if (this.state.vaccination_date_taken === null) {
      return '';
    } else {
      return new Date(this.state.vaccination_date_taken);
    }
  };

  dateVaccinationHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateVaccinationConverter(this.state.startDate);
    }
  };

  dateVaccinationConverter = (value) => {
    let date = value,
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join('-');
    this.setState({
      vaccination_date_taken: convertDate,
    });
  };

  dateConverter(value) {
    let date = new Date(value);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_date = dd + '-' + mm + '-' + yyyy;
    return set_date;
  }

  vaccineTypeHandler = async (value) => {
    await this.setState({
      vaccine_type: {
        value: value.value,
        label: value.label,
      },
    });
  };

  shiftHandler = async (value) => {
    await this.setState({
      shift: {
        value: value.value,
        label: value.label,
      },
    });
  };
  saveVaccination = async (e) => {
    e.preventDefault();
    const postVaccination = {
      child_id: this.state.childId,
      vaccine_types: this.state.dynamic_vaccine.map((item) => {
        return {
          id: item.vaccine_selected.value,
          period: item.period,
        };
      }),
      // vaccine_type_id: this.state.vaccine_type.value,
      shift_id: this.state.shift.value,
      // period: parseInt(this.state.period),
      date_plan: this.state.vaccination_date_taken,
    };

    axios
      .post(`${API_URL}/admin/vaccinations`, postVaccination, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Jadwal Vaksinasi Berhasil Ditambahkan!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/upcoming-schedule/${this.state.childId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        if (err.response.data.message === 'The given data was invalid.') {
          swal({
            title: 'GAGAL',
            text: ' Harap Lengkapi Data',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        } else {
          swal({
            title: 'GAGAL',
            text: 'Anak Telah Memiliki Tiket, Batalkan Tiket Terlebih Dahulu',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        }
      });
  };

  // === CREATE VACCINATION HANDLER END ===

  // CREATE VISIT HANDLER START
  showVisit = async (e) => {
    let getVisitTypes = await axios.get(`${API_URL}/admin/visit_types`, {
      headers,
    });
    let visit_types = getVisitTypes.data.visitTypes.map((type) => ({
      value: type.id,
      label: type.name,
    }));
    this.setState({
      visit_types: visit_types,
      openVisit: !this.state.openVisit,
    });
  };

  hideVisit = (e) => {
    this.setState({ openVisit: false });
  };

  selectedVisitDate = (value) => {
    if (this.state.visit_date_taken === null) {
      return '';
    } else {
      return new Date(this.state.visit_date_taken);
    }
  };

  dateVisitHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateVisitConverter(this.state.startDate);
    }
  };

  dateVisitConverter = (value) => {
    let date = value,
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join('-');
    this.setState({
      visit_date_taken: convertDate,
    });
  };

  visitTypeHandler = async (value) => {
    await this.setState({
      visit_type: {
        value: value.value,
        label: value.label,
      },
    });
  };

  saveVisit = async (e) => {
    e.preventDefault();
    const postVisit = {
      child_id: this.state.childId,
      visit_type_id: this.state.visit_type.value,
      date_plan: this.state.visit_date_taken,
      shift_id: this.state.shift.value,
    };

    axios
      .post(`${API_URL}/admin/visits`, postVisit, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Jadwal Kunjungan Berhasil Ditambahkan!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/upcoming-schedule/${this.state.childId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        if (err.response.data.message === 'The given data was invalid.') {
          swal({
            title: 'GAGAL',
            text: ' Harap Lengkapi Data',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        } else {
          swal({
            title: 'GAGAL',
            text: 'Anak Telah Memiliki Tiket, Batalkan Tiket Terlebih Dahulu',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        }
      });
  };

  // CREATE VISIT HANDLER END

  render() {
    let {
      immediate,
      setFocusOnError,
      parentId,
      isLoaded,
      fullname,
      gender,
      birthdate,
      birthplace,
      age,
      vaccination_products,
      vaccination_columns,
      visit_products,
      visit_columns,
      image_path,

      // VACCINATION
      openVaccination,
      vaccine_types,
      vaccine_type,
      dynamic_vaccine,
      shifts,
      shift,

      // VISIT
      openVisit,
      visit_types,
      visit_type,
    } = this.state;

    return (
      <div>
        <AdminSidebar />
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <div className="content-section">
            <div className="title-section">
              <h1 className="patient-page-title">Jadwal Mendatang</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: '#039BEB' }}>Manajemen Anak</span>
                <span style={{ color: '#039BEB' }}>
                  / Daftar Kepala Keluarga
                </span>
                <span style={{ color: '#039BEB' }}>
                  / Detail Kepala Keluarga
                </span>
                <span style={{ color: '#00000029' }}> / Jadwal Mendatang</span>
              </h2>
            </div>

            <div className="card shadow mb-4 mt-5 text-left">
              <div className="card-body">
                <table cellPadding="10" className="parent-data mb-5">
                  <tbody>
                    <tr>
                      <td>Nama</td>
                      <td>: {fullname}</td>
                    </tr>
                    <tr>
                      <td>Umur</td>
                      <td>: {age}</td>
                    </tr>
                    <tr>
                      <td>Jenis Kelamin</td>
                      <td>
                        :
                        {gender !== null ? (
                          gender === 'male' ? (
                            ' Laki-laki'
                          ) : (
                            ' Perempuan'
                          )
                        ) : (
                          <span className="text-danger">Tidak Terisi</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Tanggal Lahir</td>
                      <td>: {this.dateConverter(birthdate)}</td>
                    </tr>
                    <tr>
                      <td>Tempat Lahir</td>
                      <td> : {birthplace !== null ? birthplace : '-'}</td>
                    </tr>
                  </tbody>
                </table>

                {image_path !== null ? (
                  <div className="image-container">
                    <img src={image_path} className="patient-photo" alt="img" />
                  </div>
                ) : (
                  <div className="no-photo">
                    <p className="no-photo-text">Belum Ada Foto</p>
                  </div>
                )}

                <div className="mb-5">
                  <p className="family-members">
                    Jadwal Vaksinasi {vaccination_products.length > 0 ? '' : ''}
                  </p>
                  <button
                    className="btn btn-primary btn-checkup"
                    onClick={this.showVaccination}
                  >
                    Tambahkan Jadwal Vaksinasi
                  </button>
                  <BootstrapTable
                    bordered={false}
                    keyField="id"
                    data={vaccination_products}
                    columns={vaccination_columns}
                    pagination={paginationFactory()}
                  />
                </div>

                <div className="mb-5">
                  <p className="family-members">
                    Jadwal Kunjungan {visit_products.length > 0 ? '' : ''}
                  </p>
                  <button
                    className="btn btn-primary btn-checkup"
                    onClick={this.showVisit}
                  >
                    Tambahkan Jadwal Kunjungan
                  </button>
                  <BootstrapTable
                    bordered={false}
                    keyField="id"
                    data={visit_products}
                    columns={visit_columns}
                    pagination={paginationFactory()}
                  />
                </div>

                <div className="patient-buttons">
                  <Link to={`/detail-patient/${parentId}`}>
                    <button
                      className="btn btn-secondary back-button"
                      style={{ color: 'white' }}
                    >
                      Kembali
                    </button>
                  </Link>
                </div>
                {/* VACCINATION MODAL SECTION */}
                <Modal show={openVaccination} onHide={this.hideVaccination}>
                  <Modal.Header>
                    <Modal.Title>
                      <h1 className="note-child-title">
                        BUAT JADWAL VAKSINASI
                      </h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ValidationForm
                      onSubmit={this.saveVaccination.bind(this)}
                      ref={this.formRef}
                      immediate={immediate}
                      setFocusOnError={setFocusOnError}
                    >
                      <table cellPadding="10" className="add-table">
                        <tbody>
                          <tr>
                            <td
                              style={{ verticalAlign: 'baseline' }}
                              className="label-class"
                            >
                              <label htmlFor="" className="add-vaccine-label">
                                Vaksin dan Periode
                              </label>
                              <br />
                              <button
                                className="btn btn-success mr-1"
                                onClick={() => {
                                  this.vaccineAddHandler();
                                }}
                              >
                                Tambah Data
                              </button>
                            </td>
                            <td>
                              {dynamic_vaccine.map((vaccine, i) => (
                                <DynamicVaccine
                                  vaccineTypes={vaccine_types}
                                  dynamic_vaccine={dynamic_vaccine}
                                  key={i}
                                  vaccine_id={vaccine.id}
                                  vaccine_selected={vaccine.vaccine_selected}
                                  period={vaccine.period}
                                  vaccineChangeHandler={
                                    this.vaccineChangeHandler
                                  }
                                  vaccineRemoveHandler={
                                    this.vaccineRemoveHandler
                                  }
                                />
                              ))}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label htmlFor="">Tanggal Rencana</label>
                            </td>
                            <td>
                              :
                              <DatePicker
                                selected={this.selectedVaccinationDate()}
                                onChange={this.dateVaccinationHandler}
                                dateFormat="dd-MM-yyyy"
                                className="add-fam-date"
                                required
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label htmlFor="">Shift</label>
                            </td>
                            <td>
                              :
                              <Select
                                options={shifts}
                                value={shift}
                                onChange={(value) => this.shiftHandler(value)}
                                required
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ValidationForm>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="patient-buttons">
                      <button
                        className="btn btn-primary mr-1"
                        onClick={this.saveVaccination}
                      >
                        Simpan
                      </button>
                      <button
                        className="btn btn-danger mr-1"
                        onClick={this.hideVaccination}
                      >
                        Batal
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
                {/* VISIT MODAL SECTION */}
                <Modal show={openVisit} onHide={this.hideVisit}>
                  <Modal.Header>
                    <Modal.Title>
                      <h1 className="note-child-title">BUAT JADWAL KUNJUGAN</h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ValidationForm
                      onSubmit={this.saveVisit.bind(this)}
                      ref={this.formRef}
                      immediate={immediate}
                      setFocusOnError={setFocusOnError}
                    >
                      <table cellPadding="10" className="add-table">
                        <tbody>
                          <tr>
                            <td>
                              <label htmlFor="">Jenis Kunjungan</label>
                            </td>
                            <td>
                              :
                              <Select
                                options={visit_types}
                                value={visit_type}
                                onChange={(value) =>
                                  this.visitTypeHandler(value)
                                }
                                required
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label htmlFor="">Tanggal Rencana</label>
                            </td>
                            <td>
                              :
                              <DatePicker
                                selected={this.selectedVisitDate()}
                                onChange={this.dateVisitHandler}
                                dateFormat="dd-MM-yyyy"
                                className="add-fam-date"
                                required
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label htmlFor="">Shift</label>
                            </td>
                            <td>
                              :
                              <Select
                                options={shifts}
                                value={shift}
                                onChange={(value) => this.shiftHandler(value)}
                                required
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ValidationForm>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="patient-buttons">
                      <button
                        className="btn btn-primary mr-1"
                        onClick={this.saveVisit}
                      >
                        Simpan
                      </button>
                      <button
                        className="btn btn-danger mr-1"
                        onClick={this.hideVisit}
                      >
                        Batal
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default UpcomingSchedule;
