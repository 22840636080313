import React, { Component } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';

import 'react-datepicker/dist/react-datepicker.css';
import { API_URL } from '../../../BaseUrlApi/Url';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import './UpdateVisit.css';
import moment from 'moment';
import { TYPE_MANAGEMENT_QUEUE } from '../../../../constans';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};
class UpdateVisit extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      isLoaded: false,
      showOptions: false,
      // MODAL STATE
      isFamilyOpen: false,
      isChildOpen: false,
      excludeDate: [],

      visitId: null,
      visit_type: {},
      visit_types: [],
      fathers: [],
      father: {},
      mothers: [],
      mother: {},
      children: [],
      child: {},
      shifts: [],
      shiftIds: [],
      shift: {},
      filter_date: null,
      plan_schedule: null,
      startDate: new Date(),
      period: null,

      // EDIT FAMILY
      edit_father_name: '',
      edit_mother_name: '',
      edit_phone_number: '',
      edit_address: '',
      jobs: [],
      father_job: {},
      mother_job: {},
      father_job_id: null,
      mother_job_id: null,
      provinces: [],
      province: {},
      cities: [],
      city: {},
      districts: [],
      district: {},
      edit_province_id: null,
      edit_city_id: null,
      edit_district_id: null,
      isFree: null,
      free: [],

      // CHILD DATA
      child_fullname: null,
      child_birthdate: null,
      child_birthplace: '',
      choose_gender: {},
      gender: [],
      bef_gender: '',
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let visitId = this.props.match.params.value;

    // GET VISIT INFO
    let getVisit = await axios.get(`${API_URL}/admin/visits/${visitId}`, {
      headers,
    });

    console.log(getVisit);
    // GET ALL VISIT TYPES
    let getVisitTypes = await axios.get(`${API_URL}/admin/visit_types`, {
      headers,
    });
    let visitTypes = getVisitTypes.data.visitTypes.map((type) => ({
      value: type.id,
      label: type.name,
    }));

    // GET SHIFTS FOR SELECTOR
    let getShifts = await axios.get(`${API_URL}/admin/shifts`, { headers });
    let shifts = getShifts.data.shifts.map((shift) => ({
      value: shift.id,
      label:
        shift.name + ' (' + shift.start_time + ' - ' + shift.end_time + ') ',
    }));
    const filteredShifts = getShifts?.data?.shifts
      ?.filter(
        (item) => item?.type === TYPE_MANAGEMENT_QUEUE?.KONSULTASI_ONLINE
      )
      ?.map((shift) => shift?.id);

    this.setState({
      shiftIds: filteredShifts,
      visit_types: visitTypes,
      shifts: shifts,
      plan_schedule: Date.parse(
        new Date(getVisit.data.visit.date_plan).toUTCString()
      ),
      visitId: visitId,
      visit_type: {
        value: getVisit.data.visit.visit_type.id,
        label: getVisit.data.visit.visit_type.name,
      },
    });

    if (getVisit.data.visit.child !== null) {
      let getParent = await axios.get(
        `${API_URL}/admin/parents/${getVisit.data.visit.child.parent_id}`,
        { headers }
      );
      this.setState({
        child: {
          value: getVisit.data.visit.child.id,
          label: getVisit.data.visit.child.fullname,
        },
        father: {
          value: getParent.data.parent.id,
          label: getParent.data.parent.father_name,
        },
        mother: {
          value: getParent.data.parent.id,
          label: getParent.data.parent.mother_name,
        },
        filter_date: getVisit.data.visit.child.birthdate,
      });
    }

    if (getVisit.data.queue !== null) {
      this.setState({
        shift: {
          value: getVisit.data.visit.queue.shift.id,
          label: getVisit.data.visit.queue.shift.name,
        },
      });
    }

    const { data: dataDates } = await axios.get(
      `${API_URL}/admin/blocked_dates`,
      {
        headers,
      }
    );

    //parse date to format string long to ISO
    let filterBlockedDates = await dataDates.blockedDates.map((item) =>
      Date.parse(new Date(item.date).toUTCString())
    );

    const { data: dataDatesKonsulOnline } = await axios.get(
      `${API_URL}/blocked_dates?shift_type=konsultasi_online`,
      {
        headers,
      }
    );

    let filterBlockedDatesKonsultasiOnline =
      await dataDatesKonsulOnline.blockedDates.map((item) =>
        Date.parse(new Date(item.date).toUTCString())
      );

    this.getIsFree();
    this.setState({
      isLoaded: false,
      excludeDate: [
        ...filterBlockedDatesKonsultasiOnline,
        ...filterBlockedDates,
      ],
    });
  }

  getIsFree = async () => {
    let free = [
      {
        label: 'Tidak Gratis',
        value: 0,
      },
      {
        label: 'Gratis',
        value: 1,
      },
    ];
    this.setState({
      free: free,
    });
  };

  onInputChange = (typedOption) => {
    if (typedOption.length >= 4) {
      this.setState({
        showOptions: true,
      });
      this.parentsData(typedOption);
    } else {
      this.setState({
        showOptions: false,
      });
    }
  };

  parentsData = async (keyword) => {
    this.setState({ isLoaded: true });
    let getParents = await axios.get(
      `${API_URL}/admin/parents/search?keyword=${keyword}`,
      { headers }
    );
    let fathers = getParents.data.parents.map((father) => ({
      value: father.id,
      label: father.father_name,
    }));
    let mothers = getParents.data.parents.map((mother) => ({
      value: mother.id,
      label: mother.mother_name,
    }));

    this.setState({
      fathers: fathers,
      mothers: mothers,
      isLoaded: false,
    });
  };

  fatherHandler = async (value) => {
    this.setState({
      father: {
        value: value.value,
        label: value.label,
      },
      child: {},
    });
    this.getChildren(parseInt(value.value));
    this.getMother(parseInt(value.value));
  };

  // MOTHER HANDLER IF VALUE CHANGED
  motherHandler = async (value) => {
    this.setState({
      mother: {
        value: value.value,
        label: value.label,
      },
      child: {},
    });
    this.getChildren(parseInt(value.value));
    this.getFather(parseInt(value.value));
  };

  childHandler = async (value) => {
    let getChildren = await axios.get(
      `${API_URL}/admin/children/${value.value}`,
      { headers }
    );
    this.getMother(getChildren.data.child.parent_id);
    this.getFather(getChildren.data.child.parent_id);
    this.setState({
      child: {
        value: value.value,
        label: value.label,
      },
      filter_date: getChildren.data.child.birthdate,
    });
  };

  // SHIFT HANDLER IF VALUE CHANGED
  shiftHandler = async (value) => {
    this.setState({
      shift: {
        value: value.value,
        label: value.label,
      },
    });
  };

  visitHandler = async (value) => {
    this.setState({
      visit_type: {
        value: value.value,
        label: value.label,
      },
    });
  };
  // GET FATHER WHEN CHILD OR MOTHER IS SELECTED
  getFather = async (value) => {
    this.setState({ isLoaded: true });
    let getParents = await axios.get(`${API_URL}/admin/parents/${value}`, {
      headers,
    });
    this.setState({
      father: {
        value: getParents.data.parent.id,
        label: getParents.data.parent.father_name,
      },
      address: getParents.data.parent.address,
      isLoaded: false,
    });
  };

  // GET MOTHER WHEN CHILD OR FATHER IS SELECTED
  getMother = async (value) => {
    this.setState({ isLoaded: true });
    let getParents = await axios.get(`${API_URL}/admin/parents/${value}`, {
      headers,
    });
    this.setState({
      mother: {
        value: getParents.data.parent.id,
        label: getParents.data.parent.mother_name,
      },
      isLoaded: false,
    });
  };

  // GET CHILDREN WHEN MOTHER OR FATHER IS SELECTED
  getChildren = async (value) => {
    this.setState({ isLoaded: true });
    let getChildren = await axios.get(
      `${API_URL}/admin/children?parent_id=${value}`,
      { headers }
    );
    let children = getChildren.data.children.map((child) => ({
      value: child.id,
      label: child.fullname,
    }));
    await this.setState({
      children: children,
      isLoaded: false,
    });
  };

  // === START GET CHILD BY FILTER DATE ===
  selectedFilterDate = (value) => {
    if (this.state.filter_date === null) {
      return '';
    } else {
      return new Date(this.state.filter_date);
    }
  };

  dateFilterHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateFilterConverter(this.state.startDate);
    }
  };

  dateFilterConverter = async (value) => {
    let date = value,
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join('-');
    this.setState({
      filter_date: convertDate,
    });
    this.setState({ isLoaded: true });
    let getChildren = await axios.get(
      `${API_URL}/admin/children?birthdate=${convertDate}`,
      { headers }
    );
    let children = getChildren.data.children.map((child) => ({
      value: child.id,
      label: child.fullname,
    }));

    if (children.length === 1) {
      this.childHandler(children[0]);
    }
    await this.setState({
      children: children,
      isLoaded: false,
    });
  };

  // BIRTHDATE FILTER HANDLER END

  // selectedPlanDate = (value) => {
  //   if (this.state.plan_schedule === null) {
  //     return "";
  //   } else {
  //     return new Date(this.state.plan_schedule);
  //   }
  // };

  // datePlanHandler = async (date) => {
  //   await this.setState({
  //     startDate: date,
  //   });
  //   if (this.state.startDate !== null) {
  //     this.datePlanConverter(this.state.startDate);
  //   }
  // };

  datePlanConverter = (value) => {
    let date = value,
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join('-');
    this.setState({
      plan_schedule: convertDate,
    });
  };

  clearForm = () => {
    this.setState({
      visit_type: {},
      father: {},
      mother: {},
      child: {},
      shift: {},
      filter_date: null,
      schedule: null,
      plan_schedule: null,
      period: null,
    });
  };

  saveSchedule = async (e) => {
    e.preventDefault();
    const postSchedule = {
      _method: 'PATCH',
      child_id: this.state.child.value,
      visit_type_id: this.state.visit_type.value,
      date_plan: moment(this.state.plan_schedule).format('YYYY-MM-DD'),
      shift_id: this.state.shift.value,
    };

    axios
      .post(`${API_URL}/admin/visits/${this.state.visitId}`, postSchedule, {
        headers,
      })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Jadwal Kunjungan Telah Berhasil Diperbarui!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/visits`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Lengkapi semua data terlebih dahulu',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };

  // === MODALS FOR FAMILY EDIT STARTS ===

  showFamilyEdit = async (e) => {
    this.setState({ isLoaded: true });
    let getParent = await axios.get(
      `${API_URL}/admin/parents/${this.state.father.value}`,
      { headers }
    );
    await this.setState({
      parentId: getParent.data.parent.id,
      isFree: getParent.data.parent.is_free,
      edit_father_name: getParent.data.parent.father_name,
      edit_mother_name: getParent.data.parent.mother_name,
      edit_address: getParent.data.parent.address,
      edit_phone_number: getParent.data.parent.phone_number,
      father_job_id: getParent.data.parent.father_job_id,
      mother_job_id: getParent.data.parent.mother_job_id,
      edit_province_id: getParent.data.parent.province_id,
      edit_city_id: getParent.data.parent.city_id,
      edit_district_id: getParent.data.parent.district_id,
      isFamilyOpen: !this.state.isFamilyOpen,
    });
    this.getJobs();
    this.getProvinces();
    this.getCity();
    this.getDistrict();
    this.setState({ isLoaded: false });
  };

  hideFamilyEdit = async (e) => {
    this.setState({ isFamilyOpen: false });
  };

  isFreeHandler = async (e) => {
    await this.setState({
      isFree: parseInt(e.target.value),
    });
  };

  inputFamilyEditHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  fatherJobHandler = async (value) => {
    this.setState({
      father_job: {
        value: value.value,
        label: value.label,
      },
    });
  };

  motherJobHandler = async (value) => {
    this.setState({
      mother_job: {
        value: value.value,
        label: value.label,
      },
    });
  };

  // GET PARENT JOB
  getJobs = async () => {
    let getJobs = await axios.get(`${API_URL}/admin/jobs`, { headers });
    let jobs = getJobs.data.jobs.map((job) => ({
      value: job.id,
      label: job.name,
    }));
    await this.setState({
      jobs: jobs,
    });

    let { father_job_id, mother_job_id } = this.state;
    let getFatherJob = await axios.get(`${API_URL}/jobs/${father_job_id}`);
    let getMotherJob = await axios.get(`${API_URL}/jobs/${mother_job_id}`);
    this.setState({
      father_job: {
        value: getFatherJob.data.job.id,
        label: getFatherJob.data.job.name,
      },
      mother_job: {
        value: getMotherJob.data.job.id,
        label: getMotherJob.data.job.name,
      },
    });
  };

  provinceHandler = async (value) => {
    let getCities = await axios.get(
      `${API_URL}/regions/provinces?id=${value.value}`
    );
    let cities = getCities.data.provinces.cities.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    this.setState({
      province: {
        value: value.value,
        label: value.label,
      },
      cities: cities,
    });
  };

  cityHandler = async (value) => {
    let getDistricts = await axios.get(
      `${API_URL}/regions/cities?id=${value.value}`,
      {
        headers,
      }
    );
    let districts = getDistricts.data.cities.districts.map((district) => ({
      value: district.id,
      label: district.name,
    }));

    this.setState({
      city: {
        value: value.value,
        label: value.label,
      },
      districts: districts,
    });
  };

  districtHandler = async (value) => {
    this.setState({
      district: {
        value: value.value,
        label: value.label,
      },
    });
  };

  // GET PROVINCE
  getProvinces = async () => {
    let getProvinces = await axios.get(`${API_URL}/regions/provinces`);
    let provinces = getProvinces.data.provinces.map((province) => ({
      value: province.id,
      label: province.name,
    }));
    this.setState({
      provinces: provinces,
    });

    let { edit_province_id } = this.state;
    if (edit_province_id !== null) {
      let getProvince = await axios.get(
        `${API_URL}/regions/provinces?id=${edit_province_id}`
      );
      this.setState({
        province: {
          value: getProvince.data.provinces.id,
          label: getProvince.data.provinces.name,
        },
      });
    }
  };

  // GET CITY
  getCity = async () => {
    let { edit_city_id } = this.state;
    if (edit_city_id !== null) {
      let getCity = await axios.get(
        `${API_URL}/regions/cities?id=${edit_city_id}`
      );
      this.setState({
        city: {
          value: getCity.data.cities.id,
          label: getCity.data.cities.name,
        },
      });
    }
  };

  // GET PARENT DISTRICT
  getDistrict = async () => {
    let { edit_district_id } = this.state;
    if (edit_district_id !== null) {
      let getDistrict = await axios.get(
        `${API_URL}/regions/districts?id=${edit_district_id}`,
        { headers }
      );
      this.setState({
        district: {
          value: getDistrict.data.districts.id,
          label: getDistrict.data.districts.name,
        },
      });
    }
  };

  updateFamilyData = async (e) => {
    e.preventDefault();
    const updateFamilyData = {
      _method: 'PATCH',
      father_name: this.state.edit_father_name,
      mother_name: this.state.edit_mother_name,
      is_free: this.state.isFree,
      father_job_id: this.state.father_job.value,
      mother_job_id: this.state.mother_job.value,
      address: this.state.edit_address,
      phone_number: this.state.edit_phone_number,
      province_id: this.state.province.value,
      city_id: this.state.city.value,
      district_id: this.state.district.value,
    };

    axios
      .post(
        `${API_URL}/admin/parents/${this.state.parentId}`,
        updateFamilyData,
        { headers }
      )
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Data Kepala Keluarga Telah Berhasil Diperbarui!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/queues/${this.state.shiftId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Lengkapi semua data terlebih dahulu',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };

  // === MODALS FOR FAMILY EDIT END ===

  // === MODALS FOR CHILD EDIT STARTS ===
  showChildEdit = async (e) => {
    let getChildData = await axios.get(
      `${API_URL}/admin/children/${this.state.child.value}`,
      { headers }
    );
    this.setState({
      childId: getChildData.data.child.id,
      child_fullname: getChildData.data.child.fullname,
      bef_gender: getChildData.data.child.gender,
      child_birthdate: getChildData.data.child.birthdate,
      child_birthplace: getChildData.data.child.birthplace,
      parentId: getChildData.data.child.parentId,
      isChildOpen: !this.state.isChildOpen,
    });
    this.genderController();
  };

  hideChildEdit = async (e) => {
    this.setState({ isChildOpen: false });
  };

  genderController = async () => {
    let gender = [
      {
        gender: 'male',
        label: 'Laki-laki',
      },
      {
        gender: 'female',
        label: 'Perempuan',
      },
    ];

    let getGender = gender.map((type) => ({
      value: type.gender,
      label: type.label,
    }));
    await this.setState({
      gender: getGender,
    });

    if (this.state.bef_gender === 'male') {
      this.setState({
        choose_gender: {
          value: 'male',
          label: 'Laki-laki',
        },
      });
    } else {
      this.setState({
        choose_gender: {
          value: 'female',
          label: 'Perempuan',
        },
      });
    }
  };

  genderHandler = async (value) => {
    this.setState({
      choose_gender: {
        value: value.value,
        label: value.label,
      },
    });
  };

  birthplaceHandler = async (e) => {
    await this.setState({
      child_birthplace: e.target.value,
    });
  };

  childSelectedDate = (value) => {
    if (this.state.child_birthdate === null) {
      return '';
    } else {
      return new Date(this.state.child_birthdate);
    }
  };

  childDateHandler = async (date) => {
    await this.setState({
      child_birthdate: date,
    });
    if (this.state.child_birthdate !== null) {
      this.childDateConverter(this.state.child_birthdate);
    }
  };

  childDateConverter = (value) => {
    let date = value,
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join('-');
    this.setState({
      child_birthdate: convertDate,
    });
  };

  updateChildData = async (e) => {
    e.preventDefault();
    const updateChildData = {
      _method: 'PATCH',
      parentId: this.state.parentId,
      fullname: this.state.child_fullname,
      birthdate: this.state.child_birthdate,
      birthplace: this.state.child_birthplace,
      gender: this.state.choose_gender.value,
    };

    axios
      .post(
        `${API_URL}/admin/children/${this.state.childId}`,
        updateChildData,
        { headers }
      )
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Data Anak Telah Berhasil Diubah!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/update-visit/${this.state.visitId}`;
          } else {
            return;
          }
        });
      });
  };
  // === MODALS FOR CHILD EDIT END ===

  render() {
    let {
      immediate,
      setFocusOnError,
      isChildOpen,
      isFamilyOpen,
      showOptions,
      fathers,
      father,
      mothers,
      mother,
      children,
      child,
      visit_types,
      visit_type,
      shifts,
      shiftIds,
      shift,
      excludeDate,
      plan_schedule,
      // FAMILY EDIT
      edit_father_name,
      edit_mother_name,
      edit_phone_number,
      edit_address,
      jobs,
      father_job,
      mother_job,
      provinces,
      province,
      cities,
      city,
      districts,
      district,
      isFree,
      free,

      // CHILD DATA
      child_fullname,
      gender,
      choose_gender,
      child_birthplace,
    } = this.state;
    const isWeekday = (date) => {
      // console.log(date)
      const day = date.getDay();
      return day !== 0;
    };
    return (
      <div className="admin-overlay">
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <AdminSidebar />
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Ubah Jadwal Kunjungan</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: '#039BEB' }}>
                  Manajemen Atur Jadwal Kunjungan
                </span>
                <span style={{ color: '#039BEB' }}>
                  / Daftar Jadwal Kunjungan
                </span>
                <span style={{ color: '#00000029' }}>
                  / Ubah Jadwal Kunjungan
                </span>
              </h2>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <ValidationForm
                  onSubmit={this.saveSchedule.bind(this)}
                  ref={this.formRef}
                  immediate={this.state.immediate}
                  setFocusOnError={this.state.setFocusOnError}
                >
                  <table cellPadding="10" className="add-table text-left mb-5">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="">Nama Ayah</label>
                        </td>
                        <td>
                          :
                          <Select
                            options={showOptions ? fathers : []}
                            value={father}
                            onChange={(value) => this.fatherHandler(value)}
                            onInputChange={this.onInputChange}
                            required
                          />
                          {father.value !== undefined ? (
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={this.showFamilyEdit}
                              className="queue-edit"
                            />
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="">Nama Ibu</label>
                        </td>
                        <td>
                          :
                          <Select
                            options={showOptions ? mothers : []}
                            value={mother}
                            onChange={(value) => this.motherHandler(value)}
                            onInputChange={this.onInputChange}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label-class">
                          <label htmlFor="">Nama Anak</label>
                        </td>
                        <td>
                          :
                          <Select
                            options={children}
                            value={child}
                            onChange={(value) => this.childHandler(value)}
                            required
                          />
                          {child.value !== undefined ? (
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={this.showChildEdit}
                              className="queue-edit"
                            />
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="">Tanggal Lahir</label>
                        </td>
                        <td>
                          :
                          <DatePicker
                            selected={this.selectedFilterDate()}
                            onChange={this.dateFilterHandler}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="dd-MM-yyyy"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label-class">
                          <label htmlFor="">Jenis Kunjungan</label>
                        </td>
                        <td>
                          :
                          <Select
                            options={visit_types}
                            value={visit_type}
                            onChange={(value) => this.visitHandler(value)}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="">Shift</label>
                        </td>
                        <td>
                          :
                          <Select
                            options={shifts}
                            value={shift}
                            onChange={(value) => this.shiftHandler(value)}
                            required
                          />
                        </td>
                      </tr>
                      {JSON.stringify(shift)}
                      <tr>
                        <td>
                          <label htmlFor="">
                            Tanggal Pemeriksaan Selanjutnya
                          </label>
                        </td>
                        <td>
                          :
                          <DatePicker
                            selected={plan_schedule}
                            className="date-picker-custom"
                            onChange={(e) => {
                              console.log(e);
                              this.setState({ plan_schedule: e });
                            }}
                            excludeDates={excludeDate}
                            filterDate={
                              !shiftIds?.includes(shift?.value)
                                ? isWeekday
                                : null
                            }
                            minDate={new Date()}
                            dateFormat="dd MMMM, yyyy"
                            placeholderText="Pilih Tanggal Pemeriksaan"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ValidationForm>
                <div className="patient-buttons">
                  <button
                    className="btn btn-primary"
                    onClick={this.saveSchedule}
                  >
                    Simpan
                  </button>
                  <Link to="/visits">
                    <button className="btn btn-danger mr-1">Batal</button>
                  </Link>
                  <button
                    className="btn btn-warning text-dark mr-1"
                    onClick={this.clearForm}
                  >
                    Clear
                  </button>
                </div>
              </div>

              {/* SHOW MODAL FAMILY EDIT*/}
              <Modal
                show={isFamilyOpen}
                onHide={this.hideFamilyEdit}
                className="family-modals"
              >
                <Modal.Header>
                  <Modal.Title>
                    <h1 className="note-child-title">Edit Data Keluarga</h1>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ValidationForm
                    onSubmit={this.updateFamilyData.bind(this)}
                    ref={this.formRef}
                    immediate={immediate}
                    setFocusOnError={setFocusOnError}
                  >
                    <table cellPadding="10" className="add-table mb-5">
                      <tbody>
                        <tr>
                          <td className="label-class">
                            <label htmlFor="">Nama Ayah</label>
                          </td>
                          <td>
                            :
                            <TextInput
                              type="text"
                              name="edit_father_name"
                              value={edit_father_name}
                              onChange={this.inputHandler}
                              className="add-vaccine-input"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label-class">
                            <label htmlFor="">Nama Ibu</label>
                          </td>
                          <td>
                            :
                            <TextInput
                              type="text"
                              name="edit_mother_name"
                              value={edit_mother_name}
                              onChange={this.inputHandler}
                              className="add-vaccine-input"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label-class">
                            <label htmlFor="">Pekerjaan Ayah</label>
                          </td>
                          <td>
                            :
                            <Select
                              options={jobs}
                              name="father_job"
                              value={father_job}
                              onChange={(value) => this.fatherJobHandler(value)}
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label-class">
                            <label htmlFor="">Pekerjaan Ibu</label>
                          </td>
                          <td>
                            :
                            <Select
                              options={jobs}
                              name="mother_job"
                              value={mother_job}
                              onChange={(value) => this.motherJobHandler(value)}
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label-class">
                            <label htmlFor="">Status Digratiskan</label>
                          </td>
                          <td>
                            :
                            <div className="radio-style">
                              {free.map((res) => (
                                <label className="container">
                                  <input
                                    type="radio"
                                    name="radio"
                                    id={res.label}
                                    value={res.value}
                                    onChange={this.isFreeHandler}
                                    checked={
                                      isFree === res.value ? 'checked' : ''
                                    }
                                  />
                                  <span className="checkmark"></span>
                                  {res.label}
                                </label>
                              ))}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="label-class">
                            <label htmlFor="" className="add-vaccine-label">
                              Provinsi
                            </label>
                          </td>
                          <td>
                            :
                            <Select
                              options={provinces}
                              value={province}
                              onChange={(value) => this.provinceHandler(value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label-class">
                            <label htmlFor="" className="add-vaccine-label">
                              Kota/Kabupaten
                            </label>
                          </td>
                          <td>
                            :
                            <Select
                              options={cities}
                              value={city}
                              onChange={(value) => this.cityHandler(value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label-class">
                            <label htmlFor="">Kecamatan</label>
                          </td>
                          <td>
                            :
                            <Select
                              options={districts}
                              value={district}
                              onChange={(value) => this.districtHandler(value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="">Alamat</label>
                          </td>
                          <td>
                            :
                            <TextInput
                              type="text"
                              name="edit_address"
                              value={edit_address}
                              onChange={this.inputHandler}
                              className="add-vaccine-input"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="">No.Telp</label>
                          </td>
                          <td>
                            :
                            <TextInput
                              type="text"
                              name="edit_phone_number"
                              value={edit_phone_number}
                              onChange={this.inputHandler}
                              className="add-vaccine-input"
                              required
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ValidationForm>
                </Modal.Body>
                <Modal.Footer>
                  <div className="patient-buttons">
                    <button
                      className="btn btn-primary "
                      onClick={this.updateFamilyData}
                    >
                      Simpan
                    </button>
                    <button
                      className="btn btn-danger mr-1"
                      onClick={this.hideFamilyEdit}
                    >
                      Batal
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>

              {/* SHOW MODAL CHILD EDIT */}
              <Modal show={isChildOpen} onHide={this.hideChildEdit}>
                <Modal.Header>
                  <Modal.Title>
                    <h1 className="note-child-title">Edit Data Anak</h1>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ValidationForm
                    onSubmit={this.updateChildData.bind(this)}
                    ref={this.formRef}
                    immediate={immediate}
                    setFocusOnError={setFocusOnError}
                  >
                    <table cellPadding="10" className="add-table mb-5">
                      <tbody>
                        <tr>
                          <td>
                            <label htmlFor="">Nama</label>
                          </td>
                          <td>
                            :
                            <TextInput
                              type="text"
                              name="child_fullname"
                              value={child_fullname}
                              onChange={this.inputHandler}
                              className="add-vaccine-input"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label-class">
                            <label htmlFor="">Gender</label>
                          </td>
                          <td>
                            :
                            <Select
                              options={gender}
                              value={choose_gender}
                              onChange={(value) => this.genderHandler(value)}
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="">Tanggal Lahir</label>
                          </td>
                          <td>
                            :
                            <DatePicker
                              selected={this.childSelectedDate()}
                              onChange={this.childDateHandler}
                              dateFormat="dd-MM-yyyy"
                              className="add-fam-date"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="">Tempat Lahir</label>
                          </td>
                          <td>
                            :
                            <TextInput
                              type="text"
                              name="child_birthplace"
                              value={child_birthplace}
                              onChange={this.inputHandler}
                              className="add-vaccine-input"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ValidationForm>
                </Modal.Body>
                <Modal.Footer>
                  <div className="patient-buttons">
                    <button
                      className="btn btn-primary "
                      onClick={this.updateChildData}
                    >
                      Simpan
                    </button>
                    <button
                      className="btn btn-danger mr-1"
                      onClick={this.hideChildEdit}
                    >
                      Batal
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default UpdateVisit;
