import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import Login from './Components/LoginPage/LoginPage';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import AdminDashboard from './Components/Admin/AdminDashboard/AdminDashboard';
import { QueryClient, QueryClientProvider } from 'react-query';

class Index extends React.Component {
  render() {
    if (window.location.pathname === '/') {
      window.location.href = '/login';
    }

    const queryClient = new QueryClient();

    return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route
              path="/reset-password/token=:token"
              component={ResetPassword}
            />
            <Route path="/admin-dashboard" component={AdminDashboard} />
            <Route path="/" component={App} />
          </Switch>
        </Router>
      </QueryClientProvider>
    );
  }
}

ReactDOM.render(<Index />, document.getElementById('root'));
serviceWorker.unregister();
