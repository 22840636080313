import React, { Component } from 'react';
import swal from 'sweetalert';
import Select from 'react-select';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingOverlay from 'react-loading-overlay';
import { API_URL } from '../../BaseUrlApi/Url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../Navbar/Navbar';
import './UpdateVisit.css';
import axios from 'axios';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};
class UpdateVisit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      status: undefined,
      childId: null,
      visitId: null,
      nurseId: null,
      queueId: null,
      visit_type_id: null,
      visit_types: [],
      visit_type: {},
      date_taken: null,
      date_plan: null,
      loading_button: false,
      startDate: new Date(),
      excludeDate: [],
    };
  }
  async componentDidMount() {
    let nurseId = localStorage.getItem('nurse_id');
    const { status, queueId, childId, visitId } = queryString.parse(
      this.props.location.search
    );

    this.setState({
      childId,
      visitId,
      nurseId,
      queueId,
      status: parseInt(status),
      isLoaded: true,
    });

    try {
      let getVisitData = await axios.get(`${API_URL}/nurse/visits/${visitId}`, {
        headers,
      });

      this.setState({
        date_plan: getVisitData.data.visit.date_plan,
        visit_type: {
          value: getVisitData.data.visit.visit_type.id,
          label: getVisitData.data.visit.visit_type.name,
        },
      });
      const { data: dataDates } = await axios.get(`${API_URL}/blocked_dates`, {
        headers,
      });

      //parse date to format string long to ISO
      let filterBlockedDates = await dataDates.blockedDates.map((item) =>
        Date.parse(new Date(item.date).toUTCString())
      );

      const { data: dataDatesKonsulOnline } = await axios.get(
        `${API_URL}/blocked_dates?shift_type=konsultasi_online`,
        {
          headers,
        }
      );

      let filterBlockedDatesKonsultasiOnline =
        await dataDatesKonsulOnline.blockedDates.map((item) =>
          Date.parse(new Date(item.date).toUTCString())
        );

      this.setState({
        excludeDate: [
          ...filterBlockedDatesKonsultasiOnline,
          ...filterBlockedDates,
        ],
      });
    } catch (e) {
      swal({
        title: 'GAGAL',
        text: ' Gagal Mengambil Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }

    try {
      let getVisitTypes = await axios.get(`${API_URL}/nurse/visit_types`, {
        headers,
      });
      let visit_types = getVisitTypes.data.visitTypes.map((type) => ({
        value: type.id,
        label: type.name,
      }));
      this.setState({
        visit_types: visit_types,
      });
    } catch (e) {
      swal({
        title: 'GAGAL',
        text: ' Gagal Mengambil Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }

    if (this.state.visit_type.value === undefined) {
      this.setState({
        visit_type: {
          value: 0,
          label: <p className="select-placeholder">Pilih Tipe Kunjungan</p>,
        },
      });
    }
    this.setState({ isLoaded: false });
    // this.dateTakenHandler(this?.state?.startDate);
  }

  visitHandler = async (value) => {
    this.setState({
      visit_type: {
        value: value.value,
        label: value.label,
      },
    });
  };

  saveVisit = async (e) => {
    e.preventDefault();
    this.setState({ loading_button: true });
    let {
      status,
      queueId,
      visitId,
      visit_type,
      date_plan,
      date_taken,
      nurseId,
    } = this.state;

    // STATUS 1 = VISIT UPDATE
    // STATUS 2 = VISIT SCHEDULE UPDATE

    if (status === 1) {
      let data = {
        _method: 'PATCH',
        visit_type_id: visit_type.value,
        date_plan: date_plan,
        date_taken: moment(date_taken).format('YYYY-MM-DD'),
        nurse_id: parseInt(nurseId),
      };
      await axios
        .post(`${API_URL}/nurse/visits/${visitId}`, data, { headers })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Kunjungan Berhasil Diubah!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.location.href = `/patient-detail?tab=onprocess&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          this.setState({ loading_button: false });
          this.errorHandler(err);
        });
    } else if (status === 2) {
      let data = {
        _method: 'PATCH',
        visit_type_id: visit_type.value,
        date_plan: moment(date_taken).format('YYYY-MM-DD'),
        nurse_id: parseInt(nurseId),
      };
      await axios
        .post(`${API_URL}/nurse/visits/${visitId}`, data, { headers })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Data Berhasil Diubah!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.location.href = `/patient-detail?tab=onprocess&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          this.setState({ loading_button: false });
          this.errorHandler(err);
        });
    }
  };

  errorHandler = (err) => {
    if (err.response.data.message === 'The given data was invalid.') {
      swal({
        title: 'GAGAL',
        text: ' Harap Lengkapi Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, registration deadline has been reached!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Telah Mencapai Batas Waktu Pendaftaran',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, registration limit has been reached!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Antrian Sudah Penuh',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, your child already has a ticket!, please cancel it first!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Anak Telah Memiliki Tiket',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else {
      swal({
        title: 'GAGAL',
        text: 'Gagal Menyimpan Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  };

  deleteVisit = async (e) => {
    let { queueId, visitId } = this.state;
    this.setState({ isLoaded: true });
    if (visitId === null) {
      swal({
        title: 'GAGAL',
        text: 'Tidak Ada Kunjungan',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else {
      swal({
        title: 'Yakin Ingin Menghapus?',
        icon: 'warning',
        buttons: ['Batal', 'Hapus'],
        dangerMode: true,
      })
        .then(async (move) => {
          if (move) {
            await axios
              .delete(`${API_URL}/nurse/visits/${visitId}`, { headers })
              .then((res) => {
                swal({
                  title: 'Sukses!',
                  text: 'Kunjungan Berhasil Dihapus!',
                  icon: 'success',
                  button: 'OK',
                }).then((move) => {
                  if (move) {
                    window.location.href = `/patient-detail?tab=onprocess&queueId=${queueId}`;
                  } else {
                    return;
                  }
                });
              })
              .catch((err) => {
                swal({
                  title: 'GAGAL',
                  text: 'Data Gagal Dihapus',
                  icon: 'warning',
                  type: 'error',
                  buttons: 'OK',
                });
                this.setState({ isLoaded: false });
              });
          } else {
            return;
          }
        })
        .catch((err) => {
          swal({
            title: 'GAGAL',
            text: 'Data Gagal Dihapus',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
          this.setState({ isLoaded: false });
        });
    }
  };
  render() {
    let {
      status,
      isLoaded,
      queueId,
      loading_button,
      visit_type,
      visit_types,
      date_taken,
      excludeDate,
    } = this.state;

    const isWeekday = (date) => {
      // console.log(date)
      const day = date.getDay();
      return day !== 0;
    };

    return (
      <div>
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <Navbar
            navtitle="Kunjungan"
            url={`/patient-detail?tab=onprocess&queueId=${queueId}`}
          />
          {status !== null ? (
            <div className="vaccination-body">
              {status === 1 ? (
                <button
                  className="btn btn-danger delete-vaccination-button"
                  onClick={this.deleteVisit}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="delete-vaccination-icon"
                  />
                </button>
              ) : (
                ''
              )}
              <div>
                <DatePicker
                  selected={date_taken}
                  onChange={(e) => {
                    console.log(e);
                    this.setState({ date_taken: e });
                  }}
                  // minDate={new Date()}
                  excludeDates={excludeDate}
                  filterDate={isWeekday}
                  dateFormat="dd MMMM, yyyy"
                  placeholderText="Tanggal Pengambilan Data saat ini"
                />
              </div>
              <div className="merk-vaccine">
                <Select
                  options={visit_types}
                  value={visit_type}
                  onChange={(value) => this.visitHandler(value)}
                  Placeholder="massukkan data"
                />
              </div>
              <br />
              <br />
              <a href="/patient-detail">
                <button
                  className="btn btn-primary btn-saveschedule"
                  onClick={this.saveVisit}
                >
                  {loading_button ? (
                    <div>
                      <FontAwesomeIcon icon={faSpinner} spin /> Loading
                    </div>
                  ) : (
                    'SIMPAN'
                  )}
                </button>
              </a>
            </div>
          ) : (
            <div className="vaccination-body"></div>
          )}
        </LoadingOverlay>
      </div>
    );
  }
}

export default UpdateVisit;
