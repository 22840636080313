import React, { useState, useEffect } from "react";
import Sidebar from "../../AdminSidebar/AdminSidebar";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import LoadingOverlay from "react-loading-overlay";
import { API_URL } from "../../../BaseUrlApi/Url";
import axios from "axios";

function createdFormatter(cell, row, rowIndex, formatExtraData) {
  if (cell === null) {
    return <p>-</p>;
  } else {
    let date = cell.slice(0, 10);
    let changed_date = new Date(date);
    let dd = changed_date.getDate();
    let mm = changed_date.getMonth() + 1;
    let yyyy = changed_date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    let time = cell.slice(11, 20);
    console.log(date);
    return `${time} | ${dd}-${mm}-${yyyy}`;
  }
}

function Notifications(props) {
  const { SearchBar } = Search;
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    "Content-Type": "application/json",
  };
  const [isLoaded, setLoaded] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [column] = useState([
    {
      dataField: "user.name",
      text: "Nama Pengguna",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
    },
    {
      dataField: "body",
      text: "Isi Notifikasi",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
    },
    {
      dataField: "created_at",
      text: "Waktu Terkirim",
      headerStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      formatter: createdFormatter,
    },
  ]);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total showing-button">
      Menampilkan {from} / {to} dari {size} Hasil
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "Semua",
        value: notifications.length,
      },
    ],
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div className="card shadow mb-4">
      <div className="card-body">
        <div className="table-responsive">
          <ToolkitProvider
            keyField="id"
            columns={column}
            data={notifications}
            search
          >
            {(toolkitprops) => (
              <div>
                <SearchBar
                  {...toolkitprops.searchProps}
                  className="search-content"
                />
                <BootstrapTable
                  bordered={false}
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    axios
      .get(`${API_URL}/admin/notifications/scheduleAndTicketReminderSent`, {
        headers,
      })
      .then((res) => {
        setNotifications(res.data.notifications);
        setLoaded(false);
      })
      .catch((err) => console.log(err));
  }, [headers]);

  return (
    <div>
      <Sidebar />
      <div className="admin-overlay">
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Konteks</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Notifikasi</span>
                <span style={{ color: "#00000029" }}> / Konteks</span>
              </h2>
            </div>
            <PaginationProvider pagination={paginationFactory(options)}>
              {contentTable}
            </PaginationProvider>
          </div>
        </LoadingOverlay>
      </div>
    </div>
  );
}

export default Notifications;
