import { useQuery } from 'react-query';
import API from '../../config/axios';
import { QUERY_KEY } from '../../constans/query-keys';
import queryString from 'query-string';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 50;

const fetchQueue = async ({
  type = 'praktek_offline',
  period,
  page = DEFAULT_PAGE,
  perPage = DEFAULT_PER_PAGE,
  ...params
}) => {
  const pathname = queryString.stringifyUrl({
    url: '/queues',
    query: {
      type,
      period,
      page,
      limit: perPage,
      ...params,
    },
  });
  const { data } = await API.get(pathname);
  return data;
};

const useGetQueue = ({ type, period, page, perPage, ...params }) => {
  return useQuery(
    [QUERY_KEY.GET_QUEUE, { period, page, ...params }],
    () =>
      fetchQueue({
        type,
        period,
        page,
        perPage,
        ...params,
      }),
    {
      keepPreviousData: false,
      cacheTime: 0,
    }
  );
};

export default useGetQueue;
