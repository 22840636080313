import React from 'react';

const CustomCheckbox = ({ label, desc, onChange, ...props }) => {
  return (
    <div className="checkbox rounded-8">
      <div className="checkbox-container mt-1">
        <input
          id="checkbox-rounded-8"
          type="checkbox"
          onChange={onChange}
          {...props}
        />
        <div className="checkbox-checkmark"></div>
      </div>
      <label for="checkbox-rounded-8" style={{ lineHeight: '1em' }}>
        {label} {desc && <>{desc}</>}
      </label>
    </div>
  );
};

export default CustomCheckbox;
