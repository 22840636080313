import API from '../../../../config/axios';

export const onDeleteDuplicate = (data) => {
  const payload = {
    parent_ids: data?.map((item) => item?.id)?.join(','),
  };
  const response = API.post(`/parents/mass-delete`, payload)
    .then((res) => res)
    .catch((err) => err);

  return response;
};
