import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import { API_URL } from '../../BaseUrlApi/Url';
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert';
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from 'react-datepicker';
// import DynamicVaccine from "../../../CustomJS/DynamicVaccine";
import DynamicVaccineNurse from '../../CustomJS/DynamicVaccineNurse';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

function EditUpcomingVaccination(props) {
  const [isLoaded, setLoading] = useState(false);
  const nurseId = localStorage.getItem('nurse_id');
  const [shifts, setShifts] = useState([]);
  const [shift, setShift] = useState({});
  const [vaccineTypes, setVaccineTypes] = useState([]);
  const [vaccinationId, setVaccinationId] = useState(0);
  const [dynamicVaccine, setDynamicVaccine] = useState([]);
  const [date_plan, setDatePlan] = useState(null);
  const [lastChoice, setLastChoice] = useState(0);
  // const [current_date, setCurrentDate] = useState(null);
  const [excludeDate, setExcludeDate] = useState(null);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${API_URL}/nurse/shifts`, { headers })
      .then((res) => {
        let shifts = res.data.shifts.map((shift) => ({
          value: shift.id,
          label: shift.name,
        }));
        setShifts(shifts);
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: ' Gagal Mengambil Data',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });

    axios
      .get(`${API_URL}/nurse/vaccine_types`, { headers })
      .then((res) => {
        let vaccine_types = res.data.data.map((type) => ({
          value: type.id,
          label: type.name,
        }));
        setVaccineTypes(vaccine_types);
        // setLoading(false);
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: ' Gagal Mengambil Data',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });

    axios
      .get(`${API_URL}/blocked_dates`, {
        headers,
      })
      .then(async (res) => {
        let filterBlockedDates = res.data.blockedDates.map((item) =>
          Date.parse(new Date(item.date).toUTCString())
        );
        const { data: dataDatesKonsulOnline } = await axios.get(
          `${API_URL}/blocked_dates?shift_type=konsultasi_online`,
          {
            headers,
          }
        );

        let filterBlockedDatesKonsultasiOnline =
          await dataDatesKonsulOnline.blockedDates.map((item) =>
            Date.parse(new Date(item.date).toUTCString())
          );

        setExcludeDate([
          ...filterBlockedDatesKonsultasiOnline,
          ...filterBlockedDates,
        ]);
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: ' Gagal Mengambil Data',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });

    axios
      .get(
        `${API_URL}/nurse/vaccinations/upcomingVaccinations?child_id=${props.match.params.childId}&queue_id=${props.match.params.queueId}`,
        {
          headers,
        }
      )
      .then((res) => {
        let filterUpcomingVaccine = res.data.vaccinations[0];
        let filterValueVaccine = filterUpcomingVaccine.vaccine_types.map(
          (item, i) => {
            return {
              id: i + 1,
              vaccine_selected: {
                value: item.id,
                label: item.name,
              },
              period: item.pivot.period,
            };
          }
        );
        setDatePlan(
          Date.parse(new Date(filterUpcomingVaccine.date_plan).toUTCString())
        );
        setShift({
          value: filterUpcomingVaccine.queue.shift.id,
          label: filterUpcomingVaccine.queue.shift.name,
        });
        setLastChoice(lastChoice + filterValueVaccine.length);
        setDynamicVaccine(filterValueVaccine);
        setVaccinationId(filterUpcomingVaccine.id);
        setLoading(false);
      });

    // setShift({
    //   value: null,
    //   label: <span className="select-placeholder">Pilih Shift</span>,
    // });
    // setLoading(false);

    // getCurrentDate();
  }, [lastChoice, props.match.params.childId, props.match.params.queueId]);

  const vaccineChangeHandler = (vaccine_id, newVaccineSelected, newPeriod) => {
    const newVaccine = {
      id: vaccine_id,
      vaccine_selected: {
        value: newVaccineSelected.value,
        label: newVaccineSelected.label,
      },
      period: newPeriod,
    };

    const oldVaccine = dynamicVaccine.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    let newVaccines = [...oldVaccine, newVaccine];
    newVaccines.sort((a, b) => a.id - b.id);
    setDynamicVaccine(newVaccines);
  };

  const vaccineRemoveHandler = (vaccine_id) => {
    const vaccinesValue = [...dynamicVaccine];
    const newVaccines = vaccinesValue.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    setDynamicVaccine(newVaccines);
  };

  const vaccineAddHandler = () => {
    let objValue = dynamicVaccine.find(
      (c) => c.period === '' || c.vaccine_selected === null
    );
    if (objValue !== undefined) {
      return;
    }

    const newVaccine = {
      id: lastChoice + 1,
      vaccine_selected: null,
      period: '',
    };
    // this.setState({
    //   lastChoice: lastChoice+1,
    //   dynamic_vaccine: [...dynamicVaccine, newVaccine]
    // })
    setDynamicVaccine([...dynamicVaccine, newVaccine]);
    setLastChoice(lastChoice + 1);
  };

  const saveVaccination = (e) => {
    e.preventDefault();
    // let child = parseInt(props.match.params.child);

    // if (moment(new Date()).format('YYYY-MM-DD') !== moment(date_plan).format('YYYY-MM-DD')) {
    if (shift.value !== null) {
      let data = {
        _method: 'PATCH',
        child_id: parseInt(props.match.params.childId),
        date_plan: moment(date_plan).format('YYYY-MM-DD'),
        shift_id: shift.value,
        queue_id: parseInt(props.match.params.queueId),
        nurse_id: parseInt(nurseId),
        // vaccine_type_id: vaccineType.value,
        // period: parseInt(period),
        vaccine_types: dynamicVaccine.map((item) => {
          return {
            id: item.vaccine_selected.value,
            period: item.period,
          };
        }),
      };

      axios
        .post(`${API_URL}/nurse/vaccinations/${vaccinationId}`, data, {
          headers,
        })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Berhasil Diubah!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.location.href = `/checkup`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          errorHandler(err);
        });
    } else {
      let data = {
        _method: 'PATCH',
        child_id: parseInt(props.match.params.childId),
        date_plan: moment(date_plan).format('YYYY-MM-DD'),
        queue_id: parseInt(props.match.params.queueId),
        nurse_id: parseInt(nurseId),
        vaccine_types: dynamicVaccine.map((item) => {
          return {
            id: item.vaccine_selected.value,
            period: item.period,
          };
        }),
      };
      axios
        .post(`${API_URL}/nurse/vaccinations/${vaccinationId}`, data, {
          headers,
        })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Berhasil Diubah!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.location.href = `/checkup`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          errorHandler(err);
        });
      //   }
    }
  };

  function errorHandler(err) {
    if (err.response.data.message === 'The given data was invalid.') {
      swal({
        title: 'GAGAL',
        text: ' Harap Lengkapi Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, registration deadline has been reached!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Telah Mencapai Batas Waktu Pendaftaran',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, registration limit has been reached!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Antrian Sudah Penuh',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, your child already has a ticket!, please cancel it first!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Anak Telah Memiliki Tiket',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else {
      swal({
        title: 'GAGAL',
        text: 'Gagal Menyimpan Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  }

  const isWeekday = (date) => {
    // console.log(date)
    const day = date.getDay();
    return day !== 0;
  };

  return (
    <div className="nurse-overlay">
      <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
        <Navbar navtitle="Edit Jadwal Vaksinasi" url={`/checkup`} />
        <div className="vaccination-body">
          <div>
            <DatePicker
              selected={date_plan}
              className="date-picker-custom"
              onChange={(e) => {
                console.log(e);
                setDatePlan(e);
              }}
              excludeDates={excludeDate}
              // onChange={dateTakenHandler}
              filterDate={isWeekday}
              // minDate={new Date()}
              // dateFormat="dd-MM-yyyy"
              dateFormat="dd MMMM, yyyy"
              placeholderText="Pilih tanggal Pemeriksaan"
            />
          </div>
          <div className="merk-vaccine">
            <Select
              options={shifts}
              value={shift}
              onChange={(value) =>
                setShift({ value: value.value, label: value.label })
              }
              Placeholder="massukkan data"
            />
          </div>
          <div className="merk-vaccine">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span>Vaksin dan Periode</span>
              <button className="btn btn-success " onClick={vaccineAddHandler}>
                {/* {loading_button ? "..Loading.." : "SIMPAN"} */}
                Tambah Data
              </button>
            </div>
            {dynamicVaccine.map((vaccine, i) => (
              <DynamicVaccineNurse
                vaccineTypes={vaccineTypes}
                dynamic_vaccine={dynamicVaccine}
                key={i}
                vaccine_id={vaccine.id}
                vaccine_selected={vaccine.vaccine_selected}
                period={vaccine.period}
                vaccineChangeHandler={vaccineChangeHandler}
                vaccineRemoveHandler={vaccineRemoveHandler}
              />
            ))}
          </div>
          <a href="/patient-detail">
            <button
              className="btn btn-primary btn-saveschedule"
              onClick={saveVaccination}
            >
              {/* {loading_button ? "..Loading.." : "SIMPAN"} */}
              SIMPAN
            </button>
          </a>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default EditUpcomingVaccination;
