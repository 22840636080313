import React, { useCallback, useEffect, useState } from 'react';
import AdminSidebar from '../AdminSidebar/AdminSidebar';
import LoadingOverlay from 'react-loading-overlay';
import './DuplicateData.css';
import {
  Col,
  Row,
  Typography,
  Breadcrumb,
  Button,
  Input,
  message,
  Table,
  Pagination,
} from 'antd';
import Spacer from '../../Spacer';
import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { TableDuplicateData } from './components/TableDuplicateData/TableDuplicateData';
import { onGetDataDuplicate } from './services/getDataDuplicate';
import { Modal, Space } from 'antd';
import ICRemoveDuplicate from '../../../assets/icons/ic-remove-duplicate.svg';
import { onGetDuplicateDetails } from './services/getDataDetailsDuplicate';
import { onDeleteDuplicate } from './services/postDeleteDataDuplicate';
import { useDebounce } from 'use-debounce';
import API from '../../../config/axios';
import _ from 'lodash';

const { Title, Text } = Typography;

export const DuplicateData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const [isShowModalDeleteDataDuplicate, setIsShowModalDeleteDataDuplicate] =
    useState(false);
  const [dataDuplicate, setDataDuplicate] = useState(null);
  const [dataDuplicateDetails, setDataDuplicateDetails] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [debounceKeyword] = useDebounce(keyword, 300);

  const [paramsGetListDuplicate, setParamsGetListDuplicate] = useState({
    page: 1,
    limit: 10,
    is_duplicate: 1,
  });

  const [paginationTable, setPaginationTable] = useState({
    current: 1,
    total: 0,
    lastPage: 0,
  });

  const [selectedDataTable, setSelectedDataTable] = useState([]);
  const [selectedDataDetail, setSelectedDataDetail] = useState([]);
  const [idDeleteDuplicateData, setIdDeleteDuplicateData] = useState(null);

  const onGetListDataDuplicate = useCallback(() => {
    setIsLoading(true);
    onGetDataDuplicate({ ...paramsGetListDuplicate, keyword: debounceKeyword })
      .then((res) => {
        const tempData = res?.data?.data?.map((item, index) => ({
          father_name: item?.father_name,
          mother_name: item?.mother_name,
          children_count: item?.children_count,
          last_visit_date: item?.last_visit_date,
          is_registered_on_app: item?.is_registered_on_app,
          duplicate_data_count: item?.duplicate_data_count,
          no: index + 1,
          key: index + 1,
          id: item?.id,
        }));

        const responseDataDuplicate = {
          ...res?.data,
          data: tempData,
        };

        setDataDuplicate(responseDataDuplicate);
        setPaginationTable({
          ...paginationTable,
          current: res?.data?.current_page || 1,
          defaultCurrent: res?.data?.current_page || 1,
          lastPage: res?.data?.last_page || 1,
          total: res?.data?.total || 0,
        });
      })
      .catch((err) => err)
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsGetListDuplicate, debounceKeyword]);

  const onSearchListParents = useCallback(() => {
    if (debounceKeyword && !_.isEmpty(dataDuplicate.data)) {
      setIsLoading(true);
      return API.get(`/parents/search?keyword=${debounceKeyword}`)
        .then((res) => {
          const filterDataDuplicate = _.filter(dataDuplicate?.data, ({ id }) =>
            res?.data?.parents?.some(({ id: idParent }) => idParent === id)
          );

          setDataDuplicate((prevValue) => ({
            ...prevValue,
            data: filterDataDuplicate,
          }));
        })
        .catch((err) => err)
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceKeyword, dataDuplicate?.current_page]);

  const onGetDataDuplicateDetails = () => {
    if (!idDeleteDuplicateData) return null;
    else {
      setIsLoadingDetail(true);
      onGetDuplicateDetails(idDeleteDuplicateData)
        .then((res) => {
          const tempDataOriginal = [
            {
              father_name: res?.data?.father_name,
              mother_name: res?.data?.mother_name,
              children_count: res?.data?.children_count,
              last_visit_date: res?.data?.last_visit_date,
              is_registered_on_app: res?.data?.is_registered_on_app,
              duplicate_data_count: res?.data?.duplicate_data_count,
              no: 1,
              key: 1,
              id: res?.data?.id,
            },
          ];

          const tempDataDuplicate = res?.data?.duplicate_data?.map(
            (item, index) => ({
              father_name: item?.father_name,
              mother_name: item?.mother_name,
              children_count: item?.children_count || 0,
              last_visit_date: item?.last_visit_date,
              is_registered_on_app: item?.is_registered_on_app,
              duplicate_data_count: item?.duplicate_data_count,
              no: index + 1,
              key: index + 1,
              id: item?.id,
            })
          );

          const responseDataDuplicate = {
            ...res?.data,
            dataOriginal: tempDataOriginal || [],
            dataDuplicate: tempDataDuplicate || [],
          };

          setDataDuplicateDetails(responseDataDuplicate);
        })
        .catch((err) => err)
        .finally(() => setIsLoadingDetail(false));
    }
  };

  const onDeleteDataDuplicate = (data) => {
    setIsLoadingDeleteData(true);
    onDeleteDuplicate(data)
      .then(() => {
        message.success('Data duplikat berhasil dihapus !');
        onGetListDataDuplicate();
        setIsShowModalDeleteDataDuplicate(false);
        setSelectedDataDetail([]);
        setSelectedDataTable([]);
      })
      .catch(() => {
        message.error('Data duplikat gagal dihapus !');
      })
      .finally(() => setIsLoadingDeleteData(false));
  };

  useEffect(() => {
    onGetListDataDuplicate();
  }, [onGetListDataDuplicate]);

  useEffect(() => {
    onSearchListParents();
  }, [onSearchListParents]);

  useEffect(() => {
    if (idDeleteDuplicateData) {
      onGetDataDuplicateDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDeleteDuplicateData]);

  return (
    <div className="admin-overlay">
      <AdminSidebar />
      <LoadingOverlay active={false} spinner text="Memuat data...">
        <div className="content-section">
          <div className="duplicate-data-container">
            <Row align="middle">
              <Col span={12}>
                <Title level={3} style={{ margin: 0, textAlign: 'left' }}>
                  Daftar Data Duplikat
                </Title>
                <Spacer height={12} />
                <Breadcrumb style={{ textAlign: 'left' }}>
                  <Breadcrumb.Item>
                    <a href="/admin-dashboard">Dashboard</a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Daftar Data Duplikat</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={12}>
                <div className="header-right-content-wrapper">
                  <div className="multiple-delete-wrapper">
                    {!!selectedDataTable?.length && (
                      <>
                        <Text>{selectedDataTable?.length} data dipilih</Text>
                      </>
                    )}
                    <Button
                      type="primary"
                      danger
                      style={{
                        ...(!!selectedDataTable.length && {
                          background: '#CB444A',
                        }),
                      }}
                      size="large"
                      disabled={
                        !selectedDataTable.length || isLoadingDeleteData
                      }
                      loading={isLoadingDeleteData}
                      onClick={() => onDeleteDataDuplicate(selectedDataTable)}
                    >
                      Hapus Data Dipilih
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            <Spacer height={40} />

            <Row>
              <Col>
                <Input
                  placeholder="Cari data"
                  value={keyword}
                  onChange={(e) => setKeyword(e?.target?.value)}
                  suffix={<SearchOutlined />}
                />
              </Col>
            </Row>

            <Spacer height={30} />

            <Row>
              <Col>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TableDuplicateData
                    dataSource={dataDuplicate?.data || []}
                    isDetail={isShowModalDeleteDataDuplicate}
                    rowSelection={{
                      type: 'checkbox',
                      hideSelectAll: true,
                      selectedRowKeys: selectedDataTable?.map(
                        (item) => item?.id
                      ),
                      onSelect: (record, selected) => {
                        if (selected) {
                          return setSelectedDataTable([
                            ...selectedDataTable,
                            record,
                          ]);
                        } else {
                          const tempData = selectedDataTable?.filter(
                            (item) => item?.id !== record?.id
                          );
                          return setSelectedDataTable(tempData);
                        }
                      },
                    }}
                    isLoading={isLoading}
                    onShowModalDetail={(id) => {
                      setIdDeleteDuplicateData(id);
                      setIsShowModalDeleteDataDuplicate(true);
                    }}
                  />
                  <Spacer height={16} />
                  <Space style={{ alignSelf: 'end' }}>
                    <Button
                      icon={<LeftOutlined style={{ fontSize: 12 }} />}
                      onClick={() =>
                        setParamsGetListDuplicate((prev) => ({
                          ...prev,
                          page: prev.page - 1,
                        }))
                      }
                      disabled={paramsGetListDuplicate?.page === 1 || isLoading}
                    ></Button>
                    <Button>{paramsGetListDuplicate?.page}</Button>
                    <Button
                      icon={<RightOutlined style={{ fontSize: 12 }} />}
                      disabled={
                        paramsGetListDuplicate?.page ===
                          paginationTable.lastPage || isLoading
                      }
                      onClick={() =>
                        setParamsGetListDuplicate((prev) => ({
                          ...prev,
                          page: prev.page + 1,
                        }))
                      }
                    ></Button>
                  </Space>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </LoadingOverlay>

      <div style={{ overflowY: 'auto' }}>
        <Modal
          visible={isShowModalDeleteDataDuplicate}
          onOk={() => {}}
          zIndex={9999999}
          className="modal-duplicate-data-certificate"
          onCancel={() => {
            setIsShowModalDeleteDataDuplicate(false);
            setSelectedDataDetail([]);
          }}
          footer={null}
        >
          <div className="modal-duplicate-data-wrapper">
            <>
              <img src={ICRemoveDuplicate} alt="ic-remove-duplicate.svg" />
              <Spacer height={30} />
              <Title level={4}>Request Diterima</Title>
              <p className="modal-duplicate-data-description">
                Anda akan menghapus data duplikat user Klinik Pediatrica Hussada
                dengan detail sebagai berikut:
              </p>

              <Spacer height={16} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Title level={5}>Data Original</Title>
              </div>
              <div style={{ maxWidth: '100%' }}>
                <TableDuplicateData
                  dataSource={dataDuplicateDetails?.dataOriginal}
                  isDetail={isShowModalDeleteDataDuplicate}
                  rowSelection={{
                    type: 'checkbox',
                    hideSelectAll: true,
                    selectedRowKeys: selectedDataDetail?.map(
                      (item) => item?.id
                    ),
                    onSelect: (record, selected) => {
                      if (selected) {
                        return setSelectedDataDetail([
                          ...selectedDataDetail,
                          record,
                        ]);
                      } else {
                        const tempData = selectedDataDetail?.filter(
                          (item) => item?.id !== record?.id
                        );
                        return setSelectedDataDetail(tempData);
                      }
                    },
                  }}
                  isLoading={isLoadingDetail}
                  pagination={{
                    ...paginationTable,
                  }}
                />
              </div>

              <Spacer height={16} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Title level={5}>Data Duplicate</Title>
              </div>
              <div style={{ maxWidth: '100%' }}>
                <TableDuplicateData
                  dataSource={dataDuplicateDetails?.dataDuplicate}
                  isDetail={isShowModalDeleteDataDuplicate}
                  rowSelection={{
                    type: 'checkbox',
                    hideSelectAll: true,
                    selectedRowKeys: selectedDataDetail?.map(
                      (item) => item?.id
                    ),
                    onSelect: (record, selected) => {
                      if (selected) {
                        return setSelectedDataDetail([
                          ...selectedDataDetail,
                          record,
                        ]);
                      } else {
                        const tempData = selectedDataDetail?.filter(
                          (item) => item?.id !== record?.id
                        );
                        return setSelectedDataDetail(tempData);
                      }
                    },
                  }}
                  isLoading={isLoadingDetail}
                  pagination={{
                    ...paginationTable,
                  }}
                />
              </div>

              <Spacer height={34} />
              <div>
                <Space size="small">
                  <Button
                    onClick={() => {
                      setIsShowModalDeleteDataDuplicate(false);
                      setSelectedDataDetail([]);
                    }}
                  >
                    Batalkan
                  </Button>
                  <Button
                    type="primary"
                    danger
                    style={{
                      ...(!!selectedDataDetail.length && {
                        background: '#CB444A',
                      }),
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    disabled={!selectedDataDetail.length || isLoadingDeleteData}
                    loading={isLoadingDeleteData}
                    onClick={() => onDeleteDataDuplicate(selectedDataDetail)}
                  >
                    Hapus Duplikasi
                  </Button>
                </Space>
              </div>
            </>
          </div>
        </Modal>
      </div>
    </div>
  );
};
