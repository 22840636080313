import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { useFieldArray } from 'react-hook-form';

import Spacer from '../../../../../../Components/Spacer';
import FieldInputNumber from '../../../../../../Components/V2/HookForm/FieldInputNumber/FieldInputNumber';

const { Title } = Typography;

const NestedAdditionalFrequencies = () => {
  const { fields, append, remove } = useFieldArray({
    name: 'additional_frequencies',
  });

  const isNotEmpty = !!fields?.length;

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        {fields?.map((field, index) => (
          <Space
            direction="vertical"
            style={{ width: '100%' }}
            size="large"
            key={field.id}
          >
            <Title level={5} style={{ textAlign: 'left' }}>
              Opsional Frekuensi ke-{index + 1}
            </Title>

            <FieldInputNumber
              size="large"
              name={`additional_frequencies.${index}.age_lower_limit`}
              label="Umur dosis pertama diberikan"
            />
            <FieldInputNumber
              size="large"
              name={`additional_frequencies.${index}.frequency`}
              label="Frekuensi"
            />

            <Row>
              <Col offset={4} span={13}>
                <Button
                  size="large"
                  block
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  danger
                  onClick={() => remove(index)}
                >
                  Hapus Opsional Frekuensi ke-{index + 1}
                </Button>
              </Col>
            </Row>
          </Space>
        ))}
      </Space>

      {isNotEmpty && <Spacer height={24} />}
      <Row>
        <Col offset={4} span={13}>
          <Button
            size="large"
            block
            icon={<PlusOutlined />}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            type="dashed"
            onClick={() => append({ frequency: '', age_lower_limit: '' })}
          >
            Tambah opsional frekuensi
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NestedAdditionalFrequencies;
