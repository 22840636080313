import React, { Component } from 'react';
import swal from 'sweetalert';
import Select from 'react-select';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../BaseUrlApi/Url';
import LoadingOverlay from 'react-loading-overlay';
import Navbar from '../Navbar/Navbar';
import './Vaccination.css';
import axios from 'axios';
import DynamicVaccineNurse from '../../CustomJS/DynamicVaccineNurse';
import API from '../../../config/axios';
import _ from 'lodash';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};
class Vaccination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '',
      isLoaded: false,
      queueId: null,
      childId: null,
      nurseId: null,
      startDate: new Date(),
      vaccinationId: null,
      date_plan: null,
      date_taken: null,
      vaccine_type_id: null,
      vaccine_id: null,
      vaccine_types: [],
      vaccine_type: {},
      vaccine_brand: {},
      dynamic_vaccine: [],
      isHistory: false,
      lastChoice: 0,
      vaccines: [],
      vaccine: {},
      vaccineDatas: [],
      period: '',
      batch: '',
      vaccination: [],
      loading_button: false,
      excludeDate: [],
      nextVaccinationPeriod: null,
      vaccination_type_ids: [],
    };
  }
  async componentDidMount() {
    let nurseId = localStorage.getItem('nurse_id');
    const { tab, queueId, childId } = queryString.parse(
      this.props.location.search
    );
    this.setState({ tab, queueId, childId, nurseId, isLoaded: true });

    try {
      // GET VACCINE TYPES & VACCINE
      let getVaccineTypes = await axios.get(`${API_URL}/nurse/vaccine_types`, {
        headers,
      });

      let vaccine_types = getVaccineTypes.data.data.map((type) => ({
        value: type.id,
        label: type.name,
      }));

      let getVaccines = await axios.get(`${API_URL}/nurse/vaccines`, {
        headers,
      });

      this.setState({
        vaccineDatas: getVaccines.data.vaccines,
      });

      // await this.setState({ vaccine_types });
      const newVaccine = [
        {
          id: this.state.lastChoice + 1,
          vaccine_selected: null,
          period: '',
        },
      ];
      this.setState({
        isHistory: true,
        vaccines: getVaccines.data.vaccines.map((itemj) => {
          return {
            value: itemj.id,
            label: itemj.brand_name,
          };
        }),
        // vaccine_types: vaccine_types,
        lastChoice: this.state.lastChoice + 1,
        dynamic_vaccine: newVaccine,
      });

      const { data: dataDates } = await axios.get(`${API_URL}/blocked_dates`, {
        headers,
      });

      //parse date to format string long to ISO
      let filterBlockedDates = await dataDates.blockedDates.map((item) =>
        Date.parse(new Date(item.date).toUTCString())
      );

      const { data: dataDatesKonsulOnline } = await axios.get(
        `${API_URL}/blocked_dates?shift_type=konsultasi_online`,
        {
          headers,
        }
      );

      let filterBlockedDatesKonsultasiOnline =
        await dataDatesKonsulOnline.blockedDates.map((item) =>
          Date.parse(new Date(item.date).toUTCString())
        );

      this.setState({
        excludeDate: [
          ...filterBlockedDatesKonsultasiOnline,
          ...filterBlockedDates,
        ],
      });
    } catch (e) {
      swal({
        title: 'GAGAL',
        text: 'Gagal Mengambil Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
    this.getInitialSelectValue();
    this.setState({ isLoaded: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState) === JSON.stringify(this.state)) return null;
    const { childId, vaccination_type_ids } = this.state;

    console.log(vaccination_type_ids);

    if (childId && !!vaccination_type_ids.length) {
      API.get('/vaccinations/next-vaccination-period', {
        params: {
          child_id: childId,
          vaccine_type_ids: vaccination_type_ids.join(',').toString(),
        },
      })
        .then((res) => {
          const tempGeneratePeriodVaccine = _.map(
            this.state?.dynamic_vaccine,
            (user) => {
              const vaccineId = user.vaccine_selected.value;
              const matchedVaccine = _.find(res?.data?.vaccine_types, {
                id: vaccineId,
              });
              if (matchedVaccine) {
                return _.assign({}, user, {
                  period: matchedVaccine.next_vaccination_period,
                });
              }
              return user;
            }
          );

          this.setState({
            dynamic_vaccine: [...tempGeneratePeriodVaccine],
            // nextVaccinationPeriod: res?.data?.vaccine_types
            //   ?.map((item) => item?.next_vaccination_period)
            //   ?.join(','),
          });
        })
        .catch((err) => console.log(err));
    }
  }

  getInitialSelectValue = () => {
    // IF VACCINE & VACCINE TYPE UNDEFINED
    if (this.state.vaccine.value === undefined) {
      this.setState({
        vaccine: {
          value: 0,
          label: <p className="select-placeholder"> Pilih Merk Vaksin</p>,
        },
      });
    }

    if (this.state.vaccine_type.value === undefined) {
      this.setState({
        vaccine_type: {
          value: 0,
          label: <p className="select-placeholder"> Pilih Tipe Vaksin</p>,
        },
      });
    }
  };

  vaccineType = async (value) => {
    this.setState({ isLoaded: true });
    try {
      let getVaccines = await axios.get(
        `${API_URL}/nurse/vaccines?vaccine_type_id=${value.value}`,
        { headers }
      );
      let vaccines = getVaccines.data.vaccines.map((vaccine) => ({
        value: vaccine.id,
        label: vaccine.brand_name,
      }));
      this.setState({
        vaccine_type: {
          value: value.value,
          label: value.label,
        },
        vaccines: vaccines,
      });
    } catch (e) {
      swal({
        title: 'GAGAL',
        text: 'Gagal Mengambil Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
    this.setState({ isLoaded: false });
  };

  vaccineChangeHandler = (vaccine_id, newVaccineSelected, newPeriod) => {
    this.setState({
      vaccination_type_ids: [newVaccineSelected.value],
    });

    const newVaccine = {
      id: vaccine_id,
      vaccine_selected: {
        value: newVaccineSelected.value,
        label: newVaccineSelected.label,
      },
      period: newPeriod,
    };

    const oldVaccine = this.state.dynamic_vaccine.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    let newVaccines = [...oldVaccine, newVaccine];
    newVaccines.sort((a, b) => a.id - b.id);
    this.setState({
      dynamic_vaccine: newVaccines,
    });
  };

  vaccineRemoveHandler = (vaccine_id) => {
    const vaccinesValue = [...this.state.dynamic_vaccine];
    const newVaccines = vaccinesValue.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    this.setState({
      dynamic_vaccine: newVaccines,
    });
  };

  vaccineAddHandler = () => {
    let objValue = this.state.dynamic_vaccine.find(
      (c) => c.period === '' || c.vaccine_selected === null
    );
    if (objValue !== undefined) {
      return;
    }

    const newVaccine = {
      id: this.state.lastChoice + 1,
      vaccine_selected: null,
      period: '',
    };
    this.setState({
      lastChoice: this.state.lastChoice + 1,
      dynamic_vaccine: [...this.state.dynamic_vaccine, newVaccine],
    });
  };

  inputHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  deleteVaccination = async (e) => {
    let { queueId, vaccinationId, tab } = this.state;
    if (vaccinationId === null) {
      swal({
        title: 'GAGAL',
        text: 'Tidak Ada Vaksinasi',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else {
      swal({
        title: 'Yakin Ingin Menghapus?',
        icon: 'warning',
        buttons: ['Batal', 'Hapus'],
        dangerMode: true,
      })
        .then(async (move) => {
          if (move) {
            await axios
              .delete(`${API_URL}/nurse/vaccinations/${vaccinationId}`, {
                headers,
              })
              .then((res) => {
                swal({
                  title: 'Sukses!',
                  text: 'Vaksinasi Berhasil Dihapus!',
                  icon: 'success',
                  button: 'OK',
                }).then((move) => {
                  if (move) {
                    window.location.href = `/patient-detail?tab=${tab}&queueId=${queueId}`;
                  } else {
                    return;
                  }
                });
              })
              .catch((err) => {
                swal({
                  title: 'GAGAL',
                  text: 'Data Gagal Dihapus',
                  icon: 'warning',
                  type: 'error',
                  buttons: 'OK',
                });
              });
          } else {
            return;
          }
        })
        .catch((err) => {
          swal({
            title: 'GAGAL',
            text: 'Data Gagal Dihapus',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });
    }
  };
  saveVaccination = async (e) => {
    e.preventDefault();
    let { tab, queueId, nurseId, childId } = this.state;
    this.setState({ loading_button: true });

    let data = {
      // vaccine_type_id: this.state.vaccine_type.value,
      date_plan: moment(this.state.date_taken).format('YYYY-MM-DD'),
      vaccine_id: this.state.vaccine_brand.value,
      // period: parseInt(this.state.period),
      vaccine_types: this.state.dynamic_vaccine.map((item) => {
        return {
          id: item.vaccine_selected.value,
          period: item?.period,
        };
      }),
      batch: this.state.batch,
      child_id: childId,
      nurse_id: parseInt(nurseId),
      queue_id: queueId,
      date_taken: moment(this.state.date_taken).format('YYYY-MM-DD'),
    };

    await axios
      .post(`${API_URL}/nurse/vaccinations`, data, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Vaksinasi Berhasil Ditambahkan!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/patient-detail?tab=${tab}&queueId=${queueId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        this.setState({ loading_button: false });
        if (err.response.data.message === 'The given data was invalid.') {
          swal({
            title: 'GAGAL',
            text: ' Harap Lengkapi Data',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        } else if (
          err.response.data.message ===
          'Sorry, registration deadline has been reached!'
        ) {
          swal({
            title: 'GAGAL',
            text: 'Telah Mencapai Batas Waktu Pendaftaran',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        } else if (
          err.response.data.message ===
          'Sorry, registration limit has been reached!'
        ) {
          swal({
            title: 'GAGAL',
            text: 'Antrian Sudah Penuh',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        } else if (
          err.response.data.message ===
          'Sorry, your child already has a ticket!, please cancel it first!'
        ) {
          swal({
            title: 'GAGAL',
            text: 'Anak Telah Memiliki Tiket',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        } else {
          swal({
            title: 'GAGAL',
            text: 'Gagal Menyimpan Data',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        }
      });
  };

  render() {
    let {
      tab,
      queueId,
      isHistory,
      vaccinationId,
      isLoaded,
      dynamic_vaccine,
      vaccines,
      vaccine_types,
      vaccineDatas,
      vaccine_brand,
      lastChoice,
      loading_button,
      date_taken,
      excludeDate,
    } = this.state;

    const isWeekday = (date) => {
      // console.log(date)
      const day = date.getDay();
      return day !== 0;
    };
    return (
      <div className="nurse-overlay">
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <Navbar
            navtitle="Vaksinasi"
            url={`/patient-detail?tab=${tab}&queueId=${queueId}`}
          />
          <div className="vaccination-body">
            {vaccinationId !== null ? (
              <button
                className="btn btn-danger delete-vaccination-button"
                onClick={this.deleteVaccination}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className="delete-vaccination-icon"
                />
              </button>
            ) : (
              ''
            )}
            <div>
              <DatePicker
                selected={date_taken}
                onChange={(e) => {
                  console.log(e);
                  this.setState({ date_taken: e });
                }}
                excludeDates={excludeDate}
                filterDate={isWeekday}
                // minDate={new Date()}
                dateFormat="dd MMMM, yyyy"
                placeholderText="Tanggal Pengambilan Data saat ini"
              />
            </div>

            <div className="merk-vaccine">
              <span style={{ float: 'left' }}>Merek Vaksin</span>
              <Select
                placeholder={'Pilih Merek Vaksin'}
                options={vaccines}
                value={vaccine_brand}
                onChange={(value) => {
                  console.log(value);
                  // const newVaccine = [
                  //   {
                  //     id: lastChoice+1,
                  //     vaccine_selected: null,
                  //     period: ''
                  //   }
                  // ]
                  let filterVaccineType = vaccineDatas.filter(
                    (x) => x.id === value.value
                  )[0];

                  // console.log(vaccineDatas.filter(x => x.id === value.value)[0])
                  this.setState({
                    vaccination_type_ids: filterVaccineType?.vaccine_types?.map(
                      (item) => item?.id
                    ),
                    isHistory: true,
                    vaccine_brand: value,
                    vaccine_types: filterVaccineType.vaccine_types.map(
                      (itemj) => {
                        return {
                          value: itemj.id,
                          label: itemj.name,
                        };
                      }
                    ),
                    dynamic_vaccine: filterVaccineType.vaccine_types.map(
                      (item, i) => {
                        return {
                          id: i + 1,
                          vaccine_selected: {
                            value: item.id,
                            label: item.name,
                          },
                          period: '',
                        };
                      }
                    ),
                    lastChoice: filterVaccineType.vaccine_types.length,
                  });
                }}
              />
            </div>
            <div className="merk-vaccine">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <span>Vaksin dan Periode</span>
                <button
                  style={{
                    display:
                      vaccine_types.length > 1 && !isHistory ? '' : 'none',
                  }}
                  className="btn btn-success "
                  onClick={this.vaccineAddHandler}
                >
                  {/* {loading_button ? "..Loading.." : "SIMPAN"} */}
                  Tambah Data
                </button>
              </div>
              {/* <Select
                options={this.state.vaccine_types}
                value={this.state.vaccine_type}
                onChange={(value) => this.vaccineType(value)}
                Placeholder="massukkan data"
              /> */}
              {dynamic_vaccine.map((vaccine, i) => (
                <DynamicVaccineNurse
                  isHistory={isHistory}
                  vaccineTypes={vaccine_types}
                  dynamic_vaccine={dynamic_vaccine}
                  key={i}
                  vaccine_id={vaccine.id}
                  vaccine_selected={vaccine.vaccine_selected}
                  period={vaccine?.period}
                  vaccineChangeHandler={this.vaccineChangeHandler}
                  vaccineRemoveHandler={this.vaccineRemoveHandler}
                />
              ))}
            </div>
            <input
              type="text"
              name="batch"
              placeholder="Batch"
              value={this.state.batch}
              className="vaccination-input"
              onChange={this.inputHandler}
            />
            <a href="/patient-detail">
              <button
                className="btn btn-primary btn-saveschedule"
                onClick={this.saveVaccination}
              >
                {loading_button ? (
                  <div>
                    <FontAwesomeIcon icon={faSpinner} spin /> Loading
                  </div>
                ) : (
                  'SIMPAN'
                )}
              </button>
            </a>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default Vaccination;
