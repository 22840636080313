import { Button, Space, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { GENDER } from '../../../../../../constans';

const ColumnTableQueue = ({ onClickDetail, onClickDelete }) => {
  return [
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      width: 250,
      align: 'center',
      render: (text, record, index) => {
        return <span>{moment(text).format('dddd, DD MMMM YYYY')}</span>;
      },
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      key: 'shift',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return <span>{record?.shift?.name || '-'}</span>;
      },
    },
    {
      title: 'No. Registrasi',
      dataIndex: 'queue_number',
      key: 'queue_number',
      align: 'center',
      render: (text, record) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: 'Nama',
      dataIndex: 'child',
      key: 'child',
      align: 'center',
      render: (text, record) => {
        return <span>{record?.child?.fullname || '-'}</span>;
      },
    },
    {
      title: 'Umur',
      dataIndex: 'age',
      key: 'age',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return <span>{record?.child?.age || '-'}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'queue_status',
      key: 'queue_status',
      align: 'center',
      render: (text, record) => {
        const colorTag = (statusId) => {
          if (statusId === 7) return 'orange';
          else if ([2, 4].includes(statusId)) return 'green';
          else if (statusId === 3) return 'cyan';
          else if (statusId === 6) return 'purple';
          else if (statusId === 5) return 'blue';
          else if (statusId === 8) return 'red';
          else return 'orange';
        };

        return (
          <Tag
            bordered={false}
            color={colorTag(record?.queue_status?.id)}
            style={{
              textTransform: 'capitalize',
              fontSize: 14,
            }}
          >
            {record?.queue_status?.name}
          </Tag>
        );
      },
    },
    {
      title: 'Jenis Kelamin',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      render: (text, record) => {
        const tempGender =
          GENDER[record?.child?.gender] || 'Data Anak Terhapus';
        return <span>{tempGender || '-'}</span>;
      },
    },
    {
      title: 'Aksi',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 180,
      render: (_, record) => (
        <Space size="small">
          <Button
            type="primary"
            danger
            onClick={() => onClickDelete(record?.id)}
          >
            Hapus
          </Button>
          <Button type="primary" onClick={() => onClickDetail(record?.id)}>
            Detail
          </Button>
        </Space>
      ),
    },
  ];
};

export default ColumnTableQueue;
