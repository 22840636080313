import { Button, Space, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import {
  FILTER_CERTIFICATE_BY_STATUS,
  STATUS_CERTIFICATE,
} from '../../../../../constans/status-certificate';

export const Column = ({ onClickDetail }) => {
  return [
    {
      title: 'No',
      dataIndex: 'no',
      fixed: 'left',
      key: 'no',
      width: 100,
      render: (text, record, index) => {
        return <span>{index + 1 || '-'}</span>;
      },
    },
    {
      fixed: 'left',
      title: 'Nama Anak',
      render: (text, record) => {
        return <span>{record?.child?.fullname || '-'}</span>;
      },
    },
    {
      title: 'Nama Ayah',
      render: (text, record) => {
        return <span>{record?.child?.parents?.father_name || '-'}</span>;
      },
    },
    {
      title: 'Nama Ibu',
      dataIndex: 'age',
      key: 'age',
      render: (text, record) => {
        return <span>{record?.child?.parents?.mother_name || '-'}</span>;
      },
    },
    {
      title: 'Tanggal Request',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => {
        return <span>{moment(text).format('DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterResetToDefaultFilteredValue: true,
      defaultFilteredValue: ['requested'],
      // filters: FILTER_CERTIFICATE_BY_STATUS,
      // onFilter: (value, record) => {
      //   return record?.status?.startsWith(value);
      // },
      render: (text) => {
        const colorTag = (status) => {
          switch (status) {
            case STATUS_CERTIFICATE.SENT:
              return 'green';
            case STATUS_CERTIFICATE.REQUESTED:
              return 'orange';
            case STATUS_CERTIFICATE.WAITING_FOR_SIGNING:
              return 'orange';
            default:
              return 'red';
          }
        };

        return (
          <Tag
            bordered={false}
            color={colorTag(text)}
            style={{
              textTransform: 'capitalize',
              fontSize: 14,
            }}
          >
            {text === STATUS_CERTIFICATE.WAITING_FOR_SIGNING
              ? 'Waiting For Signing'
              : text
              ? text
              : '-'}
          </Tag>
        );
      },
    },
    {
      title: 'Vaksin Terakhir',
      dataIndex: 'age',
      key: 'age',
      render: (text, record) => {
        return (
          <span>
            {moment(record?.child?.parents?.last_vaccination_date).format(
              'DD/MM/YYYY'
            ) || '-'}
          </span>
        );
      },
    },
    {
      title: 'Tujuan Penggunaan Sertifikat',
      dataIndex: 'reason_for_request',
      key: 'reason_for_request',
      width: 300,
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: 'Aksi',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <Space size="small">
          <Button
            type="primary"
            onClick={() => onClickDetail(record?.id, record?.child_id)}
          >
            Detail
          </Button>
        </Space>
      ),
    },
  ];
};
