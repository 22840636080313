import React, { Component } from "react";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";

import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import AdminSidebar from "../../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../../BaseUrlApi/Url";
import "./EditVaccineType.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class EditVaccineType extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      vaccine_id: null,
      vaccine_type: "",
      frequency: null,
      description: "",
      isLoaded: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });

    let vaccineId = this.props.match.params.value;
    let getVaccine = await axios.get(
      `${API_URL}/admin/vaccine_types/${vaccineId}`,
      { headers }
    );
    await this.setState({
      vaccine_type: getVaccine.data.vaccine_type.name,
      vaccine_id: vaccineId,
      frequency: getVaccine.data.vaccine_type.frequency,
      description: getVaccine.data.vaccine_type.description,
    });
    this.setState({ isLoaded: false });
  }

  inputHandler = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleKeyPress = async (e) => {
    // e.preventDefault()
    if (e.key === "Enter") {
      this.updateVaccineType();
    }
  };

  updateVaccineType = async (e) => {
    const updateVaccine = {
      _method: "PATCH",
      name: this.state.vaccine_type,
      frequency: parseInt(this.state.frequency),
      description: this.state.description,
    };
    await axios
      .post(
        `${API_URL}/admin/vaccine_types/${this.state.vaccine_id}`,
        updateVaccine,
        { headers }
      )
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Tipe Vaksin Baru Telah Berhasil Diubah!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = "/vaccine-types";
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };
  cancelVaccineType = () => {
    window.location.href = "/vaccine-types";
  };
  render() {
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Tambah Tipe Vaksin</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Master Data</span>
                <span style={{ color: "#039BEB" }}>/ Daftar Tipe Vaksin</span>
                <span style={{ color: "#00000029" }}>
                  
                  / Tambah Tipe Vaksin
                </span>
              </h2>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <ValidationForm
                  onSubmit={this.updateVaccineType.bind(this)}
                  ref={this.formRef}
                  immediate={this.state.immediate}
                  setFocusOnError={this.state.setFocusOnError}
                >
                  <table className="add-table text-left">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Nama Tipe Vaksin
                          </label>
                        </td>
                        <td>
                          
                          :
                          <TextInput
                            type="text"
                            name="vaccine_type"
                            value={this.state.vaccine_type}
                            onChange={this.inputHandler}
                            onKeyPress={this.handleKeyPress}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <br />
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Deskripsi
                          </label>
                          <span>:</span>
                        </td>
                        <td>
                          <textarea
                            type="text"
                            name="description"
                            value={this.state.description}
                            onChange={this.inputHandler}
                            onKeyPress={this.handleKeyPress}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <br />
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Frekuensi
                          </label>
                        </td>
                        <td>
                          
                          :
                          <TextInput
                            type="text"
                            name="frequency"
                            value={this.state.frequency}
                            onChange={this.inputHandler}
                            onKeyPress={this.handleKeyPress}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ValidationForm>
                <div className="patient-buttons">
                  <button
                    className="btn btn-primary "
                    onClick={this.updateVaccineType}
                  >
                    Simpan
                  </button>
                  <button
                    className="btn btn-danger mr-1"
                    onClick={this.cancelVaccineType}
                  >
                    Batal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default EditVaccineType;
