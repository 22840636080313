import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import "react-datepicker/dist/react-datepicker.css";
import AdminSidebar from "../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../BaseUrlApi/Url";
import "./UpdateFamily.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};
class UpdateFamily extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      isLoaded: false,
      parent_id: null,
      user: null,
      email: '',
      father_name: "",
      mother_name: "",
      loading: true,
      father_job: {},
      mother_job: {},
      jobs: [],
      province_id: null,
      province: {},
      provinces: [],
      city_id: null,
      city: {},
      cities: [],
      district: {},
      districts: [],
      district_id: null,
      address: "",
      father_job_id: null,
      mother_job_id: null,
      phone_number: null,
      mother_phone_number:null,
      isFree: null,
      free: [],
      startDate: new Date(),
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let parentId = this.props.match.params.value;
    let getParent = await axios.get(`${API_URL}/admin/parents/${parentId}`, {
      headers,
    });
    this.setState({
      parent_id: parentId,
      father_name: getParent.data.parent.father_name,
      mother_name: getParent.data.parent.mother_name,
      address: getParent.data.parent.address,
      phone_number: getParent.data.parent.phone_number,
      mother_phone_number: getParent.data.parent.mother_phone_number,
      father_job_id: getParent.data.parent.father_job_id,
      mother_job_id: getParent.data.parent.mother_job_id,
      province_id: getParent.data.parent.province_id,
      city_id: getParent.data.parent.city_id,
      district_id: getParent.data.parent.district_id,
      isFree: getParent.data.parent.is_free,
      user: getParent.data.parent.user,
      email: getParent.data.parent.user !== null ? getParent.data.parent.user.email : ''
    });

    this.getDistricts();
    this.getJobs();
    this.getFree();
    this.getProvinces();
    this.getCities();
    this.setState({ isLoaded: false });
  }

  getFree = async () => {
    let free = [
      {
        label: "Tidak Gratis",
        value: 0,
      },
      {
        label: "Gratis",
        value: 1,
      },
    ];
    this.setState({
      free: free,
    });
  };

  getJobs = async () => {
    let getJobs = await axios.get(`${API_URL}/admin/jobs`, { headers });
    let jobs = getJobs.data.jobs.map((job) => ({
      value: job.id,
      label: job.name,
    }));
    await this.setState({
      jobs: jobs,
    });

    let { father_job_id, mother_job_id } = this.state;
    console.log('job_father', father_job_id)
    let getFatherJob = await axios.get(`${API_URL}/jobs/${father_job_id}`);
    let getMotherJob = await axios.get(`${API_URL}/jobs/${mother_job_id}`);
    this.setState({
      father_job: {
        value: getFatherJob.data.job.id,
        label: getFatherJob.data.job.name,
      },
      mother_job: {
        value: getMotherJob.data.job.id,
        label: getMotherJob.data.job.name,
      },
    });
  };

  getProvinces = async () => {
    // GET PROVINCES
    let getProvinces = await axios.get(`${API_URL}/regions/provinces`, {
      headers,
    });
    let provinces = getProvinces.data.provinces.map((province) => ({
      value: province.id,
      label: province.name,
    }));

    this.setState({ provinces: provinces });
    let { province_id } = this.state;

    if (province_id !== null) {
      let getProvince = await axios.get(
        `${API_URL}/regions/provinces?id=${province_id}`,
        {
          headers,
        }
      );
      this.setState({
        province: {
          value: getProvince.data.provinces.id,
          label: getProvince.data.provinces.name,
        },
      });
    }
  };

  getCities = async () => {
    let { city_id } = this.state;
    if (city_id !== null) {
      let getCity = await axios.get(`${API_URL}/regions/cities?id=${city_id}`, {
        headers,
      });
      this.setState({
        city: {
          value: getCity.data.cities.id,
          label: getCity.data.cities.name,
        },
      });
    }
  };

  getDistricts = async () => {
    let { district_id } = this.state;
    if (district_id !== null) {
      let getDistrict = await axios.get(
        `${API_URL}/regions/districts?id=${district_id}`,
        { headers }
      );
      this.setState({
        district: {
          value: getDistrict.data.districts.id,
          label: getDistrict.data.districts.name,
        },
      });
    }
  };

  inputHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  fatherJobHandler = async (value) => {
    this.setState({
      father_job: {
        value: value.value,
        label: value.label,
      },
    });
  };

  motherJobHandler = async (value) => {
    this.setState({
      mother_job: {
        value: value.value,
        label: value.label,
      },
    });
  };

  provinceHandler = async (value) => {
    this.setState({ isLoaded: true });
    // GET CITIES
    let getCities = await axios.get(
      `${API_URL}/regions/provinces?id=${value.value}`,
      { headers }
    );
    let cities = getCities.data.provinces.cities.map((city) => ({
      value: city.id,
      label: city.name,
    }));

    this.setState({
      province: {
        value: value.value,
        label: value.label,
      },
      cities: cities,
      isLoaded: false,
    });
  };

  cityHandler = async (value) => {
    this.setState({ isLoaded: true });
    // GET DISTRICT LIST
    let getDistricts = await axios.get(
      `${API_URL}/regions/cities?id=${value.value}`,
      {
        headers,
      }
    );
    let districts = getDistricts.data.cities.districts.map((district) => ({
      value: district.id,
      label: district.name,
    }));
    this.setState({
      city: {
        value: value.value,
        label: value.label,
      },
      district: {},
      districts: districts,
      isLoaded: false,
    });
  };

  districtHandler = async (value) => {
    this.setState({
      district: {
        value: value.value,
        label: value.label,
      },
    });
  };

  isFreeHandler = async (e) => {
    await this.setState({
      isFree: parseInt(e.target.value),
    });
  };

  clearForm = async (e) => {
    this.setState({
      father_name: "",
      mother_name: "",
      father_job: {},
      mother_job: {},
      province: {},
      city: {},
      district: {},
      address: "",
      phone_number: "",
    });
  };

  postData = async (e) => {
    e.preventDefault();
    const postData = {
      _method: "PATCH",
      father_name: this.state.father_name,
      mother_name: this.state.mother_name,
      father_job_id: this.state.father_job.value,
      mother_job_id: this.state.mother_job.value,
      address: this.state.address,
      phone_number: this.state.phone_number,
      mother_phone_number: this.state.mother_phone_number,
      province_id: this.state.province.value,
      city_id: this.state.city.value,
      district_id: this.state.district.value,
      is_free: this.state.isFree,
    };

    

    await axios
      .post(`${API_URL}/admin/parents/${this.state.parent_id}`, postData, {
        headers,
      })
      .then(async(res) => {
        if(this.state.user !== null) {
          let formDataUser = {
            _method: "PATCH",
            email: this.state.email
          }
          await axios.post(`${API_URL}/admin/users/${this.state.user.id}`, formDataUser, {
            headers,
          })
        }
      })
      .then(() => {
        swal({
          title: "Sukses!",
          text: this.state.email === '' ? `Data Kepala Keluarga Telah Berhasil Diperbarui!`
          : `Data Kepala Keluarga dan Email user Telah Berhasil Diperbarui!`,
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/patients`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: err.response.data.message,
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };

  render() {
    let {
      father_name,
      mother_name,
      phone_number,
      mother_phone_number,
      address,
      jobs,
      father_job,
      mother_job,
      province,
      provinces,
      city,
      cities,
      districts,
      district,
      isFree,
      free,
      user,
      email
    } = this.state;

    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Ubah Kepala Keluarga</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Manajemen Anak</span>
                <span style={{ color: "#039BEB" }}>
                  / Daftar Kepala Keluarga
                </span>
                <span style={{ color: "#039BEB" }}>
                  / Detail Kepala Keluarga
                </span>
                <span style={{ color: "#00000029" }}>
                  / Ubah Kepala Keluarga
                </span>
              </h2>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <ValidationForm
                  onSubmit={this.postData.bind(this)}
                  ref={this.formRef}
                  immediate={this.state.immediate}
                  setFocusOnError={this.state.setFocusOnError}
                >
                  <table
                    cellPadding="10"
                    className="add-table text-left pl-5 mb-5"
                  >
                    <tbody>
                      {user !== null && (
                        <tr>
                          <td className="label-class">
                            <label htmlFor="" className="add-vaccine-label">
                              Email
                            </label>
                          </td>
                          <td>
                            :
                            <TextInput
                              type="email"
                              name="email"
                              value={email}
                              onChange={this.inputHandler}
                              className="add-vaccine-input"
                              // required
                            />
                          </td>
                        </tr>
                      )}
                      
                      <tr>
                        <td className="label-class">
                          <label htmlFor="" className="add-vaccine-label">
                            Nama Ayah
                          </label>
                        </td>
                        <td>
                          :
                          <TextInput
                            type="text"
                            name="father_name"
                            value={father_name}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label-class">
                          <label htmlFor="" className="add-vaccine-label">
                            Nama Ibu
                          </label>
                        </td>
                        <td>
                          :
                          <TextInput
                            type="text"
                            name="mother_name"
                            value={mother_name}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label-class">
                          <label htmlFor="" className="add-vaccine-label">
                            Pekerjaan Ayah
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                            options={jobs}
                            name="father_job"
                            value={father_job}
                            onChange={(value) => this.fatherJobHandler(value)}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label-class">
                          <label htmlFor="" className="add-vaccine-label">
                            Pekerjaan Ibu
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                            options={jobs}
                            name="mother_job"
                            value={mother_job}
                            onChange={(value) => this.motherJobHandler(value)}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label-class">
                          <label htmlFor="" className="add-vaccine-label">
                            Status Digratiskan
                          </label>
                        </td>
                        <td>
                          :
                          <div className="radio-style">
                            {free.map((res) => (
                              <label className="container">
                                <input
                                  type="radio"
                                  name="radio"
                                  id={res.label}
                                  value={res.value}
                                  onChange={this.isFreeHandler}
                                  checked={
                                    isFree === res.value ? "checked" : ""
                                  }
                                />
                                <span className="checkmark"></span>
                                {res.label}
                              </label>
                            ))}
                          </div>
                        </td>
                      </tr>
                      <tr className="selector">
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Provinsi
                          </label>
                        </td>
                        <td>
                          <span style={{ margin: "1.5rem 2rem 0 0" }}> : </span>
                          <Select
                            options={provinces}
                            value={province}
                            onChange={(value) => this.provinceHandler(value)}
                            className="select-selector"
                          />
                        </td>
                      </tr>
                      <tr className="selector">
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Kota/Kabupaten
                          </label>
                        </td>
                        <td>
                          <span style={{ margin: "1.5rem 2rem 0 0" }}> : </span>
                          <Select
                            options={cities}
                            value={city}
                            onChange={(value) => this.cityHandler(value)}
                            className="select-selector"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label-class">
                          <label htmlFor="" className="add-vaccine-label">
                            Kecamatan
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                            options={districts}
                            value={district}
                            onChange={(value) => this.districtHandler(value)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Alamat
                          </label>
                        </td>
                        <td>
                          :
                          <TextInput
                            type="text"
                            name="address"
                            value={address}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            No.Telp Ayah
                          </label>
                        </td>
                        <td>
                          :
                          <TextInput
                            type="text"
                            name="phone_number"
                            value={phone_number}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            No.Telp Ibu
                          </label>
                        </td>
                        <td>
                          :
                          <TextInput
                            type="text"
                            name="mother_phone_number"
                            value={mother_phone_number}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ValidationForm>
                <div className="patient-buttons">
                  <Link to="/patients">
                    <button
                      className="btn btn-primary mr-1"
                      onClick={this.postData}
                    >
                      Simpan
                    </button>
                    <button className="btn btn-danger mr-1">Batal</button>
                  </Link>
                  <button
                    className="btn btn-warning text-dark mr-1"
                    onClick={this.clearForm}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default UpdateFamily;
