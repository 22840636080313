import API from '../../../../config/axios';

export const onGetAllRequestCertificate = () => {
  const response = API.get('/vaccination-certificate-requests')
    .then((res) => {
      return {
        data:
          res?.data?.vaccination_certificate_requests?.map((item, index) => ({
            key: index + 1,
            no: index + 1,
            ...item,
          })) || [],
        error: false,
      };
    })
    .catch((err) => {
      return {
        data: [],
        error: true,
      };
    });

  return response;
};
