import React, { Component } from "react";
import swal from "sweetalert";
import queryString from "query-string";
import { API_URL } from "../../BaseUrlApi/Url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingOverlay from "react-loading-overlay";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../Navbar/Navbar";
import "./PatientNote.css";
import axios from "axios";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};
class PatientNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      queueId: null,
      childId: null,
      loading_button: false,
      isLoaded: true,
    };
  }

  async componentDidMount() {
    const { queueId, childId } = queryString.parse(this.props.location.search);
    try {
      let getQueue = await axios.get(`${API_URL}/nurse/queues/${queueId}`, {
        headers,
      });
      this.setState({
        note: getQueue.data.queue_info.note,
        isLoaded: false,
        queueId: queueId,
        childId: childId,
      });
    } catch (e) {
      swal({
        title: "GAGAL",
        text: "Gagal Mengambil Data",
        icon: "warning",
        type: "error",
        buttons: "OK",
      });
    }
  }
  noteHandler = async (e) => {
    await this.setState({
      note: e.target.value,
    });
  };

  saveNote = async (e) => {
    e.preventDefault();
    this.setState({ loading_button: true });
    let data = {
      _method: "PATCH",
      note: this.state.note,
    };
    await axios
      .post(`${API_URL}/nurse/queues/${this.state.queueId}`, data, { headers })
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Catatan Anak Telah Berhasil Ditambahkan!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/patient-detail?tab=onprocess&queueId=${this.state.queueId}`;
          } else {
            return;
          }
        });
      }).catch(e => {
        swal({
          title: "GAGAL",
          text: "Gagal Menyimpan Data",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
        this.setState({ loading_button: false });
      })
  };

  render() {
    let { note, queueId, isLoaded, loading_button } = this.state;
    return (
      <div>
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <Navbar
            navtitle="CATATAN"
            url={`/patient-detail?tab=onprocess&queueId=${queueId}`}
          />
          <div className="note-body">
            <textarea
              type="text"
              placeholder={note === null ? "Catatan Kesehatan Anak" : ""}
              value={note !== null ? note : ""}
              className="note-input"
              onChange={this.noteHandler}
            />
            <br />
            <button className="btn btn-primary" onClick={this.saveNote}>
              {loading_button ? (
                <div>
                  <FontAwesomeIcon icon={faSpinner} spin /> Loading
                </div>
              ) : (
                "SIMPAN"
              )}
            </button>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default PatientNote;
