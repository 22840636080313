import React from 'react';
import Select from 'react-select';

export default function DynamicVaccine(props) {
  const {
    vaccineTypes,
    dynamic_vaccine,
    vaccine_id,
    vaccine_selected,
    isHistory,
    period,
    vaccineChangeHandler,
    vaccineRemoveHandler,
  } = props;
  return (
    <span style={{ padding: '.5rem 0' }}>
      {!isHistory && <br />}
      <Select
        style={{ width: '1rem' }}
        // isMulti
        isDisabled={isHistory}
        // components={{ Placeholder }}
        placeholder={'Pilih Jenis Vaksin'}
        // styles={{
        // placeholder: base => ({
        //     ...base,
        //     // fontSize: '1em',
        //     color: 'black',
        //     // fontWeight: 400,
        // }),
        // }}
        options={vaccineTypes}
        value={vaccine_selected}
        onChange={(value) => {
          vaccineChangeHandler(vaccine_id, value, period);
        }}
        required
      />
      {/* </span> */}
      <br />
      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
        <input
          disabled
          style={{
            width: dynamic_vaccine.length < 2 ? '100%' : '70%',
            marginLeft: isHistory ? '0rem' : '1.3rem',
            cursor: 'not-allowed',
          }}
          type="text"
          name="period"
          placeholder="Masukkan Periode (Dengan angka)"
          value={period}
          onChange={(e) => {
            vaccineChangeHandler(vaccine_id, vaccine_selected, e.target.value);
          }}
          className="add-vaccine-input"
          required
        />
        {dynamic_vaccine.length > 1 && !isHistory && (
          <button
            className="btn btn-danger mr-1"
            onClick={() => {
              vaccineRemoveHandler(vaccine_id);
            }}
          >
            Hapus Data
          </button>
        )}
      </span>
      <hr />
    </span>
  );
}
