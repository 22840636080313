import React, { useEffect } from 'react';
import './FailedCheckin.css';

import useCountdown from '@bradgarropy/use-countdown';
import ICFailedCheckin from '../../../../../assets/images/ic-failed.png';

export const ModalFailedCheckin = ({ isOpen, onBack, onClose }) => {
  const countdown = useCountdown({
    seconds: 15,
    format: 'ss',
    onCompleted: () => onClose(),
  });

  useEffect(() => {
    if (!isOpen) {
      countdown.pause();
    } else {
      countdown.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div
      className="container-failed-checkin"
      style={{ display: isOpen ? 'flex' : 'none' }}
    >
      <div className="wrapper-failed-checkin">
        <div className="img-wrapper-failed-checkin">
          <img src={ICFailedCheckin} alt="ic-failed.png" />
        </div>

        <p className="title-failed-checkin">Check In Gagal</p>
        <p className="sub-title-failed-checkin">
          Anda gagal checkin. Silahkan coba pindai ulang QR Code anda
        </p>

        <button
          type="button"
          class="btn btn-link"
          style={{ color: '#f06b10' }}
          onClick={onBack}
        >
          Ke Halaman Utama ({countdown.formatted}) detik
        </button>

        <button
          type="button"
          class="btn btn-primary button-failed-checkin"
          onClick={onClose}
        >
          Coba Lagi
        </button>
      </div>
    </div>
  );
};
