import React, { Component } from 'react';
import swal from 'sweetalert';
import Select from 'react-select';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { API_URL } from '../../../BaseUrlApi/Url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'react-loading-overlay';
import Navbar from '../../Navbar/Navbar';
import axios from 'axios';
import DynamicVaccineNurse from '../../../CustomJS/DynamicVaccineNurse';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};
class Vaccination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: undefined,
      isLoaded: false,
      status: null,
      queueId: null,
      childId: null,
      nurseId: null,
      startDate: new Date(),
      vaccinationId: null,
      date_plan: null,
      date_taken: null,
      vaccine_type_id: null,
      vaccine_id: null,
      vaccine_types: [],
      vaccine_type: {},
      vaccine_brand: {},
      dynamic_vaccine: [],
      isHistory: false,
      shifts: [],
      shift: {},
      lastChoice: 0,
      vaccines: [],
      vaccineDatas: [],
      vaccine: {},
      period: null,
      batch: '',
      vaccination: [],
      loading_button: false,
      excludeDate: [],
    };
  }
  async componentDidMount() {
    let nurseId = localStorage.getItem('nurse_id');
    const { tab, queueId, childId, vaccinationId, status } = queryString.parse(
      this.props.location.search
    );

    this.setState({
      tab,
      queueId,
      childId,
      nurseId,
      status: parseInt(status),
      isLoaded: true,
    });

    try {
      // GET QUEUE DATA
      let getVaccination = await axios.get(
        `${API_URL}/nurse/vaccinations/${vaccinationId}`,
        {
          headers,
        }
      );

      // let filterVaccineTypes = getVaccination.data.vaccination.
      this.setState({
        vaccinationId: getVaccination.data.vaccination.id,
        date_taken: Date.parse(
          new Date(getVaccination.data.vaccination.date_taken).toUTCString()
        ),
        date_plan: getVaccination.data.vaccination.date_plan,
        // vaccine_type_id: getVaccination.data.vaccination.vaccine_type_id,
        // vaccine_id: getVaccination.data.vaccination.vaccine_id,
        // period: getVaccination.data.vaccination.period,
        batch: getVaccination.data.vaccination.batch,
      });

      // SET DATE TAKEN & DATE PLAN
      // this.dateTakenHandler(this.state.startDate);

      let getVaccines = await axios.get(`${API_URL}/nurse/vaccines`, {
        headers,
      });

      let getVaccineTypes = await axios.get(`${API_URL}/nurse/vaccine_types`, {
        headers,
      });
      let vaccine_types = getVaccineTypes.data.data.map((type) => ({
        value: type.id,
        label: type.name,
      }));

      this.setState({
        vaccineDatas: getVaccines.data.vaccines,
      });

      if (this.state.status === 1) {
        if (getVaccination.data.vaccination.vaccine_id !== null) {
          let filterValueVaccine =
            getVaccination.data.vaccination.vaccine_types.map((item, i) => {
              return {
                id: i + 1,
                vaccine_selected: {
                  value: item.id,
                  label: item.name,
                },
                period: item.pivot.period,
              };
            });
          // console.log
          this.setState({
            isHistory: true,
            vaccine_types: getVaccines.data.vaccines
              .filter(
                (x) => x.id === getVaccination.data.vaccination.vaccine_id
              )[0]
              .vaccine_types.map((itemx) => {
                return {
                  value: itemx.id,
                  label: itemx.name,
                };
              }),
            vaccines: getVaccines.data.vaccines.map((itemj) => {
              return {
                value: itemj.id,
                label: itemj.brand_name,
              };
            }),
            vaccine_brand: {
              value: getVaccination.data.vaccination.vaccine.id,
              label: getVaccination.data.vaccination.vaccine.brand_name,
            },
            lastChoice: this.state.lastChoice + filterValueVaccine.length,
            dynamic_vaccine: filterValueVaccine,
          });
        } else {
          const newVaccine = [
            {
              id: this.state.lastChoice + 1,
              vaccine_selected: null,
              period: '',
            },
          ];
          this.setState({
            vaccines: getVaccines.data.vaccines.map((itemj) => {
              return {
                value: itemj.id,
                label: itemj.brand_name,
              };
            }),
            // vaccine_types: vaccine_types,
            lastChoice: this.state.lastChoice + 1,
            dynamic_vaccine: newVaccine,
          });
        }
      } else {
        let getShifts = await axios.get(`${API_URL}/nurse/shifts`, { headers });
        let shifts = getShifts.data.shifts.map((shift) => ({
          value: shift.id,
          label: shift.name,
        }));
        let filterSelectShift = {
          value: getVaccination.data.vaccination.queue.shift.id,
          label: getVaccination.data.vaccination.queue.shift.name,
        };
        let filterValueVaccine =
          getVaccination.data.vaccination.vaccine_types.map((item, i) => {
            return {
              id: i + 1,
              vaccine_selected: {
                value: item.id,
                label: item.name,
              },
              period: item.pivot.period,
            };
          });
        this.setState({
          lastChoice: this.state.lastChoice + filterValueVaccine.length,
          dynamic_vaccine: filterValueVaccine,
          shifts: shifts,
          shift: filterSelectShift,
          vaccine_types: vaccine_types,
          date_taken: Date.parse(
            new Date(getVaccination.data.vaccination.date_plan).toUTCString()
          ),
        });
      }

      // GET VACCINE TYPES & VACCINE
      // let getVaccineTypes = await axios.get(`${API_URL}/nurse/vaccine_types`, {
      //   headers,
      // });
      // let vaccine_types = getVaccineTypes.data.data.map((type) => ({
      //   value: type.id,
      //   label: type.name,
      // }));

      // await this.setState({
      //   vaccine_types: vaccine_types,
      // });

      const { data: dataDates } = await axios.get(`${API_URL}/blocked_dates`, {
        headers,
      });

      //parse date to format string long to ISO
      let filterBlockedDates = await dataDates.blockedDates.map((item) =>
        Date.parse(new Date(item.date).toUTCString())
      );

      const { data: dataDatesKonsulOnline } = await axios.get(
        `${API_URL}/blocked_dates?shift_type=konsultasi_online`,
        {
          headers,
        }
      );

      let filterBlockedDatesKonsultasiOnline =
        await dataDatesKonsulOnline.blockedDates.map((item) =>
          Date.parse(new Date(item.date).toUTCString())
        );

      this.setState({
        excludeDate: [
          ...filterBlockedDatesKonsultasiOnline,
          ...filterBlockedDates,
        ],
      });
      console.log('vaccine_id: ', getVaccination.data.vaccination.vaccine_id);
      // FOR READ AND UPDATE DATA VACCINE TYPES & VACCINE
      // if (getVaccination.data.vaccination.vaccine_id !== null) {
      //   let getVaccine = await axios.get(
      //     `${API_URL}/nurse/vaccines/${this.state.vaccine_id}`,
      //     { headers }
      //   );
      //   this.setState({
      //     vaccine: {
      //       value: getVaccine.data.data.id,
      //       label: getVaccine.data.data.brand_name,
      //     },
      //   });
      // }

      // if (getVaccination.data.vaccination.vaccine_id !== null) {
      //   let getVaccineType = await axios.get(
      //     `${API_URL}/nurse/vaccine_types/${this.state.vaccine_type_id}`,
      //     { headers }
      //   );
      //   let getVaccines = await axios.get(
      //     `${API_URL}/nurse/vaccines?vaccine_type_id=${this.state.vaccine_type_id}`,
      //     { headers }
      //   );
      //   let vaccines = getVaccines.data.vaccines.map((vaccine) => ({
      //     value: vaccine.id,
      //     label: vaccine.brand_name,
      //   }));
      //   this.setState({
      //     vaccine_type: {
      //       value: getVaccineType.data.vaccine_type.id,
      //       label: getVaccineType.data.vaccine_type.name,
      //     },
      //     vaccines: vaccines,
      //   });
      // }
    } catch (e) {
      console.log(e);
      swal({
        title: 'GAGAL',
        text: 'Gagal Mengambil Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }

    // this.getInitialSelectValue();
    this.setState({ isLoaded: false });
  }

  vaccineType = async (value) => {
    this.setState({ isLoaded: true });
    let getVaccines = await axios.get(
      `${API_URL}/nurse/vaccines?vaccine_type_id=${value.value}`,
      { headers }
    );
    let vaccines = getVaccines.data.vaccines.map((vaccine) => ({
      value: vaccine.id,
      label: vaccine.brand_name,
    }));
    this.setState({
      vaccine_type: {
        value: value.value,
        label: value.label,
      },
      vaccines: vaccines,
    });
    this.setState({ isLoaded: false });
  };

  // vaccine = async (value) => {
  //   this.setState({
  //     vaccine: {
  //       value: value.value,
  //       label: value.label,
  //     },
  //   });
  // };
  vaccineChangeHandler = (vaccine_id, newVaccineSelected, newPeriod) => {
    const newVaccine = {
      id: vaccine_id,
      vaccine_selected: {
        value: newVaccineSelected.value,
        label: newVaccineSelected.label,
      },
      period: newPeriod,
    };

    const oldVaccine = this.state.dynamic_vaccine.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    let newVaccines = [...oldVaccine, newVaccine];
    newVaccines.sort((a, b) => a.id - b.id);
    this.setState({
      dynamic_vaccine: newVaccines,
    });
  };

  vaccineRemoveHandler = (vaccine_id) => {
    const vaccinesValue = [...this.state.dynamic_vaccine];
    const newVaccines = vaccinesValue.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    this.setState({
      dynamic_vaccine: newVaccines,
    });
  };

  vaccineAddHandler = () => {
    let objValue = this.state.dynamic_vaccine.find(
      (c) => c.period === '' || c.vaccine_selected === null
    );
    if (objValue !== undefined) {
      return;
    }

    const newVaccine = {
      id: this.state.lastChoice + 1,
      vaccine_selected: null,
      period: '',
    };
    this.setState({
      lastChoice: this.state.lastChoice + 1,
      dynamic_vaccine: [...this.state.dynamic_vaccine, newVaccine],
    });
  };

  inputHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // deleteVaccination = async (e) => {
  //   let { tab, queueId, vaccinationId } = this.state;
  //   if (vaccinationId === null) {
  //     swal({
  //       title: "GAGAL",
  //       text: "Tidak Ada Vaksinasi",
  //       icon: "warning",
  //       type: "error",
  //       buttons: "OK",
  //     });
  //   } else {
  //     swal({
  //       title: "Yakin Ingin Menghapus?",
  //       icon: "warning",
  //       buttons: ["Batal", "Hapus"],
  //       dangerMode: true,
  //     })
  //       .then(async (move) => {
  //         if (move) {
  //           await axios
  //             .delete(`${API_URL}/nurse/vaccinations/${vaccinationId}`, {
  //               headers,
  //             })
  //             .then((res) => {
  //               swal({
  //                 title: "Sukses!",
  //                 text: "Vaksinasi Berhasil Dihapus!",
  //                 icon: "success",
  //                 button: "OK",
  //               }).then((move) => {
  //                 if (move) {
  //                   if (tab === "onprocess") {
  //                     window.location.href = `/patient-detail?tab=onprocess&queueId=${queueId}`;
  //                   } else if (tab === "done") {
  //                     window.location.href = `/patient-detail?tab=done&queueId=${queueId}`;
  //                   }
  //                 } else {
  //                   return;
  //                 }
  //               });
  //             })
  //             .catch((err) => {
  //               swal({
  //                 title: "GAGAL",
  //                 text: "Data Gagal Dihapus",
  //                 icon: "warning",
  //                 type: "error",
  //                 buttons: "OK",
  //               });
  //             });
  //         } else {
  //           return;
  //         }
  //       })
  //       .catch((err) => {
  //         swal({
  //           title: "GAGAL",
  //           text: "Data Gagal Dihapus",
  //           icon: "warning",
  //           type: "error",
  //           buttons: "OK",
  //         });
  //       });
  //   }
  // };
  saveVaccination = async (e) => {
    e.preventDefault();
    let { tab, queueId, nurseId, childId, status } = this.state;
    this.setState({ loading_button: true });

    if (status === 1) {
      let data = {
        _method: 'PATCH',
        vaccine_types: this.state.dynamic_vaccine.map((item) => {
          return {
            id: item.vaccine_selected.value,
            period: item.period,
          };
        }),
        // vaccine_type_id: this.state.vaccine_type.value,
        date_plan: moment(this.state.date_taken).format('YYYY-MM-DD'),
        vaccine_id: this.state.vaccine_brand.value,
        // period: parseInt(this.state.period),
        batch: this.state.batch,
        child_id: childId,
        nurse_id: parseInt(nurseId),
        queue_id: queueId,
        date_taken: moment(this.state.date_taken).format('YYYY-MM-DD'),
      };
      await axios
        .post(
          `${API_URL}/nurse/vaccinations/${this.state.vaccinationId}`,
          data,
          {
            headers,
          }
        )
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Data Berhasil Diubah!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.location.href = `/patient-detail?tab=${tab}&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          this.setState({ loading_button: false });
          swal({
            title: 'GAGAL',
            text: 'Data Gagal Disimpan',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });
    } else {
      console.log();
      let data = {
        _method: 'PATCH',
        child_id: childId,
        vaccine_types: this.state.dynamic_vaccine.map((item) => {
          return {
            id: item.vaccine_selected.value,
            period: item.period,
          };
        }),
        date_plan: moment(this.state.date_taken).format('YYYY-MM-DD'),
        // period: parseInt(this.state.period),
        shift_id: this.state.shift.value,
        nurse_id: parseInt(nurseId),
      };
      await axios
        .post(
          `${API_URL}/nurse/vaccinations/${this.state.vaccinationId}`,
          data,
          {
            headers,
          }
        )
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Data Berhasil Diubah!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.location.href = `/patient-detail?tab=${tab}&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          this.setState({ loading_button: false });
          swal({
            title: 'GAGAL',
            text: 'Data Gagal Disimpan',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });
    }
  };

  render() {
    let {
      tab,
      status,
      queueId,
      isLoaded,
      dynamic_vaccine,
      vaccines,
      vaccine_types,
      vaccineDatas,
      isHistory,
      vaccine_brand,
      lastChoice,
      loading_button,
      date_taken,
      excludeDate,
    } = this.state;
    const isWeekday = (date) => {
      // console.log(date)
      const day = date.getDay();
      return day !== 0;
    };
    return (
      <div className="nurse-overlay">
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <Navbar
            navtitle="Vaksinasi"
            url={`/patient-detail?tab=${tab}&queueId=${queueId}`}
          />
          <div className="vaccination-body">
            {status !== null ? (
              <div>
                <div>
                  <DatePicker
                    selected={date_taken}
                    onChange={(e) => {
                      console.log(e);
                      this.setState({ date_taken: e });
                    }}
                    excludeDates={excludeDate}
                    filterDate={isWeekday}
                    // minDate={new Date()}
                    dateFormat="dd MMMM, yyyy"
                    placeholderText={
                      status === 2
                        ? 'Tanggal Kunjungan Vaksin'
                        : 'Tanggal Pengambilan Data saat ini'
                    }
                  />
                </div>
                {status === 1 ? (
                  <div className="merk-vaccine">
                    <span style={{ float: 'left' }}>Merek Vaksin</span>
                    <Select
                      placeholder={'Pilih Merek Vaksin'}
                      options={vaccines}
                      value={vaccine_brand}
                      onChange={(value) => {
                        console.log(value);
                        // const newVaccine = [
                        //   {
                        //     id: lastChoice+1,
                        //     vaccine_selected: null,
                        //     period: ''
                        //   }
                        // ]
                        let filterVaccineType = vaccineDatas.filter(
                          (x) => x.id === value.value
                        )[0];
                        // console.log(vaccineDatas.filter(x => x.id === value.value)[0])
                        this.setState({
                          isHistory: true,
                          vaccine_brand: value,
                          vaccine_types: filterVaccineType.vaccine_types.map(
                            (itemj) => {
                              return {
                                value: itemj.id,
                                label: itemj.name,
                              };
                            }
                          ),
                          dynamic_vaccine: filterVaccineType.vaccine_types.map(
                            (itemg, i) => {
                              return {
                                id: i + 1,
                                vaccine_selected: {
                                  value: itemg.id,
                                  label: itemg.name,
                                },
                                period: '',
                              };
                            }
                          ),
                          lastChoice: filterVaccineType.vaccine_types.length,
                        });
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className="merk-vaccine">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>Vaksin dan Periode</span>
                    <button
                      style={{
                        display:
                          vaccine_types.length > 1 && !isHistory ? '' : 'none',
                      }}
                      className="btn btn-success "
                      onClick={this.vaccineAddHandler}
                    >
                      {/* {loading_button ? "..Loading.." : "SIMPAN"} */}
                      Tambah Data
                    </button>
                  </div>
                  {/* <Select
                    options={this.state.vaccine_types}
                    value={this.state.vaccine_type}
                    onChange={(value) => this.vaccineType(value)}
                    Placeholder="massukkan data"
                  /> */}
                  {dynamic_vaccine.map((vaccine, i) => (
                    <DynamicVaccineNurse
                      isHistory={isHistory}
                      vaccineTypes={vaccine_types}
                      dynamic_vaccine={dynamic_vaccine}
                      key={i}
                      vaccine_id={vaccine.id}
                      vaccine_selected={vaccine.vaccine_selected}
                      period={vaccine.period}
                      vaccineChangeHandler={this.vaccineChangeHandler}
                      vaccineRemoveHandler={this.vaccineRemoveHandler}
                    />
                  ))}
                </div>
                {/* STATUS 1 = EDIT DATA VACCINATION
                    STATUS 2 = EDIT JADWAL VACCINATION
                */}

                {/* <input
                  type="number"
                  name="period"
                  placeholder="Periode"
                  value={this.state.period}
                  className="vaccination-input"
                  onChange={this.inputHandler}
                /> */}
                {status === 1 ? (
                  <input
                    type="text"
                    name="batch"
                    placeholder="Batch"
                    value={this.state.batch}
                    className="vaccination-input"
                    onChange={this.inputHandler}
                  />
                ) : (
                  ''
                )}
                {status === 2 && (
                  <Select
                    options={this.state.shifts}
                    value={this.state.shift}
                    onChange={(value) => {
                      this.setState({
                        shift: value,
                      });
                    }}
                    Placeholder="Masukkan Data Shift"
                  />
                )}

                <button
                  className="btn btn-primary btn-saveschedule"
                  onClick={this.saveVaccination}
                >
                  {loading_button ? (
                    <div>
                      <FontAwesomeIcon icon={faSpinner} spin /> Loading
                    </div>
                  ) : (
                    'SIMPAN'
                  )}
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default Vaccination;
