import React, { Component } from 'react';
import axios from 'axios';
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ModalBody, Form, Button, Alert } from 'react-bootstrap';
import { RadioButton } from '../../../CustomJS/RadioButton';
import Radio from '../../../CustomJS/Radio';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import { API_URL } from '../../../BaseUrlApi/Url';
import { SRLWrapper } from 'simple-react-lightbox';
import './DetailActiveQueue.css';
import WhatsappLogo from '../../../../assets/icons/whatsapp.svg';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

class DetailActiveQueueConsult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      childId: null,
      queueId: null,
      shiftId: null,
      child_name: '',
      birthdate: '',
      gender: null,
      father_name: '',
      mother_name: '',
      address: '',
      shift: '',
      date: '',
      note: null,
      nurse: null,
      period: null,
      vaccination: null,
      isOpen: false,
      isOpen2: false,
      vaccine_type: null,
      vaccine: null,
      vaccine_id: null,
      date_plan: null,
      date_taken: null,
      nurse_id: null,
      statuses: [],
      status: {},
      health_worker: null,
      is_free: null,
      // mother_phone_number:null,
      phone_number_link: '',
      phone_number: null,
      // father_phone_number:null,
      payment_confirmation: null,
      payment_status: null,
      isShow: false,
      valueOther: '',
      radioValue: null,
      selectedRefused: null,
      paymentRefuseds: [],
    };
  }

  handleChangeOther = (other) => {
    this.setState({ valueOther: other });
  };

  async componentDidMount() {
    this.setState({ isLoaded: true });

    let queueId = parseInt(this.props.match.params.value);
    let shiftId = parseInt(this.props.match.params.shiftId);
    let getQueue = await axios.get(`${API_URL}/admin/queues/${queueId}`, {
      headers,
    });
    let getQueueStatuses = await axios.get(`${API_URL}/admin/queue_statuses`, {
      headers,
    });
    let getPaymentRefused = await axios.get(
      `${API_URL}/admin/payment_rejection_reasons`,
      {
        headers,
      }
    );
    let queueStatuses = getQueueStatuses.data.queueStatuses
      .filter((x) => ![8, 3].includes(x.id))
      .map((status) => ({
        value: status.id,
        label: status.name,
      }));
    await this.setState({
      payment_confirmation: getQueue.data.queue_info.payment_confirmation,
      payment_status: getQueue.data.queue_info.payment_status,
      childId: getQueue.data.queue_info.child_id,
      queueId: queueId,
      child_name: getQueue.data.queue_info.child.fullname,
      birthdate: getQueue.data.queue_info.child.birthdate,
      gender: getQueue.data.queue_info.child.gender,
      father_name: getQueue.data.queue_info.child.parents.father_name,
      // father_phone_number: getQueue.data.queue_info.child.parents.phone_number,
      mother_name: getQueue.data.queue_info.child.parents.mother_name,
      // mother_phone_number: getQueue.data.queue_info.child.parents.mother_phone_number,
      address: getQueue.data.queue_info.child.parents.address,
      health_worker: getQueue.data.queue_info.child.parents.is_health_worker,
      is_free: getQueue.data.queue_info.child.parents.is_free,
      shift:
        getQueue.data.queue_info.shift.name +
        ' (' +
        getQueue.data.queue_info.shift.start_time +
        ' - ' +
        getQueue.data.queue_info.shift.end_time +
        ')',
      date: getQueue.data.queue_info.date,
      shiftId: shiftId,
      note: getQueue.data.queue_info.note,
      statuses: queueStatuses,
      status: {
        value: getQueue.data.queue_info.queue_status.id,
        label: getQueue.data.queue_info.queue_status.name,
      },
      phone_number_link:
        getQueue.data.queue_info.phone_number.toString().charAt(0) === '0'
          ? `https://wa.me/62${parseInt(
              getQueue.data.queue_info.phone_number,
              10
            )}`
          : `https://wa.me/${parseInt(
              getQueue.data.queue_info.phone_number,
              10
            )}`,
      phone_number: getQueue.data.queue_info.phone_number,
      vaccination:
        getQueue.data.queue_info.vaccinations.length > 0
          ? getQueue.data.queue_info.vaccinations
          : null,
      paymentRefuseds: getPaymentRefused.data.paymentRejectionReasons,
    });

    let date = new Date(this.state.birthdate);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_date = dd + '-' + mm + '-' + yyyy;
    this.setState({
      birthdate: set_date,
    });

    this.setState({ isLoaded: false });

    if (this.state.vaccination !== null) {
      let getVaccineTypeId = await axios.get(
        `${API_URL}/admin/vaccine_types/${this.state.vaccination[0].vaccine_type_id}`,
        { headers }
      );
      this.setState({ vaccine_type: getVaccineTypeId.data.vaccine_type.name });
      let getVaccine = await axios.get(
        `${API_URL}/admin/vaccines/${this.state.vaccination[0].vaccine_id}`,
        { headers }
      );
      this.setState({ vaccine: getVaccine.data.data.brand_name });
      let getNurse = await axios.get(
        `${API_URL}/admin/users/${this.state.vaccination[0].nurse_id}`,
        { headers }
      );
      await this.setState({ nurse: getNurse.data.user.name });
    }
  }

  queueStatusPayment = (id) => {
    let formData = {
      _method: 'PATCH',
      payment_status_id: id,
      payment_rejection_reason_id: this.state.selectedRefused,
    };

    function clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
    }
    clean(formData);
    swal({
      title: `Ingin Mengubah Status Pembayaran Pasien ke 
      ${
        id === 3
          ? 'Sudah Bayar?'
          : id === 4
          ? 'Pembayaran Ditolak?'
          : 'Pembayaran dikembalikan?'
      }`,
      icon: 'warning',
      buttons: {
        cancel: 'Batal!',
        catch: {
          text: 'Ya, Ubah',
          value: 'continue',
        },
      },
      dangerMode: true,
    }).then(async (move) => {
      try {
        switch (move) {
          case 'continue':
            this.setState({ isShow: false });
            this.setState({ isLoaded: true });
            const { data } = await axios.post(
              `${API_URL}/admin/queues/${this.state.queueId}`,
              formData,
              { headers }
            );
            this.setState({ isLoaded: false });
            swal({
              title: 'Berhasil mengubah Status Pembayaran Pasien',
              // text: "Dokter Tidak Di Klinik",
              icon: 'success',
              button: 'OK',
            }).then((move) => {
              if (move) {
                window.history.back();
              } else {
                return;
              }
            });
            break;

          default:
            this.setState({ isLoaded: false });
            break;
        }
      } catch (e) {
        console.log(e);
        console.log('error', e.response.data.message);
        this.setState({ isLoaded: false });
        swal({
          title: e.response.data.message,
          // text: "Dokter Tidak Di Klinik",
          icon: 'warning',
          type: 'error',
          button: 'OK',
        });
      }
    });
  };

  showModal = async (e) => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  hideModal = async (e) => {
    this.setState({ isOpen: false });
  };

  showModal2 = async (e) => {
    this.setState({ isOpen2: !this.state.isOpen });
  };

  hideModal2 = async (e) => {
    this.setState({ isOpen2: false });
  };

  statusHandler = async (value) => {
    this.setState({
      status: {
        value: value.value,
        label: value.label,
      },
    });
  };

  dateConverter(value) {
    let date = new Date(value);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_date = dd + '-' + mm + '-' + yyyy;
    return set_date;
  }

  updateStatus = async () => {
    let update_status = {
      _method: 'PATCH',
      queue_status_id: this.state.status.value,
    };

    axios
      .post(`${API_URL}/admin/queues/${this.state.queueId}`, update_status, {
        headers,
      })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Data Antrian Telah Berhasil Diubah!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/queues-online/${this.state.shiftId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Status Gagal Diperbarui',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };
  render() {
    let {
      child_name,
      birthdate,
      gender,
      father_name,
      mother_name,
      address,
      shift,
      date,
      nurse,
      shiftId,
      status,
      vaccination,
      statuses,
      vaccine_type,
      health_worker,
      vaccine,
      phone_number,
      phone_number_link,
      payment_confirmation,
      payment_status,
      isShow,
      valueOther,
      selectedRefused,
      paymentRefuseds,
      radioValue,
    } = this.state;

    const options = {
      settings: {
        overlayColor: 'rgba(43, 45, 66, 0.95)',
        transitionTimingFunction: 'ease-in-out',
        slideTransitionSpeed: 0.8,
        slideTransitionTimingFunction: [0.25, 0.75, 0.5, 1],
        slideAnimationType: 'slide',
        disablePanzoom: false,
        disableWheelControls: true,
        hideControlsAfter: true,
      },
      buttons: {
        showDownloadButton: false,
        showAutoplayButton: false,
        showNextButton: false,
        showPrevButton: false,
      },
      thumbnails: {
        showThumbnails: false,
      },
    };

    return (
      <div className="admin-overlay">
        <AdminSidebar />

        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="user-page-title">Detail Anak</h1>
              <h2 className="user-page-subtitle">
                <span style={{ color: '#039BEB' }}>Antrian</span>
                <Link to={`/queues-online/${this.state.shiftId}`}>
                  <span style={{ color: '#039BEB' }}>
                    / Daftar Antrian Online
                  </span>
                </Link>

                <span style={{ color: '#00000029' }}> / Detail Anak</span>
              </h2>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="btn-worker">
                  {health_worker === 1 ? (
                    <button className="btn btn-info">
                      Orang Tua Pekerja Kesehatan
                    </button>
                  ) : (
                    ''
                  )}
                  {health_worker === 1 ? (
                    <button
                      className="btn btn-primary"
                      style={{ marginLeft: '1rem' }}
                    >
                      Bisa Digratiskan
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                {status !== null ? (
                  status.value === 7 && payment_status.id === 3 ? (
                    <Alert
                      variant="danger"
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto auto',
                        gap: '0.5em',
                      }}
                    >
                      <div>
                        <h5>Pembayaran tiket Belum dikembalikan</h5>
                        <p>
                          Pengembalian pembayaran harus dilakukan paling lama 2
                          x 24 jam
                        </p>
                      </div>
                      <div style={{ placeSelf: 'center' }}>
                        <Button
                          variant="primary"
                          block
                          // disabled={radioValue === ''}

                          onClick={() => this.queueStatusPayment(5)}
                        >
                          Konfirmasi Pengembalian Pembayaran
                        </Button>
                      </div>
                    </Alert>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    gap: '0.5em',
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignContent: 'space-between',
                        flexDirection: 'row',
                      }}
                    >
                      <div style={{ margin: '0 0.5em', alignSelf: 'center' }}>
                        <h5 style={{ margin: '0' }}>Status Tiket </h5>
                      </div>

                      {status !== null ? (
                        status.value === 5 ? (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid rgb(63, 188, 7)',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: 'rgb(63, 188, 7)',
                              }}
                            >
                              {status.label}
                            </p>
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                      {status !== null ? (
                        status.value === 1 ||
                        status.value === 2 ||
                        status.value === 4 ||
                        status.value === 6 ? (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid #FA9000',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: '#FA9000',
                              }}
                            >
                              {status.label}
                            </p>
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                      {status !== null ? (
                        status.value === 7 ? (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid red',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: 'red',
                              }}
                            >
                              {status.label}
                            </p>
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    <p className="account-title">Anak</p>
                    <table
                      cellPadding="10"
                      className="active-queue-table text-left ml-3"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <label htmlFor="">Nama</label>
                          </td>
                          <td> : {child_name} </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="">Tanggal Lahir</label>
                          </td>
                          <td> : {birthdate !== null ? birthdate : '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="">Jenis Kelamin</label>
                          </td>
                          <td>
                            : {gender === 'male' ? 'Laki-laki' : 'Perempuan'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <p className="account-title">Orang Tua</p>
                    <table
                      cellPadding="10"
                      className="active-queue-table text-left ml-3"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <label htmlFor="">Nama Ayah</label>
                          </td>
                          <td> : {father_name !== null ? father_name : '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="">Nama Ibu</label>
                          </td>
                          <td> : {mother_name !== null ? mother_name : '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="">Alamat</label>
                          </td>
                          <td> : {address !== null ? address : '-'}</td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="">No.Telp</label>
                          </td>
                          <td> : {phone_number}</td>
                        </tr>
                        {payment_status !== null
                          ? payment_status.id === 3 && (
                              <tr>
                                <td>
                                  <a href={phone_number_link} target="_blank">
                                    <Button>
                                      <img
                                        src={WhatsappLogo}
                                        alt="whatsapp_ortu"
                                        style={{ width: '20px' }}
                                      />{' '}
                                      Mulai Konsultasi
                                    </Button>
                                  </a>
                                </td>
                              </tr>
                            )
                          : ''}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <p className="account-title">Waktu</p>
                    <table
                      cellPadding="10"
                      className="active-queue-table text-left ml-3"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <label htmlFor="">Kunjungan</label>
                          </td>
                          <td> : {shift}</td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="">Tanggal</label>
                          </td>
                          <td> : {this.dateConverter(date)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        alignContent: 'space-between',
                        flexDirection: 'row',
                      }}
                    >
                      <div style={{ margin: '0 0.5em', alignSelf: 'center' }}>
                        <h5 style={{ margin: '0' }}>Detail Pembayaran </h5>
                      </div>
                      {payment_status !== null ? (
                        payment_status.id === 2 ? (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid #039beb',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: '#039beb',
                              }}
                            >
                              {payment_status.name}
                            </p>
                          </div>
                        ) : payment_status.id === 3 ? (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid #3FBC07',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: '#3FBC07',
                              }}
                            >
                              {payment_status.name}
                            </p>
                          </div>
                        ) : (
                          <div
                            style={{
                              margin: '0 0.5em',
                              alignSelf: 'center',
                              border: '1px solid red',
                              borderRadius: '5px',
                            }}
                          >
                            <p
                              style={{
                                margin: '0',
                                padding: '.5em',
                                color: 'red',
                              }}
                            >
                              {payment_status.name}
                            </p>
                          </div>
                        )
                      ) : (
                        <div
                          style={{
                            margin: '0 0.5em',
                            alignSelf: 'center',
                            border: '1px solid red',
                            borderRadius: '5px',
                          }}
                        >
                          <p
                            style={{
                              margin: '0',
                              padding: '.5em',
                              color: 'red',
                            }}
                          >
                            Belum Bayar
                          </p>
                        </div>
                      )}
                    </div>
                    <table
                      cellPadding="10"
                      className="active-queue-table text-left ml-3"
                    >
                      <tbody>
                        {payment_status !== null ? (
                          payment_status.id === 4 ? (
                            <tr>
                              <td>Alasan Ditolak</td>
                              <td>
                                :{' '}
                                {payment_confirmation.rejection_reason.id !== 5
                                  ? payment_confirmation.rejection_reason.name
                                  : payment_confirmation.additional_reason}
                              </td>
                            </tr>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                        <tr>
                          <td>Nama Pengirim</td>
                          <td>
                            :{' '}
                            {payment_confirmation !== null
                              ? payment_confirmation.sender_account_name
                              : 'n/a'}
                          </td>
                        </tr>
                        <tr>
                          <td>Bank Pengirim</td>
                          <td>
                            :{' '}
                            {payment_confirmation !== null
                              ? payment_confirmation.sender_bank.name
                              : 'n/a'}
                          </td>
                        </tr>
                        <tr>
                          <td>Jumlah</td>
                          <td>
                            :{' '}
                            {payment_confirmation !== null
                              ? `Rp. ${payment_confirmation.idr_amount.toLocaleString(
                                  'id-ID'
                                )}`
                              : 'n/a'}
                          </td>
                        </tr>
                        {payment_confirmation === null ? (
                          <tr>
                            <td>Bukti Pembayaran</td>
                            <td>: n/a</td>
                          </tr>
                        ) : (
                          ''
                        )}
                      </tbody>
                    </table>
                    {payment_confirmation !== null ? (
                      <>
                        <p style={{ textAlign: 'left' }}>Bukti Pembayaran: </p>
                        <SRLWrapper options={options}>
                          <div className="container-wrapper-receipt">
                            <img
                              className="image-payment"
                              src={payment_confirmation.receipt_image_url}
                              alt="Receipt-patient"
                            />
                            <div className="middle-receipt">
                              {/* <a href={payment_confirmation.receipt_image_url} data-attribute="SRL"> */}
                              <h5 style={{ cursor: 'pointer' }}>
                                Klik untuk Lihat
                              </h5>
                              {/* </a> */}
                            </div>
                          </div>
                        </SRLWrapper>
                        <div style={{ marginTop: '.5em' }}>
                          {payment_status.id === 2 ? (
                            <>
                              <button
                                className="btn btn-success"
                                onClick={() => this.setState({ isShow: true })}
                                // onClick={() => this.queueStatusPayment(5)}
                              >
                                Konfirmasi Pembayaran
                              </button>{' '}
                              {/* <button className="btn btn-danger mr-1" 
                          onClick={() => this.queueStatusPayment(4)}>
                            Tolak Pembayaran
                          </button> */}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <br />
                <br />
                <div className="input-detail-queue text-left ml-3">
                  <label htmlFor="" className="detail-queue">
                    Status Antrian :
                  </label>
                  <Select
                    options={statuses}
                    value={status}
                    onChange={(value) => this.statusHandler(value)}
                    className="select-selector"
                    // styles={customStyles}
                  />
                </div>
                <Modal
                  style={{ zIndex: 1100 }}
                  size="sm"
                  show={isShow}
                  onHide={() => this.setState({ isShow: false })}
                  // centered
                >
                  <Modal.Body>
                    <h4 style={{ textAlign: 'center' }}>
                      Konfirmasi Status Pembayaran
                    </h4>
                    <Form className="form-payment-confirm">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '5em',
                        }}
                      >
                        {[3, 4].map((num) => {
                          return (
                            <RadioButton
                              key={num}
                              value={num}
                              text={num === 3 ? 'Diterima' : 'Ditolak'}
                              selected={radioValue}
                              onChange={() => {
                                if (num === 3) {
                                  this.setState({ valueOther: '' });
                                  this.setState({ selectedRefused: null });
                                }
                                console.log(num);
                                this.setState({ radioValue: num });
                              }}
                            />
                          );
                        })}
                      </div>
                      <div
                        style={{ padding: '0em 3em', marginTop: '1em' }}
                        className={`choice-refused-payment ${
                          radioValue === 4 && 'show-choice-refused'
                        }`}
                      >
                        <p>
                          <b>Keterangan Penolakan</b>
                        </p>
                        {paymentRefuseds.map((item, i) => (
                          <Radio
                            key={i}
                            value={item.id}
                            text={item.name}
                            // value={this.props.inputValue}
                            valueOther={valueOther}
                            handleChangeOther={this.handleChangeOther}
                            selected={selectedRefused}
                            onChange={() => {
                              this.setState({ selectedRefused: item.id });
                            }}
                          />
                        ))}
                      </div>

                      <Button
                        variant="primary"
                        block
                        disabled={radioValue === ''}
                        onClick={() => this.queueStatusPayment(radioValue)}
                      >
                        Kirim Konfirmasi
                      </Button>
                    </Form>
                  </Modal.Body>
                </Modal>
                <Modal show={this.state.isOpen} onHide={this.hideModal}>
                  <Modal.Header>
                    <Modal.Title>
                      <h1 className="note-child-title">Catatan Anak </h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="note-text">
                      {this.state.note !== null ? (
                        this.state.note
                      ) : (
                        <span style={{ color: 'red' }}>Belum ada catatan</span>
                      )}
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn btn-primary note-button"
                      onClick={this.hideModal}
                    >
                      OK
                    </button>
                  </Modal.Footer>
                </Modal>
                <Modal show={this.state.isOpen2} onHide={this.hideModal2}>
                  <Modal.Header>
                    <Modal.Title>
                      <h1 className="note-child-title">Vaksinasi</h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <table cellPadding="10" className="vaccination-data mb-5">
                      <tbody>
                        <tr>
                          <td>
                            <label htmlFor="" className="vaccine-label">
                              Tipe Vaksin
                            </label>
                          </td>
                          <td>
                            <p className="vaccine-text">
                              :
                              {vaccine_type !== null ? (
                                vaccine_type
                              ) : (
                                <span style={{ color: 'red' }}>
                                  Belum ada data
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="" className="vaccine-label">
                              Merk Vaksin
                            </label>
                          </td>
                          <td>
                            <p className="vaccine-text">
                              :
                              {vaccine !== null ? (
                                vaccine
                              ) : (
                                <span style={{ color: 'red' }}>
                                  Belum ada data
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="" className="vaccine-label">
                              Periode
                            </label>
                          </td>
                          <td>
                            <p className="vaccine-text">
                              :
                              {vaccination !== null ? (
                                vaccination[0].period
                              ) : (
                                <span style={{ color: 'red' }}>
                                  Belum ada data
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="" className="vaccine-label">
                              Pemeriksa
                            </label>
                          </td>
                          <td>
                            <p className="vaccine-text">
                              :
                              {nurse !== null ? (
                                nurse
                              ) : (
                                <span style={{ color: 'red' }}>
                                  Belum ada data
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn btn-primary note-button"
                      onClick={this.hideModal2}
                    >
                      OK
                    </button>
                  </Modal.Footer>
                </Modal>
                <div className="active-queue-buttons">
                  <Link to={`/queues-online/${shiftId}`}>
                    <button
                      className="btn btn-secondary btn-active-queue"
                      style={{ color: 'white', float: 'right' }}
                    >
                      Kembali
                    </button>
                  </Link>
                  <button
                    className="btn btn-primary btn-active-queue"
                    onClick={this.showModal2}
                  >
                    Vaksinasi
                  </button>
                  <button
                    className="btn btn-primary btn-active-queue"
                    onClick={this.showModal}
                  >
                    Catatan Anak
                  </button>
                  <button
                    className="btn btn-primary btn-active-queue"
                    onClick={this.updateStatus}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default DetailActiveQueueConsult;
