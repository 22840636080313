import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import swal from "sweetalert";
import "react-datepicker/dist/react-datepicker.css";
import AdminSidebar from "../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../BaseUrlApi/Url";
import "./AddUser.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: [],
      choose_gender: {},
      name: "",
      email: "",
      password: "",
      c_password: "",
      startDate: new Date(),
      birthdate: null,
      birthplace: "",
      address: "",
      phonenumber: null,
    };
  }
  async componentDidMount() {
    let gender = [
      {
        gender: "male",
        label: "Laki-laki",
      },
      {
        gender: "female",
        label: "Perempuan",
      },
    ];

    let getGender = gender.map((type) => ({
      value: type.gender,
      label: type.label,
    }));

    this.setState({
      gender: getGender,
    });
  }

  genderHandler = async (value) => {
    this.setState({
      choose_gender: {
        value: value.value,
        label: value.label,
      },
    });
  };

  selectedDate = (value) => {
    if (this.state.birthdate === null) {
      return "";
    } else {
      return new Date(this.state.birthdate);
    }
  };

  dateHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateConverter(this.state.startDate);
    }
  };

  dateConverter = (value) => {
    let date = value,
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join("-");
    this.setState({
      birthdate: convertDate,
    });
  };

  inputHandler = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  matchPassword = (value) => {
    return value && value === this.state.password;
  };

  genderHandler = async (value) => {
    this.setState({
      choose_gender: {
        value: value.value,
        label: value.label,
      },
    });
  };

  saveUser = async (e) => {
    const datas = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      c_password: this.state.c_password,
      gender: this.state.choose_gender.value,
      birthdate: this.state.birthdate,
      birthplace: this.state.birthplace,
      phone_number: this.state.phonenumber,
      address: this.state.address,
    };

    if (datas.password !== datas.c_password) {
      swal({
        title: "GAGAL",
        text: "Password tidak sama",
        icon: "warning",
        type: "error",
        buttons: "OK",
      });
    } else {
      axios
        .post(`${API_URL}/admin/users/nurseRegister`, datas, { headers })
        .then((res) => {
          swal({
            title: "Sukses!",
            text: "Data Perawat Telah Berhasil Ditambahkan!",
            icon: "success",
            button: "OK",
          }).then((move) => {
            if (move) {
              window.location.href = `/users`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          swal({
            title: "GAGAL",
            text: "Lengkapi semua data terlebih dahulu",
            icon: "warning",
            type: "error",
            buttons: "OK",
          });
        });
    }
  };

  render() {
    return (
      <div>
        <AdminSidebar />
        <div className="content-section">
          <div className="title-section mb-5">
            <h1 className="patient-page-title">Manajemen Pengguna</h1>
            <h2 className="patient-page-subtitle">
              <span style={{ color: "#039BEB" }}>Manajemen Pengguna</span>
              <span style={{ color: "#00000029" }}> / Daftar Perawat</span>
            </h2>
          </div>
          <div className="card shadow mb-4">
            <div className="card-body text-left">
              <ValidationForm
                onSubmit={this.saveUser.bind(this)}
                ref={this.formRef}
                immediate={this.state.immediate}
                setFocusOnError={this.state.setFocusOnError}
              >
                <table cellPadding="10" className="add-table ml-3">
                  <tbody>
                    <p className="account-title">Data Akun</p>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Email
                        </label>
                      </td>
                      <td>
                        
                        :
                        <TextInput
                          type="email"
                          name="email"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Nama
                        </label>
                      </td>
                      <td>
                        
                        :
                        <TextInput
                          type="text"
                          name="name"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Password
                        </label>
                      </td>
                      <td>
                        
                        :
                        <TextInput
                          type="password"
                          name="password"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Re-Password
                        </label>
                      </td>
                      <td>
                        
                        :
                        <TextInput
                          type="password"
                          name="c_password"
                          successMessage="Password diterima!"
                          errorMessage={{
                            required: "Confirm password is required",
                            validator: "Password tidak sama",
                          }}
                          onChange={this.inputHandler}
                          validator={this.matchPassword}
                          className="add-vaccine-input"
                        />
                      </td>
                    </tr>
                    <br />
                    <br />

                    {/* PRIVATE DATA SECTION */}
                    <p className="account-title">Data Pribadi</p>
                    <tr className="selector">
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Jenis Kelamin
                        </label>
                      </td>
                      <td className="add-fam-selector">
                        <span style={{ margin: "1.5rem 2rem 0 0" }}> : </span>
                        <Select
                          options={this.state.gender}
                          value={this.state.choose_gender}
                          onChange={(value) => this.genderHandler(value)}
                          className="select-selector"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Tempat Lahir
                        </label>
                      </td>
                      <td>
                        
                        :
                        <TextInput
                          type="text"
                          name="birthplace"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Tanggal Lahir
                        </label>
                      </td>
                      <td>
                        
                        :
                        <DatePicker
                          selected={this.selectedDate()}
                          onChange={this.dateHandler}
                          dateFormat="dd-MM-yyyy"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Alamat
                        </label>
                      </td>
                      <td>
                        
                        :
                        <TextInput
                          type="text"
                          name="address"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          No. Telp
                        </label>
                      </td>
                      <td>
                        
                        :
                        <TextInput
                          type="number"
                          name="phonenumber"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ValidationForm>
              <br />
              <br />
              <div className="patient-buttons">
                \
                <button
                  className="btn btn-primary mr-1"
                  onClick={this.saveUser}
                >
                  Simpan
                </button>
                <Link to="/users">
                  <button className="btn btn-danger mr-1">Batal</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddUser;
