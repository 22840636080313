import React from 'react';
import { dateFormat } from '../../../../../utils/dateFormat';

export const ColumnHistory = () => {
  return [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 100,
      render: (text, record, index) => {
        return <span>{++index}</span>;
      },
    },
    {
      title: 'Tanggal Pemeriksaan',
      dataIndex: 'date_taken',
      key: 'date_taken',
      width: 200,
      render: (text) => {
        return <span>{text ? dateFormat(text, 'DD/MM/YYYY') : '-'}</span>;
      },
    },
    {
      title: 'Vaksin/Periode',
      dataIndex: 'brand_name',
      key: 'brand_name',
      width: 300,
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: 'Jenis Obat',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: 'Batch',
      dataIndex: 'batch',
      key: 'batch',
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
    },
  ];
};
