import React, { useState } from 'react';
import { onGetAllRequestCertificate } from '../services/getAllRequestCertificate';
import { useEffect } from 'react';

export const useRequestCertificate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tempDataRequest, setTempDataRequest] = useState(null);
  const [dataTableRequest, setDataTableRequest] = useState(null);
  const [keyword, setKeyword] = useState('');

  const onChangeKeyword = (key) => setKeyword(key);

  const onGetAll = async () => {
    setIsLoading(true);
    const getAllRequestCertificate = await onGetAllRequestCertificate().finally(
      () => setIsLoading(false)
    );
    setTempDataRequest(getAllRequestCertificate);
  };

  useEffect(() => {
    onGetAll();
  }, []);

  useEffect(() => {
    const resultSearch = tempDataRequest?.data?.filter((item) =>
      item?.child?.fullname?.toLowerCase()?.match(keyword?.toLowerCase())
    );
    setDataTableRequest(resultSearch);
  }, [keyword, tempDataRequest]);

  return {
    isLoading,
    dataRequest: dataTableRequest || [],
    error: tempDataRequest?.error || false,
    keyword,
    onChangeKeyword,
  };
};
