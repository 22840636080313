import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar/Navbar';
import { API_URL } from '../../../BaseUrlApi/Url';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from 'react-datepicker';
import { useFilterShifts } from '../../../../hooks/useFilterShifts';
import { TYPE_MANAGEMENT_QUEUE } from '../../../../constans';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

function AddVisit(props) {
  const { getShiftId } = useFilterShifts();
  const [isLoaded, setLoading] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [shiftIds, setShiftIds] = useState([]);
  const [shift, setShift] = useState({});
  const [visitTypes, setVisitTypes] = useState([]);
  const [visitType, setVisitType] = useState({});
  const [date_plan, setDatePlan] = useState(null);
  const [excludeDate, setExcludeDate] = useState(null);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${API_URL}/nurse/shifts`, { headers })
      .then((res) => {
        let shifts = res.data.shifts.map((shift) => ({
          value: shift.id,
          label: shift.name,
        }));

        const tempData = getShiftId(
          res.data.shifts,
          TYPE_MANAGEMENT_QUEUE?.KONSULTASI_ONLINE
        );
        setShiftIds(tempData);
        setShifts(shifts);
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: ' Gagal Mengambil Data',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });

    axios
      .get(`${API_URL}/nurse/visit_types`, { headers })
      .then((res) => {
        let visit_types = res.data.visitTypes.map((type) => ({
          value: type.id,
          label: type.name,
        }));
        setVisitTypes(visit_types);
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: ' Gagal Mengambil Data',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });

    axios
      .get(`${API_URL}/blocked_dates`, {
        headers,
      })
      .then(async (res) => {
        let filterBlockedDates = res.data.blockedDates.map((item) =>
          Date.parse(new Date(item.date).toUTCString())
        );

        const { data: dataDatesKonsulOnline } = await axios.get(
          `${API_URL}/blocked_dates?shift_type=konsultasi_online`,
          {
            headers,
          }
        );

        let filterBlockedDatesKonsultasiOnline =
          await dataDatesKonsulOnline.blockedDates.map((item) =>
            Date.parse(new Date(item.date).toUTCString())
          );
        setExcludeDate([
          ...filterBlockedDatesKonsultasiOnline,
          ...filterBlockedDates,
        ]);
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: ' Gagal Mengambil Data',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });

    setVisitType({
      value: null,
      label: <span className="select-placeholder">Jenis Kunjungan</span>,
    });
    setShift({
      value: null,
      label: <span className="select-placeholder">Pilih Shift</span>,
    });

    // getCurrentDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveVaccination = (e) => {
    e.preventDefault();
    let child = parseInt(props.match.params.child);

    if (
      moment(new Date()).format('YYYY-MM-DD') !==
      moment(date_plan).format('YYYY-MM-DD')
    ) {
      if (shift.value !== null) {
        let data = {
          child_id: child,
          date_plan: moment(date_plan).format('YYYY-MM-DD'),
          shift_id: shift.value,
          visit_type_id: visitType.value,
        };

        axios
          .post(`${API_URL}/nurse/visits`, data, { headers })
          .then((res) => {
            swal({
              title: 'Sukses!',
              text: 'Berhasil Ditambahkan!',
              icon: 'success',
              button: 'OK',
            }).then((move) => {
              if (move) {
                window.location.href = `/checkup`;
              } else {
                return;
              }
            });
          })
          .catch((err) => {
            errorHandler(err);
          });
      } else {
        let data = {
          child_id: child,
          date_plan: moment(date_plan).format('YYYY-MM-DD'),
          visit_type_id: visitType.value,
        };
        axios
          .post(`${API_URL}/nurse/visits`, data, { headers })
          .then((res) => {
            swal({
              title: 'Sukses!',
              text: 'Berhasil Ditambahkan!',
              icon: 'success',
              button: 'OK',
            }).then((move) => {
              if (move) {
                window.location.href = `/checkup`;
              } else {
                return;
              }
            });
          })
          .catch((err) => {
            errorHandler(err);
          });
      }
    } else {
      swal({
        title: 'GAGAL',
        text: 'Tidak Bisa Menambahkan Jadwal untuk Hari Ini',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  };

  function errorHandler(err) {
    if (err.response.data.message === 'The given data was invalid.') {
      swal({
        title: 'GAGAL',
        text: ' Harap Lengkapi Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, registration deadline has been reached!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Telah Mencapai Batas Waktu Pendaftaran',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, registration limit has been reached!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Antrian Sudah Penuh',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, your child already has a ticket!, please cancel it first!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Anak Telah Memiliki Tiket',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else {
      swal({
        title: 'GAGAL',
        text: 'Gagal Menyimpan Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  }

  const isWeekday = (date) => {
    // console.log(date)
    const day = date.getDay();
    return day !== 0;
  };

  return (
    <div className="nurse-overlay">
      <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
        <Navbar navtitle="Tambah Jadwal Kunjungan" url={`/checkup`} />
        <div className="vaccination-body">
          <div className="merk-vaccine">
            <Select
              options={shifts}
              value={shift}
              onChange={(value) =>
                setShift({ value: value.value, label: value.label })
              }
              Placeholder="massukkan data"
            />
          </div>
          <div>
            <DatePicker
              selected={date_plan}
              className="date-picker-custom"
              onChange={(e) => {
                console.log(e);
                setDatePlan(e);
              }}
              excludeDates={excludeDate}
              // onChange={dateTakenHandler}
              filterDate={!shiftIds?.includes(shift?.value) ? isWeekday : null}
              // minDate={new Date()}
              // dateFormat="dd-MM-yyyy"
              dateFormat="dd MMMM, yyyy"
              placeholderText="Pilih tanggal Pemeriksaan"
            />
          </div>
          <div className="merk-vaccine">
            <Select
              options={visitTypes}
              value={visitType}
              onChange={(value) =>
                setVisitType({ value: value.value, label: value.label })
              }
              Placeholder="massukkan data"
            />
          </div>
          <a href="/patient-detail">
            <button
              className="btn btn-primary btn-saveschedule"
              onClick={saveVaccination}
            >
              {/* {loading_button ? "..Loading.." : "SIMPAN"} */}
              SIMPAN
            </button>
          </a>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default AddVisit;
