import React, { Component } from 'react';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import axios from 'axios';
import swal from 'sweetalert';
import { LOGIN_URL } from '../BaseUrlApi/Url';
import loginImage from '../../assets/images/a6afca1989997f7b42d335b748e35f75@2x.png';
import drBob from '../../assets/icons/bob.svg';
import './LoginPage.css';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      login_btn_text: 'LOGIN',
    };
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    if (inputs.length > 0) {
      this.setState({ login_btn_text: '..LOADING..' });
    }
    this.setState({ login_btn_text: '..LOADING..' });
    let loginData = {
      email: formData.email,
      password: formData.password,
    };
    this.postData(loginData);
  };
  postData = async (loginData) => {
    try {
      let responseLogin = await axios.post(LOGIN_URL, loginData);
      if (responseLogin.data.user.role.name === 'admin') {
        localStorage.setItem('access_token', responseLogin.data.success.token);
        localStorage.setItem('user_id', responseLogin.data.user.id);
        localStorage.setItem('admin_name', responseLogin.data.user.admin.name);
        localStorage.setItem('role', responseLogin.data.user.role.id);
        window.location.href = '/admin-dashboard';
      } else if (responseLogin.data.user.role_id === 5) {
        localStorage.setItem('access_token', responseLogin.data.success.token);
        localStorage.setItem('user_id', responseLogin.data.user.id);
        localStorage.setItem('admin_name', responseLogin.data.user.name);
        localStorage.setItem('role', responseLogin.data.user.role_id);
        window.location.href = '/self-checkin';
      } else {
        localStorage.setItem('access_token', responseLogin.data.success.token);
        localStorage.setItem('user_id', responseLogin.data.user.id);
        localStorage.setItem('nurse_id', responseLogin.data.user.nurse.id);
        localStorage.setItem('role', responseLogin.data.user.role.id);
        window.location.href = '/checkup';
      }
    } catch (e) {
      swal({
        title: 'GAGAL',
        text: 'Email atau Password Salah',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
      this.setState({ login_btn_text: 'LOGIN' });
    }
  };
  render() {
    //for redirect login
    // if(localStorage.getItem('user_id') !== null) {
    //   if(localStorage.getItem('user_id') === '1') {
    //     window.location.href = "/admin-dashboard";
    //   } else {
    //     window.location.href = "/checkup";
    //   }
    // }
    return (
      <div className="login-admin-body">
        <img src={loginImage} alt="" className="login-image" />
        <div className="title-wrapper">
          <img src={drBob} alt="" className="bob-style" />
          <span className="login-title">
            dr.Bob's <br />
            Clinic
          </span>
        </div>
        <div className="login-form">
          <ValidationForm
            onSubmit={this.handleSubmit.bind(this)}
            ref={this.formRef}
            immediate={this.state.immediate}
            setFocusOnError={this.state.setFocusOnError}
          >
            <p className="login-text">LOG IN</p>
            <div className="form-group">
              <TextInput
                name="email"
                id="email"
                type="text"
                placeholder="Email"
                className="form-control admin-input"
                errorMessage="Please enter something"
                required
              />
            </div>
            <div className="form-group">
              <TextInput
                type="password"
                name="password"
                placeholder="Password"
                className="form-control admin-input"
                errorMessage="Please enter something"
                required
              />
            </div>
            <button className="btn btn-primary adm-login-button">
              {this.state.login_btn_text}
            </button>
          </ValidationForm>
        </div>
      </div>
    );
  }
}

export default LoginPage;
