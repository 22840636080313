/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import useOutsideClick from '../../hooks/useOutsideClick';
import './RunningQueue.css';
import { useDebounce } from 'use-debounce';
import axios from '../../config/axios';

export default function RunningQueue() {
  const [isLoading, setIsLoading] = useState(false);

  const [tokenCheckIn, setTokenCheckIn] = useState('');
  const [debounceValueCheckin] = useDebounce(tokenCheckIn, 2000);

  const inputRef = useRef(null);

  useOutsideClick(inputRef, () => {
    inputRef.current?.focus();
  });

  const onChangeTokenCheckIn = (e) => setTokenCheckIn(e.target.value);

  const success = () => {
    Modal.success({
      title: 'Berhasil Melakukan Checkin',
    });
  };

  const error = () => {
    Modal.error({
      title: 'Gagal Melakukan Checkin',
    });
  };

  const onCheckinPatient = () => {
    setIsLoading(true);
    const payloadCheckin = {
      checkin_token: tokenCheckIn,
    };

    axios
      .post('/queues/checkin', payloadCheckin)
      .then(() => success())
      .catch(() => error())
      .finally(() => {
        setTokenCheckIn('');
        setIsLoading(false);
        setTimeout(() => {
          Modal.destroyAll();
        }, 2000);
      });
  };

  useEffect(() => {
    if (!!tokenCheckIn.length) return onCheckinPatient();
  }, [debounceValueCheckin]);

  return (
    <div className="container-running-queue">
      <div className="content-wrapper">
        <p className="content-title">Scan Barcode Pasien</p>
        <div className="divider mb-4" />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div class="form-input mb-4">
            <input
              className="input-token"
              ref={inputRef}
              autoFocus
              value={tokenCheckIn}
              placeholder="Token Checkin"
              onChange={onChangeTokenCheckIn}
            />
            <label>Token Checkin</label>
          </div>
        </div>
        <Button type="primary" size="large" loading={isLoading}>
          Checkin
        </Button>
      </div>
    </div>
  );
}
