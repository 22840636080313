import React,{Component} from 'react'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import LoadingOverlay from 'react-loading-overlay';
import paginationFactory, { PaginationProvider} from 'react-bootstrap-table2-paginator';
import {Link} from 'react-router-dom';
import swal from 'sweetalert';
import productsGenerator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {API_URL} from '../../../../BaseUrlApi/Url'
import AdminSidebar from '../../../AdminSidebar/AdminSidebar'
import './VaccineList.css'

const { SearchBar } = Search;

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}

function vaccineTypesFormatter(cell, row, rowIndex, formatExtraData) { 
  return ( 
      <div className="queues-buttons">
        <button onClick={()=>{
          
          swal({
            title: "Yakin Ingin Menghapus?",
            icon: "warning",
            buttons:["Batal", "Hapus"],
            dangerMode: true,
          })
          .then((move) => {
            if (move) {
              let rowId = row.id
              axios.delete(`${API_URL}/admin/vaccines/${rowId}`, {headers}).then(res=>{
                window.location.href = '/vaccines'
              })
            } else {
            return
            }
          }); 
          
        }} className="btn btn-danger">Hapus</button> 
        <Link to={`edit-vaccine/${row.id}`}>
          <button className="btn btn-primary ">Ubah</button> 
        </Link>
      </div>
); } 

class VaccineList extends Component{
  state = { 
    isLoaded: false,
    date: new Date(),
    products : [],
    columns: [
      {
        dataField: 'vaccine_type',
        text: 'Tipe Vaksin',
        headerStyle:{textAlign:'center'},
        style:{textAlign:'center'}
      },
      {
        dataField: 'brand_name',
        text: 'Nama Tipe Vaksin',
        headerStyle:{textAlign:'center'},
        style:{textAlign:'center'}
      },
      {
        dataField: 'Aksi',
        text:'Aksi',
        headerStyle:{textAlign:'center'},
        style:{textAlign:'center'},
        formatter: vaccineTypesFormatter
      }
    ]}

    
  loadData = () => {
    this.setState({ 
      products: productsGenerator(17) 
    });
  }

  async componentDidMount(){
    this.setState({isLoaded:true})

    let getVaccines = await axios.get(`${API_URL}/admin/vaccines`, {headers})
    let filterVaccineType = getVaccines.data.vaccines.map(item => {
      return {
        ...item,
        vaccine_type: item.vaccine_types.map(itemx => (
          itemx.name
        )).join(', ')
      }
    })
    await this.setState({
      products: filterVaccineType
    })
    this.setState({isLoaded:false})
  }
  render(){
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan { from } / { to } dari { size } Hasil
      </span>
    );
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: this.state.products.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="card shadow mb-4">
      <div className="card-body">
        <div className="table-responsive">

        <ToolkitProvider
          keyField="id"
          columns={ this.state.columns }
          data={ this.state.products }
          search
        >
          {
            toolkitprops => (
              <div>
                <SearchBar { ...toolkitprops.searchProps } className="search-content" />
                <BootstrapTable
                  bordered={ false }
                  { ...toolkitprops.baseProps }
                  { ...paginationTableProps }
                />
              </div>
            )
          }
        </ToolkitProvider></div>
</div>

        {/* <PaginationListStandalone { ...paginationProps } /> */}
      </div>
    );

    return(
      <div className="admin-overlay">
        <AdminSidebar/>
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text='Memuat data...'
          >
<div className="content-section">

        <div className="title-section mb-5">
          <h1 className="patient-page-title">Manajemen Vaksin</h1>
          <h2 className="patient-page-subtitle">
            <span style={{color: '#039BEB'}}>Master Data</span> 
            <span style={{color:'#00000029'}}> / Daftar Vaksin</span>
          </h2>
          <div className="add-button">
            <Link to="/add-vaccine">
              <button className="btn btn-primary btn-add-table mr-3 mb-2">Tambah Daftar Vaksin</button>
            </Link>
          </div>
        </div>
        <PaginationProvider
          pagination={
            paginationFactory(options)
          }
        >
          { contentTable }
        </PaginationProvider>
        </div>
        </LoadingOverlay>
      </div>
    )
  }
}

export default VaccineList