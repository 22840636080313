import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import AdminSidebar from "../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../BaseUrlApi/Url";
import "./AddFamilyMember.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class AddFamilyMember extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      parent_id: null,
      fullname: "",
      birthdate: null,
      birthplace: "",
      choose_gender: {},
      gender: [],
      startDate: new Date(),
    };
  }
  async componentDidMount() {
    // GENDER OPTIONS
    let gender = [
      {
        gender: "male",
        label: "Laki-laki",
      },
      {
        gender: "female",
        label: "Perempuan",
      },
    ];

    let getGender = gender.map((type) => ({
      value: type.gender,
      label: type.label,
    }));

    await this.setState({
      parent_id: parseInt(this.props.match.params.value),
      gender: getGender,
    });
  }

  inputHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  genderHandler = async (value) => {
    this.setState({
      choose_gender: {
        value: value.value,
        label: value.label,
      },
    });
  };

  selectedDate = (value) => {
    if (this.state.birthdate === null) {
      return "";
    } else {
      return new Date(this.state.birthdate);
    }
  };

  dateHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateConverter(this.state.startDate);
    }
  };

  dateConverter = (value) => {
    let date = value,
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join("-");
    this.setState({
      birthdate: convertDate,
    });
  };

  clearForm = async (e) => {
    this.setState({
      fullname: "",
      birthdate: null,
      birthplace: "",
      choose_gender: {},
    });
  };
  updateData = async (e) => {
    e.preventDefault();

    const postData = {
      parent_id: this.state.parent_id,
      fullname: this.state.fullname,
      birthdate: this.state.birthdate,
      birthplace: this.state.birthplace,
      gender: this.state.choose_gender.value,
    };

    axios
      .post(`${API_URL}/admin/children`, postData, { headers })
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Data Anggota Keluarga Telah Berhasil Ditambahkan!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/detail-patient/${this.state.parent_id}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };
  render() {
    let {
      immediate,
      fullname,
      birthplace,
      setFocusOnError,
      gender,
      choose_gender,
    } = this.state;
    return (
      <div>
        <AdminSidebar />
        <div className="content-section">
          <div className="title-section">
            <h1 className="patient-page-title">Tambah Data Keluarga</h1>
            <h2 className="patient-page-subtitle">
              <span style={{ color: "#039BEB" }}>Manajemen Anak</span>
              <span style={{ color: "#039BEB" }}>/ Daftar Kepala Keluarga</span>
              <span style={{ color: "#039BEB" }}>/ Detail Kepala Keluarga</span>
              <span style={{ color: "#00000029" }}>
                
                / Tambah Data Keluarga
              </span>
            </h2>
          </div>

          <div className="card shadow mb-4 mt-5">
            <div className="card-body">
              <ValidationForm
                onSubmit={this.updateData.bind(this)}
                ref={this.formRef}
                immediate={immediate}
                setFocusOnError={setFocusOnError}
              >
                <table cellPadding="10" className="add-table text-left">
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Nama
                        </label>
                      </td>
                      <td>
                        
                        :
                        <TextInput
                          type="text"
                          name="fullname"
                          value={fullname}
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Gender
                        </label>
                      </td>
                      <td>
                        
                        :
                        <Select
                          options={gender}
                          value={choose_gender}
                          onChange={(value) => this.genderHandler(value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Tanggal Lahir
                        </label>
                      </td>
                      <td>
                        
                        :
                        <DatePicker
                          selected={this.selectedDate()}
                          onChange={this.dateHandler}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="dd-MM-yyyy"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Tempat Lahir
                        </label>
                      </td>
                      <td>
                        
                        :
                        <TextInput
                          type="text"
                          name="birthplace"
                          value={birthplace}
                          className="add-vaccine-input"
                          onChange={this.inputHandler}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ValidationForm>
              <div className="patient-buttons">
                <button
                  className="btn btn-primary mr-1"
                  onClick={this.updateData}
                >
                  Simpan
                </button>
                <Link to={`/detail-patient/${this.state.parent_id}`}>
                  <button className="btn btn-danger mr-1">Batal</button>
                </Link>
                <button
                  className="btn btn-warning text-dark mr-1"
                  onClick={this.clearForm}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddFamilyMember;
