import { PluralizeText } from './pluralizeFormat';

const onCheckPluralize = ({
  year,
  month = 0,
  textYearPluralize,
  textMonthPluralize,
}) => {
  if (year && month) {
    return `${textYearPluralize} ${textMonthPluralize} old`;
  } else if (year) {
    return `${textYearPluralize} old`;
  } else if (month) {
    return `${textMonthPluralize} old`;
  } else {
    return '-';
  }
};

export const onFormatPatientAge = (year, month = 0) => {
  const tempMonth = month % 12;
  if (year && tempMonth) {
    return `${year} Tahun ${tempMonth} Bulan`;
  } else if (year) {
    return `${year} Tahun`;
  } else if (tempMonth) {
    return `${tempMonth} Bulan`;
  } else {
    return '-';
  }
};

export const onFormatPatientAgeEnglish = (year, month) => {
  const tempYear = PluralizeText({
    count: year,
    singularText: 'year',
  });

  const tempMonth = PluralizeText({
    count: month % 12,
    singularText: 'month',
  });

  const text = onCheckPluralize({
    year,
    month: month % 12,
    textYearPluralize: tempYear,
    textMonthPluralize: tempMonth,
  });
  return text;
};
