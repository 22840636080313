import { useState, useCallback } from 'react';

const useDisclosure = (initialValue = false) => {
  const [isOpen, setOpen] = useState(initialValue);

  const onOpen = useCallback(() => {
    return setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    return setOpen(false);
  }, []);

  const onToggle = useCallback(() => {
    return setOpen((value) => !value);
  }, []);

  return { isOpen, onClose, onOpen, onToggle };
};

export { useDisclosure };
export default useDisclosure;
