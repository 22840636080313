import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../../BaseUrlApi/Url";
import "./QrCode.css";
var QRCode = require("qrcode.react");

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};
class QrCode extends Component {
  state = {
    token: "",
    show_token: null,
  };
  async componentDidMount() {
    document.body.style.overflow = "hidden"
    let getToken = await axios.get(
      `${API_URL}/admin/checkin_codes/getActiveCode`,
      { headers }
    );
    await this.setState({
      token: getToken.data.checkinCodes.code,
    });
  }

  getToken = async (e) => {
    await this.setState({
      show_token: this.state.token,
    });
  };
  render() {
    return (
      <div className="code-body">
        <QRCode value={this.state.token} className="qrcode-style" />
        <p className="qrcode-text">Scan QR Code untuk check in</p>
        <p className="qrcode-text2">
          Atau 
          <span
            style={{ color: "#039BEB"}}
            className="ml-1"
            // onClick={this.getToken}
          >
             Check in dengan Kode dibawah
          </span>
        </p>
        <p>
        <div className="show-token">
            <p className="token-text"> {this.state.token} </p>
            <hr className="token-line" />
            <p className="token-subtitle">
              Masukkan kode diatas pada smartphone anda
            </p>
          </div>
        </p>
      </div>
    );
  }
}

export default QrCode;
