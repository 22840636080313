import { Table } from 'antd';
import React from 'react';
import { ColumnHistory } from './ColumnHistory';

export const TableHistory = ({ isLoading, dataSource }) => {
  return (
    <Table
      loading={isLoading}
      dataSource={dataSource || []}
      columns={ColumnHistory()}
      scroll={{ y: 260 }}
      pagination={false}
    />
  );
};
