import API from '../../../../config/axios';

export const onGetDuplicateDetails = (id) => {
  const response = API.get(`/parents/${id}`)
    .then((res) => {
      return {
        data: res?.data?.parent,
        error: false,
      };
    })
    .catch(() => {
      return {
        data: [],
        error: true,
      };
    });

  return response;
};
