import React, { Component } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { API_URL } from "../../BaseUrlApi/Url";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import "./UserManagement.css";

const { SearchBar } = Search;

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

function queuesFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="queues-buttons">
      <button
        onClick={() => {
          swal({
            title: "Yakin Ingin Menghapus?",
            icon: "warning",
            buttons: ["Batal", "Hapus"],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/users/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = "/users";
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger"
      >
        Hapus
      </button>
      <Link to={`update-user/${row.id}`}>
        <button className="btn btn-primary">Ubah</button>
      </Link>
    </div>
  );
}

function genderFormatter(cell, row, rowIndex, formatExtraData) {
  if(cell !== undefined){
    if(cell === 'female'){
      return 'Perempuan'
    }else if(cell === 'male'){
      return 'Laki-laki'
    }
  }else{
    return '-'
  }
}

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      childId: null,
      products: [],
      columns: [
        {
          dataField: "email",
          text: "Email",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
        },
        {
          dataField: "nurse.name",
          text: "Nama",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
        },
        {
          dataField: "nurse.gender",
          text: "Jenis Kelamin",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          formatter: genderFormatter,
        },
        {
          dataField:"nurse.phone_number",
          text: "No. Telp",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
          formatter: queuesFormatter,
        },
      ],
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let getQueues = await axios.get(`${API_URL}/admin/users`, { headers });
    this.setState({
      products: getQueues.data.users,
    });
    this.setState({ isLoaded: false });
  }
  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan {from} / {to} dari {size} Hasil
      </span>
    );
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "Semua",
          value: this.state.products.length,
        },
      ],
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <ToolkitProvider
              keyField="id"
              columns={this.state.columns}
              data={this.state.products}
              search
            >
              {(toolkitprops) => (
                <div>
                  <SearchBar
                    {...toolkitprops.searchProps}
                    className="search-content"
                  />
                  <BootstrapTable
                    bordered={false}
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    );

    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Manajemen Perawat</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Manajemen Perawat</span>
                <span style={{ color: "#00000029" }}> / Daftar Perawat</span>
              </h2>
              <Link to="/add-user">
                <div className="add-button mr-3">
                  <button className="btn btn-primary btn-add-table mb-2">
                    Tambah Perawat
                  </button>
                </div>
              </Link>
            </div>
            <PaginationProvider pagination={paginationFactory(options)}>
              {contentTable}
            </PaginationProvider>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default UserManagement;
