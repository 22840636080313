import React, { Component } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import LoadingOverlay from 'react-loading-overlay';
import { ValidationForm, FileInput } from 'react-bootstrap4-form-validation';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import Sidebar from '../../AdminSidebar/AdminSidebar';
import { API_URL } from '../../../BaseUrlApi/Url';
import './PatientManagement.css';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

function patientFormatter(cell, row, rowIndex, formatExtraData) {
  if (row !== undefined) {
    return (
      <div>
        <div className="table-buttons">
          <button
            onClick={() => {
              swal({
                title: 'Yakin Ingin Menghapus?',
                icon: 'warning',
                buttons: ['Batal', 'Hapus'],
                dangerMode: true,
              }).then((move) => {
                if (move) {
                  let rowId = row.id;
                  axios
                    .delete(`${API_URL}/admin/parents/${rowId}`, { headers })
                    .then((res) => {
                      window.location.href = '/patients';
                    })
                    .catch((err) => {
                      if (
                        err.response.data.message ===
                        'This parents already has children, delete children first!'
                      ) {
                        swal({
                          title: 'GAGAL',
                          text: 'Hapus Data Anak Terlebih Dahulu',
                          icon: 'warning',
                          type: 'error',
                          buttons: 'OK',
                        });
                      } else {
                        swal({
                          title: 'GAGAL',
                          text: 'Gagal Menghapus Data',
                          icon: 'warning',
                          type: 'error',
                          buttons: 'OK',
                        });
                      }
                    });
                } else {
                  return;
                }
              });
            }}
            style={{ margin: '0.5em' }}
            className="btn btn-danger patient-delete"
          >
            Hapus
          </button>
          <Link to={`/update-family/${row.id}`}>
            <button
              style={{ margin: '0.5em' }}
              className="btn btn-primary patient-change"
            >
              Edit
            </button>
          </Link>
          <Link to={`detail-patient/${row.id}`}>
            <button
              style={{ margin: '0.5em' }}
              className="btn btn-info patient-detail"
            >
              Detail
            </button>
          </Link>
        </div>
        {/* <div className="table-buttons2">
          <button
            onClick={() => {
              swal({
                title: "Yakin Ingin Menghapus?",
                icon: "warning",
                buttons: ["Batal", "Hapus"],
                dangerMode: true,
              }).then((move) => {
                if (move) {
                  let rowId = row.id;
                  axios
                    .delete(`${API_URL}/admin/parents/${rowId}`, { headers })
                    .then((res) => {
                      window.location.href = "/patients";
                    });
                } else {
                  return;
                }
              });
            }}
            style={{margin: '0.5em'}}
            className="btn btn-danger patient-delete mr-1"
          >
            <FontAwesomeIcon icon={faTrash} className="text-light " />
          </button>
          <Link to={`/update-family/${row.id}`}>
            <button className="btn btn-primary patient-change mr-1">
              <FontAwesomeIcon icon={faEdit} className="text-light" />
            </button>
          </Link>
          <Link to={`detail-patient/${row.id}`}>
            <button className="btn btn-info patient-detail">
              <FontAwesomeIcon icon={faEye} className="text-light" />
            </button>
          </Link>
        </div> */}
      </div>
    );
  } else {
    return;
  }
}

function actionStyle(column, colIndex) {
  let mini = window.matchMedia('(min-width:700px) and (max-width:1220px)');
  let miniWidth = window.matchMedia(
    '(min-width:1220px) and (max-width:1850px)'
  );
  let mediumWidth = window.matchMedia('(min-width:1850px)');
  if (mini.matches) {
    let style = {
      width: '12rem',
      textAlign: 'center',
    };
    return style;
  }
  if (miniWidth.matches) {
    let style = {
      width: '30rem',
      textAlign: 'center',
    };
    return style;
  }
  if (mediumWidth.matches) {
    let style = {
      width: '25rem',
      textAlign: 'center',
    };
    return style;
  }
}

function columnStyle(column, colIndex) {
  let miniWidth = window.matchMedia(
    '(min-width:1220px) and (max-width:1849px)'
  );
  let mediumWidth = window.matchMedia('(min-width:1850px)');
  if (miniWidth.matches) {
    let style = {
      width: '5rem',
      textAlign: 'center',
    };
    return style;
  }
  if (mediumWidth.matches) {
    let style = {
      width: '12rem',
      textAlign: 'center',
    };
    return style;
  }
}

function columnMotherStyle(column, colIndex) {
  let miniWidth = window.matchMedia(
    '(min-width:1220px) and (max-width:1849px)'
  );
  let mediumWidth = window.matchMedia('(min-width:1850px)');
  if (miniWidth.matches) {
    let style = {
      width: '5rem',
      textAlign: 'center',
    };
    return style;
  }
  if (mediumWidth.matches) {
    let style = {
      width: '12rem',
      textAlign: 'center',
    };
    return style;
  }
}

function addressStyle(column, colIndex) {
  let miniWidth = window.matchMedia(
    '(min-width:1220px) and (max-width:1849px)'
  );
  let mediumWidth = window.matchMedia('(min-width:1850px)');
  if (miniWidth.matches) {
    let style = {
      width: '5rem',
      textAlign: 'center',
    };
    return style;
  }
  if (mediumWidth.matches) {
    let style = {
      width: '15rem',
      textAlign: 'center',
    };
    return style;
  }
}

function cellFormatter(cell, row, rowIndex, formatExtraData) {
  if (row !== undefined) {
    if (cell !== null) {
      return cell;
    } else {
      return '-';
    }
  } else {
    return;
  }
}

function jobFormatter(cell, row, rowIndex, formatExtraData) {
  if (row !== undefined) {
    if (cell !== undefined) {
      return cell;
    } else {
      return '-';
    }
  } else {
    return;
  }
}

class PatientManagement extends Component {
  state = {
    isLoaded: false,
    isOpen: false,
    data: null,
    page: 1,
    search: '',
    isSearched: false,
    products: [],
    columns: [
      {
        dataField: 'father_name',
        text: 'Nama Ayah',
        headerStyle: columnStyle,
        style: { textAlign: 'center' },
        formatter: cellFormatter,
      },
      {
        dataField: 'mother_name',
        text: 'Nama Ibu',
        headerStyle: columnStyle,
        style: { textAlign: 'center' },
        formatter: cellFormatter,
      },
      {
        dataField: 'father_job.name',
        text: 'Pekerjaan Ayah',
        headerStyle: columnStyle,
        style: { textAlign: 'center' },
        formatter: jobFormatter,
      },
      {
        dataField: 'mother_job.name',
        text: 'Pekerjaan Ibu',
        headerStyle: columnStyle,
        style: { textAlign: 'center' },
        formatter: jobFormatter,
      },
      {
        dataField: 'phone_number',
        text: 'No. Telp Ayah',
        headerStyle: columnStyle,
        style: { textAlign: 'center' },
        formatter: cellFormatter,
      },
      {
        dataField: 'mother_phone_number',
        text: 'No. Telp Ibu',
        headerStyle: columnMotherStyle,
        style: { textAlign: 'center' },
        formatter: cellFormatter,
      },
      {
        dataField: 'address',
        text: 'Alamat',
        headerStyle: addressStyle,
        style: { textAlign: 'center' },
        formatter: cellFormatter,
      },
      {
        dataField: 'Aksi',
        text: 'Aksi',
        headerStyle: actionStyle,
        style: { textAlign: 'center' },
        formatter: patientFormatter,
      },
    ],
  };

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let getParents = await axios.get(
      `${API_URL}/admin/parents?page=1&limit=100`,
      {
        headers,
      }
    );
    this.setState({
      products: getParents.data.parents.data,
      data: getParents.data.parents,
    });
    this.setState({ isLoaded: false });
  }

  searchHandler = async (e) => {
    if (e.target.value.length >= 3) {
      await this.setState({ search: e.target.value });
      let input = document.getElementById('input-search');
      input.addEventListener('keyup', function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          document.getElementById('btn-search').click();
        }
      });
    } else if (e.target.value.length === 0) {
      this.setState({
        isLoaded: true,
        search: '',
        isSearched: false,
      });
      let getParents = await axios.get(
        `${API_URL}/admin/parents?page={this.state.page}&limit=100`,
        {
          headers,
        }
      );
      this.setState({
        products: getParents.data.parents.data,

        data: getParents.data.parents,
        isLoaded: false,
      });
    }
  };

  searchButton = async (e) => {
    if (this.state.search.length > 0) {
      this.setState({
        isLoaded: true,
      });
      let getParents = await axios.get(
        `${API_URL}/admin/parents/search?keyword=${this.state.search}`,
        { headers }
      );
      await this.setState({
        products: getParents.data.parents,
        // page_count: getParents.data.parents.length / 100,
        isLoaded: false,
        isSearched: true,
      });
    } else {
      swal('Gagal!', 'Masukkan minimal 3 karakter!', 'error');
    }
  };

  handleNextPage =
    ({ page, onPageChange }) =>
    async () => {
      await this.setState({
        page: this.state.page + 1,
      });
      let getParents = await axios.get(
        `${API_URL}/admin/parents?page=${this.state.page}&limit=100`,
        { headers }
      );
      await this.setState({
        products: getParents.data.parents.data,
        data: getParents.data.parents,
      });
    };

  handlePrevPage =
    ({ page, onPageChange }) =>
    async () => {
      await this.setState({
        page: this.state.page - 1,
      });
      let getParents = await axios.get(
        `${API_URL}/admin/parents?page=${this.state.page}&limit=100`,
        { headers }
      );
      await this.setState({
        products: getParents.data.parents.data,
        data: getParents.data.parents,
      });
    };

  handleNextPageSearch =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page + 1);
    };

  handlePrevPageSearch =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page - 1);
    };

  // EXCEL UPLOAD MODAL
  showUploadExcel = (e) => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  hideUplaodExcel = (e) => {
    this.setState({ isOpen: false });
  };

  uploadFileExcel = async (e) => {
    e.preventDefault();
    const fileInput = document.querySelector('#document');
    let form_data = new FormData();
    form_data.append('file', fileInput.files[0]);

    await axios
      .post(`${API_URL}/admin/import_excel`, form_data, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Dokumen Berhasil Diunggah!',
          icon: 'success',
          button: 'ok',
        });
        this.setState({ isOpen: false });
      })
      .catch((err) => {
        swal({
          title: 'Gagal!',
          text: 'Pengunggahan Dokumen Gagal!',
          icon: 'warning',
          button: 'ok',
        });
      });
  };

  render() {
    const options = {
      custom: true,
      totalSize: 10,
      sizePerPage: 100,
    };

    return (
      <div className="admin-overlay">
        <Sidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section">
              <h1 className="patient-page-title">Daftar Keluarga</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: '#039BEB' }}>Manajemen Anak</span>
                <span style={{ color: '#00000029' }}>/ Daftar Keluarga</span>
              </h2>
            </div>

            <div className="add-button">
              <Link to="/add-family">
                <button className="btn btn-primary float-right  mb-3 mr-3">
                  Tambah Daftar Keluarga
                </button>
              </Link>
              <button
                className="btn btn-primary float-right mb-3 mr-1"
                onClick={this.showUploadExcel}
              >
                Import Excel
              </button>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">
                  <img
                    src="https://img.icons8.com/pastel-glyph/64/000000/search--v2.png"
                    onClick={this.searchButton}
                    className="btn-search"
                    id="btn-search"
                    alt="search"
                  />
                  <input
                    type="text"
                    name="input-search"
                    id="input-search"
                    className="input-search"
                    placeholder="Masukkan minimal 3 karakter.."
                    onChange={this.searchHandler}
                  />
                  {this.state.isSearched === false ? (
                    <PaginationProvider pagination={paginationFactory(options)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <div>
                          <BootstrapTable
                            keyField="id"
                            bordered={false}
                            data={this.state.products}
                            columns={this.state.columns}
                            {...paginationTableProps}
                          />
                          <div className="float-left ml-5">
                            {this.state.data !== null ? (
                              <p>
                                Data {this.state.data.from} hingga{' '}
                                {this.state.data.to} dari{' '}
                                {this.state.data.total} data
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                          <div
                            className="btn-group float-right mr-5 text-center"
                            role="group"
                          >
                            <button
                              className="btn btn-info mr-1"
                              disabled={this.state.page === 1}
                              onClick={this.handlePrevPage(paginationProps)}
                            >
                              Prev Page
                            </button>
                            <button
                              className="btn btn-info"
                              disabled={this.state.products.length < 1}
                              onClick={this.handleNextPage(paginationProps)}
                            >
                              Next Page
                            </button>
                          </div>
                        </div>
                      )}
                    </PaginationProvider>
                  ) : (
                    <PaginationProvider pagination={paginationFactory(options)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <div>
                          <BootstrapTable
                            keyField="id"
                            bordered={false}
                            data={this.state.products}
                            columns={this.state.columns}
                            {...paginationTableProps}
                          />
                          <div className="float-left ml-5">
                            {this.state.products.length > 0 ? (
                              <p>
                                Hasil Pencarian : {this.state.products.length}{' '}
                                data
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                          {/* <div
                            className="btn-group float-right mr-5 text-center"
                            role="group" 
                          >
                            <button
                              className="btn btn-info mr-1"
                              disabled={this.state.page === 1}
                              onClick={this.handlePrevPageSearch(
                                paginationProps
                              )}
                            >
                              Prev Page
                            </button>
                            <button
                              className="btn btn-info"
                              onClick={this.handleNextPageSearch(
                                paginationProps
                              )}
                            >
                              Next Page
                            </button>
                          </div> */}
                        </div>
                      )}
                    </PaginationProvider>
                  )}
                </div>
              </div>
            </div>

            {/* UPLOAD FILE EXCEL */}
            <Modal show={this.state.isOpen} onHide={this.hideUplaodExcel}>
              <Modal.Header>
                <Modal.Title>
                  <h1 className="note-child-title">Unggah Dokumen Orang Tua</h1>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ValidationForm
                  onSubmit={this.uploadFileExcel.bind(this)}
                  ref={this.formRef}
                  immediate={this.state.immediate}
                  setFocusOnError={this.state.setFocusOnError}
                >
                  <table cellPadding="10" className="add-table">
                    <tbody>
                      <div className="form-group">
                        <label htmlFor="document" className="add-vaccine-label">
                          Unggah File
                        </label>
                        <FileInput
                          name="document"
                          id="document"
                          required
                          className="upload-file"
                          errorMessage="Please upload a file"
                          fileType={['xlsx']}
                          maxFileSize="500 kb"
                        />
                      </div>
                    </tbody>
                  </table>
                </ValidationForm>
              </Modal.Body>
              <Modal.Footer>
                <div className="patient-buttons">
                  <button
                    className="btn btn-secondary "
                    onClick={this.hideUplaodExcel}
                  >
                    Batal
                  </button>
                  <button
                    className="btn btn-primary  mr-1"
                    onClick={this.uploadFileExcel}
                  >
                    Simpan
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}
export default PatientManagement;
