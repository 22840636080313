import { useQuery } from 'react-query';
import API from '../../config/axios';
import { QUERY_KEY } from '../../constans/query-keys';

const fetchVaccineType = async (vaccineTypeId) => {
  const { data } = await API.get(`/vaccine_types/${vaccineTypeId}`);
  return data;
};

const useGetVaccineType = (id) => {
  return useQuery([QUERY_KEY.GET_VACCINE_TYPE], () => fetchVaccineType(id), {
    enabled: !!id,
    cacheTime: 0,
  });
};

export default useGetVaccineType;
