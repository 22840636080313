import { WarningOutlined } from '@ant-design/icons';
import { Col, Input, Row, Space, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const { Text } = Typography;

const FieldTextArea = ({
  name,
  label,
  isRequired,
  labelCol = 4,
  inputSpan = 13,
  ...props
}) => {
  const { formState, control } = useFormContext();

  const isError = _.get(formState.errors, name);

  return (
    <Row>
      <Col
        span={labelCol}
        style={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <Text style={{ margin: 0, textAlign: 'left' }}>
          {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </Text>
      </Col>

      <Col span={inputSpan}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                {...props}
                {...(isError && { status: 'error' })}
              />
            )}
          />
          {isError && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                color: 'red',
                marginTop: 4,
              }}
            >
              <WarningOutlined />
              <Text style={{ textAlign: 'left', color: 'red' }}>
                {_.get(formState.errors, name)?.message}
              </Text>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default FieldTextArea;
