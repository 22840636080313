import React, { Component } from 'react';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import { API_URL } from '../../../BaseUrlApi/Url';
import './QueueFrontPage.css';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

class QueueFrontPage extends Component {
  state = {
    shifts: [],
    isLoaded: false,
    day: '',
    date: null,
    status: '',
  };
  async componentDidMount() {
    this.setState({ isLoaded: true });
    let getShifts = await axios.get(`${API_URL}/admin/shifts`, { headers });
    let status = this.props.match.params.value;
    this.setState({
      shifts: getShifts.data.shifts,
      status: status,
    });

    // GET DATE
    let today = new Date();
    let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    let day = today.getDay();

    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!

    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_today = dd + '-' + mm + '-' + yyyy;
    this.setState({
      date: set_today,
      day: days[day],
    });
    this.setState({ isLoaded: false });
  }

  startDayShift = async (value) => {
    let startDay = {
      shift_id: value,
    };
    await axios
      .post(`${API_URL}/admin/queues/startShiftSession`, startDay, { headers })
      .then((res) => {
        if (this.state.status === 'show-admin') {
          window.location.href = `/queues/${value}`;
        } else {
          window.location.href = `/show-queues/${value}`;
        }
      })
      .catch((err) => {});
  };
  render() {
    let { date, day, shifts, status, isLoaded } = this.state;
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="queue-page-title">Antrian</h1>
              <h2 className="queue-page-subtitle">
                <span style={{ color: '#039BEB' }}>Antrian</span>
                <span style={{ color: '#00000029' }}> / Pilih Shift</span>
              </h2>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body text-left">
                <p className="shift-title">
                  {' '}
                  <span style={{ fontWeight: 'bold' }}>Mulai Shift</span> ({day}
                  , {date})
                </p>
                {shifts.map((shift) => {
                  if (status === 'show-admin') {
                    return (
                      <button
                        className="btn btn-primary btn-shift"
                        onClick={() => {
                          this.startDayShift(shift.id);
                          localStorage.setItem('shift_id', shift.id);
                        }}
                      >
                        Shift {shift.name}
                      </button>
                    );
                  } else {
                    return (
                      <button
                        className="btn btn-primary btn-shift"
                        onClick={() => {
                          localStorage.setItem('shift_id', shift.id);
                          this.startDayShift(shift.id);
                        }}
                      >
                        Shift {shift.name}
                      </button>
                    );
                  }
                })}
                <br />
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default QueueFrontPage;
