import { Breadcrumb, Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import AdminSidebar from '../../../../Components/Admin/AdminSidebar/AdminSidebar';
import Spacer from '../../../../Components/Spacer';
import FieldTextArea from '../../../../Components/V2/HookForm/FieldTextArea/FieldTextArea';
import FieldTextInput from '../../../../Components/V2/HookForm/FieldTextInput/FieldTextInput';
import './AddEditVaccineType.css';
import NestedAdditionalFrequencies from './Components/NestedAdditionalFrequencies/NestedAdditionalFrequencies';
import useAddEditVaccineType from './Hooks/useAddEditVaccineType';
import { useHistory } from 'react-router-dom';
import FieldInputNumber from '../../../../Components/V2/HookForm/FieldInputNumber/FieldInputNumber';

const { Title } = Typography;

const AddEditVaccineType = () => {
  const history = useHistory();
  const { formMethods, onSubmit, isLoading, isLoadingGetVaccineType, isEdit } =
    useAddEditVaccineType();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="admin-overlay">
          <AdminSidebar />
          <LoadingOverlay
            active={isLoadingGetVaccineType}
            spinner
            text="Memuat data..."
          >
            <div className="content-section">
              <div className="add-vaccine-container">
                <Row justify="space-between" align="middle">
                  <Col>
                    <div className="certificate-title-wrapper">
                      <Title level={3} style={{ margin: 0, textAlign: 'left' }}>
                        {isEdit ? 'Edit' : 'Tambah'} Tipe Vaksin
                      </Title>
                      <Spacer height={8} />
                      <Breadcrumb>
                        <Breadcrumb.Item>Master Data</Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <a href="/vaccine-types">Daftar Tipe Vaksin</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          {isEdit ? 'Edit' : 'Tambah'} Tipe Vaksin
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </div>
                  </Col>
                </Row>

                <div className="add-form-wrapper">
                  <FieldTextInput
                    size="large"
                    isRequired={true}
                    name="type"
                    label="Nama Tipe Vaksin"
                  />

                  <Spacer height={24} />
                  <FieldTextArea
                    size="large"
                    name="description"
                    label="Deskripsi"
                  />

                  <Spacer height={24} />
                  <FieldInputNumber
                    size="large"
                    isRequired={true}
                    name="frequency"
                    label="Frekuensi"
                  />
                  <Spacer height={24} />

                  <NestedAdditionalFrequencies />

                  <Spacer height={24} />
                  <Row>
                    <Col offset={4} span={13}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center',
                          gap: 12,
                        }}
                      >
                        <Button
                          size="large"
                          type="default"
                          onClick={() => history.push('/vaccine-types')}
                        >
                          Batal
                        </Button>
                        <Button
                          loading={isLoading}
                          size="large"
                          type="primary"
                          htmlType="submit"
                          disabled={
                            !formMethods.formState.isValid ||
                            !formMethods.formState.isDirty
                          }
                        >
                          Simpan
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddEditVaccineType;
