import React, { useState } from 'react';
import { CardCheckin } from './components/CardCheckin/CardCheckin';
import { ScanQRCode } from './components/ScanQRCode/ScanQRCode';
import './SelfCheckin.css';
import IMGPDBAD from '../../assets/images/img-logo-PDBAD.png';
import IMGPV from '../../assets/images/img-logo-PV.png';

const layout = {
  start: 'start',
  scanQRCode: 'scanQRCode',
};

export const SelfCheckin = () => {
  const [isPraktekdDrBob, setIsPraktekDrBob] = useState(false);
  const [layoutName, setLayoutName] = useState(layout.start);

  const showingLayout = {
    start: (
      <div className="self-checkin-wrapper">
        <p className="self-checkin-title">
          Selamat Datang di Klinik Pediatrica Husada
        </p>
        <p className="self-checkin-subtitle">
          Silahkan pilih layanan klinik yang ingin anda kunjungi, dan lakukan
          checkin untuk mendapat nomor antrian.
        </p>
        <div className="card-wrapper">
          <CardCheckin
            title="Dr. Bob"
            img={IMGPDBAD}
            onClick={() => {
              setIsPraktekDrBob(true);
              setLayoutName(layout.scanQRCode);
            }}
          />
          <CardCheckin
            title="Klinik Vaksin"
            img={IMGPV}
            onClick={() => {
              setIsPraktekDrBob(false);
              setLayoutName(layout.scanQRCode);
            }}
          />
        </div>
      </div>
    ),
    scanQRCode: (
      <ScanQRCode
        isPraktekdDrBob={isPraktekdDrBob}
        onBack={() => setLayoutName(layout.start)}
      />
    ),
  };

  return (
    <div className="container-self-checkin">{showingLayout[layoutName]}</div>
  );
};
