import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";
import { Link } from "react-router-dom";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import LoadingOverlay from "react-loading-overlay";
import AdminSidebar from "../../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../../BaseUrlApi/Url";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './EditTerms.css'
// import "./AddDate.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class EditTerms extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoaded: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      editorState: EditorState.createEmpty()
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    // console.log(editorState)

    
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())).toString())
  };

  async componentDidMount() {
    try {
        this.setState({ isLoaded: true });
        
        // this.setState({banks: filterBank})
        const {data} = await axios.get(`${API_URL}/admin/terms_and_conditions/${this.props.match.params.value}`,
        { headers });
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(data.terms.text)
                )
            ),
        })
        this.setState({ isLoaded: false });
    } catch(e) {
        this.setState({ isLoaded: false });
        swal({
            title: `${e.response.data.message}`,
            icon: "warning",
            button: "OK",
        })
    }
  }


  saveForm = async (e) => {
    e.preventDefault();
    this.setState({ isLoaded: true });
    const formData = {
      _method: "PATCH",
      text: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())).toString(),
    };
    axios
      .post(`${API_URL}/admin/terms_and_conditions/${this.props.match.params.value}`, formData, { headers })
      .then((res) => {
        this.setState({ isLoaded: false });
        swal({
          title: "Sukses!",
          text: "Data Syarat & Ketentuan berhasil diubah!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/terms-and-conditions`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        this.setState({ isLoaded: false });
        swal({
          title: e.response.data.message,
        //   text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };
  render() {
      const {editorState, isLoaded} = this.state
    return (
      <div className="admin-overlay">
          <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
            <AdminSidebar />
            <div className="content-section">
                <div className="title-section mb-5">
                    <h1 className="patient-page-title">Ubah Syarat &amp; Ketentuan</h1>
                    <h2 className="patient-page-subtitle">
                    <span style={{ color: "#039BEB" }}>Master Data /</span>
                    <Link to="/terms-and-conditions">
                        <span style={{ color: "#039BEB" }}> Syarat &amp; Ketentuan</span>
                    </Link>
                    <span style={{ color: "#00000029" }}> / Ubah Syarat &amp; Ketentuan</span>
                    </h2>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <ValidationForm
                            onSubmit={this.saveForm.bind(this)}
                            ref={this.formRef}
                            immediate={this.state.immediate}
                            setFocusOnError={this.state.setFocusOnError}
                        >
                            <table cellPadding="10" className="add-table text-left">
                            <tbody>
                                <tr>
                                <td>
                                    <label htmlFor="" className="add-vaccine-label">
                                    Deskripsi Syarat &amp; Ketentuan
                                    </label>
                                </td>
                                <td>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="wrapper-custom"
                                    toolbarClassName="toolbar-custom"
                                    editorClassName="editor-custom"
                                    onEditorStateChange={this.onEditorStateChange}
                                />
                                </td>
                                </tr>
                                <tr>
                                
                                </tr>
                            </tbody>
                            </table>
                        </ValidationForm>
                        <div className="patient-buttons">
                            <button className="btn btn-primary " onClick={this.saveForm}>
                            Simpan
                            </button>
                            <Link to="/terms-and-conditions">
                            <button className="btn btn-danger mr-1">Batal</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
          </LoadingOverlay>
        
      </div>
    );
  }
}

export default EditTerms;
