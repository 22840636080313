import { CloseOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import moment from 'moment';
import React from 'react';

export const Column = ({ onClickDetail, isDetail }) => {
  return [
    {
      title: 'Nama Ayah',
      dataIndex: 'father_name',
      key: 'father_name',
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
      hidden: false,
    },
    {
      title: 'Nama Ibu',
      dataIndex: 'mother_name',
      key: 'mother_name',
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
      hidden: false,
    },
    {
      title: 'Jml. Anak Terdaftar',
      dataIndex: 'children_count',
      key: 'children_count',
      render: (text) => {
        return <span>{text > 0 ? `${text} Orang` : '-'}</span>;
      },
      hidden: false,
    },
    {
      title: 'Kunjungan Terakhir',
      dataIndex: 'last_visit_date',
      key: 'last_visit_date',
      render: (text) => {
        return <span>{text ? moment(text)?.format('DD MMM YYYY') : '-'}</span>;
      },
      hidden: false,
    },
    {
      title: 'Terdaftar di App',
      dataIndex: 'is_registered_on_app',
      key: 'is_registered_on_app',
      render: (text) => {
        return <span>{text ? 'Terdaftar' : 'Tidak Terdaftar'}</span>;
      },
      hidden: false,
    },
    {
      title: 'Jml. Data Duplikaat',
      dataIndex: 'duplicate_data_count',
      key: 'duplicate_data_count',
      render: (text) => {
        return <span>{text > 0 ? `${text} Data` : '-'}</span>;
      },
      hidden: isDetail,
    },
    {
      title: 'Aksi',
      key: 'action',
      fixed: 'right',
      width: 200,
      render: (_, record) => (
        <Space size="small">
          <Button
            type="primary"
            danger
            style={{
              background: '#CB444A',
              display: 'flex',
              alignItems: 'center',
            }}
            icon={<CloseOutlined />}
            onClick={() => onClickDetail(record?.id)}
          >
            Hapus Duplikasi
          </Button>
        </Space>
      ),
      hidden: isDetail,
    },
  ].filter((item) => !item.hidden);
};
