import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import LoadingOverlay from "react-loading-overlay";
import AdminSidebar from "../../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../../BaseUrlApi/Url";
// import "./AddDate.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class AddBankAccount extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoaded: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      banks: [],
      bank: {},
      name: "",
      number: ""
    };
  }

  async componentDidMount() {
    try {
        this.setState({ isLoaded: true });
        const {data} = await axios.get(`${API_URL}/admin/banks`,
        { headers });
        let filterBank = data.banks.map(item => (
            {
                value: item.id,
                label: `${item.code} - ${item.name}`
            }
        ))
        this.setState({banks: filterBank})
        this.setState({ isLoaded: false });
    } catch(e) {
        this.setState({ isLoaded: false });
        swal({
            title: `${e.response.data.message}`,
            icon: "warning",
            button: "OK",
        })
    }
  }


  saveForm = async (e) => {
    e.preventDefault();
    this.setState({ isLoaded: true });
    const formData = {
      name: this.state.name,
      number: this.state.number,
      bank_id: this.state.bank.value,
    };
    axios
      .post(`${API_URL}/admin/bank_accounts`, formData, { headers })
      .then((res) => {
        this.setState({ isLoaded: false });
        swal({
          title: "Sukses!",
          text: "Data Telah Ditambahkan!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/bank-accounts`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        this.setState({ isLoaded: false });
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };
  render() {
      const {bank, banks, name, number, isLoaded} = this.state
    return (
      <div className="admin-overlay">
          <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
            <AdminSidebar />
            <div className="content-section">
                <div className="title-section mb-5">
                    <h1 className="patient-page-title">Tambah Rekening Bank</h1>
                    <h2 className="patient-page-subtitle">
                    <span style={{ color: "#039BEB" }}>Master Data /</span>
                    <Link to="/bank-accounts">
                        <span style={{ color: "#039BEB" }}> Daftar Rekening Bank</span>
                    </Link>
                    <span style={{ color: "#00000029" }}> / Tambah Rekening Bank</span>
                    </h2>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <ValidationForm
                            onSubmit={this.saveForm.bind(this)}
                            ref={this.formRef}
                            immediate={this.state.immediate}
                            setFocusOnError={this.state.setFocusOnError}
                        >
                            <table cellPadding="10" className="add-table text-left">
                            <tbody>
                                <tr>
                                <td>
                                    <label htmlFor="" className="add-vaccine-label">
                                    Bank
                                    </label>
                                </td>
                                <td>
                                    
                                    :
                                    <Select
                                        options={banks}
                                        value={bank}
                                        onChange={(e) => {
                                            this.setState({
                                                bank: {
                                                    value: e.value,
                                                    label: e.label,
                                                }
                                            })
                                        }}
                                        required
                                    />
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <label htmlFor="" className="add-vaccine-label">
                                    Nomor Rekening
                                    </label>
                                </td>
                                <td>
                                    
                                    :
                                    <TextInput
                                    type="tel"
                                    name="number"
                                    value={number}
                                    onChange={(e) => this.setState({number: e.target.value})}
                                    className="add-vaccine-input"
                                    required
                                    />
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <label htmlFor="" className="add-vaccine-label">
                                    Nama Pemilik Rekening
                                    </label>
                                </td>
                                <td>
                                    
                                    :
                                    <TextInput
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    className="add-vaccine-input"
                                    required
                                    />
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </ValidationForm>
                        <div className="patient-buttons">
                            <button className="btn btn-primary " onClick={this.saveForm}>
                            Simpan
                            </button>
                            <Link to="/bank-accounts">
                            <button className="btn btn-danger mr-1">Batal</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
          </LoadingOverlay>
        
      </div>
    );
  }
}

export default AddBankAccount;
