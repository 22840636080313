import { QUEUE_PERIOD_TYPE } from '.';

export const QUEUE_PERIOD_OPTIONS = [
  {
    label: 'Antrian Mendatang',
    value: QUEUE_PERIOD_TYPE.UPCOMING,
  },
  {
    label: 'Semua Antrian',
    value: QUEUE_PERIOD_TYPE.ALL_TIME,
  },
];
