import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import AdminSidebar from "../../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../../BaseUrlApi/Url";
import "./UpdateDate.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class UpdateDate extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      isLoaded: false,
      setFocusOnError: true,
      clearInputOnReset: false,
      dateId: null,
      date: null,
      information: "",
      type: "",
      startDate: new Date(),
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let dateId = this.props.match.params.value;
    let getBlockedDate = await axios.get(
      `${API_URL}/admin/blocked_dates/${dateId}`,
      { headers }
    );
    this.setState({
      dateId: dateId,
      date: getBlockedDate.data.blockedDate.date,
      type: getBlockedDate.data.blockedDate.type,
      information: getBlockedDate.data.blockedDate.information,
    });
    this.setState({ isLoaded: false });
  }

  selectedDate = (value) => {
    if (this.state.date === null) {
      return "";
    } else {
      return new Date(this.state.date);
    }
  };

  dateHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateConverter(this.state.startDate);
    }
  };

  dateConverter = async (value) => {
    let date = value,
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join("-");
    this.setState({
      date: convertDate,
    });
  };

  inputHandler = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  saveDate = async (e) => {
    e.preventDefault();

    const postDate = {
      _method: "PATCH",
      date: this.state.date,
      type: this.state.type,
      information: this.state.information,
    };
    axios
      .post(`${API_URL}/admin/blocked_dates/${this.state.dateId}`, postDate, {
        headers,
      })
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Data Telah Diubah!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/blocked-dates`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };
  render() {
    let { type, information } = this.state;
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title ">Tambah Blocked Date</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Master Data</span>
                <span style={{ color: "#039BEB" }}>/ Daftar Blocked Date</span>
                <span style={{ color: "#00000029" }}>
                  
                  / Tambah Blocked Date
                </span>
              </h2>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <ValidationForm
                  onSubmit={this.saveDate.bind(this)}
                  ref={this.formRef}
                  immediate={this.state.immediate}
                  setFocusOnError={this.state.setFocusOnError}
                >
                  <table cellPadding="10" className="add-table text-left">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Tanggal
                          </label>
                        </td>
                        <td>
                          
                          :
                          <DatePicker
                            selected={this.selectedDate()}
                            onChange={this.dateHandler}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="dd-MM-yyyy"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Tipe
                          </label>
                        </td>
                        <td>
                          
                          :
                          <TextInput
                            type="text"
                            name="type"
                            value={type}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Informasi
                          </label>
                        </td>
                        <td>
                          
                          :
                          <TextInput
                            type="text"
                            name="information"
                            value={information}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ValidationForm>
                <div className="patient-buttons">
                  <button className="btn btn-primary" onClick={this.saveDate}>
                    Simpan
                  </button>
                  <Link to="/blocked-dates">
                    <button className="btn btn-danger mr-1">Batal</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default UpdateDate;
