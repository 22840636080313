import React,{Component} from 'react'
import swal from 'sweetalert';
import axios from 'axios'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import LoadingOverlay from 'react-loading-overlay';
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import { ValidationForm, TextInput} from "react-bootstrap4-form-validation";
import AdminSidebar from '../../AdminSidebar/AdminSidebar'
import {API_URL} from '../../../BaseUrlApi/Url'
import './ChangePatientData.css'

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}

class ChangePatientData extends Component{
  constructor(props){
    super(props)
    this.formRef = React.createRef();
    this.state = {
      immediate:true,
      setFocusOnError:true,
      clearInputOnReset:false,
      isLoaded:false,
      fullname: null,
      birthdate: null,
      birthplace: '',
      parent_id:null,
      choose_gender:{},
      gender:[],
      bef_gender:'',
      child_id: null,
      startDate: new Date()
    }
  }


  async componentDidMount(){
    this.setState({isLoaded:true})
    let childId = this.props.match.params.value
    let getChildData = await axios.get(`${API_URL}/admin/children/${childId}`,{headers})
    this.setState({
      fullname: getChildData.data.child.fullname,
      bef_gender: getChildData.data.child.gender,
      birthdate: getChildData.data.child.birthdate,
      birthplace: getChildData.data.child.birthplace,
      parent_id: getChildData.data.child.parent_id,
      child_id : childId
    })
    this.genderController()
    this.setState({isLoaded:false})
  }

  genderController = async() =>{
    let gender = [
      {
        gender: 'male',
        label : 'Laki-laki'
      },
      {
        gender: 'female',
        label: 'Perempuan'
      }
    ]

    let getGender = gender.map(type => (
      {
        value: type.gender,
        label: type.label
      }
    ))

    await this.setState({
      gender: getGender,
    })

    if(this.state.bef_gender === 'male'){
      this.setState({
        choose_gender:{
            value: 'male',
            label: 'Laki-laki'
        }
      })
    }else{
      this.setState({
        choose_gender:{
            value:'female',
            label:'Perempuan'
        }
      })
    }
  }

  genderHandler = async(value) =>{
    this.setState({
      choose_gender : {
        value: value.value,
        label : value.label
      }
    })
  }

  selectedDate = (value) => {
    if(this.state.birthdate === null){
      return ''
    }else{
      return new Date(this.state.birthdate)
    }
  }

  dateHandler = async(date) => {
    await this.setState({
      birthdate: date
    });
    if(this.state.birthdate !== null){
      this.dateConverter(this.state.birthdate)
    }
  };

  dateConverter = value => {
    let date = value,
    mnth = ("0" + (date.getMonth()+1)).slice(-2),
    day  = ("0" + date.getDate()).slice(-2);
    let convertDate = [ date.getFullYear(), mnth, day ].join("-");
    this.setState({
      birthdate:convertDate
    })
  }

  inputHandler = async(e) =>{
    await this.setState({
      [e.target.name] : e.target.value
    })
  }

  clearForm = async(e) =>{
    this.setState({
      fullname: '',
      birthdate: null,
      birthplace: '',
      choose_gender:{}
    })
  }
  updateData = async(e) =>{
    e.preventDefault();
    const updateData = {
      _method: 'PATCH',
      parent_id : this.state.parent_id,
      fullname : this.state.fullname,
      gender:this.state.choose_gender.value,
      birthdate : this.state.birthdate,
      birthplace : this.state.birthplace
    }

    axios.post(`${API_URL}/admin/children/${this.state.child_id}`, updateData, {headers}).then(res=>{
      swal({
        title: "Sukses!",
        text: "Data Keluarga Telah Berhasil Diubah!",
        icon: "success",
        button: "OK",
      }).then((move) => {
        if(move){
          window.location.href = `/detail-patient/${this.state.parent_id}`
        }else{
          return
        }
      })
    })    
  }
  render(){
    let {immediate, setFocusOnError, fullname, parent_id, isLoaded, gender, choose_gender, birthplace} = this.state
    return(
      <div className="admin-overlay">
        <AdminSidebar/>
        <LoadingOverlay
        active={isLoaded}
        spinner
        text='Memuat data...'
        >
        <div className="content-section">
          <div className="title-section mb-5">
            <h1 className="patient-page-title">Ubah Data Keluarga</h1>
            <h2 className="patient-page-subtitle">
              <span style={{color: '#039BEB'}}>Manajemen Anak</span> 
              <span style={{color: '#039BEB'}}>/ Daftar Kepala Keluarga</span> 
              <span style={{color: '#039BEB'}}>/ Detail Kepala Keluarga</span> 
              <span style={{color:'#00000029'}}> / Ubah Data Keluarga</span>
            </h2>
          </div>

          <div className="card shadow mb-4">
              <div className="card-body">
                <ValidationForm 
                  onSubmit={this.updateData.bind(this)}                  
                  ref={this.formRef}
                  immediate={immediate}
                  setFocusOnError={setFocusOnError}>
                  <table cellPadding="10" className="add-table text-left">
                   <tbody>
                   <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">Nama</label>
                      </td>
                      <td> : 
                        <TextInput 
                          type="text" 
                          name="fullname" 
                          value={fullname}
                          onChange={this.inputHandler}
                          className="add-vaccine-input"/>
                      </td>
                    </tr>
                    <tr>
                      <td className="label-class">
                        <label htmlFor="" className="add-vaccine-label">Gender</label> 
                      </td>
                      <td> :
                        <Select
                          options={gender}
                          value={choose_gender}
                          onChange={value => this.genderHandler(value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">Tanggal Lahir</label>
                      </td>
                      <td> : 
                        <DatePicker
                          selected={this.selectedDate()}
                          onChange={this.dateHandler}
                          dateFormat="dd-MM-yyyy"
                          className="add-fam-date"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">Tempat Lahir</label>
                      </td>
                      <td> : 
                        <TextInput 
                          type="text" 
                          name="birthplace" 
                          value={birthplace}
                          onChange={this.inputHandler}
                          className="add-vaccine-input"/>
                      </td>
                    </tr>
                   </tbody>
                    <br/>
                  </table>
                  <div className="patient-buttons">
                    <button className="btn btn-primary mr-1" >Simpan</button>
                    <Link to={`/detail-patient/${parent_id}`}>
                      <button className="btn btn-danger mr-1">Batal</button>
                    </Link>
                    <button className="btn btn-warning text-dark mr-1" onClick={this.clearForm}>Clear</button>
                  </div>
                </ValidationForm>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    )
  }
}

export default ChangePatientData