import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";
import DatePicker from "react-datepicker";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import AdminSidebar from "../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../BaseUrlApi/Url";
import "./AddQueue.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class AddQueue extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      // FORM STATE
      isLoaded: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      showOptions: false,
      // MODAL STATE
      isFamilyOpen: false,
      isChildOpen: false,

      startDate: new Date(),
      parentId: null,
      childId: null,
      shiftId: null,
      fullname: "",
      birthplace: "",
      date: null,
      filter_date: null,
      fathers: [],
      father: {},
      mothers: [],
      mother: {},
      children: [],
      child: {},
      shifts: [],
      shift: {},
      registrations: [],
      registration: {},
      statuses: [],
      status: {},
      address: null,

      // EDIT FAMILY
      edit_father_name: "",
      edit_mother_name: "",
      edit_phone_number: "",
      edit_address: "",
      jobs: [],
      father_job: {},
      mother_job: {},
      father_job_id: null,
      mother_job_id: null,
      provinces: [],
      province: {},
      cities: [],
      city: {},
      districts: [],
      district: {},
      edit_province_id: null,
      edit_city_id: null,
      edit_district_id: null,
      isFree: null,
      free: [],

      // CHILD DATA
      child_fullname: null,
      child_birthdate: null,
      child_birthplace: "",
      choose_gender: {},
      gender: [],
      bef_gender: "",
    };
  }

  async componentDidMount() {
    // GET PARENTS DATA FOR SELECTOR
    this.setState({ isLoaded: true });

    // GET REGIRTATION ORIGINS FOR SELECTOR
    let getRegistrations = await axios.get(
      `${API_URL}/admin/registration_origins`,
      { headers }
    );
    let registrations = getRegistrations.data.registrationOrigins.map(
      (regis) => ({
        value: regis.id,
        label: regis.name,
      })
    );

    // GET QUEUE STATUSES FOR SELECTOR
    let getQueueStatuses = await axios.get(`${API_URL}/admin/queue_statuses`, {
      headers,
    });
    let statuses = getQueueStatuses.data.queueStatuses.map((status) => ({
      value: status.id,
      label: status.name,
    }));

    await this.setState({
      registrations: registrations,
      statuses: statuses,
      shiftId: parseInt(this.props.match.params.value),
    });

    this.dateConverter();
    this.getIsFree();
    this.setState({ isLoaded: false });
  }

  getIsFree = async () => {
    let free = [
      {
        label: "Tidak Gratis",
        value: 0,
      },
      {
        label: "Gratis",
        value: 1,
      },
    ];
    this.setState({
      free: free,
    });
  };

  onInputChange = (typedOption) => {
    if (typedOption.length >= 4) {
      this.setState({
        showOptions: true,
      });
      this.parentsData(typedOption);
    } else {
      this.setState({
        showOptions: false,
      });
    }
  };

  parentsData = async (keyword) => {
    this.setState({ isLoaded: true });
    let getParents = await axios.get(
      `${API_URL}/admin/parents/search?keyword=${keyword}`,
      { headers }
    );
    let fathers = getParents.data.parents.map((father) => ({
      value: father.id,
      label: father.father_name,
    }));
    let mothers = getParents.data.parents.map((mother) => ({
      value: mother.id,
      label: mother.mother_name,
    }));

    this.setState({
      fathers: fathers,
      mothers: mothers,
      isLoaded: false,
    });
  };

  // FATHER HANDLER IF VALUE CHANGED
  fatherHandler = async (value) => {
    try {
      this.setState({
        father: {
          value: value.value,
          label: value.label,
        },
      });
      this.getChildren(parseInt(value.value));
      this.getMother(parseInt(value.value));
    } catch (e) {}
  };

  // MOTHER HANDLER IF VALUE CHANGED
  motherHandler = async (value) => {
    try {
      this.setState({
        mother: {
          value: value.value,
          label: value.label,
        },
      });

      this.getChildren(parseInt(value.value));
      this.getFather(parseInt(value.value));
    } catch (e) {}
  };

  // CHILD HANDLER IF VALUE CHANGED
  childHandler = async (value) => {
    try {
      this.setState({ isLoaded: true });
      let getChildren = await axios.get(
        `${API_URL}/admin/children/${value.value}`,
        { headers }
      );
      this.getMother(getChildren.data.child.parent_id);
      this.getFather(getChildren.data.child.parent_id);
      this.setState({
        child: {
          value: value.value,
          label: value.label,
        },
        filter_date: getChildren.data.child.birthdate,
      });
    } catch (e) {}
  };

  // GET FATHER WHEN CHILD OR MOTHER IS SELECTED
  getFather = async (value) => {
    try {
      this.setState({ isLoaded: true });
      let getParents = await axios.get(`${API_URL}/admin/parents/${value}`, {
        headers,
      });
      this.setState({
        father: {
          value: getParents.data.parent.id,
          label: getParents.data.parent.father_name,
        },
        address: getParents.data.parent.address,
      });
      this.setState({ isLoaded: false });
    } catch (e) {
      this.setState({ isLoaded: false });
    }
  };

  // GET MOTHER WHEN CHILD OR FATHER IS SELECTED
  getMother = async (value) => {
    try {
      this.setState({ isLoaded: true });
      let getParents = await axios.get(`${API_URL}/admin/parents/${value}`, {
        headers,
      });
      this.setState({
        mother: {
          value: getParents.data.parent.id,
          label: getParents.data.parent.mother_name,
        },
      });
      this.setState({ isLoaded: false });
    } catch (e) {
      this.setState({ isLoaded: false });
    }
  };

  // GET CHILDREN WHEN MOTHER OR FATHER IS SELECTED
  getChildren = async (value) => {
    try {
      this.setState({ isLoaded: true });
      let getChildren = await axios.get(
        `${API_URL}/admin/children?parent_id=${value}`,
        { headers }
      );
      let children = getChildren.data.children.map((child) => ({
        value: child.id,
        label: child.fullname,
      }));
      await this.setState({
        children: children,
      });
      this.setState({ isLoaded: false });
    } catch (e) {
      this.setState({ isLoaded: false });
    }
  };

  // REGISTRASION HANDLER IF VALUE CHANGED
  registrationHandler = async (value) => {
    this.setState({
      registration: {
        value: value.value,
        label: value.label,
      },
    });
  };

  // QUEUE STATUS HANDLER IF VALUE CHANGED
  statusHandler = async (value) => {
    this.setState({
      status: {
        value: value.value,
        label: value.label,
      },
    });
  };

  // === DATE TAKEN FOR REGISTER (QUEUE) HANDLER ===
  selectedDate = (value) => {
    if (this.state.date === null) {
      return "";
    } else {
      return new Date(this.state.date);
    }
  };

  dateHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateConverter(this.state.startDate);
    }
  };

  dateConverter = async () => {
    let date = this.state.startDate,
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join("-");
    this.setState({
      date: convertDate,
    });
  };

  // === END DATE TAKEN HANDLER ===

  // === START GET CHILD BY FILTER DATE ===
  selectedFilterDate = (value) => {
    if (this.state.filter_date === null) {
      return "";
    } else {
      return new Date(this.state.filter_date);
    }
  };

  dateFilterHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateFilterConverter(this.state.startDate);
    }
  };

  dateFilterConverter = async (value) => {
    let date = value,
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join("-");
    this.setState({
      filter_date: convertDate,
    });
    let getChildren = await axios.get(
      `${API_URL}/admin/children?birthdate=${convertDate}`,
      { headers }
    );
    let children = getChildren.data.children.map((child) => ({
      value: child.id,
      label: child.fullname,
    }));
    if (children.length === 1) {
      this.childHandler(children[0]);
    }
    await this.setState({
      children: children,
    });
  };

  // === END FILTER DATE ===
  clearForm = () => {
    this.setState({
      date: null,
      filter_date: null,
      father: {},
      mother: {},
      child: {},
      shift: {},
      registration: {},
      status: {},
      address: null,
    });
  };

  // POST REQUEST CREATE QUEUE
  saveQueue = async (e) => {
    e.preventDefault();
    const postData = {
      date: this.state.date,
      shift_id: this.state.shiftId,
      child_id: this.state.child.value,
      registration_origin_id: this.state.registration.value,
      queue_status_id: this.state.shiftId === 16 ? 9 : this.state.status.value,
    };

    axios
      .post(`${API_URL}/admin/queues`, postData, { headers })
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Antrian Berhasil Ditambahkan!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = this.state.shiftId === 16 ? `/queues-online/${this.state.shiftId}` : `/queues/${this.state.shiftId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        console.log("err:", err.response.data.message);
        if (err.response.data.message === "The given data was invalid.") {
          swal({
            title: "GAGAL",
            text: " Harap Lengkapi Data",
            icon: "warning",
            type: "error",
            buttons: "OK",
          });
        } else if (
          err.response.data.message ===
            "Sorry, registration deadline has been reached!"
        ) {
          swal({
            title: "GAGAL",
            text: "Telah Mencapai Batas Waktu Pendaftaran",
            icon: "warning",
            type: "error",
            buttons: "OK",
          });
        } else if (
          err.response.data.message ===
            "Sorry, registration limit has been reached!"
        ) {
          swal({
            title: "GAGAL",
            text: "Antrian Sudah Penuh",
            icon: "warning",
            type: "error",
            buttons: "OK",
          });
        }else if (
          err.response.data.message ===
          "Sorry, your child already has a ticket!, please cancel it first!"
        ) {
          swal({
            title: "GAGAL",
            text: "Anak Telah Memiliki Tiket",
            icon: "warning",
            type: "error",
            buttons: "OK",
          });
        } else {
          swal({
            title: "GAGAL",
            text: "Gagal Menyimpan Data",
            icon: "warning",
            type: "error",
            buttons: "OK",
          });
        }
      });
  };

  cancelQueue = () => {
    window.location.href = `/queues/${this.state.shiftId}`;
  };

  // === MODALS FOR FAMILY EDIT STARTS ===
  showFamilyEdit = async (e) => {
    this.setState({ isLoaded: true });
    let getParent = await axios.get(
      `${API_URL}/admin/parents/${this.state.father.value}`,
      { headers }
    );
    await this.setState({
      parentId: getParent.data.parent.id,
      isFree: getParent.data.parent.is_free,
      edit_father_name: getParent.data.parent.father_name,
      edit_mother_name: getParent.data.parent.mother_name,
      edit_address: getParent.data.parent.address,
      edit_phone_number: getParent.data.parent.phone_number,
      father_job_id: getParent.data.parent.father_job_id,
      mother_job_id: getParent.data.parent.mother_job_id,
      edit_province_id: getParent.data.parent.province_id,
      edit_city_id: getParent.data.parent.city_id,
      edit_district_id: getParent.data.parent.district_id,
      isFamilyOpen: !this.state.isFamilyOpen,
    });
    this.getJobs();
    this.getProvinces();
    this.getCity();
    this.getDistrict();
    this.setState({ isLoaded: false });
  };

  hideFamilyEdit = async (e) => {
    this.setState({ isFamilyOpen: false });
  };

  isFreeHandler = async (e) => {
    await this.setState({
      isFree: parseInt(e.target.value),
    });
  };

  inputFamilyEditHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  fatherJobHandler = async (value) => {
    this.setState({
      father_job: {
        value: value.value,
        label: value.label,
      },
    });
  };

  motherJobHandler = async (value) => {
    this.setState({
      mother_job: {
        value: value.value,
        label: value.label,
      },
    });
  };

  // GET PARENT JOB
  getJobs = async () => {
    let getJobs = await axios.get(`${API_URL}/admin/jobs`, { headers });
    let jobs = getJobs.data.jobs.map((job) => ({
      value: job.id,
      label: job.name,
    }));
    await this.setState({
      jobs: jobs,
    });

    let { father_job_id, mother_job_id } = this.state;
    let getFatherJob = await axios.get(`${API_URL}/jobs/${father_job_id}`);
    let getMotherJob = await axios.get(`${API_URL}/jobs/${mother_job_id}`);
    this.setState({
      father_job: {
        value: getFatherJob.data.job.id,
        label: getFatherJob.data.job.name,
      },
      mother_job: {
        value: getMotherJob.data.job.id,
        label: getMotherJob.data.job.name,
      },
    });
  };

  provinceHandler = async (value) => {
    let getCities = await axios.get(
      `${API_URL}/regions/provinces?id=${value.value}`
    );
    let cities = getCities.data.provinces.cities.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    this.setState({
      province: {
        value: value.value,
        label: value.label,
      },
      cities: cities,
    });
  };

  cityHandler = async (value) => {
    let getDistricts = await axios.get(
      `${API_URL}/regions/cities?id=${value.value}`,
      {
        headers,
      }
    );
    let districts = getDistricts.data.cities.districts.map((district) => ({
      value: district.id,
      label: district.name,
    }));

    this.setState({
      city: {
        value: value.value,
        label: value.label,
      },
      districts: districts,
    });
  };

  districtHandler = async (value) => {
    this.setState({
      district: {
        value: value.value,
        label: value.label,
      },
    });
  };

  // GET PROVINCE
  getProvinces = async () => {
    let getProvinces = await axios.get(`${API_URL}/regions/provinces`);
    let provinces = getProvinces.data.provinces.map((province) => ({
      value: province.id,
      label: province.name,
    }));
    this.setState({
      provinces: provinces,
    });

    let { edit_province_id } = this.state;
    if (edit_province_id !== null) {
      let getProvince = await axios.get(
        `${API_URL}/regions/provinces?id=${edit_province_id}`
      );
      this.setState({
        province: {
          value: getProvince.data.provinces.id,
          label: getProvince.data.provinces.name,
        },
      });
    }
  };

  // GET CITY
  getCity = async () => {
    let { edit_city_id } = this.state;
    if (edit_city_id !== null) {
      let getCity = await axios.get(
        `${API_URL}/regions/cities?id=${edit_city_id}`
      );
      this.setState({
        city: {
          value: getCity.data.cities.id,
          label: getCity.data.cities.name,
        },
      });
    }
  };

  // GET PARENT DISTRICT
  getDistrict = async () => {
    let { edit_district_id } = this.state;
    if (edit_district_id !== null) {
      let getDistrict = await axios.get(
        `${API_URL}/regions/districts?id=${edit_district_id}`,
        { headers }
      );
      this.setState({
        district: {
          value: getDistrict.data.districts.id,
          label: getDistrict.data.districts.name,
        },
      });
    }
  };

  updateFamilyData = async (e) => {
    e.preventDefault();
    const updateFamilyData = {
      _method: "PATCH",
      father_name: this.state.edit_father_name,
      mother_name: this.state.edit_mother_name,
      is_free: this.state.isFree,
      father_job_id: this.state.father_job.value,
      mother_job_id: this.state.mother_job.value,
      address: this.state.edit_address,
      phone_number: this.state.edit_phone_number,
      province_id: this.state.province.value,
      city_id: this.state.city.value,
      district_id: this.state.district.value,
    };

    axios
      .post(
        `${API_URL}/admin/parents/${this.state.parentId}`,
        updateFamilyData,
        { headers }
      )
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Data Kepala Keluarga Telah Berhasil Diperbarui!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/queues/${this.state.shiftId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };

  // === MODALS FOR FAMILY EDIT END ===

  // === MODALS FOR CHILD EDIT STARTS ===
  showChildEdit = async (e) => {
    let getChildData = await axios.get(
      `${API_URL}/admin/children/${this.state.child.value}`,
      { headers }
    );
    this.setState({
      childId: getChildData.data.child.id,
      child_fullname: getChildData.data.child.fullname,
      bef_gender: getChildData.data.child.gender,
      child_birthdate: getChildData.data.child.birthdate,
      child_birthplace: getChildData.data.child.birthplace,
      parentId: getChildData.data.child.parentId,
      isChildOpen: !this.state.isChildOpen,
    });
    this.genderController();
  };

  hideChildEdit = async (e) => {
    this.setState({ isChildOpen: false });
  };

  genderController = async () => {
    let gender = [
      {
        gender: "male",
        label: "Laki-laki",
      },
      {
        gender: "female",
        label: "Perempuan",
      },
    ];

    let getGender = gender.map((type) => ({
      value: type.gender,
      label: type.label,
    }));
    await this.setState({
      gender: getGender,
    });

    if (this.state.bef_gender === "male") {
      this.setState({
        choose_gender: {
          value: "male",
          label: "Laki-laki",
        },
      });
    } else {
      this.setState({
        choose_gender: {
          value: "female",
          label: "Perempuan",
        },
      });
    }
  };

  genderHandler = async (value) => {
    this.setState({
      choose_gender: {
        value: value.value,
        label: value.label,
      },
    });
  };

  birthplaceHandler = async (e) => {
    await this.setState({
      child_birthplace: e.target.value,
    });
  };

  childSelectedDate = (value) => {
    if (this.state.child_birthdate === null) {
      return "";
    } else {
      return new Date(this.state.child_birthdate);
    }
  };

  childDateHandler = async (date) => {
    await this.setState({
      child_birthdate: date,
    });
    if (this.state.child_birthdate !== null) {
      this.childDateConverter(this.state.child_birthdate);
    }
  };

  childDateConverter = (value) => {
    let date = value,
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join("-");
    this.setState({
      child_birthdate: convertDate,
    });
  };

  updateChildData = async (e) => {
    e.preventDefault();
    const updateChildData = {
      _method: "PATCH",
      parentId: this.state.parentId,
      fullname: this.state.child_fullname,
      birthdate: this.state.child_birthdate,
      birthplace: this.state.child_birthplace,
      gender: this.state.choose_gender.value,
    };

    axios
      .post(
        `${API_URL}/admin/children/${this.state.childId}`,
        updateChildData,
        { headers }
      )
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Data Anak Telah Berhasil Diubah!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/queues/${this.state.shiftId}`;
          } else {
            return;
          }
        });
      });
  };

  // if(this.state.isLoaded === false){

  // }
  // if(this)
  render() {
    let {
      immediate,
      setFocusOnError,
      isLoaded,
      showOptions,
      fathers,
      father,
      mothers,
      mother,
      children,
      child,
      registrations,
      registration,
      statuses,
      status,
      address,

      // FAMILY EDIT
      edit_father_name,
      edit_mother_name,
      edit_phone_number,
      edit_address,
      jobs,
      father_job,
      mother_job,
      provinces,
      province,
      cities,
      city,
      districts,
      district,
      isFree,
      free,

      // CHILD DATA
      child_fullname,
      gender,
      choose_gender,
      child_birthplace,
    } = this.state;

    return (
      <div className="admin-overlay">
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <AdminSidebar />
          <div className="content-section">
            <div className="title-section">
              <h1 className="patient-page-title">Buat Antrian</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Manajemen Antrian</span>
                <span style={{ color: "#039BEB" }}>/ Daftar Antrian</span>
                <span style={{ color: "#00000029" }}> / Buat Antrian</span>
              </h2>
            </div>
            <div className="add-button">
              <Link to="/add-family">
                <button className="btn btn-primary btn-add-table mr-3 mb-2">
                  Tambah Daftar Keluarga
                </button>
              </Link>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="float-right mb-1">
                  {address !== null ? (
                    <button className="btn btn-info address-queue">
                      Alamat : {address}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <ValidationForm
                  // onSubmit={this.saveQueue.bind(this)}

                  ref={this.formRef}
                  immediate={immediate}
                  setFocusOnError={setFocusOnError}
                >
                  <table cellPadding="10" className="add-table text-left">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Nama Ayah
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                            options={showOptions ? fathers : []}
                            value={father}
                            onChange={(value) => this.fatherHandler(value)}
                            onInputChange={this.onInputChange}
                            required
                          />
                          {father.value !== undefined ? (
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={this.showFamilyEdit}
                              className="queue-edit"
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Nama Ibu
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                            options={showOptions ? mothers : []}
                            value={mother}
                            onChange={(value) => this.motherHandler(value)}
                            onInputChange={this.onInputChange}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Nama Anak
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                            options={children}
                            value={child}
                            onChange={(value) => this.childHandler(value)}
                            required
                          />
                          {child.value !== undefined ? (
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={this.showChildEdit}
                              className="queue-edit"
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Tanggal Lahir
                          </label>
                        </td>
                        <td>
                          :
                          <DatePicker
                            selected={this.selectedFilterDate()}
                            onChange={this.dateFilterHandler}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="dd-MM-yyyy"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Pendaftaran Via
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                            options={registrations}
                            value={registration}
                            onChange={(value) =>
                              this.registrationHandler(value)
                            }
                            required
                          />
                        </td>
                      </tr>
                      <tr style={{display: this.state.shiftId === 16 ? 'none' : ''}}>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Status Antrian
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                            options={statuses}
                            value={status}
                            onChange={(value) => this.statusHandler(value)}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Tanggal Pengambilan Antrian
                          </label>
                        </td>
                        <td>
                          :
                          <DatePicker
                            selected={this.selectedDate()}
                            onChange={this.dateHandler}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="dd-MM-yyyy"
                            required
                          />
                        </td>
                      </tr>
                    </tbody>
                    <br />
                  </table>
                </ValidationForm>
                <div className="patient-buttons">
                  <button
                    className="btn btn-primary"
                    onClick={this.saveQueue.bind(this)}
                  >
                    Simpan
                  </button>
                  <button
                    className="btn btn-danger mr-1"
                    onClick={this.cancelQueue}
                  >
                    Batal
                  </button>
                  <button
                    className="btn btn-warning text-dark mr-1"
                    onClick={this.clearForm}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* SHOW MODAL FAMILY EDIT*/}
          <Modal
            show={this.state.isFamilyOpen}
            onHide={this.hideFamilyEdit}
            className="family-modals"
          >
            <Modal.Header>
              <Modal.Title>
                <h1 className="note-child-title">Edit Data Keluarga</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ValidationForm
                onSubmit={this.updateFamilyData.bind(this)}
                ref={this.formRef}
                immediate={immediate}
                setFocusOnError={setFocusOnError}
              >
                <table cellPadding="10" className="add-table mb-5">
                  <tbody>
                    <tr>
                      <td className="label-class">
                        <label htmlFor="" className="add-vaccine-label">
                          Nama Ayah
                        </label>
                      </td>
                      <td>
                        :
                        <TextInput
                          type="text"
                          name="edit_father_name"
                          value={edit_father_name}
                          onChange={this.inputFamilyEditHandler}
                          className="add-vaccine-input"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label-class">
                        <label htmlFor="" className="add-vaccine-label">
                          Nama Ibu
                        </label>
                      </td>
                      <td>
                        :
                        <TextInput
                          type="text"
                          name="edit_mother_name"
                          value={edit_mother_name}
                          onChange={this.inputFamilyEditHandler}
                          className="add-vaccine-input"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label-class">
                        <label htmlFor="" className="add-vaccine-label">
                          Pekerjaan Ayah
                        </label>
                      </td>
                      <td>
                        :
                        <Select
                          options={jobs}
                          name="father_job"
                          value={father_job}
                          onChange={(value) => this.fatherJobHandler(value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label-class">
                        <label htmlFor="" className="add-vaccine-label">
                          Pekerjaan Ibu
                        </label>
                      </td>
                      <td>
                        :
                        <Select
                          options={jobs}
                          name="mother_job"
                          value={mother_job}
                          onChange={(value) => this.motherJobHandler(value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label-class">
                        <label htmlFor="" className="add-vaccine-label">
                          Status Digratiskan
                        </label>
                      </td>
                      <td>
                        :
                        <div className="radio-style">
                          {free.map((res) => (
                            <label className="container">
                              <input
                                type="radio"
                                name="radio"
                                id={res.label}
                                value={res.value}
                                onChange={this.isFreeHandler}
                                checked={isFree === res.value ? "checked" : ""}
                              />
                              <span className="checkmark"></span>
                              {res.label}
                            </label>
                          ))}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="label-class">
                        <label htmlFor="" className="add-vaccine-label">
                          Provinsi
                        </label>
                      </td>
                      <td>
                        :
                        <Select
                          options={provinces}
                          value={province}
                          onChange={(value) => this.provinceHandler(value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label-class">
                        <label htmlFor="" className="add-vaccine-label">
                          Kota/Kabupaten
                        </label>
                      </td>
                      <td>
                        :
                        <Select
                          options={cities}
                          value={city}
                          onChange={(value) => this.cityHandler(value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label-class">
                        <label htmlFor="" className="add-vaccine-label">
                          Kecamatan
                        </label>
                      </td>
                      <td>
                        :
                        <Select
                          options={districts}
                          value={district}
                          onChange={(value) => this.districtHandler(value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Alamat
                        </label>
                      </td>
                      <td>
                        :
                        <TextInput
                          type="text"
                          name="edit_address"
                          value={edit_address}
                          onChange={this.inputFamilyEditHandler}
                          className="add-vaccine-input"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          No.Telp
                        </label>
                      </td>
                      <td>
                        :
                        <TextInput
                          type="text"
                          name="edit_phone_number"
                          value={edit_phone_number}
                          onChange={this.inputFamilyEditHandler}
                          className="add-vaccine-input"
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ValidationForm>
            </Modal.Body>
            <Modal.Footer>
              <div className="patient-buttons">
                <button
                  className="btn btn-primary"
                  onClick={this.updateFamilyData}
                >
                  Simpan
                </button>
                <button
                  className="btn btn-danger mr-1"
                  onClick={this.hideFamilyEdit}
                >
                  Batal
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* SHOW MODAL CHILD EDIT */}
          <Modal show={this.state.isChildOpen} onHide={this.hideChildEdit}>
            <Modal.Header>
              <Modal.Title>
                <h1 className="note-child-title">Edit Data Anak</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ValidationForm
                onSubmit={this.updateChildData.bind(this)}
                ref={this.formRef}
                immediate={immediate}
                setFocusOnError={setFocusOnError}
              >
                <table cellPadding="10" className="add-table mb-5">
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Nama
                        </label>
                      </td>
                      <td>
                        :
                        <TextInput
                          type="text"
                          name="child_name"
                          value={child_fullname}
                          onChange={this.nameHandler}
                          className="add-vaccine-input"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label-class">
                        <label htmlFor="" className="add-vaccine-label">
                          Gender
                        </label>
                      </td>
                      <td>
                        :
                        <Select
                          options={gender}
                          value={choose_gender}
                          onChange={(value) => this.genderHandler(value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Tanggal Lahir
                        </label>
                      </td>
                      <td>
                        :
                        <DatePicker
                          selected={this.childSelectedDate()}
                          onChange={this.childDateHandler}
                          dateFormat="dd-MM-yyyy"
                          className="add-fam-date"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Tempat Lahir
                        </label>
                      </td>
                      <td>
                        :
                        <TextInput
                          type="text"
                          name="child_birthplace"
                          value={child_birthplace}
                          onChange={this.birthplaceHandler}
                          className="add-vaccine-input"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ValidationForm>
            </Modal.Body>
            <Modal.Footer>
              <div className="patient-buttons">
                <button
                  className="btn btn-primary"
                  onClick={this.updateChildData}
                >
                  Simpan
                </button>
                <button
                  className="btn btn-danger  mr-1"
                  onClick={this.hideChildEdit}
                >
                  Batal
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </LoadingOverlay>
      </div>
    );
  }
}

export default AddQueue;
