import Axios from 'axios';

const roleUser = localStorage.getItem('role');

let API = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${
    roleUser !== '5' ? `${roleUser === '2' ? 'admin' : 'nurse'}` : ''
  }`,
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

API.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response && err.response.status === 401) {
      localStorage.clear();
      return (window.location.href = '/');
    }
    return Promise.reject(err);
  }
);

export default API;
