import PropTypes from "prop-types";
import React, { Component } from "react";
import "./radioButton.css";

export class RadioButton extends Component {
  state = {};

  render() {
    const { selected, onChange, text, value, styling } = this.props;
    return (
      <div
        className={`modern-radio-button-container ${value === selected && "selected-button"}`}
        onClick={() => {
          console.log(value)
          onChange(value);
        }}
        style={styling}
      >
        <div
          className={`radio-button-outer-circle ${value !== selected && "unselected-button"}`}
        >
          <div
            className={`radio-button-inner-circle ${value !== selected &&
              "unselected-button-circle"}`}
          />
        </div>
        <div className={`helper-button-text ${value === selected && "selected-text"}`}>{text}</div>
      </div>
    );
  }
}

RadioButton.propTypes = {
  text: PropTypes.node.isRequired,
  // onChange: PropTypes.func.isRequired,
  // selected: PropTypes.string.isRequired,
  // value: PropTypes.string.isRequired
};
