import React, { Component } from 'react';
import swal from 'sweetalert';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';
import { API_URL } from '../../../BaseUrlApi/Url';
import Modal from 'react-bootstrap/Modal';
import Navbar from '../../Navbar/Navbar';
import moment from 'moment';
import './FrontPage.css';
import DynamicVaccineNurse from '../../../CustomJS/DynamicVaccineNurse';
import Form from 'react-bootstrap/Form';
import { Space } from 'antd';
import API from '../../../../config/axios';
import _ from 'lodash';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

class FrontPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentStatuses: [
        {
          value: 1,
          label: 'Belum Bayar',
        },
        {
          value: 2,
          label: 'Menunggu Konfirmasi',
        },
        {
          value: 3,
          label: 'Sudah Bayar',
        },
      ],
      nextVaccinationPeriod: null,
      vaccination_type_ids: [],
      phone_number: '',
      payments: undefined,
      isLoaded: true,
      isOpenVaccination: false,
      isOpenOnlineConsultation: false,
      isOpenVisit: false,
      childId: null,
      vaccinationId: null,
      queueId: null,
      nurseId: null,
      period: null,
      date_plan: null,
      current_date: null,
      visit_types: [],
      visit_type: {},
      vaccine_types: [],
      vaccine_type: {},
      dynamic_vaccine: [],
      lastChoice: 0,
      shifts: [],
      shiftsOnline: [],
      selectedShiftOnline: undefined,
      shift: {},
      upcomingVaccination: [],
      startDate: new Date(),
      btnLoad: false,
      excludeDate: [],
    };
  }

  queuePaymentHandler = async (value) => {
    this.setState({
      payments: {
        value: value.value,
        label: value.label,
      },
    });
  };
  async componentDidMount() {
    let queueId = parseInt(this.props.match.params.queue);
    let childId = parseInt(this.props.match.params.value);
    let nurseId = localStorage.getItem('nurse_id');

    try {
      let getUpcomingVaccination = await axios.get(
        `${API_URL}/nurse/vaccinations/upcomingVaccinations?child_id=${childId}`,
        { headers }
      );
      this.setState({
        upcomingVaccination: getUpcomingVaccination.data.vaccinations,
      });

      let getVaccineTypes = await axios.get(`${API_URL}/nurse/vaccine_types`, {
        headers,
      });

      let vaccine_types = getVaccineTypes.data.data.map((type) => ({
        value: type.id,
        label: type.name,
      }));

      let getVisitType = await axios.get(`${API_URL}/nurse/visit_types`, {
        headers,
      });
      let visit_types = getVisitType.data.visitTypes.map((type) => ({
        value: type.id,
        label: type.name,
      }));

      let getShifts = await axios.get(`${API_URL}/nurse/shifts`, { headers });
      let shifts = getShifts.data.shifts.map((shift) => ({
        value: shift.id,
        label: shift.name,
      }));

      let getShiftsOnline = await axios.get(
        `${API_URL}/nurse/shifts?type=konsultasi_online`,
        { headers }
      );
      let shiftsOnline = getShiftsOnline.data.shifts.map((shift) => ({
        value: shift.id,
        label: shift.name,
      }));

      const { data: dataDates } = await axios.get(`${API_URL}/blocked_dates`, {
        headers,
      });

      const { data: dataDatesKonsulOnline } = await axios.get(
        `${API_URL}/blocked_dates?shift_type=konsultasi_online`,
        {
          headers,
        }
      );

      let filterBlockedDatesKonsultasiOnline =
        await dataDatesKonsulOnline.blockedDates.map((item) =>
          Date.parse(new Date(item.date).toUTCString())
        );

      //parse date to format string long to ISO
      let filterBlockedDates = await dataDates.blockedDates.map((item) =>
        Date.parse(new Date(item.date).toUTCString())
      );

      const newVaccine = [
        {
          id: this.state.lastChoice + 1,
          vaccine_selected: null,
          period: '',
        },
      ];

      this.setState({
        visit_types,
        vaccine_types,
        shifts,
        childId,
        queueId,
        nurseId,
        excludeDate: [
          ...filterBlockedDates,
          ...filterBlockedDatesKonsultasiOnline,
        ],
        dynamic_vaccine: newVaccine,
        lastChoice: this.state.lastChoice + 1,
        shiftsOnline,
      });
    } catch (e) {
      swal({
        title: 'GAGAL',
        text: 'Gagal Mengambil Data Tab Jadwal',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }

    // this.getCurrentDate();
    // SETTING INITIAL VALUE OF SELECT INPUT
    this.initialSelectValue();
    this.setState({ isLoaded: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState) === JSON.stringify(this.state)) return null;
    const { childId, vaccination_type_ids } = this.state;

    if (childId && !!vaccination_type_ids.length) {
      API.get('/vaccinations/next-vaccination-period', {
        params: {
          child_id: childId,
          vaccine_type_ids: vaccination_type_ids.join(',').toString(),
        },
      })
        .then((res) => {
          const tempGeneratePeriodVaccine = _.map(
            this.state?.dynamic_vaccine,
            (user) => {
              const vaccineId = user.vaccine_selected.value;
              const matchedVaccine = _.find(res?.data?.vaccine_types, {
                id: vaccineId,
              });
              if (matchedVaccine) {
                return _.assign({}, user, {
                  period: matchedVaccine.next_vaccination_period,
                });
              }
              return user;
            }
          );

          this.setState({
            dynamic_vaccine: [...tempGeneratePeriodVaccine],
          });
        })
        .catch((err) => console.log(err));
    }
  }

  // getCurrentDate = () => {
  //   let date = new Date();
  //   let dd = date.getDate();
  //   let mm = date.getMonth() + 1;

  //   let yyyy = date.getFullYear();
  //   if (dd < 10) {
  //     dd = "0" + dd;
  //   }
  //   if (mm < 10) {
  //     mm = "0" + mm;
  //   }
  //   let set_date = yyyy + "-" + mm + "-" + dd;
  //   this.setState({ current_date: set_date });
  // };

  initialSelectValue = () => {
    if (this.state.visit_type.value === undefined) {
      this.setState({
        visit_type: {
          value: null,
          label: <p className="select-placeholder">Pilih Tipe Kunjungan</p>,
        },
      });
    }

    if (this.state.vaccine_type.value === undefined) {
      this.setState({
        vaccine_type: {
          value: null,
          label: <p className="select-placeholder">Pilih Tipe Vaksin</p>,
        },
      });
    }
    if (this.state.shift.value === undefined) {
      this.setState({
        shift: {
          value: null,
          label: <p className="select-placeholder">Pilih Shift</p>,
        },
      });
    }
  };

  visitHandler = async (value) => {
    this.setState({
      visit_type: {
        value: value.value,
        label: value.label,
      },
    });
  };

  vaccineChangeHandler = (vaccine_id, newVaccineSelected, newPeriod) => {
    this.setState({
      vaccination_type_ids: [newVaccineSelected?.value],
    });
    const newVaccine = {
      id: vaccine_id,
      vaccine_selected: {
        value: newVaccineSelected.value,
        label: newVaccineSelected.label,
      },
      period: newPeriod,
    };

    const oldVaccine = this.state.dynamic_vaccine.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    let newVaccines = [...oldVaccine, newVaccine];
    newVaccines.sort((a, b) => a.id - b.id);
    this.setState({
      dynamic_vaccine: newVaccines,
    });
  };

  vaccineRemoveHandler = (vaccine_id) => {
    const vaccinesValue = [...this.state.dynamic_vaccine];
    const newVaccines = vaccinesValue.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    this.setState({
      dynamic_vaccine: newVaccines,
    });
  };

  vaccineAddHandler = () => {
    let objValue = this.state.dynamic_vaccine.find(
      (c) => c.period === '' || c.vaccine_selected === null
    );
    if (objValue !== undefined) {
      return;
    }

    const newVaccine = {
      id: this.state.lastChoice + 1,
      vaccine_selected: null,
      period: '',
    };
    this.setState({
      lastChoice: this.state.lastChoice + 1,
      dynamic_vaccine: [...this.state.dynamic_vaccine, newVaccine],
    });
  };

  shiftHandler = async (value) => {
    this.setState({
      shift: {
        value: value.value,
        label: value.label,
      },
      selectedShiftOnline: {
        value: value.value,
        label: value.label,
      },
    });
  };
  periodHandler = async (e) => {
    await this.setState({
      period: parseInt(e.target.value),
    });
  };

  // selectedPlanDate = (value) => {
  //   if (this.state.date_plan === null) {
  //     return "";
  //   } else {
  //     return new Date(this.state.date_plan);
  //   }
  // };

  // datePlanHandler = async (date_plan) => {
  //   await this.setState({
  //     date_plan: date_plan,
  //   });
  //   if (this.state.date_plan !== null) {
  //     this.datePlanConverter(this.state.date_plan);
  //   }
  // };

  datePlanConverter = (value) => {
    let date = value,
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join('-');
    this.setState({
      date_plan: convertDate,
    });
  };

  dateChanger(value) {
    let date = new Date(value);
    let dd = date.getDate();
    let mm = date.getMonth() + 1;

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_date = dd + '-' + mm + '-' + yyyy;
    return <span>{set_date}</span>;
  }

  showFormVaccination = (e) => {
    this.setState({ isOpenVaccination: !this.state.isOpenVaccination });
  };
  showFromOnlineConsultation = (e) => {
    this.setState({
      isOpenOnlineConsultation: !this.state.isOpenOnlineConsultation,
    });
  };

  hideFormVaccination = (e) => {
    const newVaccine = [
      {
        id: 1,
        vaccine_selected: null,
        period: '',
      },
    ];
    this.setState({
      isOpenVaccination: false,
      date_plan: null,
      shift: {
        value: null,
        label: <p className="select-placeholder">Pilih Shift</p>,
      },
      period: null,
      vaccine_type: {
        value: null,
        label: <p className="select-placeholder">Pilih Tipe Vaksin</p>,
      },
      btnLoad: false,
      dynamic_vaccine: newVaccine,
      lastChoice: 1,
    });
  };

  hideFormOnlineConsultation = (e) => {
    this.setState({
      isOpenOnlineConsultation: false,
    });
  };

  saveVaccination = async (e) => {
    e.preventDefault();
    let {
      childId,
      queueId,
      nurseId,
      vaccine_type,
      date_plan,
      period,
      shift,
      current_date,
    } = this.state;

    this.setState({ btnLoad: true });

    if (this.state.isOpenOnlineConsultation) {
      const payload = {
        date: moment(date_plan).format('YYYY-MM-DD'),
        shift_id: this.state.selectedShiftOnline?.value,
        child_id: this.state.childId,
        registration_origin_id: 4,
        queue_status_id: 1,
        phone_number: this.state.phone_number,
      };

      return await axios
        .post(`${API_URL}/nurse/queues`, payload, { headers })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Jadwal Konsultasi Online Berhasil Ditambahkan!',
            icon: 'success',
            button: 'OK',
          })
            .then((move) => {
              if (move) {
                window.history.back();
              } else {
                return;
              }
            })
            .catch((err) => {
              this.errorHandler(err);
            });
        })
        .catch((err) => {
          this.setState({ btnLoad: false });
          this.errorHandler(err);
        });
    }

    if (shift.value === null) {
      let data = {
        child_id: childId,
        // vaccine_type_id: vaccine_type.value,
        date_plan: moment(date_plan).format('YYYY-MM-DD'),
        // period: period,
        vaccine_types: this.state.dynamic_vaccine.map((item) => {
          return {
            id: item.vaccine_selected.value,
            period: item?.period,
          };
        }),
        nurse_id: parseInt(nurseId),
      };

      await axios
        .post(`${API_URL}/nurse/vaccinations`, data, { headers })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Jadwal Vaksinasi Berhasil Ditambahkan!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.history.back();
              // window.location.href = `/patient-detail?tab=onprocess&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          this.setState({ btnLoad: false });
          this.errorHandler(err);
        });
    } else {
      let data = {
        child_id: childId,
        // vaccine_type_id: vaccine_type.value,
        vaccine_types: this.state.dynamic_vaccine.map((item) => {
          return {
            id: item.vaccine_selected.value,
            period: item?.period,
          };
        }),
        shift_id: shift.value,
        date_plan: moment(date_plan).format('YYYY-MM-DD'),
        // period: period,
        nurse_id: parseInt(nurseId),
      };

      await axios
        .post(`${API_URL}/nurse/vaccinations`, data, { headers })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Jadwal Vaksinasi Berhasil Ditambahkan!',
            icon: 'success',
            button: 'OK',
          })
            .then((move) => {
              if (move) {
                window.history.back();
                // window.location.href = `/patient-detail?tab=onprocess&queueId=${queueId}`;
              } else {
                return;
              }
            })
            .catch((err) => {
              this.errorHandler(err);
            });
        })
        .catch((err) => {
          this.setState({ btnLoad: false });
          this.errorHandler(err);
        });
    }
  };

  showFormVisit = (e) => {
    this.setState({ isOpenVisit: !this.state.isOpenVisit });
  };

  hideFormVisit = (e) => {
    this.setState({
      isOpenVisit: false,
      date_plan: null,
      shift: {
        value: null,
        label: <p className="select-placeholder">Pilih Shift</p>,
      },
      period: null,
      visit_type: {
        value: null,
        label: <p className="select-placeholder">Pilih Tipe Kunjungan</p>,
      },
      btnLoad: false,
    });
  };

  saveVisit = async (e) => {
    e.preventDefault();
    let { childId, queueId, nurseId, visit_type, date_plan, shift } =
      this.state;

    this.setState({ btnLoad: true });
    if (shift.value === null) {
      let data = {
        child_id: childId,
        visit_type_id: visit_type.value,
        date_plan: moment(date_plan).format('YYYY-MM-DD'),
        nurse_id: parseInt(nurseId),
      };
      await axios
        .post(`${API_URL}/nurse/visits`, data, { headers })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Jadwal Kunjungan Berhasil Ditambahkan!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.history.back();
              // window.location.href = `/patient-detail?tab=onprocess&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          this.setState({ btnLoad: false });
          this.errorHandler(err);
        });
    } else {
      let data = {
        child_id: childId,
        visit_type_id: visit_type.value,
        shift_id: shift.value,
        date_plan: moment(date_plan).format('YYYY-MM-DD'),
        nurse_id: parseInt(nurseId),
      };
      await axios
        .post(`${API_URL}/nurse/visits`, data, { headers })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Jadwal Kunjungan Berhasil Ditambahkan!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.history.back();
              // window.location.href = `/patient-detail?tab=onprocess&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          this.setState({ btnLoad: false });
          this.errorHandler(err);
        });
    }
  };

  errorHandler = (err) => {
    if (err.response.data.message === 'The given data was invalid.') {
      swal({
        title: 'GAGAL',
        text: ' Harap Lengkapi Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, registration deadline has been reached!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Telah Mencapai Batas Waktu Pendaftaran',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, registration limit has been reached!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Antrian Sudah Penuh',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, your child already has a ticket!, please cancel it first!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Anak Telah Memiliki Tiket',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else {
      swal({
        title: 'GAGAL',
        text: 'Gagal Menyimpan Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  };
  render() {
    let {
      isOpenVaccination,
      isOpenOnlineConsultation,
      isOpenVisit,
      isLoaded,
      queueId,
      visit_types,
      visit_type,
      vaccine_type,
      vaccine_types,
      dynamic_vaccine,
      lastChoice,
      shifts,
      shift,
      shiftsOnline,
      selectedShiftOnline,
      phone_number,
      period,
      btnLoad,
      excludeDate,
      date_plan,
      paymentStatuses,
      payments,
    } = this.state;

    const isWeekday = (date) => {
      // console.log(date)
      const day = date.getDay();
      return day !== 0;
    };
    return (
      <div>
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <Navbar
            navtitle="PILIH JADWAL"
            url={`/patient-detail?tab=onprocess&queueId=${queueId}`}
          />
          <div className="vaccination-body">
            <div className="vaccination-btn-schedule">
              <button
                className="btn btn-setschedule"
                onClick={this.showFormVisit}
              >
                ATUR JADWAL KUNJUNGAN
              </button>
              <button
                className="btn btn-setschedule"
                onClick={this.showFormVaccination}
              >
                ATUR JADWAL VAKSINASI
              </button>
              <button
                className="btn btn-setschedule"
                onClick={this.showFromOnlineConsultation}
              >
                ATUR JADWAL KONSULTASI ONLINE
              </button>
            </div>
            {/* ADD VACCINATION MODAL */}
            <Modal
              show={isOpenVaccination}
              onHide={this.hideFormVaccination}
              className="nurse-modal vaccination-modal"
            >
              <Modal.Header>
                <Modal.Title>
                  <h1 className="note-child-title">Tambah Jadwal Vaksinasi</h1>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <DatePicker
                    selected={date_plan}
                    className="date-picker-custom"
                    onChange={(e) => {
                      console.log(e);
                      this.setState({ date_plan: e });
                    }}
                    excludeDates={excludeDate}
                    filterDate={isWeekday}
                    // minDate={new Date()}
                    dateFormat="dd MMMM, yyyy"
                    placeholderText="Pilih Tanggal Rencana"
                  />
                </div>
                <div className="merk-vaccine">
                  <Select
                    options={shifts}
                    value={shift}
                    onChange={(value) => this.shiftHandler(value)}
                    Placeholder="massukkan data"
                  />
                </div>
                <div className="merk-vaccine">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>Vaksin dan Periode</span>
                    <button
                      style={{
                        display: vaccine_types.length > 1 ? '' : 'none',
                      }}
                      className="btn btn-success "
                      onClick={this.vaccineAddHandler}
                    >
                      {/* {loading_button ? "..Loading.." : "SIMPAN"} */}
                      Tambah Data
                    </button>
                  </div>
                  {/* <Select
                options={this.state.vaccine_types}
                value={this.state.vaccine_type}
                onChange={(value) => this.vaccineType(value)}
                Placeholder="massukkan data"
              /> */}
                  {dynamic_vaccine.map((vaccine, i) => (
                    <DynamicVaccineNurse
                      vaccineTypes={vaccine_types}
                      dynamic_vaccine={dynamic_vaccine}
                      key={i}
                      vaccine_id={vaccine.id}
                      vaccine_selected={vaccine.vaccine_selected}
                      period={vaccine?.period}
                      vaccineChangeHandler={this.vaccineChangeHandler}
                      vaccineRemoveHandler={this.vaccineRemoveHandler}
                    />
                  ))}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="patient-buttons">
                  <button
                    className="btn btn-danger mr-1"
                    onClick={this.hideFormVaccination}
                  >
                    Batal
                  </button>
                  <button
                    className="btn btn-primary mr-1"
                    onClick={this.saveVaccination}
                  >
                    {btnLoad ? '..Loading..' : 'SIMPAN'}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>

            {/* ADD VISIT MODAL */}
            <Modal
              show={isOpenVisit}
              onHide={this.hideForm}
              className="nurse-modal vaccination-modal"
            >
              <Modal.Header>
                <Modal.Title>
                  <h1 className="note-child-title">Tambah Jadwal Kunjungan</h1>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <DatePicker
                    selected={date_plan}
                    className="date-picker-custom"
                    onChange={(e) => {
                      console.log(e);
                      this.setState({ date_plan: e });
                    }}
                    excludeDates={excludeDate}
                    filterDate={isWeekday}
                    minDate={new Date()}
                    dateFormat="dd MMMM, yyyy"
                    placeholderText="Pilih Tanggal Rencana"
                  />
                </div>
                <div className="merk-vaccine">
                  <Select
                    options={visit_types}
                    value={visit_type}
                    onChange={(value) => this.visitHandler(value)}
                    Placeholder="massukkan data"
                  />
                </div>
                <div className="merk-vaccine">
                  <Select
                    options={shifts}
                    value={shift}
                    onChange={(value) => this.shiftHandler(value)}
                    Placeholder="massukkan data"
                  />
                </div>
                <br />
                <br />
              </Modal.Body>
              <Modal.Footer>
                <div className="patient-buttons">
                  <button
                    className="btn btn-danger mr-1"
                    onClick={this.hideFormVisit}
                  >
                    Batal
                  </button>
                  <button
                    className="btn btn-primary mr-1"
                    onClick={this.saveVisit}
                  >
                    {btnLoad ? '..Loading..' : 'SIMPAN'}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>

            {/* ADD Online Consultation MODAL */}
            <Modal
              show={isOpenOnlineConsultation}
              onHide={this.hideFormOnlineConsultation}
              className="nurse-modal vaccination-modal"
            >
              <Modal.Header>
                <Modal.Title>
                  <h1 className="note-child-title">
                    Tambah Jadwal Konsultasi Online
                  </h1>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <DatePicker
                    selected={date_plan}
                    className="date-picker-custom"
                    onChange={(e) => {
                      console.log(e);
                      this.setState({ date_plan: e });
                    }}
                    excludeDates={excludeDate}
                    filterDate={isWeekday}
                    minDate={new Date()}
                    dateFormat="dd MMMM, yyyy"
                    placeholderText="Pilih Tanggal Rencana"
                  />
                </div>
                <div className="merk-vaccine">
                  <Select
                    options={shiftsOnline}
                    value={selectedShiftOnline}
                    onChange={(value) => this.shiftHandler(value)}
                    placeholder="Pilih shift..."
                  />

                  <input
                    style={{
                      border: '1.3px solid rgb(36, 177, 253)',
                      padding: '.5rem',
                      borderRadius: '4px',
                      margin: '0px',
                      height: '3rem',
                      width: '100%',
                      marginBottom: 16,
                    }}
                    type="number"
                    name="period"
                    placeholder="Nomor WA yang akan dihubungi"
                    value={this.state.phone_number}
                    onChange={(e) => {
                      this.setState({
                        phone_number: e.target.value,
                      });
                    }}
                    className="add-vaccine-input"
                    required
                  />

                  <Select
                    options={paymentStatuses}
                    value={payments}
                    onChange={(value) => this.queuePaymentHandler(value)}
                    required
                    placeholder="Pilih status pembayaran..."
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="patient-buttons">
                  <button
                    className="btn btn-danger mr-1"
                    onClick={this.hideFormVaccination}
                  >
                    Batal
                  </button>
                  <button
                    className="btn btn-primary mr-1"
                    onClick={this.saveVaccination}
                  >
                    {btnLoad ? '..Loading..' : 'Simpan'}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default FrontPage;
