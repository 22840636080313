import React, { Component } from 'react';
import AdminSidebar from '../AdminSidebar/AdminSidebar';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { BounceLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import LoadingOverlay from 'react-loading-overlay';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import buttonAlert from '../../../assets/icons/alarm-2.svg';
import EmergencyImage from '../../../assets/icons/alarm.svg';
import { API_URL } from '../../BaseUrlApi/Url';
import './Queues.css';

const { SearchBar } = Search;

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

function genderFormatter(cell, row, rowIndex, formatExtraData) {
  if (row.child !== null) {
    if (row.child.gender === 'female') {
      return <p>Perempuan</p>;
    } else {
      return <p>Laki-laki</p>;
    }
  } else {
    return '';
  }
}

class QueueManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadFailed: false,
      isDataCompleted: false,
      checkbox: false,
      isLoaded: true,
      queue_patient_name: null,
      next_queue_patient_name: null,
      buttonEmergency: true,
      loading: true,
      check: '',
      process: false,
      queue_process_id: null,
      childId: null,
      shiftId: null,
      orders: null,
      order: 0,
      old_order: null,
      limit: null,
      shift_name: null,
      next_order: 0,
      emergencyQueue: [],
      total_checkin: [],
      emergency: false,
      onProcess: [],
      doctor_arrival_status: 0,
      button: (
        <div>
          <button
            className="btn btn-danger btn-come1"
            onClick={this.passPatient}
          >
            Tidak Datang
          </button>
          <button
            className="btn btn-primary btn-come2"
            onClick={this.checkPatient}
          >
            Periksa
          </button>
        </div>
      ),
      date: new Date(),
      products: [],
      columns: [
        {
          dataField: 'queue_number',
          text: 'No. Antri',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: this.orderFormatter,
        },
        {
          dataField: 'child.fullname',
          text: 'Nama',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
        },
        {
          dataField: 'child.age',
          text: 'Umur',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
        },
        {
          dataField: 'queue_status.name',
          text: 'Status',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: this.statusFormatter,
        },
        {
          dataField: 'child.gender',
          text: 'Jenis Kelamin',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: genderFormatter,
        },
        {
          dataField: 'Aksi',
          text: 'Aksi',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: (cell, row, rowIndex, formatExtraData) => {
            return (
              <div className="history-buttons">
                <Link to={`/active-queue/${row.id}/${row.shift.id}`}>
                  <button className="btn btn-primary">Detail</button>
                </Link>
                <button
                  className="btn btn-emergency"
                  onClick={async () => {
                    let updateStatus = {
                      _method: 'PATCH',
                      queue_status_id: 8,
                    };

                    await axios
                      .post(`${API_URL}/admin/queues/${row.id}`, updateStatus, {
                        headers,
                      })
                      .then(async (res) => {
                        this.setState({
                          emergency: true,
                          emergencyButton: false,
                        });
                      })
                      .catch((err) => {});
                  }}
                >
                  <img
                    src={buttonAlert}
                    alt="EMERGENCY"
                    className="emergency-icon"
                  />
                  <span className="emergency-text">Darurat</span>
                </button>
              </div>
            );
          },
        },
      ],
    };
  }

  onCheckPatientName = (data, queueNumber) => {
    const filterData =
      data?.queue?.filter((item) => item?.queue_number === queueNumber)?.[0] ||
      null;
    return filterData?.child?.fullname || null;
  };

  async componentDidMount() {
    let delay = 10000;
    localStorage.setItem('failed', 0);
    this.setState({ shiftId: parseInt(this.props.match.params.value) });
    this.todayInfo();

    // INTERVAL SECTION
    this.updateInterval = setInterval(async () => {
      try {
        // GET ALL ACTIVE QUEUES
        let getActiveQueues = await axios.get(
          `${API_URL}/admin/queues/getAllActiveQueue`,
          { headers }
        );
        this.setState({ isDataCompleted: true });

        if (getActiveQueues.data.queue.length > 0) {
          // GET CHECKIN QUEUES OF ACTIVE QUEUES
          let getCheckinQueues = getActiveQueues.data.queue.filter(
            (order) => order.queue_status.id === 3
          );

          this.setState({
            products: getActiveQueues.data.queue,
            current_queue: getActiveQueues.data.current_queue,
            next_queue: getActiveQueues.data.next_queue,
            queue_patient_name: this.onCheckPatientName(
              getActiveQueues.data,
              getActiveQueues.data.current_queue
            ),
            next_queue_patient_name: this.onCheckPatientName(
              getActiveQueues.data,
              getActiveQueues.data.next_queue
            ),
            orders: getActiveQueues.data.queue.length,
            total_checkin: getCheckinQueues,
            button: (
              <div>
                <button
                  className="btn btn-danger btn-come1"
                  onClick={this.passPatient}
                >
                  Tidak Datang
                </button>
                <button
                  className="btn btn-primary btn-come2"
                  onClick={this.checkPatient}
                >
                  Periksa
                </button>
              </div>
            ),
          });

          // ON PROCESS QUEUES
          let getProcessQueue = getActiveQueues.data.queue.filter(
            (order) => order.queue_status.id === 4
          );

          if (
            this.state.total_checkin.length === 0 && //IF THERE'S NO CHECK IN  AND PROCESS QUEUE (ORDER = 0 AND NEXT_ORDER = 0)
            getProcessQueue.length === 0
          ) {
            this.setState({
              order: 0,
              queue_patient_name: null,
              next_order: 0,
              next_queue_patient_name: null,
            });
          }
          // else if (
          //   this.state.total_checkin.length === 1 && //IF THERE'S ONE CHECK IN QUEUE AND NO PROCESS QUEUE THEN ORDER = CHECKIN[0] AND NEXT_ORDER = 0
          //   getProcessQueue.length === 0
          // ) {
          //   this.setState({
          //     order: this.state.total_checkin[0].order,
          //     queue_patient_name: this.state.total_checkin[0].child.fullname,
          //     next_order: 0,
          //     next_queue_patient_name: null,
          //   });
          // } else if (
          //   this.state.total_checkin.length > 1 && //IF THERE ARE MORE THAN ONE CHECK IN QUEUE BUT PROCESS QUEUE DOESN'T EXIST THEN....
          //   getProcessQueue.length === 0
          // ) {
          //   this.setState({
          //     order: this.state.total_checkin[0].order,
          //     queue_patient_name: this.state.total_checkin[0].child.fullname,
          //     next_order: this.state.total_checkin[1].order,
          //     next_queue_patient_name:
          //       this.state.total_checkin[1].child.fullname,
          //   });
          // } else if (
          //   this.state.total_checkin.length === 0 && // IF THERE'S NO CHECK IN BUT PROCESS EXIST THEN...
          //   getProcessQueue.length > 0
          // ) {
          //   this.setState({
          //     order: getProcessQueue[0].order,
          //     queue_patient_name: getProcessQueue[0].child.fullname,
          //     next_order: 0,
          //     next_queue_patient_name: null,
          //   });
          // } else if (
          //   this.state.total_checkin.length > 0 && // IF THERE'S CHECK IN AND PROCESS QUEUE THEN ...
          //   getProcessQueue.length > 0
          // ) {
          //   this.setState({
          //     order: getProcessQueue[0].order,
          //     queue_patient_name: getProcessQueue[0].child.fullname,
          //     next_order: this.state.total_checkin[0].order,
          //     next_queue_patient_name:
          //       this.state.total_checkin[0].child.fullname,
          //   });
          // }

          // IF PROCESS QUEUE EXIST, THEN CHANGE THE BUTTONS
          if (getProcessQueue.length > 0) {
            this.setState({
              button: (
                <button className="btn btn-secondary btn-come2">
                  Sedang diperiksa
                </button>
              ),
            });
          }

          // CHECK EMERGENCY QUEUE IF EXIST
          let getEmergency = getActiveQueues.data.queue.filter(
            (order) => order.queue_status.id === 8
          );

          // IF EMERGENCY QUEUE EXIST
          if (getEmergency.length === 1) {
            this.setState({
              // order: getEmergency[0].order,
              // queue_patient_name: getEmergency[0].child.fullname,
              emergency: true,
              emergencyQueue: getEmergency,
              button: (
                <button className="btn btn-danger btn-come2">DARURAT</button>
              ),
            });

            // if (this.state.total_checkin.length > 0) {
            //   this.setState({
            //     // next_order: this.state.total_checkin[0].order,
            //     // next_queue_patient_name:
            //     //   this.state.total_checkin[0].child.fullname,
            //   });
            // }
          } else if (getEmergency.length > 1) {
            let next_order = getEmergency.length - 1;
            this.setState({
              // order: getEmergency[next_order].order,
              // queue_patient_name: getEmergency[next_order].child.fullname,
              button: (
                <button className="btn btn-danger btn-come2">DARURAT</button>
              ),
            });

            // if (this.state.total_checkin.length > 0) {
            //   this.setState({
            //     next_order: this.state.total_checkin[0].order,
            //     next_queue_patient_name:
            //       this.state.total_checkin[0].child.fullname,
            //   });
            // }
          } else if (getEmergency.length === 0) {
            this.setState({
              emergency: false,
            });
          }
        }
      } catch (e) {
        let failedCounter = parseInt(localStorage.getItem('failed')) + 1;
        localStorage.setItem('failed', failedCounter);
        delay *= 1.5;
        if (failedCounter >= 3) {
          swal({
            title: 'Bad Network',
            text: 'Mohon Periksa Koneksi Internetnya',
            icon: 'warning',
            type: 'error',
            buttons: 'Refresh',
          }).then((move) => {
            window.location.href = `/queues/${this.state.shiftId}`;
          });
        }
      }
    }, delay);
    this.setState({ isLoaded: false });
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  todayInfo = async () => {
    // GET TODAYS QUEUE INFO
    await axios
      .get(`${API_URL}/admin/queues/getTodaysQueueInfo`, { headers })
      .then(async (res) => {
        if (res.data.message === 'Tidak ada shift yang sedang berjalan') {
          window.location.href = `/queues-front/show-admin`;
        } else {
          this.setState({
            time: Date.now(),
            shift_name: res.data.shift.name,
            limit: res.data.shift.registration_limit,
            doctor_arrival_status: res.data.shift.doctor_arrival_status,
          });

          if (this.state.doctor_arrival_status === 0) {
            this.setState({ checkbox: false });
          } else {
            this.setState({ checkbox: true });
          }
        }
      })
      .catch((err) => {
        swal({
          title: 'Bad Network',
          text: 'Mohon Periksa Koneksi Internetnya',
          icon: 'warning',
          type: 'error',
          buttons: 'Refresh',
        }).then((move) => {
          window.location.href = `/queues/${this.state.shiftId}`;
        });
      });
  };

  statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.queue_status.id === 1) {
      return (
        <button className="registered-style">
          <p>Registered </p>
        </button>
      );
    } else if (row.queue_status.id === 2) {
      return (
        <button className="confirmed-style">
          <p>Confirmed </p>
        </button>
      );
    } else if (row.queue_status.id === 3) {
      return (
        <button className="confirmed-style">
          <p>Checked-in </p>
        </button>
      );
    } else if (row.queue_status.id === 4) {
      return (
        <button className="process-style">
          <p>On-process </p>
        </button>
      );
    } else if (row.queue_status.id === 5) {
      return (
        <button className="done-style">
          <p>Done </p>
        </button>
      );
    } else if (row.queue_status.id === 6) {
      return (
        <button className="passed-style">
          <p>Passed </p>
        </button>
      );
    } else if (row.queue_status.id === 7) {
      return (
        <button className="confirmed-style">
          <p>Canceled </p>
        </button>
      );
    } else if (row.queue_status.id === 8) {
      return (
        <button className="emergency-style">
          <p>Emergency </p>
        </button>
      );
    }
    return (
      <button className="registered-style">
        <p>{row?.queue_status?.name} </p>
      </button>
    );
  };

  orderFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {row.queue_status.id === 8 ? (
          <div>
            <img src={EmergencyImage} alt="" className="order-emergency" />
            <span>{row?.queue_number || '-'}</span>
          </div>
        ) : (
          <p>{row?.queue_number || '-'}</p>
        )}
      </div>
    );
  };

  tick() {
    this.setState({
      date: new Date(),
    });
  }

  doctorStatusHandler = async (e) => {
    await this.setState({
      checkbox: !this.state.checkbox,
      isLoaded: true,
    });
    if (this.state.checkbox) {
      let data = {
        _method: 'PATCH',
        doctor_arrival_status: 1,
      };
      await axios
        .post(`${API_URL}/admin/shifts/${this.state.shiftId}`, data, {
          headers,
        })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Dokter Telah Di Klinik!',
            icon: 'success',
            button: 'OK',
          });
          this.setState({ isLoaded: false });
        })
        .catch((err) => {
          this.setState({ isLoaded: false });
          swal({
            title: 'GAGAL',
            text: 'Gagal Mengubah Status Kedatangan Dokter',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });
    } else {
      let data = {
        _method: 'PATCH',
        doctor_arrival_status: 0,
      };
      await axios
        .post(`${API_URL}/admin/shifts/${this.state.shiftId}`, data, {
          headers,
        })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Dokter Tidak Di Klinik',
            icon: 'success',
            button: 'OK',
          });
          this.setState({ isLoaded: false });
        })
        .catch((err) => {
          this.setState({ isLoaded: false });
          swal({
            title: 'GAGAL',
            text: 'Gagal Mengubah Status Kedatangan Dokter',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });
    }
  };

  checkPatient = async (e) => {
    e.preventDefault();
    let queue = this.state.total_checkin;
    this.setState({ isLoaded: true });
    if (queue.length > 0) {
      let queueId = parseInt(queue[0].id);
      let updateStatus = {
        _method: 'PATCH',
        queue_status_id: 4,
      };
      await axios
        .post(`${API_URL}/admin/queues/${queueId}`, updateStatus, { headers })
        .then((res) => {
          this.setState({ isLoaded: false });
          this.setState({
            button: (
              <button
                className="btn btn-secondary btn-come2"
                onClick={this.checkPatient}
              >
                Sedang diperiksa
              </button>
            ),
          });
        })
        .catch((err) => {});
    } else {
      this.setState({ isLoaded: false });
      swal({
        title: 'GAGAL',
        text: 'Tidak Ada Antrian Selanjutnya',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  };

  passPatient = async (e) => {
    e.preventDefault();
    let queue = this.state.total_checkin;
    this.setState({ isLoaded: true });
    if (queue.length > 0) {
      let queueId = parseInt(queue[0].id);
      let updateStatus = {
        _method: 'PATCH',
        queue_status_id: 6,
      };
      await axios
        .post(`${API_URL}/admin/queues/${queueId}`, updateStatus, { headers })
        .then((res) => {
          this.setState({ isLoaded: false });
          return;
        })
        .catch((err) => {});
    } else {
      this.setState({ isLoaded: false });
      swal({
        title: 'GAGAL',
        text: 'Tidak Ada Antrian Selanjutnya',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  };

  endShift = async (e) => {
    e.preventDefault();
    this.setState({
      isLoaded: true,
    });
    swal({
      title: 'Ingin Mengakhiri Shift?',
      icon: 'warning',
      buttons: {
        cancel: 'Batal!',
        catch: {
          text: 'Lanjut',
          value: 'continue',
        },
      },
      dangerMode: true,
    }).then(async (move) => {
      switch (move) {
        case 'continue':
          let data = {
            _method: 'POST',
          };
          await axios
            .post(`${API_URL}/admin/queues/endShiftSession`, data, { headers })
            .then(async (res) => {
              localStorage.removeItem('shift_id');
              localStorage.removeItem('shift_token');
              window.location.href = `/queues-front/show-admin`;
            })
            .catch((err) => {
              swal({
                title: 'GAGAL',
                text: 'Gagal Mengakhiri Shift',
                icon: 'warning',
                type: 'error',
                buttons: 'OK',
              });
              this.setState({
                isLoaded: false,
              });
            });
          break;

        default:
          this.setState({
            isLoaded: false,
          });
      }
    });
  };
  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan {from} / {to} dari {size} Hasil
      </span>
    );
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="btn-group" role="group" style={{ marginRight: 10 }}>
        <button key="10" type="button" className="btn btn-primary btn-sm">
          10
        </button>
      </div>
    );
    const override = {
      display: 'block',
      margin: '20% auto',
      borderColor: 'red',
    };
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPage: 10,
      sizePerPageRenderer,
      sizePerPageList: [
        {
          text: '5',
          value: 5,
        },
        {
          text: '10',
          value: 10,
        },
        {
          text: 'All',
          value: this.state.products.length,
        },
      ],
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="mt-4" style={{ paddingBottom: '200px' }}>
        <ToolkitProvider
          keyField="id"
          columns={this.state.columns}
          data={this.state.products}
          search
        >
          {(toolkitprops) => (
            <div>
              <div className="buttons-queue">
                <Link to={`/add-queue/${this.state.shiftId}`}>
                  <button className="btn btn-primary add-queue-button ">
                    Tambah Antrian
                  </button>
                </Link>
                <SearchBar {...toolkitprops.searchProps} />
              </div>
              <BootstrapTable
                bordered={false}
                {...toolkitprops.baseProps}
                {...paginationTableProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );

    let {
      isDataCompleted,
      isLoaded,
      checkbox,
      queue_patient_name,
      next_queue_patient_name,
      shift_name,
      current_queue,
      orders,
      order,
      next_order,
      next_queue,
      limit,
      emergency,
      emergencyQueue,
    } = this.state;

    if (isDataCompleted) {
      return (
        <div className="admin-overlay">
          <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
            <AdminSidebar />
            <div className="content-section">
              <div className="title-section">
                <h1 className="patient-page-title">
                  Manajemen Antrian ({shift_name})
                </h1>
                <h2 className="patient-page-subtitle">
                  <span style={{ color: '#039BEB' }}>Manajemen Antrian</span>
                  <span style={{ color: '#00000029' }}>
                    / Daftar Kepala Keluarga
                  </span>
                </h2>
              </div>
              <div className="add-button">
                <button
                  className="btn btn-danger end-shift-button mr-3 mb-2"
                  onClick={this.endShift}
                >
                  Akhiri Shift {shift_name}
                </button>
              </div>

              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="demo-section text-left">
                    <div className="checkbox rounded-8">
                      <div className="checkbox-container mt-1">
                        <input
                          id="checkbox-rounded-8"
                          type="checkbox"
                          checked={checkbox}
                          onChange={this.doctorStatusHandler}
                        />
                        <div className="checkbox-checkmark"></div>
                      </div>
                      <label for="checkbox-rounded-8">
                        Status Kedatangan Dokter (
                        <span className="text-info">
                          Ceklis Bila Dokter Ada di Klinik
                        </span>
                        )
                      </label>
                    </div>
                  </div>
                  <div className="queue-container clearfix">
                    {emergencyQueue.length > 0 && emergency ? (
                      <img
                        src={EmergencyImage}
                        alt="EMERGENCY"
                        className="emergency-image"
                      />
                    ) : (
                      ''
                    )}
                    {emergencyQueue.length > 0 && emergency ? (
                      <img
                        src={EmergencyImage}
                        alt="EMERGENCY"
                        className="emergency-image2"
                      />
                    ) : (
                      ''
                    )}
                    <p className="queue-information">
                      Antrian shift {shift_name} maksimal {limit} orang
                    </p>
                    <h3 className="queue-title">Nomor Antrian Saat Ini</h3>
                    <div className="number-container">
                      <h4 className="number-title">
                        {emergencyQueue.length > 0 && emergency ? (
                          <span style={{ color: '#CE2828' }}>
                            {emergencyQueue?.[0]?.queue_number}
                          </span>
                        ) : (
                          <span style={{ color: 'black' }}>
                            {current_queue}
                          </span>
                        )}
                      </h4>
                      <p className="number-subtitle">
                        Dari {orders !== null ? orders : 0}
                        <p className="queue_patient_name">
                          {queue_patient_name !== null
                            ? `(${queue_patient_name})`
                            : ''}
                        </p>
                      </p>
                    </div>
                    {this.state.button}
                  </div>
                  <div className="queue-container2">
                    <h3 className="queue2-title">Nomor Antrian Selanjutnya</h3>
                    <p className="queue2-number">{next_queue}</p>
                    <p className="next-queue-patient-name">
                      {next_queue_patient_name !== null
                        ? next_queue_patient_name
                        : ''}
                    </p>
                  </div>
                  <br />
                  <br />
                  <h3 className="queue2-body-title">
                    Daftar Antrian
                    {this.state.products.length > 0 ? (
                      ''
                    ) : (
                      <span className="warning-text"> (Tidak ada Antrian)</span>
                    )}
                  </h3>

                  <PaginationProvider pagination={paginationFactory(options)}>
                    {contentTable}
                  </PaginationProvider>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      );
    } else {
      return (
        <div className="sweet-loading">
          <BounceLoader
            css={override}
            size={50}
            color={'#1289D1'}
            loading={this.state.loading}
          />
        </div>
      );
    }
  }
}

export default QueueManagement;
