import React, { Component } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import AdminSidebar from '../../../AdminSidebar/AdminSidebar';
import { API_URL } from '../../../../BaseUrlApi/Url';
import './AddDate.css';
import { withRouter } from 'react-router-dom';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

class AddDate extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      date: null,
      information: '',
      type: '',
      startDate: new Date(),
    };
  }

  async componentDidMount() {}

  selectedDate = (value) => {
    if (this.state.date === null) {
      return '';
    } else {
      return new Date(this.state.date);
    }
  };

  dateHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateConverter(this.state.startDate);
    }
  };

  dateConverter = async (value) => {
    let date = value,
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join('-');
    this.setState({
      date: convertDate,
    });
  };

  inputHandler = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  saveDate = async (e) => {
    e.preventDefault();

    const postDate = {
      date: this.state.date,
      type: this.state.type,
      information: this.state.information,
    };

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const shift_type = params.get('shift_type');

    const blockDate = `${API_URL}/admin/blocked_dates`;
    const blockDateKonsultasiOnline = `${API_URL}/admin/blocked_dates?shift_type=${shift_type}`;

    const url = shift_type ? blockDateKonsultasiOnline : blockDate;

    axios
      .post(url, postDate, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Data Telah Ditambahkan!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            return this.props.history.goBack();
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Lengkapi semua data terlebih dahulu',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };
  render() {
    return (
      <div className="patient-section">
        <AdminSidebar />
        <div className="content-section">
          <div className="title-section mb-5">
            <h1 className="patient-page-title">Tambah Blocked Date</h1>
            <h2 className="patient-page-subtitle">
              <span style={{ color: '#039BEB' }}>Master Data</span>
              <span style={{ color: '#039BEB' }}>/ Daftar Blocked Date</span>
              <span style={{ color: '#00000029' }}> / Tambah Blocked Date</span>
            </h2>
          </div>
          <div className="card shadow mb-4">
            <div className="card-body">
              <ValidationForm
                onSubmit={this.saveDate.bind(this)}
                ref={this.formRef}
                immediate={this.state.immediate}
                setFocusOnError={this.state.setFocusOnError}
              >
                <table cellPadding="10" className="add-table text-left">
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Tanggal
                        </label>
                      </td>
                      <td>
                        :
                        <DatePicker
                          selected={this.selectedDate()}
                          onChange={this.dateHandler}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="dd-MM-yyyy"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Tipe
                        </label>
                      </td>
                      <td>
                        :
                        <TextInput
                          type="text"
                          name="type"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="" className="add-vaccine-label">
                          Informasi
                        </label>
                      </td>
                      <td>
                        :
                        <TextInput
                          type="text"
                          name="information"
                          onChange={this.inputHandler}
                          className="add-vaccine-input"
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ValidationForm>
              <div className="patient-buttons">
                <button className="btn btn-primary " onClick={this.saveDate}>
                  Simpan
                </button>
                <button
                  className="btn btn-danger mr-1"
                  onClick={() => this.props.history.goBack()}
                >
                  Batal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddDate);
