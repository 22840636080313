import React, { Component } from 'react';
import swal from 'sweetalert';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../BaseUrlApi/Url';
import Navbar from '../Navbar/Navbar';
import './PatientVisit.css';
import axios from 'axios';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};
class PatientVisit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      childId: null,
      visitId: undefined,
      nurseId: null,
      queueId: null,
      visit_type_id: null,
      visit_types: [],
      visit_type: {},
      date_taken: null,
      loading_button: false,
      startDate: new Date(),
      excludeDate: [],
    };
  }
  async componentDidMount() {
    let nurseId = localStorage.getItem('nurse_id');
    const { queueId, childId, visitId } = queryString.parse(
      this.props.location.search
    );
    this.setState({
      childId,
      nurseId,
      queueId,
      visitId,
      isLoaded: true,
    });

    const { data: dataDates } = await axios.get(`${API_URL}/blocked_dates`, {
      headers,
    });

    //parse date to format string long to ISO
    let filterBlockedDates = await dataDates.blockedDates.map((item) =>
      Date.parse(new Date(item.date).toUTCString())
    );

    const { data: dataDatesKonsulOnline } = await axios.get(
      `${API_URL}/blocked_dates?shift_type=konsultasi_online`,
      {
        headers,
      }
    );

    let filterBlockedDatesKonsultasiOnline =
      await dataDatesKonsulOnline.blockedDates.map((item) =>
        Date.parse(new Date(item.date).toUTCString())
      );

    if (visitId !== undefined) {
      await axios
        .get(`${API_URL}/nurse/visits/${visitId}`, { headers })
        .then(async (res) => {
          let getVisitType = await axios.get(
            `${API_URL}/nurse/visits/${visitId}`,
            { headers }
          );
          this.setState({
            visit_type: {
              value: getVisitType.data.visit.visit_type.id,
              label: getVisitType.data.visit.visit_type.name,
            },
          });
        })
        .catch((err) => {
          swal({
            title: 'GAGAL',
            text: 'Gagal Mengambil Data',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });
    }

    try {
      let getVisitTypes = await axios.get(`${API_URL}/nurse/visit_types`, {
        headers,
      });
      let visit_types = getVisitTypes.data.visitTypes.map((type) => ({
        value: type.id,
        label: type.name,
      }));
      this.setState({
        visit_types: visit_types,
      });
    } catch (e) {
      swal({
        title: 'GAGAL',
        text: 'Gagal Mengambil Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }

    if (this.state.visit_type.value === undefined) {
      this.setState({
        visit_type: {
          value: 0,
          label: <p className="select-placeholder">Pilih Tipe Kunjungan</p>,
        },
      });
    }
    this.setState({
      excludeDate: [
        ...filterBlockedDatesKonsultasiOnline,
        ...filterBlockedDates,
      ],
    });
    this.setState({ isLoaded: false });
  }

  visitHandler = async (value) => {
    this.setState({
      visit_type: {
        value: value.value,
        label: value.label,
      },
    });
  };

  saveVisit = async (e) => {
    e.preventDefault();
    let { queueId, childId, visitId, visit_type, date_taken, nurseId } =
      this.state;

    if (visitId === undefined) {
      this.setState({ loading_button: true });
      let data = {
        queue_id: queueId,
        child_id: childId,
        visit_type_id: visit_type.value,
        date_plan: moment(date_taken).format('YYYY-MM-DD'),
        date_taken: moment(date_taken).format('YYYY-MM-DD'),
        nurse_id: parseInt(nurseId),
      };
      await axios
        .post(`${API_URL}/nurse/visits`, data, { headers })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Kunjungan Berhasil Ditambahkan!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.location.href = `/patient-detail?tab=onprocess&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          swal({
            title: 'GAGAL',
            text: 'Gagal Menyimpan Data',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
          this.setState({ loading_button: false });
          this.errorHandler(err);
        });
    } else {
      this.setState({ loading_button: true });
      let data = {
        _method: 'PATCH',
        queue_id: queueId,
        child_id: childId,
        visit_type_id: visit_type.value,
        date_plan: moment(date_taken).format('YYYY-MM-DD'),
        date_taken: moment(date_taken).format('YYYY-MM-DD'),
        nurse_id: parseInt(nurseId),
      };
      await axios
        .post(`${API_URL}/nurse/visits/${visitId}`, data, { headers })
        .then((res) => {
          swal({
            title: 'Sukses!',
            text: 'Kunjungan Berhasil Diubah!',
            icon: 'success',
            button: 'OK',
          }).then((move) => {
            if (move) {
              window.location.href = `/patient-detail?tab=onprocess&queueId=${queueId}`;
            } else {
              return;
            }
          });
        })
        .catch((err) => {
          this.setState({ loading_button: false });
          this.errorHandler(err);
        });
    }
  };

  errorHandler = (err) => {
    if (err.response.data.message === 'The given data was invalid.') {
      swal({
        title: 'GAGAL',
        text: ' Harap Lengkapi Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, registration deadline has been reached!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Telah Mencapai Batas Waktu Pendaftaran',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, registration limit has been reached!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Antrian Sudah Penuh',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else if (
      err.response.data.message ===
      'Sorry, your child already has a ticket!, please cancel it first!'
    ) {
      swal({
        title: 'GAGAL',
        text: 'Anak Telah Memiliki Tiket',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    } else {
      swal({
        title: 'GAGAL',
        text: 'Gagal Menyimpan Data',
        icon: 'warning',
        type: 'error',
        buttons: 'OK',
      });
    }
  };
  render() {
    let { isLoaded, queueId, loading_button, date_taken, excludeDate } =
      this.state;
    const isWeekday = (date) => {
      // console.log(date)
      const day = date.getDay();
      return day !== 0;
    };
    return (
      <div>
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <Navbar
            navtitle="Kunjungan"
            url={`/patient-detail?tab=onprocess&queueId=${queueId}`}
          />
          <div className="vaccination-body">
            <div>
              <DatePicker
                selected={date_taken}
                onChange={(e) => {
                  console.log(e);
                  this.setState({ date_taken: e });
                }}
                excludeDates={excludeDate}
                filterDate={isWeekday}
                // minDate={new Date()}
                dateFormat="dd MMMM, yyyy"
                placeholderText="Tanggal Pengambilan Data saat ini"
              />
            </div>
            <div className="merk-vaccine">
              <Select
                options={this.state.visit_types}
                value={this.state.visit_type}
                onChange={(value) => this.visitHandler(value)}
                Placeholder="massukkan data"
              />
            </div>
            <br />
            <br />
            <a href="/patient-detail">
              <button
                className="btn btn-primary btn-saveschedule"
                onClick={this.saveVisit}
              >
                {loading_button ? (
                  <div>
                    <FontAwesomeIcon icon={faSpinner} spin /> Loading
                  </div>
                ) : (
                  'SIMPAN'
                )}
              </button>
            </a>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default PatientVisit;
