import React, { Component } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import Modal from 'react-bootstrap/Modal';
import swal from 'sweetalert';
import LoadingOverlay from 'react-loading-overlay';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { API_URL } from '../../../BaseUrlApi/Url';
import './Broadcast.css';

const { SearchBar } = Search;

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};
function lastSentFormatter(cell, row, rowIndex, formatExtraData) {
  if (cell === null) {
    return <p>-</p>;
  } else {
    let date = cell.slice(0, 11);
    let changed_date = new Date(date);
    let dd = changed_date.getDate();
    let mm = changed_date.getMonth() + 1;
    let yyyy = changed_date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let time = cell.slice(11, 20);
    return `${dd}-${mm}-${yyyy} ${time}`;
  }
}

function titleHeaderStyle(column, colIndex) {
  let miniWidth = window.matchMedia(
    '(min-width:1220px) and (max-width:1849px)'
  );
  let mediumWidth = window.matchMedia('(min-width:1850px)');
  if (miniWidth.matches) {
    let style = {
      width: '10rem',
      textAlign: 'center',
    };
    return style;
  }
  if (mediumWidth.matches) {
    let style = {
      width: '13rem',
      textAlign: 'center',
    };
    return style;
  }
}

function bodyHeaderStyle(column, colIndex) {
  let miniWidth = window.matchMedia(
    '(min-width:1220px) and (max-width:1849px)'
  );
  let mediumWidth = window.matchMedia('(min-width:1850px)');
  if (miniWidth.matches) {
    let style = {
      width: '20rem',
      textAlign: 'center',
    };
    return style;
  }
  if (mediumWidth.matches) {
    let style = {
      width: '40rem',
      textAlign: 'center',
    };
    return style;
  }
}

function actionStyle(column, colIndex) {
  let miniWidth = window.matchMedia(
    '(min-width:1220px) and (max-width:1849px)'
  );
  let mediumWidth = window.matchMedia('(min-width:1850px)');
  if (miniWidth.matches) {
    let style = {
      width: '25rem',
      textAlign: 'center',
    };
    return style;
  }
  if (mediumWidth.matches) {
    let style = {
      width: '40rem',
      textAlign: 'center',
    };
    return style;
  }
}

class Broadcast extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoaded: false,
      openMakeBroadcast: false,
      openUpdateBroadcast: false,
      products: [],
      title: '',
      body: '',
      broadcastId: null,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      columns: [
        {
          dataField: 'title',
          text: 'Judul Pesan',
          headerStyle: titleHeaderStyle,
          style: { textAlign: 'center' },
        },
        {
          dataField: 'body',
          text: 'Isi Pesan',
          headerStyle: bodyHeaderStyle,
          style: { textAlign: 'center' },
        },
        {
          dataField: 'last_sent',
          text: 'Terakhir Dikirim',
          headerStyle: { textAlign: 'center', width: '8rem' },
          style: { textAlign: 'center' },
          formatter: lastSentFormatter,
        },
        {
          dataField: 'Aksi',
          text: 'Aksi',
          headerStyle: actionStyle,
          style: { textAlign: 'center' },
          formatter: (cell, row, rowIndex, formatExtraData) => {
            return (
              <div className="table-buttons">
                <button
                  onClick={() => {
                    swal({
                      title: 'Yakin Ingin Menghapus?',
                      icon: 'warning',
                      buttons: ['Batal', 'Hapus'],
                      dangerMode: true,
                    }).then((move) => {
                      if (move) {
                        let rowId = row.id;
                        axios
                          .delete(
                            `${API_URL}/admin/broadcast_messages/${rowId}`,
                            { headers }
                          )
                          .then((res) => {
                            window.location.href = '/broadcast';
                          });
                      } else {
                        return;
                      }
                    });
                  }}
                  className="btn btn-danger patient-delete"
                >
                  Hapus
                </button>
                <button
                  className="btn btn-primary patient-change"
                  onClick={() => {
                    this.showUpdateBroadcast(row.id);
                  }}
                >
                  Ubah
                </button>

                <button
                  className="btn btn-info patient-detail"
                  onClick={() => {
                    swal({
                      title:
                        'Apakah anda ingin mengirim notifikasi via Whatsapp?',
                      icon: 'warning',
                      buttons: ['Tidak', 'Ya'],
                      dangerMode: false,
                    }).then((move) => {
                      let rowId = row.id;
                      const url = move
                        ? `${API_URL}/admin/broadcast_messages/sendBroadcast/${rowId}?send_via_whatsapp=1`
                        : `${API_URL}/admin/broadcast_messages/sendBroadcast/${rowId}`;
                      axios.get(url, { headers }).then((res) => {
                        window.location.href = '/broadcast';
                      });
                    });
                  }}
                >
                  Kirim
                </button>
              </div>
            );
          },
        },
      ],
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });

    let getBroadcast = await axios.get(`${API_URL}/admin/broadcast_messages`, {
      headers,
    });
    await this.setState({
      products: getBroadcast.data.broadcastMessages,
    });
    this.setState({ isLoaded: false });
  }
  showMakeBroadcast = async (e) => {
    this.setState({ openMakeBroadcast: !this.state.openMakeBroadcast });
  };

  hideMakeBroadcast = async (e) => {
    this.setState({ openMakeBroadcast: false });
  };

  showUpdateBroadcast = async (value) => {
    this.setState({ openUpdateBroadcast: !this.state.openUpdateBroadcast });
    let getBroadcast = await axios.get(
      `${API_URL}/admin/broadcast_messages/${value}`,
      { headers }
    );
    this.setState({
      broadcastId: value,
      title: getBroadcast.data.broadcastMessage.title,
      body: getBroadcast.data.broadcastMessage.body,
    });
  };
  hideUpdateBroadcast = async (e) => {
    this.setState({ openUpdateBroadcast: false });
  };

  titleHandler = async (e) => {
    await this.setState({
      title: e.target.value,
    });
  };
  messageHandler = async (e) => {
    await this.setState({
      body: e.target.value,
    });
  };
  postMessage = async (e, formData, inputs) => {
    e.preventDefault();
    let message = {
      title: this.state.title,
      body: this.state.body,
    };
    axios
      .post(`${API_URL}/admin/broadcast_messages`, message, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Pesan Berhasil Ditambahkan!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = '/broadcast';
          } else {
            return;
          }
        });
      });
  };

  updateMessage = async (e, formData, inputs) => {
    e.preventDefault();

    let message = {
      _method: 'PATCH',
      title: this.state.title,
      body: this.state.body,
    };
    axios
      .post(
        `${API_URL}/admin/broadcast_messages/${this.state.broadcastId}`,
        message,
        { headers }
      )
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Pesan Berhasil Diubah!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = '/broadcast';
          } else {
            return;
          }
        });
      });
  };

  render() {
    let {
      openMakeBroadcast,
      openUpdateBroadcast,
      immediate,
      setFocusOnError,
      title,
      body,
    } = this.state;
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan {from} / {to} dari {size} Hasil
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '10',
          value: 10,
        },
        {
          text: '25',
          value: 25,
        },
        {
          text: '50',
          value: 50,
        },
        {
          text: 'Semua',
          value: this.state.products.length,
        },
      ],
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <ToolkitProvider
              keyField="id"
              columns={this.state.columns}
              data={this.state.products}
              search
            >
              {(toolkitprops) => (
                <div>
                  <SearchBar
                    {...toolkitprops.searchProps}
                    className="search-content"
                  />
                  <BootstrapTable
                    bordered={false}
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    );
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Broadcast</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: '#039BEB' }}>Notifikasi</span>
                <span style={{ color: '#00000029' }}> / Broadcast</span>
              </h2>
            </div>
            <div className="add-button">
              <button
                className="btn btn-primary btn-add-table mb-2 mr-3 "
                onClick={this.showMakeBroadcast}
              >
                Buat Pesan
              </button>
            </div>
            <PaginationProvider pagination={paginationFactory(options)}>
              {contentTable}
            </PaginationProvider>
          </div>
        </LoadingOverlay>

        {/* MAKE A BROADCAST SECTION */}
        <Modal show={openMakeBroadcast} onHide={this.hideMakeBroadcast}>
          <Modal.Header>
            <Modal.Title>
              <h1 className="note-child-title">Buat Pesan</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ValidationForm
              onSubmit={this.postMessage.bind(this)}
              ref={this.formRef}
              immediate={immediate}
              setFocusOnError={setFocusOnError}
            >
              <table cellPadding="10" className="add-table">
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor="" className="add-vaccine-label mr-5">
                        Judul Pesan
                      </label>
                      <span className="">:</span>
                    </td>
                    <td>
                      <TextInput
                        type="text"
                        name="title_message"
                        onChange={this.titleHandler}
                        className="add-vaccine-input"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="" className="add-vaccine-label mr-5">
                        Isi Pesan
                      </label>
                      <span className="">:</span>
                    </td>
                    <td>
                      <textarea
                        type="text"
                        name="content_message"
                        onChange={this.messageHandler}
                        className="add-broadcast"
                        required
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </ValidationForm>
          </Modal.Body>
          <Modal.Footer>
            <div className="patient-buttons">
              <button className="btn btn-primary" onClick={this.postMessage}>
                Simpan
              </button>
              <button
                className="btn btn-danger mr-1"
                onClick={this.hideMakeBroadcast}
              >
                Batal
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* UPDATE */}
        <Modal show={openUpdateBroadcast} onHide={this.hideUpdateBroadcast}>
          <Modal.Header>
            <Modal.Title>
              <h1 className="note-child-title">Edit Pesan</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ValidationForm
              onSubmit={this.postMessage.bind(this)}
              ref={this.formRef}
              immediate={immediate}
              setFocusOnError={setFocusOnError}
            >
              <table cellPadding="10" className="add-table">
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor="" className="add-vaccine-label mr-5">
                        Judul Pesan
                      </label>
                      <span className="">:</span>
                    </td>
                    <td>
                      <TextInput
                        type="text"
                        name="title_message"
                        value={title}
                        onChange={this.titleHandler}
                        className="add-vaccine-input"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="" className="add-vaccine-label mr-5">
                        Isi Pesan
                      </label>
                      <span className="">:</span>
                    </td>
                    <td>
                      <textarea
                        type="text"
                        name="content_message"
                        value={body}
                        onChange={this.messageHandler}
                        className="add-broadcast"
                        required
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </ValidationForm>
          </Modal.Body>
          <Modal.Footer>
            <div className="patient-buttons">
              <button className="btn btn-primary " onClick={this.updateMessage}>
                Simpan
              </button>
              <button
                className="btn btn-danger mr-1"
                onClick={this.hideUpdateBroadcast}
              >
                Batal
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Broadcast;
