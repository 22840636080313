export const PAYMENT_STATUS = {
  NOT_PAID: 'Belum Bayar',
  WAITING_CONFIRMATION: 'Menunggu Konfirmasi',
  PAID: 'Sudah Bayar',
  REJECTED: 'Pembayaran Ditolak',
};

export const LIST_PAYMENT_STATUS = [
  { id: 1, name: PAYMENT_STATUS.NOT_PAID },
  { id: 2, name: PAYMENT_STATUS.WAITING_CONFIRMATION },
  { id: 3, name: PAYMENT_STATUS.PAID },
  { id: 4, name: PAYMENT_STATUS.REJECTED },
];
