import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import "react-datepicker/dist/react-datepicker.css";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import AdminSidebar from "../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../BaseUrlApi/Url";
import "./AddFamily.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class AddFamily extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoaded: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      parent_id: null,
      father_name: "",
      mother_name: "",
      jobs: [],
      mother_job: {},
      father_job: {},
      isFree: null,
      free: [],
      province: {},
      provinces: [],
      city: {},
      cities: [],
      district: {},
      districts: [],
      address: "",
      phone_number: null,
      mother_phone_number: null, 
      startDate: new Date(),
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });

    // FREE STATUS
    let free = [
      {
        label: "Tidak Gratis",
        value: 0,
      },
      {
        label: "Gratis",
        value: 1,
      },
    ];

    // GET JOB LIST
    let getJobs = await axios.get(`${API_URL}/admin/jobs`, { headers });
    let jobs = getJobs.data.jobs.map((job) => ({
      value: job.id,
      label: job.name,
    }));

    // GET PROVINCES
    let getProvinces = await axios.get(`${API_URL}/regions/provinces`, {
      headers,
    });
    let provinces = getProvinces.data.provinces.map((province) => ({
      value: province.id,
      label: province.name,
    }));

    await this.setState({
      parent_id: parseInt(this.props.match.params.value),
      jobs: jobs,
      provinces: provinces,
      free: free,
    });
    this.setState({ isLoaded: false });
  }

  inputHandler = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  motherJobHandler = async (value) => {
    this.setState({
      mother_job: {
        value: value.value,
        label: value.label,
      },
    });
  };
  fatherJobHandler = async (value) => {
    this.setState({
      father_job: {
        value: value.value,
        label: value.label,
      },
    });
  };

  provinceHandler = async (value) => {
    this.setState({ isLoaded: true });
    // GET CITIES
    let getCities = await axios.get(
      `${API_URL}/regions/provinces?id=${value.value}`,
      { headers }
    );
    let cities = getCities.data.provinces.cities.map((city) => ({
      value: city.id,
      label: city.name,
    }));

    this.setState({
      province: {
        value: value.value,
        label: value.label,
      },
      cities: cities,
      isLoaded: false,
    });
  };

  cityHandler = async (value) => {
    this.setState({ isLoaded: true });
    // GET DISTRICT LIST
    let getDistricts = await axios.get(`${API_URL}/regions/cities?id=${value.value}`, {
      headers,
    });
    let districts = getDistricts.data.cities.districts.map((district) => ({
      value: district.id,
      label: district.name,
    }));
    this.setState({
      city: {
        value: value.value,
        label: value.label,
      },
      districts: districts,
      isLoaded: false,
    });
  };

  districtHandler = async (value) => {
    this.setState({
      district: {
        value: value.value,
        label: value.label,
      },
    });
  };

  clearForm = async (e) => {
    this.setState({
      father_name: "",
      mother_name: "",
      father_job: {},
      mother_job: {},
      province: {},
      city: {},
      district: {},
      address: "",
      phone_number: "",
      mother_phone_number:''
    });
  };

  postData = async (e) => {
    e.preventDefault();
    const postData = {
      father_name: this.state.father_name,
      mother_name: this.state.mother_name,
      father_job_id: this.state.father_job.value,
      mother_job_id: this.state.mother_job.value,
      province_id: this.state.province.value,
      city_id: this.state.city.value,
      district_id: this.state.district.value,
      is_free: parseInt(this.state.isFree),
      address: this.state.address,
      phone_number: this.state.phone_number,
      mother_phone_number: this.state.mother_phone_number
    };

    axios
      .post(`${API_URL}/admin/parents`, postData, { headers })
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Data Keluarga Telah Berhasil Ditambahkan!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/detail-patient/${res.data.data.id}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };

  render() {
    let {
      free,
      father_name,
      mother_name,
      address,
      phone_number,
      mother_phone_number,
      jobs,
      mother_job,
      father_job,
      province,
      provinces,
      city,
      cities,
      districts,
      district,
      isLoaded,
    } = this.state;
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Tambah Kepala Keluarga</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Manajemen Anak</span>
                <span style={{ color: "#039BEB" }}>
                  / Daftar Kepala Keluarga
                </span>
                <span style={{ color: "#00000029" }}>
                  / Tambah Kepala Keluarga
                </span>
              </h2>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <ValidationForm
                  onSubmit={this.postData.bind(this)}
                  ref={this.formRef}
                  immediate={this.state.immediate}
                  setFocusOnError={this.state.setFocusOnError}
                >
                  <table cellPadding="10" className="add-table text-left">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Nama Ayah
                          </label>
                        </td>
                        <td>
                          :
                          <TextInput
                            type="text"
                            name="father_name"
                            value={father_name}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Nama Ibu
                          </label>
                        </td>
                        <td>
                          :
                          <TextInput
                            type="text"
                            name="mother_name"
                            value={mother_name}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Pekerjaan Ayah
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                            options={jobs}
                            value={father_job}
                            onChange={(value) => this.fatherJobHandler(value)}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Pekerjaan Ibu
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                            options={jobs}
                            value={mother_job}
                            onChange={(value) => this.motherJobHandler(value)}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label-class">
                          <label htmlFor="" className="add-vaccine-label">
                            Status Digratiskan
                          </label>
                        </td>
                        <td>
                          :
                          <div className="radio-style">
                            {free.map((res) => (
                              <label className="container">
                                <input
                                  type="radio"
                                  name="isFree"
                                  id={res.label}
                                  value={res.value}
                                  onChange={this.inputHandler}
                                />
                                <span className="checkmark"></span>
                                {res.label}
                              </label>
                            ))}
                          </div>
                        </td>
                      </tr>
                      <tr className="selector">
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Provinsi
                          </label>
                        </td>
                        <td>
                          <span style={{ margin: "1.5rem 2rem 0 0" }}> : </span>
                          <Select
                            options={provinces}
                            value={province}
                            onChange={(value) => this.provinceHandler(value)}
                            className="select-selector"
                          />
                        </td>
                      </tr>
                      <tr className="selector">
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Kota/Kabupaten
                          </label>
                        </td>
                        <td>
                          <span style={{ margin: "1.5rem 2rem 0 0" }}> : </span>
                          <Select
                            options={cities}
                            value={city}
                            onChange={(value) => this.cityHandler(value)}
                            className="select-selector"
                          />
                        </td>
                      </tr>
                      <tr className="selector">
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Kecamatan
                          </label>
                        </td>
                        <td>
                          <span style={{ margin: "1.5rem 2rem 0 0" }}> : </span>
                          <Select
                            options={districts}
                            value={district}
                            onChange={(value) => this.districtHandler(value)}
                            className="select-selector"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Alamat
                          </label>
                        </td>
                        <td>
                          :
                          <TextInput
                            type="text"
                            name="address"
                            value={address}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            No.Telp Ayah
                          </label>
                        </td>
                        <td>
                          :
                          <TextInput
                            type="text"
                            name="phone_number"
                            value={phone_number}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            No.Telp Ibu
                          </label>
                        </td>
                        <td>
                          :
                          <TextInput
                            type="text"
                            name="mother_phone_number"
                            value={mother_phone_number}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                    </tbody>
                    <br />
                  </table>
                </ValidationForm>
                <div className="patient-buttons">
                  <Link to="/patients">
                    <button
                      className="btn btn-primary mr-1"
                      onClick={this.postData}
                    >
                      Simpan
                    </button>
                    <button className="btn btn-danger mr-1">Batal</button>
                  </Link>
                  <button
                    className="btn btn-warning text-dark mr-1"
                    onClick={this.clearForm}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default AddFamily;
