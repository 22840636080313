import { useQuery } from 'react-query';
import API from '../../config/axios';
import { QUERY_KEY } from '../../constans/query-keys';

const fetchShifts = async () => {
  const { data } = await API.get('/shifts');
  return data;
};

const useGetShifts = () => {
  return useQuery([QUERY_KEY.GET_SHIFTS], fetchShifts);
};

export default useGetShifts;
