import React from 'react';
import './TemplateCertificate.css';
import moment from 'moment';

import IMGLogo from '../../../../../assets/images/img-logo-PDBAD.png';
import { onFormatPatientAgeEnglish } from '../../../../../utils/ageFormat';

const onFormatVaccineName = (name, fullName) => {
  if (name && fullName) {
    return `${name} (${fullName})`;
  }
  return name;
};

const TemplateCertificate = ({ data }) => {
  return (
    <>
      <div className="container-template">
        <div class="certificate-wrapper">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                width: '30mm',
                height: '30mm',
                margin: '0 auto',
                // marginLeft: 50,
              }}
            >
              <img
                src={IMGLogo}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                alt="img-logo.png"
              />
            </div>
            <div
              style={{
                width: '100%',
              }}
            >
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: 'normal',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>Praktek dr. Bob</span>
                Jl. A. Mappanyukki No. 119, Kelurahan Mario
                <br />
                Kecamatan Mariso, Kota Makassar
                <br />
                Phone: 0411-8915995, Email: praktekdokterbobs@gmail.com
              </p>
            </div>
          </div>
          <div
            style={{
              borderBottom: '1px solid #f5f5f5',
              width: '100%',
              marginBottom: 10,
            }}
          ></div>

          <h4
            style={{
              borderBottom: '1px solid #f5f5f5',
              margin: 0,
              padding: '2px 8px',
            }}
          >
            Certificate of Immunization Status (CIS)
          </h4>
          <p>
            No. {data?.vaccination_certificate_request?.reference_number || '-'}
          </p>

          <div
            style={
              {
                // paddingBottom: '20px',
              }
            }
          ></div>

          <div style={{ margin: '10px 0px' }}>
            <table>
              <tr>
                <td width={200} align="left">
                  Name
                </td>
                <td align="left">:</td>
                <td align="left">
                  <span style={{ padding: 8 }}>
                    {data?.child?.fullname || '-'}
                  </span>
                </td>
              </tr>
              <tr>
                <td width={200} align="left">
                  Birthdate / Age
                </td>
                <td align="left">:</td>
                <td align="left">
                  <span style={{ padding: 8 }}>
                    {moment(data?.child?.birthdate).format('DD-MM-YYYY')} /{' '}
                    {onFormatPatientAgeEnglish(
                      data?.child?.age,
                      data?.child?.age_in_month
                    )}
                  </span>
                </td>
              </tr>
            </table>
          </div>

          <div
            className="table-template-certificate-wrapper"
            style={{ width: '80%', margin: '20px 0px' }}
          >
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ width: 200 }}>
                    Type
                  </th>
                  <th colSpan={7}>Date / Brand / Batch No.</th>
                </tr>
                <tr>
                  <th style={{ width: 100 }}>I</th>
                  <th style={{ width: 100 }}>II</th>
                  <th style={{ width: 100 }}>III</th>
                  <th style={{ width: 100 }}>IV</th>
                  <th style={{ width: 100 }}>V</th>
                  <th style={{ width: 100 }}>VI</th>
                  <th style={{ width: 100 }}>VII</th>
                </tr>
              </thead>
              <tbody>
                {data?.finished_vaccinations_by_type?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {onFormatVaccineName(item?.name, item?.full_name) || ''}
                      </td>
                      <>
                        {[1, 2, 3, 4, 5, 6, 7]?.map((_, index) => (
                          <td>
                            <>
                              {item?.vaccinations?.[index] ? (
                                <div>
                                  <p>
                                    {moment(
                                      item?.vaccinations?.[index]?.date_taken
                                    ).format('DD-MM-YYYY')}{' '}
                                    /
                                    <span>
                                      {item?.vaccinations?.[index]?.brand_name}{' '}
                                      /{' '}
                                    </span>
                                    <span>
                                      {item?.vaccinations?.[index]?.batch}{' '}
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          </td>
                        ))}
                      </>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* <Table
              className="table-request-certificate"
              rowClassName="table-column-request-certificate"
              pagination={false}
              bordered
              dataSource={data?.finished_vaccinations_by_type || []}
            >
              <Column
                width={200}
                align="left"
                title="Type"
                dataIndex="name"
                key="name"
                render={(value, record, index) => {
                  return (
                    <span>
                      {onFormatVaccineName(record?.name, record?.full_name) ||
                        ''}
                    </span>
                  );
                }}
              />
              <ColumnGroup title="Date / Brand / Batch No.">
                <Column
                  width={100}
                  title="I"
                  render={(value, record, index) => {
                    const { vaccinations, frequency } = record;
                    return {
                      props: {
                        style: {
                          background: frequency >= 1 ? 'white' : 'white',
                        },
                      },
                      children: (
                        <span className="text-certificate">
                          {!!vaccinations?.length ? (
                            <>
                              {vaccinations?.[0] ? (
                                <div>
                                  <p>
                                    {moment(
                                      vaccinations?.[0]?.date_taken
                                    ).format('DD-MM-YYYY')}{' '}
                                    /
                                    <span>
                                      {vaccinations?.[0]?.brand_name} /{' '}
                                    </span>
                                    <span>{vaccinations?.[0]?.batch} </span>
                                  </p>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </span>
                      ),
                    };
                  }}
                />
                <Column
                  width={100}
                  title="II"
                  render={(value, record, index) => {
                    const { vaccinations, frequency } = record;
                    return {
                      props: {
                        style: {
                          background: frequency >= 2 ? 'white' : 'white',
                        },
                      },
                      children: (
                        <span>
                          {!!vaccinations?.length ? (
                            <>
                              {vaccinations?.[1] ? (
                                <div>
                                  <p>
                                    {moment(
                                      vaccinations?.[1]?.date_taken
                                    ).format('DD-MM-YYYY')}{' '}
                                    /
                                    <span>
                                      {vaccinations?.[1]?.brand_name} /{' '}
                                    </span>
                                    <span>{vaccinations?.[1]?.batch} </span>
                                  </p>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </span>
                      ),
                    };
                  }}
                />
                <Column
                  width={100}
                  title="III"
                  render={(value, record, index) => {
                    const { vaccinations, frequency } = record;
                    return {
                      props: {
                        style: {
                          background: frequency >= 3 ? 'white' : 'white',
                        },
                      },
                      children: (
                        <span>
                          {!!vaccinations?.length ? (
                            <>
                              {vaccinations?.[2] ? (
                                <div>
                                  <p>
                                    {moment(
                                      vaccinations?.[2]?.date_taken
                                    ).format('DD-MM-YYYY')}{' '}
                                    /
                                    <span>
                                      {vaccinations?.[2]?.brand_name} /{' '}
                                    </span>
                                    <span>{vaccinations?.[2]?.batch} </span>
                                  </p>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </span>
                      ),
                    };
                  }}
                />
                <Column
                  width={100}
                  title="IV"
                  render={(value, record, index) => {
                    const { vaccinations, frequency } = record;
                    return {
                      props: {
                        style: {
                          background: frequency >= 4 ? 'white' : 'white',
                        },
                      },
                      children: (
                        <span>
                          {!!vaccinations?.length ? (
                            <>
                              {vaccinations?.[3] ? (
                                <div>
                                  <p>
                                    {moment(
                                      vaccinations?.[3]?.date_taken
                                    ).format('DD-MM-YYYY')}{' '}
                                    /
                                    <span>
                                      {vaccinations?.[3]?.brand_name} /{' '}
                                    </span>
                                    <span>{vaccinations?.[3]?.batch} </span>
                                  </p>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </span>
                      ),
                    };
                  }}
                />
                <Column
                  width={100}
                  title="V"
                  render={(value, record, index) => {
                    const { vaccinations, frequency } = record;
                    return {
                      props: {
                        style: {
                          background: frequency >= 5 ? 'white' : 'white',
                        },
                      },
                      children: (
                        <span>
                          {!!vaccinations?.length ? (
                            <>
                              {vaccinations?.[4] ? (
                                <div>
                                  <p>
                                    {moment(
                                      vaccinations?.[4]?.date_taken
                                    ).format('DD-MM-YYYY')}{' '}
                                    /
                                    <span>
                                      {vaccinations?.[4]?.brand_name} /{' '}
                                    </span>
                                    <span>{vaccinations?.[4]?.batch} </span>
                                  </p>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </span>
                      ),
                    };
                  }}
                />
                <Column
                  width={100}
                  title="VI"
                  render={(value, record, index) => {
                    const { vaccinations, frequency } = record;
                    return {
                      props: {
                        style: {
                          background: frequency >= 6 ? 'white' : 'white',
                        },
                      },
                      children: (
                        <span>
                          {!!vaccinations?.length ? (
                            <>
                              {vaccinations?.[5] ? (
                                <div>
                                  <p>
                                    {moment(
                                      vaccinations?.[5]?.date_taken
                                    ).format('DD-MM-YYYY')}{' '}
                                    /
                                    <span>
                                      {vaccinations?.[5]?.brand_name} /{' '}
                                    </span>
                                    <span>{vaccinations?.[5]?.batch} </span>
                                  </p>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </span>
                      ),
                    };
                  }}
                />
                <Column
                  width={100}
                  title="VII"
                  render={(value, record, index) => {
                    const { vaccinations, frequency } = record;
                    return {
                      props: {
                        style: {
                          background: frequency >= 7 ? 'white' : 'white',
                        },
                      },
                      children: (
                        <span>
                          {!!vaccinations?.length ? (
                            <>
                              {vaccinations?.[6] ? (
                                <div>
                                  <p>
                                    {moment(
                                      vaccinations?.[6]?.date_taken
                                    ).format('DD-MM-YYYY')}{' '}
                                    /
                                    <span>
                                      {vaccinations?.[6]?.brand_name} /{' '}
                                    </span>
                                    <span>{vaccinations?.[6]?.batch} </span>
                                  </p>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </span>
                      ),
                    };
                  }}
                />
              </ColumnGroup>
            </Table> */}
          </div>

          <div
            className="signing-wrapper"
            style={{ width: '80%', marginTop: 50, marginRight: 260 }}
          >
            <p style={{ textAlign: 'right' }}>
              Makassar, {moment().format('DD-MM-YYYY')}
            </p>
            <div style={{ height: 100 }}></div>
            <p style={{ textAlign: 'right' }}>{data?.doctor_full_name || ''}</p>
            <p style={{ textAlign: 'right' }}>Examining Doctor</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateCertificate;
