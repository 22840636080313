import _ from 'lodash';
import API from '../../../../config/axios';

export const onGetDataDuplicate = ({
  page = 1,
  limit = 20,
  is_duplicate,
  keyword = '',
}) => {
  const tempParams = {
    page,
    limit,
    is_duplicate,
  };

  const filteredParams = _.pickBy(tempParams, (value) => !!value);

  const response = API.get('/parents', {
    params: {
      ...filteredParams,
    },
  })
    .then((res) => {
      return {
        data: res?.data?.parents,
        error: false,
      };
    })
    .catch(() => {
      return {
        data: [],
        error: true,
      };
    });

  return response;
};
