import React from 'react';
import './CardCheckin.css';

export const CardCheckin = ({ title, onClick, img }) => {
  return (
    <div className="card-checkin" onClick={onClick}>
      <div className="card-img-wrapper">
        <img src={img} className="card-img" alt="img-logo.png" />
      </div>
      <button
        type="button"
        class="btn btn-primary button-card-checkin"
        onClick={onClick}
      >
        Checkin
      </button>
    </div>
  );
};
