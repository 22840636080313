import React from 'react';

const CardDashboard = ({ icon, title, count = 0, onClick }) => {
  return (
    <div
      className="morning-patients ml-5"
      style={{
        cursor: 'pointer',
      }}
      {...(onClick && { onClick })}
    >
      {icon && icon}
      <p className="card-title">{title}</p>
      <p className="card-number">{count}</p>
    </div>
  );
};

export default CardDashboard;
