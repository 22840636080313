import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import "antd/dist/antd.css";

import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { TimePicker } from "antd";

import { API_URL } from "../../../../BaseUrlApi/Url";
import AdminSidebar from "../../../AdminSidebar/AdminSidebar";
import "./AddShift.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class AddShift extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      name: "",
      start_time: "10:00",
      choose_start: "",
      end_time: "",
      deadline: "",
      limit: null,
      shifts: [],
      choose_shift: {},
    };
  }

  async componentDidMount() {}

  startHandler = async (time) => {
    let choose_start = String(time._d);
    let starttime = choose_start.slice(16, 24);
    await this.setState({
      start_time: time,
      choose_start: starttime,
    });
  };
  endHandler = async (time) => {
    let end_time = String(time._d);
    let choose_end = end_time.slice(16, 24);
    await this.setState({
      start_time: time,
      end_time: choose_end,
    });
  };

  deadlineHandler = async (time) => {
    let end_time = String(time._d);
    let deadline = end_time.slice(16, 24);
    await this.setState({
      start_time: time,
      deadline: deadline,
    });
  };

  limitHandler = async (e) => {
    await this.setState({
      limit: e.target.value,
    });
  };

  shiftHandler = async (e) => {
    await this.setState({
      choose_shift: e.target.value,
    });
  };

  postData = async (e) => {
    e.preventDefault();

    const postData = {
      name: this.state.choose_shift,
      start_time: this.state.choose_start,
      end_time: this.state.end_time,
      registration_deadline: this.state.deadline,
      registration_limit: this.state.limit,
    };

    axios
      .post(`${API_URL}/admin/shifts`, postData, { headers })
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Jadwal Telah Berhasil Ditambahkan!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/shifts`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };

  cancelData = () => {
    window.location.href = "/shifts";
  };

  render() {
    return (
      <div className="patient-section">
        <AdminSidebar />
        <div className="content-section">
          <div className="title-section mb-5">
            <h1 className="patient-page-title">Tambah Shift</h1>
            <h2 className="patient-page-subtitle">
              <span style={{ color: "#039BEB" }}>Master Data</span>
              <span style={{ color: "#039BEB" }}>/ Daftar Shift</span>
              <span style={{ color: "#00000029" }}> / Tambah Shift</span>
            </h2>
          </div>
          <div className="card shadow mb-4">
            <div className="card-body">
              <ValidationForm
                onSubmit={this.postData.bind(this)}
                ref={this.formRef}
                immediate={this.state.immediate}
                setFocusOnError={this.state.setFocusOnError}
              >
                <table cellPadding="10" className="add-table text-left">
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="">Jadwal</label>
                      </td>
                      <td>
                        :
                        <TextInput
                          type="text"
                          className="add-vaccine-input"
                          onChange={this.shiftHandler}
                          required
                        />
                      </td>
                    </tr>
                    <td>
                      <label htmlFor="">Waktu Mulai</label>
                    </td>
                    <td>
                      :
                      <TimePicker
                        size="large"
                        className="time-picker-style"
                        onChange={this.startHandler}
                      />
                    </td>
                    <tr>
                      <td>
                        <label htmlFor="">Waktu Selesai</label>
                      </td>
                      <td>
                        :
                        <TimePicker
                          size="large"
                          className="time-picker-style"
                          onChange={this.endHandler}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">Batas Waktu Pendaftaran</label>
                      </td>
                      <td>
                        :
                        <TimePicker
                          size="large"
                          className="time-picker-style"
                          onChange={this.deadlineHandler}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">Batas Registrasi/Peserta</label>
                      </td>
                      <td>
                        :
                        <TextInput
                          type="number"
                          className="add-vaccine-input"
                          onChange={this.limitHandler}
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ValidationForm>
              <div className="patient-buttons">
                <button className="btn btn-primary " onClick={this.postData}>
                  Simpan
                </button>
                <button
                  className="btn btn-danger mr-1 "
                  onClick={this.cancelData}
                >
                  Batal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddShift;
