import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'react-loading-overlay';
import swal from 'sweetalert';
import axios from 'axios';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import profile from '../../../assets/images/blank_profile.jpg';
import menubar from '../../../assets/icons/menubar.svg';
import EmergencyImg from '../../../assets/icons/alarm-3.svg';
import { API_URL } from '../../BaseUrlApi/Url';
import './Checkup.css';

const Container = styled.div`
  display: flex;
`;

// TAB COMPONENT
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

// TAB COMPONENT END

export default function FullWidthTabs() {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json',
  };

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState([]);
  const [emergency, setEmergency] = useState([]);
  const [passed, setPassed] = useState([]);
  const [queue, setQueue] = useState([]);
  const [done, setDone] = useState([]);
  const [loaded, setLoad] = useState({ isLoaded: true });
  const [upcomingVaccinations, setUpcomingVaccinations] = useState([]);
  const [column] = useState([
    {
      dataField: 'child.fullname',
      text: 'Nama',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'child.birthdate',
      text: 'Tgl Lahir',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
      formatter: dateFormatter,
    },
    {
      dataField: 'vaccine_type',
      text: 'Tipe Vaksin',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'action',
      text: 'Aksi',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
      formatter: (cell, row) => {
        return (
          <span>
            <Link to={`/add-upcoming-vaccination/${row.child.id}`}>
              <button
                style={{ margin: '.2rem' }}
                className="btn-info nurse-btn-formatter"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="nurse-btn-icon mr-1"
                />
                Tambah
              </button>
            </Link>
            <Link
              to={`/edit-upcoming-vaccination/${row.queue_id}/${row.child.id}`}
            >
              <button
                style={{ margin: '.2rem' }}
                className="btn-info nurse-btn-formatter"
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  className="nurse-btn-icon mr-1"
                />
                Edit
              </button>
            </Link>
          </span>
        );
      },
    },
  ]);
  const [upcomingVisits, setUpcomingVisits] = useState([]);
  const [column_visit] = useState([
    {
      dataField: 'child.fullname',
      text: 'Nama',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'child.birthdate',
      text: 'Tgl Lahir',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
      formatter: dateFormatter,
    },
    {
      dataField: 'visit_type.name',
      text: 'Tipe Kunjungan',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'action',
      text: 'Aksi',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
      formatter: (cell, row) => {
        return (
          <Link to={`/add-upcoming-visit/${row.child.id}`}>
            <button className="btn-info nurse-btn-formatter">
              <FontAwesomeIcon icon={faPlus} className="nurse-btn-icon mr-1" />
              Tambah
            </button>
          </Link>
        );
      },
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const dateHandler = (value) => {
    let date = new Date(value);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_date = dd + '-' + mm + '-' + yyyy;
    return set_date;
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      let date = new Date();
      let dd = date.getDate();
      let mm = date.getMonth() + 1; //January is 0!

      let yyyy = date.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      let set_date = `${yyyy}-${mm}-${dd}`;

      // GET TAB UPCOMING SCHEDULE DATA START
      axios
        .get(
          `${API_URL}/nurse/vaccinations/upcomingVaccinations?date_plan=${set_date}`,
          { headers }
        )
        .then((res) => {
          let filterVaccineTypeUpcoming = res.data.vaccinations.map((itemx) => {
            return {
              ...itemx,
              vaccine_type: itemx.vaccine_types
                .map((items) => items.name)
                .join(', '),
            };
          });
          setUpcomingVaccinations(filterVaccineTypeUpcoming);
        })
        .catch((err) => {
          swal({
            title: 'GAGAL',
            text: 'Gagal Menampilkan Data',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });

      axios
        .get(`${API_URL}/nurse/visits/upcomingVisits`, { headers })
        .then((res) => {
          let visits_data = res.data.visits.filter(
            (visit) => visit.date_plan === set_date
          );
          setUpcomingVisits(visits_data);
        })
        .catch((err) => {
          swal({
            title: 'GAGAL',
            text: 'Gagal Menampilkan Data',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });

      // GET TAB UPCOMING SCHEDULE DATA END

      // GET ALL ALL QUEUES DATA
      await axios
        .get(`${API_URL}/nurse/queues/getAllQueuesByStatus`, { headers })
        .then((result) => {
          if (result.data.queue !== undefined) {
            const process_queues = result.data.queue.filter(
              (queue) => queue.id === 4
            );
            const emergency = result.data.queue.filter(
              (queue) => queue.id === 8
            );
            const passed_queues = result.data.queue.filter(
              (queue) => queue.id === 6
            );
            const queues = result.data.queue.filter((queue) => queue.id === 3);
            const completed = result.data.queue.filter(
              (queue) => queue.id === 5
            );
            setData(process_queues[0].queues);
            setPassed(passed_queues[0].queues);
            setQueue(queues[0].queues);
            setDone(completed[0].queues);
            setEmergency(emergency[0].queues);
            setLoad(false);
          } else if (
            result.data.message === 'Tidak ada shift yang sedang berjalan'
          ) {
            swal({
              title: 'GAGAL',
              text: 'Tidak ada shift yang berjalan',
              icon: 'warning',
              type: 'error',
              buttons: 'OK',
            });
            setLoad(false);
          }
        })
        .catch((err) => {
          swal({
            title: 'GAGAL',
            text: 'Antrian Gagal Ditampilkan',
            icon: 'warning',
            type: 'error',
            buttons: 'OK',
          });
        });
    }, 8000);

    return () => clearInterval(interval);
  }, [headers]);

  function dateFormatter(cell) {
    let date = new Date(cell);
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_date = dd + '-' + mm + '-' + yyyy;
    return set_date;
  }

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    totalSize: upcomingVaccinations.length,
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div className="table-responsive nurse-table">
      <ToolkitProvider
        keyField="id"
        columns={column}
        data={upcomingVaccinations}
        search
      >
        {(toolkitprops) => (
          <div>
            <BootstrapTable
              bordered={false}
              {...toolkitprops.baseProps}
              {...paginationTableProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <PaginationListStandalone {...paginationProps} />
    </div>
  );

  const options2 = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    totalSize: upcomingVisits.length,
  };

  const contentTable2 = ({ paginationProps, paginationTableProps }) => (
    <div className="table-responsive nurse-table">
      <ToolkitProvider
        keyField="id"
        columns={column_visit}
        data={upcomingVisits}
        search
      >
        {(toolkitprops) => (
          <div>
            <BootstrapTable
              bordered={false}
              {...toolkitprops.baseProps}
              {...paginationTableProps}
            />
          </div>
        )}
      </ToolkitProvider>

      <PaginationListStandalone {...paginationProps} />
    </div>
  );

  const logout = async (e) => {
    e.preventDefault();
    swal({
      title: 'LOG OUT',
      text: 'Apakah anda yakin ingin Log Out dari aplikasi ini ?',
      buttons: ['Tidak', 'Ya'],
      dangerMode: true,
    }).then((removeData) => {
      if (removeData) {
        const logOutResponse = async () => {
          const headers = {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
          };

          await axios
            .post(`${API_URL}/auth/logout`, [], { headers })
            .then((res) => {
              localStorage.clear();
              window.location.href = '/login';
            })
            .catch((e) => {
              swal({
                title: 'GAGAL',
                text: 'Gagal Keluar',
                icon: 'warning',
                type: 'error',
                buttons: 'OK',
              });
            });
        };
        logOutResponse();
      } else {
        return;
      }
    });
  };

  if (localStorage.getItem('access_token') === null) {
    return <Redirect to="/login" />;
  } else {
    return (
      <div className="checkup-overlay">
        <div className={classes.root}>
          <LoadingOverlay active={loaded} spinner text="Memuat data...">
            <AppBar position="static" color="default">
              <div className="checkup-navbar dropdown">
                <img
                  src={menubar}
                  alt=""
                  className="menu-bars dropdown-toggle"
                  data-toggle="dropdown"
                />
                <div className="dropdown-menu">
                  <button className="dropdown-item" onClick={logout}>
                    Logout
                  </button>
                </div>
                <p className="checkup-navbar-title">STATUS ANTRIAN</p>
              </div>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Periksa" {...a11yProps(0)} />
                <Tab label="Antri" {...a11yProps(1)} />
                <Tab label="Lewat" {...a11yProps(2)} />
                <Tab label="Selesai" {...a11yProps(3)} />
                <Tab label="Jadwal" {...a11yProps(4)} />
              </Tabs>
            </AppBar>

            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                className="tab-body"
              >
                {data.length > 0
                  ? data.map((queue) => (
                      <Link
                        to={`/patient-detail?tab=onprocess&queueId=${queue.id}`}
                        className="anchor-card"
                      >
                        <div className="tab-card">
                          {queue.child.image_url !== null ? (
                            <img
                              src={queue.child.image_url}
                              alt="profile"
                              className="checkup-picture"
                            />
                          ) : (
                            <img
                              src={profile}
                              alt=""
                              className="checkup-picture"
                            />
                          )}
                          <div className="profile-body">
                            <div className="checkup-name-box">
                              <p className="checkup-name">
                                {queue.child.fullname}
                              </p>
                            </div>
                            <p className="checkup-date">
                              {dateHandler(queue.child.birthdate)}
                            </p>
                          </div>
                          <div className="checkup-number">
                            <p className="queue-text">{queue.order}</p>
                          </div>
                        </div>
                      </Link>
                    ))
                  : ''}
                {emergency.length > 0
                  ? emergency.map((queue) => (
                      <Link
                        to={`/patient-detail?tab=onprocess&queueId=${queue.id}`}
                        className="anchor-card"
                      >
                        <div className="tab-card">
                          {queue.child.image_url !== null ? (
                            <img
                              src={queue.child.image_url}
                              alt="profile"
                              className="checkup-picture"
                            />
                          ) : (
                            <img
                              src={profile}
                              alt=""
                              className="checkup-picture"
                            />
                          )}
                          <div className="profile-body">
                            <div className="checkup-name-box">
                              <p className="checkup-name">
                                {queue.child.fullname}
                              </p>
                            </div>
                            <p className="checkup-date">
                              {dateHandler(queue.child.birthdate)}
                            </p>
                          </div>
                          <div className="emergency-number">
                            <img
                              src={EmergencyImg}
                              alt="Emergency"
                              className="nurse-emergency-icon"
                            />
                            <p className="queue-text">{queue.order}</p>
                          </div>
                        </div>
                      </Link>
                    ))
                  : ''}
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                dir={theme.direction}
                className="tab-body"
              >
                {queue.length > 0
                  ? queue.map((queue) => (
                      <Link
                        to={`/patient-detail?tab=queue&queueId=${queue.id}`}
                        className="anchor-card"
                      >
                        <div className="tab-card">
                          {queue.child.image_url !== null ? (
                            <img
                              src={queue.child.image_url}
                              alt="profile"
                              className="checkup-picture"
                            />
                          ) : (
                            <img
                              src={profile}
                              alt=""
                              className="checkup-picture"
                            />
                          )}
                          <div className="profile-body">
                            <div className="checkup-name-box">
                              <p className="checkup-name">
                                {queue.child.fullname}
                              </p>
                            </div>
                            <p className="checkup-date">
                              {dateHandler(queue.child.birthdate)}
                            </p>
                          </div>
                          <div className="queue-number">
                            <p className="queue-text">{queue.order}</p>
                          </div>
                        </div>
                      </Link>
                    ))
                  : ''}
              </TabPanel>
              <TabPanel
                value={value}
                index={2}
                dir={theme.direction}
                className="tab-body"
              >
                {passed.length > 0
                  ? passed.map((queue) => (
                      <Link
                        to={`/patient-detail?tab=queue&queueId=${queue.id}`}
                      >
                        <div className="anchor-card">
                          <div className="tab-card">
                            {queue.child.image_url !== null ? (
                              <img
                                src={queue.child.image_url}
                                alt=""
                                className="checkup-picture"
                              />
                            ) : (
                              <img src="" alt="" className="checkup-picture" />
                            )}
                            <img
                              src={profile}
                              alt=""
                              className="checkup-picture"
                            />
                            <div className="profile-body">
                              <div className="checkup-name-box">
                                <p className="checkup-name">
                                  {queue.child.fullname}
                                </p>
                              </div>
                              <p className="checkup-date">
                                {dateHandler(queue.child.birthdate)}
                              </p>
                            </div>
                            <div className="skip-number">
                              <p className="queue-text">{queue.order}</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  : ''}
              </TabPanel>
              <TabPanel
                value={value}
                index={3}
                dir={theme.direction}
                className="tab-body"
              >
                {done.length > 0
                  ? done.map((queue) => (
                      <Link to={`/patient-detail?tab=done&queueId=${queue.id}`}>
                        <div className="anchor-card">
                          <div className="tab-card">
                            {queue.child.image_url !== null ? (
                              <img
                                src={queue.child.image_url}
                                alt="profile"
                                className="checkup-picture"
                              />
                            ) : (
                              <img
                                src={profile}
                                alt=""
                                className="checkup-picture"
                              />
                            )}
                            <div className="profile-body">
                              <div className="checkup-name-box">
                                <p className="checkup-name">
                                  {queue.child.fullname}
                                </p>
                              </div>
                              <p className="checkup-date">
                                {dateHandler(queue.child.birthdate)}
                              </p>
                            </div>
                            <div className="completed-number">
                              <p className="queue-text">{queue.order}</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  : ''}
              </TabPanel>
              <TabPanel
                value={value}
                index={4}
                dir={theme.direction}
                className="tab-body"
              >
                <div className="nurse-table-body nurse-overlay2">
                  <Container>
                    <p className="table-information">Jadwal Vaksinasi</p>
                  </Container>
                  <PaginationProvider pagination={paginationFactory(options)}>
                    {contentTable}
                  </PaginationProvider>
                  <br />
                  <hr />
                  <br />
                  <Container>
                    <p className="table-information">Jadwal Kunjungan</p>
                  </Container>
                  <PaginationProvider pagination={paginationFactory(options2)}>
                    {contentTable2}
                  </PaginationProvider>
                </div>
              </TabPanel>
            </SwipeableViews>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}
