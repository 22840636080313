import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useAddVaccineType from '../../../../../hooks/mutation/useAddVaccineType';
import useGetVaccineType from '../../../../../hooks/queries/useGetVaccineType';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const schema = yup.object().shape({
  type: yup.string().required('Tipe vaksin tidak boleh kosong!'),
  description: yup.string(),
  frequency: yup.string().required('Frekuensi tidak boleh kosong!'),
  additional_frequencies: yup.array().of(
    yup.object().shape({
      frequency: yup.string().required('Frekuensi tidak boleh kosong!'),
      age_lower_limit: yup.string().required('Batas usia tidak boleh kosong!'),
    })
  ),
});

const INITIAL_VALUE_FORM = {
  type: '',
  description: '',
  frequency: '',
  additional_frequencies: [],
};

const useAddEditVaccineType = () => {
  const history = useHistory();
  const params = useParams();
  const isEdit = !!+params?.value;

  const formMethods = useForm({
    defaultValues: INITIAL_VALUE_FORM,
    resolver: yupResolver(schema),
  });

  const { data, isLoading: isLoadingGetVaccineType } = useGetVaccineType(
    +params?.value
  );
  const { mutate, isLoading } = useAddVaccineType();

  const onSubmit = (values) => {
    const payload = {
      ...(isEdit && {
        _method: 'PATCH',
        vaccineTypeId: +params?.value,
      }),
      name: values?.type,
      description: values?.description,
      frequency: +values?.frequency,
      additional_frequencies: values?.additional_frequencies?.map((item) => ({
        frequency: +item?.frequency,
        age_lower_limit: +item?.age_lower_limit,
      })),
    };

    mutate(payload, {
      onSuccess: () => {
        notification.success({ message: 'Berhasil menambahkan tipe vaksin' });
        isEdit
          ? history.push('/vaccine-types')
          : formMethods.reset(INITIAL_VALUE_FORM);
      },
      onError: () => {
        notification.error({ message: 'Gagal menambahkan tipe vaksin' });
      },
    });
  };

  useEffect(() => {
    if (!isLoadingGetVaccineType) {
      formMethods.reset(
        {
          type: data?.vaccine_type?.name,
          description: data?.vaccine_type?.description,
          frequency: data?.vaccine_type?.frequency,
          additional_frequencies: data?.vaccine_type?.additional_frequencies,
        },
        {
          keepDirty: false,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingGetVaccineType]);

  return {
    formMethods,
    onSubmit,
    isLoading,
    isLoadingGetVaccineType,
    isEdit,
  };
};

export default useAddEditVaccineType;
