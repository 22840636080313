import { useQuery } from 'react-query';
import API from '../../config/axios';
import { QUERY_KEY } from '../../constans/query-keys';

const onGetPendingCertificate = async () => {
  const { data } = await API.get('/vaccination-certificate-requests/pending');
  return data;
};

const useGetPendingCertificate = () => {
  return useQuery([QUERY_KEY.GET_PENDING_CERTIFICATE], onGetPendingCertificate);
};

export default useGetPendingCertificate;
