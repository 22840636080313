import { Table, Row, Col, Typography, Space, Button, notification } from 'antd';
import React, { useState } from 'react';
import ColumnTableQueue from './ColumnTableQueue';
import Spacer from '../../../../../../Components/Spacer';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ModalDelete from '../ModalDelete/ModalDelete';
import useDisclosure from '../../../../../../hooks/useDisclosure';
import useDeleteQueue from '../../../../../../hooks/mutation/useDeleteQueue';
import { useQueryClient } from 'react-query';
import { QUERY_KEY } from '../../../../../../constans/query-keys';

const { Text } = Typography;

const TableQueue = ({
  isLoading,
  pagination = true,
  dataSource = [],
  from = 0,
  to = 0,
  total = 0,
  page,
  lastPage,
  onPrevPage,
  onNextPage,
}) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const [queueId, setQueueId] = useState(null);

  const { mutate, isLoading: isLoadingDelete } = useDeleteQueue();

  const modalDelete = useDisclosure();

  const onClickDetail = (id) => {
    history.push({
      pathname: `detail-queue/${id}`,
    });
  };

  const onClickDelete = (id) => {
    setQueueId(id);
    modalDelete.onOpen();
  };

  const onCloseModalDelete = () => {
    setQueueId(null);
    modalDelete.onClose();
  };

  const onDeleteQueue = () => {
    mutate(queueId, {
      onSuccess: () => {
        notification.success({
          message: 'Berhasil menghapus antrian',
        });
        queryClient.invalidateQueries([QUERY_KEY.GET_QUEUE]);
        queryClient.invalidateQueries([QUERY_KEY.GET_SEARCH_QUEUE]);
        onCloseModalDelete();
      },
      onError: () => {
        notification.error({
          message: 'Gagal menghapus antrian',
        });
      },
    });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Table
            dataSource={dataSource}
            loading={isLoading}
            columns={ColumnTableQueue({ onClickDetail, onClickDelete })}
            pagination={false}
            scroll={{ y: window.innerHeight - 520 }}
          />
        </Col>
      </Row>
      <Spacer height={24} />
      {pagination && (
        <Row justify="space-between">
          <Col>
            <Text>
              Data {from} hingga {to} dari {total}
            </Text>
          </Col>
          <Col>
            <Space size="small">
              <Button
                type="primary"
                size="middle"
                disabled={page === 1}
                onClick={onPrevPage}
                icon={<LeftOutlined />}
              />
              <Button
                size="middle"
                disabled
                style={{ background: 'white', color: 'black' }}
              >
                {page}
              </Button>
              <Button
                type="primary"
                size="middle"
                disabled={page === lastPage}
                onClick={onNextPage}
                icon={<RightOutlined />}
              />
            </Space>
          </Col>
        </Row>
      )}

      <ModalDelete
        isLoading={isLoadingDelete}
        isOpen={modalDelete.isOpen}
        onClose={onCloseModalDelete}
        onSubmit={onDeleteQueue}
      />
    </>
  );
};

export default TableQueue;
