import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";

import Select from "react-select";
import "antd/dist/antd.css";
import { TimePicker } from "antd";
import moment from "moment";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import AdminSidebar from "../../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../../BaseUrlApi/Url";
import "./UpdateSchedule.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class UpdateSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      shiftId: null,
      name: "",
      start_time: null,
      end_time: null,
      registration_limit: null,
      deadline: null,
      shifts: [],
      choose_shift: {},
      choose_start: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let shiftId = this.props.match.params.value;
    this.setState({
      shiftId: shiftId,
    });

    let getShifts = await axios.get(`${API_URL}/admin/shifts`, { headers });
    let getShift = await axios.get(`${API_URL}/admin/shifts/${shiftId}`, {
      headers,
    });

    let getSchedule = getShifts.data.shifts.map((type) => ({
      value: type.id,
      label: type.name,
    }));

    let chooseShift = getShifts.data.shifts.filter(
      (shift) => shift.id === getShift.data.shift.id
    );

    let getChooseShift = chooseShift.map((shift) => ({
      value: shift.id,
      label: shift.name,
    }));
    this.setState({
      name: getShift.data.shift.name,
      choose_start: getShift.data.shift.start_time,
      end_time: getShift.data.shift.end_time,
      registration_limit: getShift.data.shift.registration_limit,
      deadline: getShift.data.shift.registration_deadline,
      shifts: getSchedule,
      choose_shift: getChooseShift[0],
    });
    this.setState({ isLoaded: false });
  }

  shiftHandler = async (value) => {
    this.setState({
      choose_shift: {
        value: value.value,
        label: value.label,
      },
    });
  };

  startHandler = async (time) => {
    let choose_start = String(time._d);
    let starttime = choose_start.slice(16, 24);
    await this.setState({
      choose_start: starttime,
    });
  };

  endHandler = async (time) => {
    let end_time = String(time._d);
    let choose_end = end_time.slice(16, 24);
    await this.setState({
      end_time: choose_end,
    });
  };

  deadlineHandler = async (time) => {
    let end_time = String(time._d);
    let deadline = end_time.slice(16, 24);
    await this.setState({
      deadline: deadline,
    });
  };

  limitHandler = async (e) => {
    await this.setState({
      registration_limit: e.target.value,
    });
  };

  postData = async (e) => {
    e.preventDefault();
    const postData = {
      _method: "PATCH",
      name: this.state.choose_shift.label,
      start_time: this.state.choose_start,
      end_time: this.state.end_time,
      registration_deadline: this.state.deadline,
      registration_limit: parseInt(this.state.registration_limit),
    };

    axios
      .post(`${API_URL}/admin/shifts/${this.state.shiftId}`, postData, {
        headers,
      })
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Jadwal Shift Telah Berhasil Diubah!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/shifts`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };

  cancelData = () => {
    window.location.href = "/shifts";
  };

  render() {
    let { choose_start, end_time, registration_limit, deadline } = this.state;

    if (choose_start !== null && end_time !== null) {
      return (
        <div className="admin-overlay">
          <AdminSidebar />
          <LoadingOverlay
            active={this.state.isLoaded}
            spinner
            text="Memuat data..."
          >
            <div className="content-section">
              <div className="title-section mb-5">
                <h1 className="patient-page-title">Ubah Shift</h1>
                <h2 className="patient-page-subtitle">
                  <span style={{ color: "#039BEB" }}>Master Data</span>
                  <span style={{ color: "#039BEB" }}>/ Daftar Shift</span>
                  <span style={{ color: "#00000029" }}> / Ubah Shift</span>
                </h2>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <ValidationForm
                      onSubmit={this.postData.bind(this)}
                      ref={this.formRef}
                      immediate={this.state.immediate}
                      setFocusOnError={this.state.setFocusOnError}
                    >
                      <table
                        cellPadding="10"
                        className="add-table add-shift-table text-left"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <label htmlFor="">Jadwal</label>
                            </td>
                            <td>
                              :
                              <Select
                                options={this.state.shifts}
                                value={this.state.choose_shift}
                                onChange={(value) => this.shiftHandler(value)}
                                className="select-selector"
                                required
                              />
                            </td>
                          </tr>
                          <td>
                            <label htmlFor="">Waktu Mulai</label>
                          </td>
                          <td>
                            :
                            <TimePicker
                              defaultValue={moment(choose_start, "HH:mm:ss")}
                              size="large"
                              className="time-picker-style"
                              onChange={this.startHandler}
                            />
                          </td>
                          <tr>
                            <td>
                              <label htmlFor="">Waktu Selesai</label>
                            </td>
                            <td>
                              :
                              <TimePicker
                                defaultValue={moment(end_time, "HH:mm:ss")}
                                size="large"
                                className="time-picker-style"
                                onChange={this.endHandler}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label htmlFor="">Batas Waktu Pendaftaran</label>
                            </td>
                            <td>
                              :
                              <TimePicker
                                defaultValue={moment(deadline, "HH:mm:ss")}
                                size="large"
                                className="time-picker-style"
                                onChange={this.deadlineHandler}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label htmlFor="">Batas Registrasi/Peserta</label>
                            </td>
                            <td>
                              :
                              <TextInput
                                type="number"
                                className="add-vaccine-input"
                                value={registration_limit}
                                onChange={this.limitHandler}
                                required
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ValidationForm>
                    <div className="patient-buttons">
                      <button
                        className="btn btn-primary"
                        onClick={this.postData}
                      >
                        Simpan
                      </button>
                      <button
                        className="btn btn-danger mr-1 "
                        onClick={this.cancelData}
                      >
                        Batal
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      );
    } else {
      return (
        <div className="admin-overlay">
          <AdminSidebar />
          <LoadingOverlay
            active={this.state.isLoaded}
            spinner
            text="Memuat data..."
          >
            <div className="content-section">
              <div className="title-section mb-5">
                <h1 className="patient-page-title">Ubah Shift</h1>
                <h2 className="patient-page-subtitle">
                  <span style={{ color: "#039BEB" }}>Master Data</span>
                  <span style={{ color: "#039BEB" }}>/ Daftar Shift</span>
                  <span style={{ color: "#00000029" }}> / Ubah Shift</span>
                </h2>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="patient-buttons">
                      <button
                        className="btn btn-primary"
                        onClick={this.postData}
                      >
                        Simpan
                      </button>
                      <button
                        className="btn btn-danger mr-1 "
                        onClick={this.cancelData}
                      >
                        Batal
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      );
    }
  }
}

export default UpdateSchedule;
