import React, { Component } from "react";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";

import axios from "axios";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import AdminSidebar from "../../../AdminSidebar/AdminSidebar";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { API_URL } from "../../../../BaseUrlApi/Url";
import "./EditVaccine.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

const animatedComponents = makeAnimated();

class EditVaccine extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      immediate: true,
      isLoaded: false,

      setFocusOnError: true,
      clearInputOnReset: false,
      vaccine_name: "",
      vaccine_type: [],
      vaccine_types: [],
      vaccine_id: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoaded: true });

    let vaccineId = this.props.match.params.value;
    let getVaccine = await axios.get(`${API_URL}/admin/vaccines/${vaccineId}`, {
      headers,
    });
    let getVaccineTypes = await axios.get(`${API_URL}/admin/vaccine_types`, {
      headers,
    });
    let getVaccineTypes2 = getVaccineTypes.data.data.map((type) => ({
      value: type.id,
      label: type.name,
    }));
    this.setState({
      vaccine_name: getVaccine.data.data.brand_name,
      vaccine_type: getVaccine.data.data.vaccine_types.map(itemx => {
        return {
          value: itemx.id,
          label: itemx.name
        }
      }),
      vaccine_types: getVaccineTypes2,
      vaccine_id: vaccineId,
    });
    this.setState({ isLoaded: false });
  }

  vaccineHandler = (value) => {
    console.log(value);
    if(value === null) {
      this.setState({
        vaccine_type: []
      })
    } else {
      this.setState({
        vaccine_type: [...value]
      })
    }
  }
  vaccineNameHandler = async (e) => {
    await this.setState({
      vaccine_name: e.target.value,
    });
  };

  handleKeyPress = async (e) => {
    // e.preventDefault()
    if (e.key === "Enter") {
      this.updateVaccine();
    }
  };
  updateVaccine = async (e) => {
    const updateVaccine = {
      _method: "PATCH",
      vaccine_type_ids: this.state.vaccine_type.map(itemx => (
        itemx.value
      )),
      brand_name: this.state.vaccine_name,
    };

    axios
      .post(
        `${API_URL}/admin/vaccines/${this.state.vaccine_id}`,
        updateVaccine,
        { headers }
      )
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Daftar Vaksin Telah Berhasil Diubah!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = "/vaccines";
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };
  cancelVaccine = () => {
    window.location.href = "/vaccines";
  };
  render() {
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Ubah Vaksin</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Master Data</span>
                <span style={{ color: "#039BEB" }}>/ Daftar Vaksin</span>
                <span style={{ color: "#00000029" }}> / Ubah Vaksin</span>
              </h2>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <ValidationForm
                  onSubmit={this.updateVaccine.bind(this)}
                  ref={this.formRef}
                  immediate={this.state.immediate}
                  setFocusOnError={this.state.setFocusOnError}
                >
                  <table cellPadding="15" className="add-table">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Tipe Vaksin
                          </label>
                        </td>
                        <td>
                          :
                          <Select
                              // style={{width: '1rem'}}
                            isMulti
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            // components={{ Placeholder }}
                            placeholder={'Pilih Tipe Vaksin'}
                            // styles={{
                            // placeholder: base => ({
                            //     ...base,
                            //     // fontSize: '1em',
                            //     color: 'black',
                            //     // fontWeight: 400,
                            // }),
                            // }}
                            options={this.state.vaccine_types}
                            value={this.state.vaccine_type}
                            onChange={(value) => {
                                this.vaccineHandler(value)
                            }}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="add-vaccine-label">
                            Nama Vaksin
                          </label>
                        </td>
                        <td>
                          
                          :
                          <TextInput
                            type="text"
                            name="vaccineType"
                            onChange={this.vaccineNameHandler}
                            onKeyPress={this.handleKeyPress}
                            value={this.state.vaccine_name}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ValidationForm>
                <div className="patient-buttons">
                  <button
                    className="btn btn-primary"
                    onClick={this.updateVaccine}
                  >
                    Simpan
                  </button>
                  <button
                    className="btn btn-danger mr-1 "
                    onClick={this.cancelVaccine}
                  >
                    Batal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default EditVaccine;
