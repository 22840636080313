import { useMutation } from 'react-query';
import API from '../../config/axios';

const onDeleteQueue = async (queueId) => {
  const { data } = await API.delete(`/queues/${queueId}`);
  return data;
};

const useDeleteQueue = () => {
  return useMutation((id) => onDeleteQueue(id));
};

export default useDeleteQueue;
