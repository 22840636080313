import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css";

class Navbar extends Component {
  render() {
    return (
      <div className="navbar-body">
        {/* the prev developer distinguished the use of tag a and tag link based on the url props, which have different functions in this program*/}
        {this.props.url === "/checkup" ? (
          <a href={this.props.url}>
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </a>
        ) : (
          <Link to={this.props.url}>
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </Link>
        )}
        <p className="navbar-title">{this.props.navtitle}</p>
      </div>
    );
  }
}

export default Navbar;
