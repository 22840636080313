import API from '../../../../config/axios';

export const onGetDetailChild = (id) => {
  const response = API.get(`/children/${id}`)
    .then((res) => {
      return {
        data: res?.data?.child,
        error: false,
      };
    })
    .catch((err) => {
      return {
        data: [],
        error: true,
      };
    });

  return response;
};
