import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { API_URL } from '../../../BaseUrlApi/Url';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import './BlockedDates.css';

const { SearchBar } = Search;

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

function buttonsFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="detail-buttons">
      <button
        onClick={() => {
          swal({
            title: 'Yakin Ingin Menghapus?',
            icon: 'warning',
            buttons: ['Batal', 'Hapus'],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/blocked_dates/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = `/blocked-dates`;
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger patient-delete"
      >
        Hapus
      </button>
      <Link to={`/update-date/${row.id}`}>
        <button className="btn btn-primary patient-change">Ubah</button>
      </Link>
    </div>
  );
}

function dateFormatter(cell, row, rowIndex, formatExtraData) {
  let date = new Date(cell);
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '-' + mm + '-' + yyyy;
}
class BlockedDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      products: [],
      shift_type: null,
      columns: [
        {
          dataField: 'date',
          text: 'Tanggal',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
          formatter: dateFormatter,
        },
        {
          dataField: 'type',
          text: 'Tipe',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
        },
        {
          dataField: 'information',
          text: 'Informasi',
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center' },
        },
        {
          dataField: 'Aksi',
          text: 'Aksi',
          headerStyle: { textAlign: 'center' },
          style: { margin: 'auto' },
          formatter: buttonsFormatter,
        },
      ],
    };
  }
  async componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const shift_type = params.get('shift_type');

    this.setState({ shift_type });

    const blockDate = `${API_URL}/admin/blocked_dates`;
    const blockDateKonsultasiOnline = `${API_URL}/admin/blocked_dates?shift_type=${shift_type}`;

    const url = shift_type ? blockDateKonsultasiOnline : blockDate;

    this.setState({ isLoaded: true });
    let getBlockedDates = await axios.get(url, {
      headers,
    });
    this.setState({
      products: getBlockedDates.data.blockedDates,
    });
    this.setState({ isLoaded: false });
  }
  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total showing-button">
        Menampilkan {from} / {to} dari {size} Hasil
      </span>
    );
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5,
        },
        {
          text: '10',
          value: 10,
        },
        {
          text: 'All',
          value: this.state.products.length,
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <ToolkitProvider
              keyField="id"
              columns={this.state.columns}
              data={this.state.products}
              search={{ searchFormatted: true }}
            >
              {(toolkitprops) => (
                <div>
                  <br />
                  <SearchBar
                    {...toolkitprops.searchProps}
                    className="search-content"
                  />
                  <BootstrapTable
                    bordered={false}
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
    );
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay
          active={this.state.isLoaded}
          spinner
          text="Memuat data..."
        >
          <div className="content-section">
            <div className="title-section">
              <h1 className="patient-page-title">Blocked Dates</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: '#039BEB' }}>Master Data</span>
                <span style={{ color: '#00000029' }}> / Blocked Dates</span>
              </h2>
              <div className="add-button">
                <Link
                  to={
                    this.state.shift_type
                      ? `/add-date?shift_type=${this.state.shift_type}`
                      : '/add-date'
                  }
                >
                  <button className="btn btn-primary btn-add-table mb-2 mr-3">
                    Tambah Blocked Dates
                  </button>
                </Link>
              </div>
            </div>
            <PaginationProvider pagination={paginationFactory(options)}>
              {contentTable}
            </PaginationProvider>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default BlockedDates;
