import PropTypes from "prop-types";
import React, { Component } from "react";
import "./radio.css";

class Radio extends Component  {
  state = {
    // valueOther: ''
  };

  handleInputChange = event => {
    console.log(event)
    this.props.handleChangeOther(event.target.value)
  }
  handleChangeChoice = () => {
    this.props.handleChangeOther('')
  }

  render() {
    const { selected, onChange, text, value, valueOther } = this.props;
    console.log(this.props)
    return (
      <div
        className="modern-radio-container"
        onClick={() => {
          console.log(value)
          this.handleChangeChoice()
          onChange(value);
        }}
      >
        <div
          className={`radio-outer-circle ${value !== selected && "unselected"}`}
        >
          <div
            className={`radio-inner-circle ${value !== selected &&
              "unselected-circle"}`}
          />
        </div>
        {value !== 5
        ? 
        <div className={`helper-text ${value === selected && "selected-text"}`}>{text}</div>
        : <input type="text" className={`input-radio ${value === selected && "selected-input"}`}
          placeholder={text} value={valueOther} onChange={(e) => this.handleInputChange(e)} onClick={() => onChange(value)} />
        }
        
      </div>
    );
  }
}

// Radio.propTypes = {
//   text: PropTypes.node.isRequired,
//   onChange: PropTypes.func.isRequired,
//   selected: PropTypes.string.isRequired,
//   value: PropTypes.string.isRequired
// };

export default Radio