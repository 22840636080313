import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";
import "react-datepicker/dist/react-datepicker.css";
import AdminSidebar from "../../AdminSidebar/AdminSidebar";
import { API_URL } from "../../../BaseUrlApi/Url";
import "./UpdateUser.css";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  "Content-Type": "application/json",
};

class UpdateUser extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoaded: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      nurseId: null,
      bef_gender: "",
      gender: [],
      choose_gender: {},
      name: null,
      startDate: new Date(),
      birthdate: null,
      birthplace: "",
      address: "",
      phonenumber: null,
    };
  }
  async componentDidMount() {
    this.getGender();
    this.getUserData();
  }

  getUserData = async () => {
    this.setState({ isLoaded: true });
    let userId = this.props.match.params.value;
    let getUser = await axios.get(`${API_URL}/admin/users/${userId}`, {
      headers,
    });
    this.setState({
      name: getUser.data.user.nurse.name,
      bef_gender: getUser.data.user.nurse.bef_gender,
      birthplace: getUser.data.user.nurse.birthplace,
      birthdate: getUser.data.user.nurse.birthdate,
      address: getUser.data.user.nurse.address,
      phonenumber: getUser.data.user.nurse.phone_number,
      nurseId: getUser.data.user.nurse.id,
    });
    if (this.state.bef_gender === "male") {
      this.setState({
        choose_gender: {
          value: "male",
          label: "Laki-laki",
        },
      });
    } else {
      this.setState({
        choose_gender: {
          value: "female",
          label: "Perempuan",
        },
      });
    }
    this.setState({ isLoaded: false });
  };

  getGender = async () => {
    let gender = [
      {
        gender: "male",
        label: "Laki-laki",
      },
      {
        gender: "female",
        label: "Perempuan",
      },
    ];

    let getGender = gender.map((type) => ({
      value: type.gender,
      label: type.label,
    }));

    this.setState({
      gender: getGender,
    });
  };

  genderHandler = async (value) => {
    this.setState({
      choose_gender: {
        value: value.value,
        label: value.label,
      },
    });
  };

  selectedDate = (value) => {
    if (this.state.birthdate === null) {
      return "";
    } else {
      return new Date(this.state.birthdate);
    }
  };

  dateHandler = async (date) => {
    this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateConverter(this.state.startDate);
    }
  };

  dateConverter = (value) => {
    let date = value,
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join("-");
    this.setState({
      birthdate: convertDate,
    });
  };

  inputHandler = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  genderHandler = async (value) => {
    this.setState({
      choose_gender: {
        value: value.value,
        label: value.label,
      },
    });
  };

  saveUser = async (e) => {
    const datas = {
      _method: "PATCH",
      name: this.state.name,
      gender: this.state.choose_gender.value,
      birthdate: this.state.birthdate,
      birthplace: this.state.birthplace,
      phone_number: this.state.phonenumber,
      address: this.state.address,
    };
    axios
      .post(`${API_URL}/admin/nurses/${this.state.nurseId}`, datas, { headers })
      .then((res) => {
        swal({
          title: "Sukses!",
          text: "Data Perawat Telah Berhasil Diubah!",
          icon: "success",
          button: "OK",
        }).then((move) => {
          if (move) {
            window.location.href = `/users`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: "GAGAL",
          text: "Lengkapi semua data terlebih dahulu",
          icon: "warning",
          type: "error",
          buttons: "OK",
        });
      });
  };

  render() {
    let { isLoaded, name, birthplace, address, phonenumber } = this.state;
    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <div className="content-section">
            <div className="title-section mb-5">
              <h1 className="patient-page-title">Ubah Pengguna</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: "#039BEB" }}>Manajemen Pengguna</span>
                <span style={{ color: "#00000029" }}> / Ubah Pengguna</span>
              </h2>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <ValidationForm
                  onSubmit={this.saveUser.bind(this)}
                  ref={this.formRef}
                  immediate={this.state.immediate}
                  setFocusOnError={this.state.setFocusOnError}
                >
                  <table cellPadding="10" className="add-table text-left pl-2">
                    <tbody>
                      <p className="account-title ml-2">Data Pribadi</p>
                      <tr>
                        <td>
                          <label htmlFor="" className="label-update">
                            Nama
                          </label>
                        </td>
                        <td>
                          
                          :
                          <TextInput
                            type="text"
                            name="name"
                            value={name}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="label-update">
                            Jenis Kelamin
                          </label>
                        </td>
                        <td>
                          
                          :
                          <Select
                            options={this.state.gender}
                            value={this.state.choose_gender}
                            onChange={(value) => this.genderHandler(value)}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="label-update">
                            Tempat Lahir
                          </label>
                        </td>
                        <td>
                          
                          :
                          <TextInput
                            type="text"
                            name="birthplace"
                            value={birthplace}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="label-update">
                            Tanggal Lahir
                          </label>
                        </td>
                        <td>
                          
                          :
                          <DatePicker
                            selected={this.selectedDate()}
                            onChange={this.dateHandler}
                            dateFormat="dd-MM-yyyy"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="label-update">
                            Alamat
                          </label>
                        </td>
                        <td>
                          
                          :
                          <TextInput
                            type="text"
                            name="address"
                            value={address}
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="" className="label-update">
                            No. Telp
                          </label>
                        </td>
                        <td>
                          
                          :
                          <TextInput
                            type="number"
                            value={phonenumber}
                            name="phonenumber"
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ValidationForm>
                <div className="patient-buttons">
                  <button
                    className="btn btn-primary mr-1"
                    onClick={this.saveUser}
                  >
                    Simpan
                  </button>
                  <Link to="/users">
                    <button className="btn btn-danger mr-1">Batal</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}
export default UpdateUser;
