import React, { Component } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import swal from 'sweetalert';
import LoadingOverlay from 'react-loading-overlay';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import productsGenerator from 'react-bootstrap-table2-paginator';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import { API_URL } from '../../../BaseUrlApi/Url';
import './CheckupHistory.css';
import DynamicVaccine from '../../../CustomJS/DynamicVaccine';
import API from '../../../../config/axios';
import _ from 'lodash';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
};

function vaccinationFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="detail-buttons">
      <button
        onClick={() => {
          swal({
            title: 'Yakin Ingin Menghapus?',
            icon: 'warning',
            buttons: ['Batal', 'Hapus'],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/vaccinations/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = `/checkup-history/${row.child_id}`;
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger mr-1"
      >
        Hapus
      </button>
    </div>
  );
}

function dateFormatter(cell, row, rowIndex, formatExtraData) {
  let date = new Date(row.date_taken);
  let dd = date.getDate();
  let mm = date.getMonth() + 1; //January is 0!

  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  let set_date = dd + '-' + mm + '-' + yyyy;
  return set_date;
}

function measurementFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="detail-buttons">
      <button
        onClick={() => {
          swal({
            title: 'Yakin Ingin Menghapus?',
            icon: 'warning',
            buttons: ['Batal', 'Hapus'],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/physical_measurements/${rowId}`, {
                  headers,
                })
                .then((res) => {
                  window.location.href = `/checkup-history/${row.child_id}`;
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger mr-1"
      >
        Hapus
      </button>
    </div>
  );
}

function visitFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <div className="detail-buttons">
      <button
        onClick={() => {
          swal({
            title: 'Yakin Ingin Menghapus?',
            icon: 'warning',
            buttons: ['Batal', 'Hapus'],
            dangerMode: true,
          }).then((move) => {
            if (move) {
              let rowId = row.id;
              axios
                .delete(`${API_URL}/admin/visits/${rowId}`, { headers })
                .then((res) => {
                  window.location.href = `/checkup-history/${row.child_id}`;
                });
            } else {
              return;
            }
          });
        }}
        className="btn btn-danger mr-1"
      >
        Hapus
      </button>
    </div>
  );
}

class CheckupHistory extends Component {
  state = {
    vaccination_type_ids: [],
    openVaccination: false,
    openVisit: false,
    openMeasurement: false,
    isLoaded: false,
    childId: null,
    parentId: null,
    fullname: null,
    gender: null,
    warning: '',
    choose_gender: '',
    parent_name: '',
    birthdate: '',
    birthplace: '',
    age: '',
    date_taken: '',
    image_path: null,

    // FORM
    immediate: true,
    setFocusOnError: true,
    clearInputOnReset: false,
    vaccine_type: {},
    vaccine_types: [],
    vaccines: [],
    vaccine: {},
    period: null,
    batch: null,
    products: [],
    vaccination_date_taken: null,
    excludeDate: [],

    // VISIT FORM
    visit_types: [],
    visit_type: {},
    vaccine_brand: {},
    dynamic_vaccine: [],
    isHistory: false,
    vaccineDatas: [],
    lastChoice: 0,
    visit_date_taken: null,
    startDate: new Date(),

    // MEASUREMENT FORM
    body_weight: null,
    body_length: null,
    head_circumference: null,
    temperature: null,
    measurement_date_taken: null,

    columns: [
      {
        dataField: 'date_taken',
        text: 'Tanggal Pemeriksaan',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: dateFormatter,
      },
      {
        dataField: 'vaccine_types',
        text: 'Vaksin / Periode',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      {
        dataField: 'vaccine.brand_name',
        text: 'Jenis Obat',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      {
        dataField: 'batch',
        text: 'Batch',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      // {
      //   dataField: "period",
      //   text: "Periode",
      //   headerStyle: { textAlign: "center" },
      //   style: { textAlign: "center" },
      // },
      {
        dataField: 'Aksi',
        text: 'Aksi',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: vaccinationFormatter,
      },
    ],
    measurement_products: [],
    measurement_columns: [
      {
        dataField: 'date_taken',
        text: 'Tanggal Pemeriksaan',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: dateFormatter,
      },
      {
        dataField: 'body_weight',
        text: 'Berat Badan',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      {
        dataField: 'body_length',
        text: 'Tinggi Badan',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      {
        dataField: 'head_circumference',
        text: 'Lingkar Kepala',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      {
        dataField: 'temperature',
        text: 'Temperatur',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      {
        dataField: 'Aksi',
        text: 'Aksi',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: measurementFormatter,
      },
    ],
    visit_products: [],
    visit_columns: [
      {
        dataField: 'date_taken',
        text: 'Tanggal Pemeriksaan',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: dateFormatter,
      },
      {
        dataField: 'visit_type.name',
        text: 'Tipe Kunjungan',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      {
        dataField: 'Aksi',
        text: 'Aksi',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: visitFormatter,
      },
    ],
  };

  async componentDidMount() {
    this.setState({ isLoaded: true });
    let childId = parseInt(this.props.match.params.value);

    // GET CHILD DATA
    let getData = await axios.get(`${API_URL}/admin/children/${childId}`, {
      headers,
    });
    this.setState({
      fullname: getData.data.child.fullname,
      gender: getData.data.child.gender,
      birthplace: getData.data.child.birthplace,
      birthdate: getData.data.child.birthdate,
      childId: childId,
      image_path: getData.data.child.image_url,
      age: getData.data.child.age,
      parentId: parseInt(getData.data.child.parent_id),
    });

    // GET FINISHED VACCINATIONS
    // let getFinishedVaccinations = await axios.get(
    //   `${API_URL}/admin/vaccinations/finishedVaccinations`,
    //   { headers }
    // );
    const { data: dataDates } = await axios.get(`${API_URL}/blocked_dates`, {
      headers,
    });

    const { data: dataDatesKonsulOnline } = await axios.get(
      `${API_URL}/blocked_dates?shift_type=konsultasi_online`,
      {
        headers,
      }
    );

    let filterBlockedDatesKonsultasiOnline =
      await dataDatesKonsulOnline.blockedDates.map((item) =>
        Date.parse(new Date(item.date).toUTCString())
      );

    let filterBlockedDates = await dataDates.blockedDates.map((item) =>
      Date.parse(new Date(item.date).toUTCString())
    );

    this.setState({
      excludeDate: [
        ...filterBlockedDates,
        ...filterBlockedDatesKonsultasiOnline,
      ],
    });

    // GET VACCINATION HISTORY
    let getVaccinationHistory = await axios.get(
      `${API_URL}/admin/vaccinations/finishedVaccinations?child_id=${childId}`,
      { headers }
    );

    if (getVaccinationHistory.data.vaccinations.length > 0) {
      let filterVaccinationData = getVaccinationHistory.data.vaccinations.map(
        (items) => {
          return {
            ...items,
            vaccine_types: items.vaccine_types
              .map(
                (itemx, i) =>
                  `(${i + 1}). ${itemx.name} - Periode: Ke-${
                    itemx.pivot.period
                  }`
              )
              .join('; '),
          };
        }
      );
      await this.setState({
        products: filterVaccinationData,
      });
    }

    // GET MEASUREMENT HISTORY
    let getMeasurementHistory = await axios.get(
      `${API_URL}/admin/physical_measurements?child_id=${childId}`,
      { headers }
    );
    this.setState({
      measurement_products: getMeasurementHistory.data.physicalMeasurements,
    });

    // GET VACCINATION HISTORY
    let getVisitsHistory = await axios.get(
      `${API_URL}/admin/visits/finishedVisits?child_id=${childId}`,
      { headers }
    );
    this.setState({
      visit_products: getVisitsHistory.data.visits,
    });

    this.dateChanger(this.state.birthdate);
    this.setState({ isLoaded: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState) === JSON.stringify(this.state)) return null;
    const { childId, vaccination_type_ids } = this.state;

    if (childId && !!vaccination_type_ids.length) {
      API.get('/vaccinations/next-vaccination-period', {
        params: {
          child_id: this.state.childId,
          vaccine_type_ids: vaccination_type_ids.join(',').toString(),
        },
      })
        .then((res) => {
          const tempGeneratePeriodVaccine = _.map(
            this.state?.dynamic_vaccine,
            (user) => {
              const vaccineId = user.vaccine_selected.value;
              const matchedVaccine = _.find(res?.data?.vaccine_types, {
                id: vaccineId,
              });
              if (matchedVaccine) {
                return _.assign({}, user, {
                  period: matchedVaccine.next_vaccination_period,
                });
              }
              return user;
            }
          );

          this.setState({
            dynamic_vaccine: [...tempGeneratePeriodVaccine],
          });
        })
        .catch((err) => console.log(err));
    }
  }

  loadData = () => {
    this.setState({
      products: productsGenerator(17),
    });
  };

  dateChanger = (date) => {
    let today = new Date(date);
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!

    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let set_today = dd + '-' + mm + '-' + yyyy;
    this.setState({
      birthdate: set_today,
    });
  };

  vaccineChangeHandler = (vaccine_id, newVaccineSelected, newPeriod) => {
    const newVaccine = {
      id: vaccine_id,
      vaccine_selected: {
        value: newVaccineSelected.value,
        label: newVaccineSelected.label,
      },
      period: newPeriod,
    };

    const oldVaccine = this.state.dynamic_vaccine.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    let newVaccines = [...oldVaccine, newVaccine];
    newVaccines.sort((a, b) => a.id - b.id);
    this.setState({
      dynamic_vaccine: newVaccines,
    });
  };

  vaccineRemoveHandler = (vaccine_id) => {
    const vaccinesValue = [...this.state.dynamic_vaccine];
    const newVaccines = vaccinesValue.filter(
      (vaccine) => vaccine.id !== vaccine_id
    );
    this.setState({
      dynamic_vaccine: newVaccines,
    });
  };

  vaccineAddHandler = () => {
    let objValue = this.state.dynamic_vaccine.find(
      (c) => c.period === '' || c.vaccine_selected === null
    );
    if (objValue !== undefined) {
      return;
    }

    const newVaccine = {
      id: this.state.lastChoice + 1,
      vaccine_selected: null,
      period: '',
    };
    this.setState({
      lastChoice: this.state.lastChoice + 1,
      dynamic_vaccine: [...this.state.dynamic_vaccine, newVaccine],
    });
  };

  // === CREATE VACCINATION HISTORY ===
  showVaccination = async (e) => {
    // let getVaccineTypes = await axios.get(`${API_URL}/admin/vaccine_types`, {
    //   headers,
    // });

    let getVaccines = await axios.get(`${API_URL}/admin/vaccines`, {
      headers,
    });

    // let vaccine_types = getVaccineTypes.data.data.map((type) => ({
    //   value: type.id,
    //   label: type.name,
    // }));

    const newVaccine = [
      {
        id: this.state.lastChoice + 1,
        vaccine_selected: null,
        period: '',
      },
    ];

    this.setState({
      vaccineDatas: getVaccines.data.vaccines,
      isHistory: true,
      // vaccine_types: vaccine_types,
      openVaccination: !this.state.openVaccination,
      vaccines: getVaccines.data.vaccines.map((itemj) => {
        return {
          value: itemj.id,
          label: itemj.brand_name,
        };
      }),
      // vaccine_types: vaccine_types,
      lastChoice: this.state.lastChoice + 1,
      dynamic_vaccine: newVaccine,
    });
  };

  hideVaccination = (e) => {
    this.setState({ openVaccination: false });
  };

  inputHandler = async (e) => {
    e.persist();
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  selectedVaccinationDate = (value) => {
    if (this.state.vaccination_date_taken === null) {
      return '';
    } else {
      return new Date(this.state.vaccination_date_taken);
    }
  };

  dateVaccinationHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateVaccinationConverter(this.state.startDate);
    }
  };

  dateVaccinationConverter = (value) => {
    let date = value,
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join('-');
    this.setState({
      vaccination_date_taken: convertDate,
    });
  };

  vaccineTypeHandler = async (value) => {
    await this.setState({
      vaccine_type: {
        value: value.value,
        label: value.label,
      },
    });
    // GET VACCINES
    let getVaccines = await axios.get(
      `${API_URL}/admin/vaccines?vaccine_type_id=${value.value}`,
      { headers }
    );
    let vaccines = getVaccines.data.vaccines.map((vaccine) => ({
      value: vaccine.id,
      label: vaccine.brand_name,
    }));

    this.setState({
      vaccines: vaccines,
    });
  };

  vaccineHandler = async (value) => {
    await this.setState({
      vaccine: {
        value: value.value,
        label: value.label,
      },
    });
  };

  saveVaccination = async (e) => {
    e.preventDefault();

    const postVaccination = {
      child_id: this.state.childId,
      vaccine_types: this.state.dynamic_vaccine.map((item) => {
        return {
          id: item.vaccine_selected.value,
          period: item.period,
        };
      }),
      vaccine_id: this.state.vaccine_brand.value,
      batch: this.state.batch,
      date_taken: moment(this.state.date_taken).format('YYYY-MM-DD'),
      date_plan: moment(this.state.date_taken).format('YYYY-MM-DD'),
    };
    axios
      .post(`${API_URL}/admin/vaccinations`, postVaccination, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Vaksinasi Berhasil Ditambahkan!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/checkup-history/${this.state.childId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Lengkapi semua data terlebih dahulu',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };

  // === CREATE VACCINATION HANDLER END ===

  // CREATE VISIT HANDLER START
  showVisit = async (e) => {
    let getVisitTypes = await axios.get(`${API_URL}/admin/visit_types`, {
      headers,
    });
    let visit_types = getVisitTypes.data.visitTypes.map((type) => ({
      value: type.id,
      label: type.name,
    }));
    this.setState({
      visit_types: visit_types,
      openVisit: !this.state.openVisit,
    });
  };

  hideVisit = (e) => {
    this.setState({ openVisit: false });
  };

  selectedVisitDate = (value) => {
    if (this.state.visit_date_taken === null) {
      return '';
    } else {
      return new Date(this.state.visit_date_taken);
    }
  };

  dateVisitHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateVisitConverter(this.state.startDate);
    }
  };

  dateVisitConverter = (value) => {
    let date = value,
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join('-');
    this.setState({
      visit_date_taken: convertDate,
    });
  };

  visitTypeHandler = async (value) => {
    await this.setState({
      visit_type: {
        value: value.value,
        label: value.label,
      },
    });
  };

  saveVisit = async (e) => {
    e.preventDefault();
    const postVisit = {
      child_id: this.state.childId,
      visit_type_id: this.state.visit_type.value,
      date_taken: this.state.visit_date_taken,
      date_plan: this.state.visit_date_taken,
    };

    axios
      .post(`${API_URL}/admin/visits`, postVisit, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Kunjungan Berhasil Ditambahkan!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/checkup-history/${this.state.childId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Lengkapi semua data terlebih dahulu',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };

  // CREATE VISIT HANDLER END

  // CREATE MEASUREMENT HANDLER START
  showMeasurement = async (e) => {
    this.setState({
      openMeasurement: !this.state.openMeasurement,
    });
  };

  hideMeasurement = (e) => {
    this.setState({ openMeasurement: false });
  };

  selectedMeasurementDate = (value) => {
    if (this.state.measurement_date_taken === null) {
      return '';
    } else {
      return new Date(this.state.measurement_date_taken);
    }
  };

  dateMeasurementHandler = async (date) => {
    await this.setState({
      startDate: date,
    });
    if (this.state.startDate !== null) {
      this.dateMeasurementConverter(this.state.startDate);
    }
  };

  dateMeasurementConverter = (value) => {
    let date = value,
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let convertDate = [date.getFullYear(), mnth, day].join('-');
    this.setState({
      measurement_date_taken: convertDate,
    });
  };
  saveMeasurement = async () => {
    let saveData = {
      child_id: this.state.childId,
      date_taken: this.state.measurement_date_taken,
      body_weight: parseFloat(this.state.body_weight),
      body_length: parseFloat(this.state.body_length),
      temperature: parseFloat(this.state.temperature),
      head_circumference: parseFloat(this.state.head_circumference),
    };

    axios
      .post(`${API_URL}/admin/physical_measurements`, saveData, { headers })
      .then((res) => {
        swal({
          title: 'Sukses!',
          text: 'Pengukuran Berhasil Ditambahkan!',
          icon: 'success',
          button: 'OK',
        }).then((move) => {
          if (move) {
            window.location.href = `/checkup-history/${this.state.childId}`;
          } else {
            return;
          }
        });
      })
      .catch((err) => {
        swal({
          title: 'GAGAL',
          text: 'Lengkapi semua data terlebih dahulu',
          icon: 'warning',
          type: 'error',
          buttons: 'OK',
        });
      });
  };

  render() {
    let {
      isLoaded,
      immediate,
      setFocusOnError,
      parentId,
      fullname,
      gender,
      birthdate,
      birthplace,
      age,
      products,
      columns,
      measurement_columns,
      measurement_products,
      visit_products,
      visit_columns,
      image_path,

      // VACCINATION
      openVaccination,
      vaccine_types,
      vaccine_type,
      vaccines,
      vaccine,
      vaccine_brand,
      lastChoice,
      isHistory,
      vaccineDatas,
      dynamic_vaccine,

      // VISIT
      openVisit,
      visit_types,
      visit_type,

      // MEASUREMENT
      // body_length,
      // body_weight,
      // head_circumference,
      // temperature,
      openMeasurement,
    } = this.state;

    const isWeekday = (date) => {
      // console.log(date)
      const day = date.getDay();
      return day !== 0;
    };

    return (
      <div className="admin-overlay">
        <AdminSidebar />
        <LoadingOverlay active={isLoaded} spinner text="Memuat data...">
          <div className="content-section">
            <div className="title-section">
              <h1 className="patient-page-title">Riwayat Pemeriksaan</h1>
              <h2 className="patient-page-subtitle">
                <span style={{ color: '#039BEB' }}>Manajemen Anak</span>
                <span style={{ color: '#039BEB' }}>
                  / Daftar Kepala Keluarga
                </span>
                <span style={{ color: '#039BEB' }}>
                  / Detail Kepala Keluarga
                </span>
                <span style={{ color: '#00000029' }}>
                  / Riwayat Pemeriksaan
                </span>
              </h2>
            </div>

            <div className="card shadow mb-4 mt-5">
              <div className="card-body text-left">
                <table cellPadding="10" className="parent-data mb-5">
                  <tbody>
                    <tr>
                      <td>Nama</td>
                      <td>: {fullname}</td>
                    </tr>
                    <tr>
                      <td>Umur</td>
                      <td>: {age}</td>
                    </tr>
                    <tr>
                      <td>Jenis Kelamin</td>
                      <td>
                        :
                        {gender !== null ? (
                          gender === 'male' ? (
                            ' Laki-laki'
                          ) : (
                            ' Perempuan'
                          )
                        ) : (
                          <span className="text-danger">Tidak Terisi</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Tanggal Lahir</td>
                      <td>: {birthdate !== null ? birthdate : '-'}</td>
                    </tr>
                    <tr>
                      <td>Tempat Lahir</td>
                      <td> : {birthplace !== null ? birthplace : '-'}</td>
                    </tr>
                  </tbody>
                </table>

                {image_path !== null ? (
                  <div className="image-container">
                    <img src={image_path} className="patient-photo" alt="" />
                  </div>
                ) : (
                  <div className="no-photo">
                    <p className="no-photo-text">Belum Ada Foto</p>
                  </div>
                )}

                <div className="checkup-table mb-5">
                  <p className="family-members">
                    Riwayat Vaksinasi {products.length > 0 ? '' : ''}
                  </p>
                  <button
                    className="btn btn-primary btn-checkup"
                    onClick={this.showVaccination}
                  >
                    Tambahkan Riwayat Vaksinasi
                  </button>
                  <BootstrapTable
                    bordered={false}
                    keyField="id"
                    data={products}
                    columns={columns}
                    pagination={paginationFactory()}
                  />
                </div>

                <div className="checkup-table mb-5">
                  <p className="family-members">
                    Riwayat Kunjungan {visit_products.length > 0 ? '' : ''}
                  </p>
                  <button
                    className="btn btn-primary btn-checkup"
                    onClick={this.showVisit}
                  >
                    Tambahkan Riwayat Kunjungan
                  </button>
                  <BootstrapTable
                    bordered={false}
                    keyField="id"
                    data={visit_products}
                    columns={visit_columns}
                    pagination={paginationFactory()}
                  />
                </div>

                <div className="checkup-table mb-5">
                  <p className="family-members">
                    Riwayat Pengukuran
                    {measurement_products.length > 0 ? '' : ''}
                  </p>
                  <button
                    className="btn btn-primary btn-checkup"
                    onClick={this.showMeasurement}
                  >
                    Tambahkan Riwayat Pengukuran
                  </button>
                  <BootstrapTable
                    bordered={false}
                    keyField="id"
                    data={measurement_products}
                    columns={measurement_columns}
                    pagination={paginationFactory()}
                  />
                </div>

                <div className="patient-buttons mt-5">
                  <Link to={`/detail-patient/${parentId}`}>
                    <button
                      className="btn btn-secondary back-button"
                      style={{ color: 'white' }}
                    >
                      Kembali
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            {/* VACCINATION MODAL SECTION */}
            <Modal
              show={openVaccination}
              onHide={this.hideVaccination}
              className="history-modal"
            >
              <Modal.Header>
                <Modal.Title>
                  <h1 className="note-child-title">BUAT RIWAYAT VAKSINASI</h1>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ValidationForm
                  onSubmit={this.saveVaccination.bind(this)}
                  ref={this.formRef}
                  immediate={immediate}
                  setFocusOnError={setFocusOnError}
                >
                  <table cellPadding="10" className="add-table">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="">Merk Vaksin</label>
                        </td>
                        <td>
                          :
                          <Select
                            placeholder={'Pilih Merek Vaksin'}
                            options={vaccines}
                            value={vaccine_brand}
                            onChange={(value) => {
                              console.log(value);
                              let filterVaccineType = vaccineDatas.filter(
                                (x) => x.id === value.value
                              )[0];
                              // console.log(vaccineDatas.filter(x => x.id === value.value)[0])
                              const tempVaccineType =
                                filterVaccineType?.vaccine_types?.map(
                                  (item) => item?.id
                                );

                              this.setState({
                                isHistory: true,
                                vaccine_brand: value,
                                vaccine_types:
                                  filterVaccineType.vaccine_types.map(
                                    (itemj) => {
                                      return {
                                        value: itemj.id,
                                        label: itemj.name,
                                      };
                                    }
                                  ),
                                vaccination_type_ids: [...tempVaccineType],
                                dynamic_vaccine:
                                  filterVaccineType.vaccine_types.map(
                                    (itemg, i) => {
                                      return {
                                        id: i + 1,
                                        vaccine_selected: {
                                          value: itemg.id,
                                          label: itemg.name,
                                        },
                                        period: '',
                                      };
                                    }
                                  ),
                                lastChoice:
                                  filterVaccineType.vaccine_types.length,
                              });
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: 'baseline' }}
                          className="label-class"
                        >
                          <label htmlFor="" className="add-vaccine-label">
                            Vaksin dan Periode
                          </label>
                          <br />
                          <button
                            style={{
                              display:
                                vaccine_types.length > 1 && !isHistory
                                  ? ''
                                  : 'none',
                            }}
                            className="btn btn-success mr-1"
                            onClick={() => {
                              this.vaccineAddHandler();
                            }}
                          >
                            Tambah Data
                          </button>
                        </td>
                        <td style={{ paddingLeft: isHistory ? '2rem' : '' }}>
                          {dynamic_vaccine.map((vaccine, i) => (
                            <DynamicVaccine
                              isHistory={isHistory}
                              vaccineTypes={vaccine_types}
                              dynamic_vaccine={dynamic_vaccine}
                              key={i}
                              vaccine_id={vaccine.id}
                              vaccine_selected={vaccine.vaccine_selected}
                              period={vaccine.period}
                              vaccineChangeHandler={this.vaccineChangeHandler}
                              vaccineRemoveHandler={this.vaccineRemoveHandler}
                            />
                          ))}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="">Batch </label>
                        </td>
                        <td>
                          :
                          <input
                            type="text"
                            name="batch"
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="">Tanggal Pengambilan Data</label>
                        </td>
                        <td>
                          :
                          <DatePicker
                            selected={this.state.date_taken}
                            onChange={(e) => {
                              console.log(e);
                              this.setState({ date_taken: e });
                            }}
                            excludeDates={this.state.excludeDate}
                            filterDate={isWeekday}
                            // minDate={new Date()}
                            dateFormat="dd MMMM, yyyy"
                            placeholderText={'Tanggal Pengambilan Data'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ValidationForm>
              </Modal.Body>
              <Modal.Footer>
                <div className="patient-buttons">
                  <button
                    className="btn btn-primary mr-1"
                    onClick={this.saveVaccination}
                  >
                    Simpan
                  </button>
                  <button
                    className="btn btn-danger mr-1 "
                    onClick={this.hideVaccination}
                  >
                    Batal
                  </button>
                </div>
              </Modal.Footer>
            </Modal>

            {/* VISIT MODAL SECTION */}
            <Modal
              show={openVisit}
              onHide={this.hideVisit}
              className="history-modal"
            >
              <Modal.Header>
                <Modal.Title>
                  <h1 className="note-child-title">BUAT RIWAYAT KUNJUGAN</h1>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ValidationForm
                  onSubmit={this.saveVisit.bind(this)}
                  ref={this.formRef}
                  immediate={immediate}
                  setFocusOnError={setFocusOnError}
                >
                  <table cellPadding="10" className="add-table">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="">Tipe Kunjungan</label>
                        </td>
                        <td>
                          :
                          <Select
                            options={visit_types}
                            value={visit_type}
                            onChange={(value) => this.visitTypeHandler(value)}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="">Tanggal Pengambilan Data</label>
                        </td>
                        <td>
                          :
                          <DatePicker
                            selected={this.selectedVisitDate()}
                            onChange={this.dateVisitHandler}
                            dateFormat="dd-MM-yyyy"
                            className="add-fam-date"
                            required
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ValidationForm>
              </Modal.Body>
              <Modal.Footer>
                <div className="patient-buttons">
                  <button
                    className="btn btn-primary mr-1"
                    onClick={this.saveVisit}
                  >
                    Simpan
                  </button>
                  <button
                    className="btn btn-danger mr-1"
                    onClick={this.hideVisit}
                  >
                    Batal
                  </button>
                </div>
              </Modal.Footer>
            </Modal>

            {/* MEASUREMENT MODAL SECTION */}
            <Modal show={openMeasurement} onHide={this.hideMeasurement}>
              <Modal.Header>
                <Modal.Title>
                  <h1 className="note-child-title">BUAT RIWAYAT PENGUKURAN</h1>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ValidationForm
                  onSubmit={this.saveMeasurement.bind(this)}
                  ref={this.formRef}
                  immediate={immediate}
                  setFocusOnError={setFocusOnError}
                >
                  <table cellPadding="10" className="add-table">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="">Berat Badan (kg)</label>
                        </td>
                        <td>
                          :
                          <input
                            type="number"
                            name="body_weight"
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="">Panjang Badan (cm)</label>
                        </td>
                        <td>
                          :
                          <input
                            type="number"
                            name="body_length"
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="">Lingkar Kepala (cm)</label>
                        </td>
                        <td>
                          :
                          <input
                            type="number"
                            name="head_circumference"
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="">Temperatur</label>
                        </td>
                        <td>
                          :
                          <input
                            type="number"
                            name="temperature"
                            onChange={this.inputHandler}
                            className="add-vaccine-input"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="">Tanggal Pengambilan Data</label>
                        </td>
                        <td>
                          :
                          <DatePicker
                            selected={this.selectedMeasurementDate()}
                            onChange={this.dateMeasurementHandler}
                            dateFormat="dd-MM-yyyy"
                            className="add-fam-date"
                            required
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ValidationForm>
              </Modal.Body>
              <Modal.Footer>
                <div className="patient-buttons">
                  <button
                    className="btn btn-primary mr-1"
                    onClick={this.saveMeasurement}
                  >
                    Simpan
                  </button>
                  <button
                    className="btn btn-danger mr-1"
                    onClick={this.hideMeasurement}
                  >
                    Batal
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default CheckupHistory;
