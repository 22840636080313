import API from '../../../../config/axios';

export const onGetDetailRequestCertificate = (id) => {
  const response = API.get(`/vaccination-certificate-requests/${id}`)
    .then((res) => {
      return {
        data: res?.data,
        error: false,
      };
    })
    .catch((err) => {
      return {
        data: [],
        error: true,
      };
    });

  return response;
};
