import React, { useEffect } from 'react';
import './CheckIsOver.css';

import useCountdown from '@bradgarropy/use-countdown';
import ICTimeOver from '../../../../../assets/images/ic-timeover.png';

export const ModalCheckinIsOver = ({ isOpen, onBack, onClose }) => {
  const countdown = useCountdown({
    seconds: 15,
    format: 'ss',
    onCompleted: () => onBack(),
  });

  useEffect(() => {
    if (!isOpen) {
      countdown.pause();
    } else {
      countdown.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div
      className="container-is-over"
      style={{ display: isOpen ? 'flex' : 'none' }}
    >
      <div className="wrapper-is-over">
        <div className="img-wrapper-is-over">
          <img src={ICTimeOver} alt="ic-timeover.png" />
        </div>

        <p className="title-is-over">Sesi Check In Berakhir</p>
        <p className="sub-title-is-over">
          Sesi check in akan berakhir, dan akan kembali ke halam utama
          dalam&nbsp;
          {countdown.formatted} detik
        </p>

        <button
          type="button"
          class="btn btn-primary button-is-over"
          onClick={onClose}
        >
          Lanjutkan Check In
        </button>
      </div>
    </div>
  );
};
