export const useFilterShifts = () => {
  const getShiftId = (data, type) => {
    return data
      ?.filter((item) => item?.type === type)
      ?.map((shift) => shift?.id);
  };

  return {
    getShiftId,
  };
};
