import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message,
  Upload,
} from 'antd';
import React, { useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useLocation, useParams } from 'react-router-dom';
import ICFailed from '../../../assets/images/ic-failed.png';
import ICSuccess from '../../../assets/images/ic-success.png';
import ICUpload from '../../../assets/images/ic-upload.png';
import ICFile from '../../../assets/images/ic-file.png';
import API from '../../../config/axios';
import Spacer from '../../Spacer';
import AdminSidebar from '../AdminSidebar/AdminSidebar';
import './DetailRequestCertificate.css';
import { TableHistory } from './components/TableHistory/TableHistory';
import { useDetailChild } from './hooks/useDetailChild';
import { useDetailRequestCertificate } from './hooks/useDetailRequestCertificate';
import { findGetParameter } from '../../../utils/findGetParameter';
import TemplateCertificate from './components/TemplateCertificate';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { CloseCircleFilled } from '@ant-design/icons';

const { Title, Text } = Typography;
const { Dragger } = Upload;

export const DetailRequestCertificate = () => {
  const { id } = useParams();
  const childId = findGetParameter('child');
  const reportTemplateRef = useRef(null);

  // this state for rejected
  const [isShowReasonModal, setIsShowReasonModal] = useState(false);
  const [isShowFormReason, setIsShowFormReason] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reason, setReason] = useState('');
  const [certificateData, setCertificateData] = useState(null);

  const [isShowModalSendCertificate, setIsShowModalSendCertificate] =
    useState(false);
  const [fileCertificate, setFileCertificate] = useState(null);

  // this state for send certificate
  const [isShowModalSuccessSend, setIsShowModalSuccessSend] = useState(false);

  const {
    dataDetail,
    childDetail,
    finishedVaccinations,
    parentsDetail,
    isLoading,
    refetch,
  } = useDetailRequestCertificate(id);
  const { vaccinationHistory, isLoading: isLoadingChildDetail } =
    useDetailChild(childId);

  const onRejectRequest = () => {
    setIsSubmitting(true);
    const payload = { reason };
    API.post(`/vaccination-certificate-requests/reject/${id}`, payload)
      .then(() => {
        setIsShowFormReason(false);
        refetch();
      })
      .catch((err) => err)
      .finally(() => setIsSubmitting(false));
  };

  const onSendCertificate = () => {
    setIsSubmitting(true);
    const tempFileUploaded = fileCertificate?.[0]?.originFileObj;
    delete tempFileUploaded?.uid;

    const formData = new FormData();
    formData.append('vaccination_certificate_request_id', id);
    formData.append('certificate_file', tempFileUploaded);

    API.post(`/vaccination-certificate-requests/upload`, formData)
      .then(() => {
        setIsShowModalSendCertificate(false);
        setIsShowModalSuccessSend(true);
        refetch();
      })
      .catch((err) => err)
      .finally(() => setIsSubmitting(false));
  };

  const onDownloadCertificate = () => {
    API.post(`/vaccination-certificate-requests/process/${id}`)
      .then((res) => {
        setCertificateData(res.data);
        onSaveCertificate();
      })
      .catch((err) => err);
  };

  const onSaveCertificate = useReactToPrint({
    documentTitle: `CSI-${
      dataDetail?.vaccination_certificate_request?.child?.fullname || ''
    }-${new Date().getTime()}`,
    content: () => reportTemplateRef.current,
  });

  return (
    <div className="admin-overlay">
      <AdminSidebar />
      <LoadingOverlay
        active={isLoading || isLoadingChildDetail}
        spinner
        text="Memuat data..."
      >
        <div className="content-section">
          <div className="detail-request-certificate-container">
            <Row justify="space-between" align="middle">
              <Col>
                <div className="detail-request-certificate-title-wrapper">
                  <Title level={3} style={{ margin: 0, textAlign: 'left' }}>
                    Request Sertifikat a/n{' '}
                    {dataDetail?.vaccination_certificate_request?.child
                      ?.fullname || '-'}
                  </Title>
                  <Spacer height={8} />
                  <Breadcrumb style={{ textAlign: 'left' }}>
                    <Breadcrumb.Item>
                      <a href="/admin-dashboard">Dashboard</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <a href="/request-certificate">Request Sertifikat</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {dataDetail?.vaccination_certificate_request?.child
                        ?.fullname || '-'}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              {dataDetail?.vaccination_certificate_request?.status !== 'sent' &&
                dataDetail?.vaccination_certificate_request?.status !==
                  'rejected' &&
                !isLoading && (
                  <Col>
                    <Space size="small">
                      <Button
                        size="large"
                        ghost
                        danger
                        type="primary"
                        onClick={() => setIsShowReasonModal(true)}
                      >
                        Tolak Request
                      </Button>
                      <Button
                        hidden={
                          dataDetail?.vaccination_certificate_request
                            ?.status === 'requested'
                        }
                        loading={isSubmitting}
                        size="large"
                        type="primary"
                        onClick={() => setIsShowModalSendCertificate(true)}
                      >
                        Kirim Sertifikat
                      </Button>
                      <Button
                        hidden={
                          dataDetail?.vaccination_certificate_request
                            ?.status === 'waiting_for_signing'
                        }
                        loading={isSubmitting}
                        size="large"
                        type="primary"
                        onClick={onDownloadCertificate}
                      >
                        Download & Verifikasi
                      </Button>
                    </Space>
                  </Col>
                )}
            </Row>

            <Spacer height={24} />
            <Spacer height={32} />

            <Title level={4} style={{ margin: 0, textAlign: 'left' }}>
              Informasi Anak
            </Title>

            <Spacer height={20} />

            <Row gutter={[20, 20]}>
              {childDetail?.map((item) => (
                <>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                    span={5}
                  >
                    <Text style={{ textAlign: 'left' }}>{item?.label}</Text>
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                    span={1}
                  >
                    <Text>:</Text>
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                    span={18}
                  >
                    <Text style={{ textTransform: 'capitalize' }}>
                      {item?.value === 'waiting_for_signing'
                        ? 'waiting for signing'
                        : item?.value}
                    </Text>
                  </Col>
                </>
              ))}
            </Row>

            <Spacer height={20} />

            <Divider plain />

            <Spacer height={20} />

            <Title level={4} style={{ margin: 0, textAlign: 'left' }}>
              Informasi Orang Tua
            </Title>

            <Spacer height={20} />

            <Row gutter={[20, 20]}>
              {parentsDetail?.map((item) => (
                <>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                    span={5}
                  >
                    <Text style={{ textAlign: 'left' }}>{item.label}</Text>
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                    span={1}
                  >
                    <Text>:</Text>
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                    span={18}
                  >
                    <Text style={{ textTransform: 'capitalize' }}>
                      {item.value}
                    </Text>
                  </Col>
                </>
              ))}
            </Row>

            <Spacer height={20} />

            <Divider plain />

            <Spacer height={20} />

            <Title level={4} style={{ margin: 0, textAlign: 'left' }}>
              Riwayat Vaksin
            </Title>

            <Spacer height={28} />

            <Row>
              <Col span={24}>
                <TableHistory
                  isLoading={isLoading}
                  dataSource={finishedVaccinations || []}
                />
              </Col>
            </Row>
          </div>
        </div>
      </LoadingOverlay>
      <Modal
        open={isShowReasonModal}
        onOk={() => {}}
        zIndex={9999999}
        className="modal-input-reason"
        onCancel={() => setIsShowReasonModal(false)}
        footer={null}
      >
        <div className="modal-reason-content-wrapper">
          {isShowFormReason ? (
            <>
              <Title level={3}>Alasan Penolakan</Title>
              <p className="modal-reason-description">
                Anda akan menolak permintaan sertifikat vaksinasi atas nama{' '}
                <span>
                  {dataDetail?.vaccination_certificate_request?.child
                    ?.fullname || ''}
                </span>
                , silahkan masukkan alasan penolakan.
              </p>
              <Input
                size="small"
                placeholder="Alasan Penolakan"
                style={{ borderRadius: '10px', padding: '8px 16px' }}
                value={reason}
                onChange={(e) => setReason(e?.target?.value || '')}
              />
              <Spacer height={20} />
              <Button
                style={{ width: '100%', height: 40, borderRadius: 8 }}
                type="primary"
                loading={isSubmitting}
                onClick={onRejectRequest}
              >
                Submit
              </Button>
            </>
          ) : (
            <>
              <div className="img-reason-wrapper">
                <img src={ICFailed} alt="ic-failed.png" />
              </div>
              <Spacer height={24} />
              <Title level={3}>Request Ditolak</Title>
              <p className="modal-reason-description">
                Request Sertifikat Vaksin An.{' '}
                {dataDetail?.vaccination_certificate_request?.child?.fullname ||
                  ''}{' '}
                ditolak!
              </p>
            </>
          )}
        </div>
      </Modal>

      <Modal
        open={isShowModalSuccessSend}
        onOk={() => {}}
        zIndex={9999999}
        className="modal-success-send-certificate"
        onCancel={() => setIsShowModalSuccessSend(false)}
        footer={null}
      >
        <div className="modal-success-send-content-wrapper">
          <>
            <div className="img-success-send-wrapper">
              <img src={ICSuccess} alt="ic-success.png" />
            </div>
            <Spacer height={24} />
            <Title level={4}>Sertifikat berhasil dikirim</Title>
            <Button
              type="primary"
              size="large"
              style={{
                width: '100%',
                paddingLeft: 20,
                paddingRight: 20,
                border: 'none',
              }}
              onClick={() => setIsShowModalSuccessSend(false)}
            >
              Kembali
            </Button>
          </>
        </div>
      </Modal>

      <Modal
        open={isShowModalSendCertificate}
        onOk={() => {}}
        zIndex={9999999}
        className="modal-upload-certificate"
        onCancel={() => setIsShowModalSendCertificate(false)}
        footer={null}
      >
        <div className="modal-upload-certificate-wrapper">
          <>
            <div className="modal-upload-certificate-image">
              <img src={ICUpload} alt="ic-success.png" />
            </div>
            <Spacer height={32} />
            <p className="modal-upload-certificate-title">Upload Sertifikat</p>
            <Spacer height={22} />
            <p className="modal-upload-certificate-desc">
              Silahkan upload sertifikat vaksin yang telah diverifikasi untuk
              anak terkait
            </p>

            <Spacer height={24} />
            <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
              {!!fileCertificate?.length ? (
                <div className="">
                  <p className="modal-upload-drag-certificate-desc">
                    File yang diupload
                  </p>
                  <Spacer height={14} />
                  <div className="list-file-uploaded-wrapper">
                    <div
                      style={{ display: 'flex', gap: 16, alignItems: 'center' }}
                    >
                      <div style={{ width: 46, height: 46 }}>
                        <img src={ICFile} alt={ICFile} />
                      </div>
                      <div className="">
                        <p className="list-file-uploaded-name">
                          {fileCertificate?.[0]?.name}
                        </p>
                        <p className="modal-upload-drag-certificate-desc">
                          {Math.ceil(fileCertificate?.[0]?.size / 1024)}kb
                        </p>
                      </div>
                    </div>
                    <Button
                      type="text"
                      shape="circle"
                      danger
                      icon={<CloseCircleFilled />}
                      onClick={() => setFileCertificate(null)}
                    />
                  </div>
                </div>
              ) : (
                <Dragger
                  accept=".pdf"
                  className="modal-upload-certificate-drag-wrapper"
                  onChange={(info) => {
                    if (info?.file?.type !== 'application/pdf') {
                      message.error(`${info?.file?.name} is not a pdf`);
                      return false;
                    } else {
                      if (!!info?.fileList?.length) {
                        return setFileCertificate(info?.fileList);
                      }
                      return message.error('Gagal upload certificate !!');
                    }
                  }}
                  onDrop={(e) => {
                    const fileUploaded = e?.dataTransfer?.files;
                    if (!!fileUploaded?.length) {
                      if (fileUploaded?.[0]?.type !== 'application/pdf') {
                        message.error(
                          `${fileUploaded?.[0]?.name} is not a pdf`
                        );
                        return false;
                      }
                      return setFileCertificate(fileUploaded);
                    }
                    return message.error('Gagal upload certificate !!');
                  }}
                >
                  <p className="modal-upload-drag-certificate-title">
                    Drag & Drop File Sertifikat Disini
                  </p>
                  <Spacer height={16} />
                  <p className="modal-upload-drag-certificate-desc">atau</p>
                  <Spacer height={16} />
                  <Button type="primary">Pilih File</Button>
                </Dragger>
              )}
            </div>
            <Spacer height={43} />

            <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
              <Button
                disabled={!fileCertificate}
                loading={isSubmitting}
                type="primary"
                size="large"
                style={{
                  width: '100%',
                  paddingLeft: 20,
                  paddingRight: 20,
                  border: 'none',
                }}
                onClick={onSendCertificate}
              >
                Kirim Sertifikat
              </Button>
            </div>
          </>
        </div>
      </Modal>

      <div style={{ display: 'none' }}>
        <div ref={reportTemplateRef}>
          <TemplateCertificate data={certificateData} />
        </div>
      </div>
    </div>
  );
};
